import React, { HTMLAttributes } from 'react';
import { ButtonTextContainer } from './styles';

interface IProps {
  buttonText: string;
  color: string;
  type?: 'button' | 'submit' | 'reset';
}

const ButtonText: React.FC<IProps & HTMLAttributes<HTMLButtonElement>> = ({
  buttonText,
  color,
  type,
  ...props
}) => {
  return (
    <ButtonTextContainer color={color} type={type} {...props}>
      {buttonText}
    </ButtonTextContainer>
  );
};

export default ButtonText;
