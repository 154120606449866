import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IoMdArrowBack } from 'react-icons/io';
import { Link, Navigate } from 'react-router-dom';
import Button from '../../components/Button';
import Select2 from '../../components/inputs/Select2';
import Textfield from '../../components/inputs/Textfield';
import ChoosePlan from '../ChoosePlan';
import { SignUpStyled } from './styles';

export type ISignUpInputs = {
  username: string;
  email: string;
  password: string;
  passwordConfirm: string;
  profession: string;
  foundUsThrough: string;
  otherWay: string;
};

const SignUp: React.FC = () => {
  const [signUpStep, setSignUpStep] = useState<number>(1);
  const [signUpData, setSignUpData] = useState<ISignUpInputs>(
    {} as ISignUpInputs
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ISignUpInputs>();
  const onSubmitStepOne: SubmitHandler<ISignUpInputs> = (data) => {
    setSignUpData(data);
    setSignUpStep(2);
    window.scrollTo(0, 0);
  };

  const options = [
    {
      optionName: 'Instagram',
      optionValue: 'instagram',
    },
    {
      optionName: 'Facebook',
      optionValue: 'facebook',
    },
    {
      optionName: 'LinkedIn',
      optionValue: 'linkedin',
    },
    {
      optionName: 'TikTok',
      optionValue: 'tiktok',
    },
    {
      optionName: 'Google',
      optionValue: 'google',
    },
    {
      optionName: 'Outros meios',
      optionValue: 'outros meios',
    },
  ];

  if (signUpStep === 1) {
    return (
      <SignUpStyled>
        <div className="content">
          <span className="lamp-idea" />
          <Link to="/sign-in">
            <span className="return-page">
              <IoMdArrowBack size={24} />
              <span className="return-page-text">voltar</span>
            </span>
          </Link>
          <div className="texts-box">
            <h2 className="heading-2">
              Comece a criar de um jeito muito mais leve, produtivo e original.
            </h2>
            <p className="subtitle">
              Crie sua conta para ter acesso a ferramentas que irão
              potencializar seu processo criativo.
            </p>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmitStepOne)}>
            <Textfield
              label="Qual a sua profissão?"
              type="text"
              placeholder="Ex: Publicitário"
              error={errors.profession?.message}
              {...register('profession', {
                required: 'O campo é requerido.',
              })}
            />
            <Select2
              setValue={setValue}
              textLabel="Como você conheceu o site?"
              error={errors.foundUsThrough?.message}
              options={options.map((marketSegment) => {
                return {
                  optionName: marketSegment.optionName,
                  optionValue: marketSegment.optionValue,
                };
              })}
              {...register('foundUsThrough', {
                required: 'O campo é requerido.',
              })}
            />
            {watch('foundUsThrough') === 'outros meios' && (
              <Textfield
                label="Qual outro meio você conheceu o site?"
                type="text"
                placeholder="Ex: Amigos"
                error={errors.otherWay?.message}
                {...register('otherWay', {
                  required: 'O campo é requerido.',
                })}
              />
            )}

            <Textfield
              label="Qual o seu e-mail?"
              type="text"
              placeholder="Insira o seu melhor e-mail"
              error={errors.email?.message}
              {...register('email', {
                required: 'O campo é requerido.',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Digite um e-mail válido',
                },
              })}
            />
            <Textfield
              label="Dê um nome ao seu usuário"
              type="text"
              error={errors.username?.message}
              {...register('username', { required: 'O campo é requerido.' })}
            />
            <Textfield
              label="Senha"
              type="password"
              error={errors.passwordConfirm?.message}
              {...register('password', {
                required: 'O campo é requerido.',
                maxLength: {
                  value: 40,
                  message: 'Limite máximo de 40 caracteres',
                },
                minLength: {
                  value: 8,
                  message: 'Mínimo de 08 caracteres',
                },
              })}
            />
            <Textfield
              label="Confirmar Senha"
              type="password"
              error={errors.passwordConfirm?.message}
              {...register('passwordConfirm', {
                required: 'O campo é requerido',
                maxLength: {
                  value: 40,
                  message: 'Limite máximo de 40 caracteres',
                },
                minLength: {
                  value: 8,
                  message: 'Mínimo de 08 caracteres',
                },
                validate: (confirmPassword: string) => {
                  if (watch('password') !== confirmPassword) {
                    return 'As senhas não conferem';
                  }
                },
              })}
            />
            <Button
              buttonText="Selecionar plano"
              isFullWidth
              className="button"
              variant="contained"
              type="submit"
            />
          </form>
          <p className="sign-in">
            Já possui uma conta?
            <Link to="/sign-in">
              <span className="link">Entrar</span>
            </Link>
          </p>
        </div>
      </SignUpStyled>
    );
  } else if (signUpStep === 2) {
    return <ChoosePlan signUpData={signUpData} />;
  } else {
    return <Navigate to="/sign-in" />;
  }
};

export default SignUp;
