import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { IStyledProps, ProgressBarStyled } from './styles';

interface IProps extends IStyledProps {
  description: string;
}

const ProgressBar: React.FC<IProps> = ({ progress, description }) => {
  return (
    <ProgressBarStyled progress={progress}>
      <div className="content">
        <div className="box-content">
          <p className="paragraph">{description}</p>
          <div className="progress"></div>
        </div>
      </div>
    </ProgressBarStyled>
  );
};

export default ProgressBar;
