import React, { useCallback, useEffect, useState, HTMLAttributes } from 'react';
import { BreadCrumbContainer } from './styles';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { useTheme } from 'styled-components';

const BreadCrumb: React.FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
  const [routeSplitted, setRouteSplitted] = useState<string[]>([]);
  const { pathname } = useLocation();
  const theme = useTheme();
  let routeConcat = '';

  const routeSplit = useCallback(() => {
    let route = pathname.split('/');
    setRouteSplitted(route);
  }, [pathname]);

  useEffect(() => {
    routeSplit();
  }, [pathname, routeSplit]);

  return (
    <BreadCrumbContainer {...props}>
      {routeSplitted.map((route, index) => {
        if (!!route) {
          routeConcat = routeConcat.concat('/', route);
        }

        return (
          index > 1 && (
            <React.Fragment key={index}>
              <Link to={`${routeConcat}`} className="breadcrumb-link">
                <span
                  className="breadcrumb-item"
                  data-set={routeConcat}
                  style={{
                    fontWeight:
                      index + 1 === routeSplitted.length
                        ? theme.typeFaceWeight.bold
                        : theme.typeFaceWeight.regular,
                  }}
                >
                  {route.replaceAll('-', ' ')}
                </span>
              </Link>
              {index + 1 < routeSplitted.length && (
                <React.Fragment>
                  &nbsp;
                  <MdOutlineArrowForwardIos size={20} />
                  &nbsp;
                </React.Fragment>
              )}
            </React.Fragment>
          )
        );
      })}
    </BreadCrumbContainer>
  );
};

export default BreadCrumb;
