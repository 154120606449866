import styled from 'styled-components';

export const SectionToolsStyled = styled.section`
  ${(props) => props.theme.mixins.containerGridDefault};
  padding: 8rem 0;

  @media ${(props) => props.theme.medias.laptopSmall} {
    padding: 8rem 0 4rem;
  }

  .content {
    grid-column: center-start / center-end;
  }

  .heading-2 {
    text-align: center;
    color: ${(props) => props.theme.pallete.colors.common.black};
  }

  .subtitle {
    font-size: 2rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 4.8rem;
  }

  .grid-cards {
    display: grid;
    grid-template-columns: minmax(25rem, 1fr) minmax(25rem, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      grid-template-columns: 1fr;
    }
  }

  .card {
    display: flex;
    align-items: center;
    gap: 3rem;
    padding: 3rem;
    border-radius: 2rem;
    background-color: ${(props) =>
      props.theme.pallete.colors.backgrounds.paper};
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};
    }
  }

  .icon {
  }

  .card-text {
  }

  .card-text-title-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.8rem;
    margin-bottom: 1.6rem;

    h3 {
      margin: 0;
    }
  }

  .card-text-type {
    width: max-content;
    padding: 0.6rem 1.4rem;
    border-radius: 100px;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    white-space: nowrap;

    &--brainstorming {
      margin-bottom: 1rem;
      display: block;
    }

    &--ia {
      background: ${(props) =>
        props.theme.pallete.colors.brandPrimary.yellow[2]};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
    }

    &--comming-soon {
      background: ${(props) =>
        props.theme.pallete.colors.brandPrimary.gray[13]};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    }
  }
`;
