import React, { useCallback, useContext, useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../components/Button';
import ProjectWall from '../../components/ProjectWall';
import ShowYourIdeaSection from '../../components/ShowYourIdeaSection';
import LightboxWithoutLogin from '../../components/lightboxes/LightboxWithoutLogin';
import Megaphone from '../../components/svgs/Megaphone';
import AuthContext from '../../contexts/AuthContext';
import ToastfyContext from '../../contexts/ToastfyContext';
import { listCreativeChallengeService } from '../../services/creativeChallenge.service';
import { IListCreativeChallengeData } from '../../services/creativeChallenge.types';
import { listCreativeChallengeTagService } from '../../services/creativeChallengeTag.service';
import { IListCreativeChallengeTagData } from '../../services/creativeChallengeTag.types';
import EmailBanner from './EmailBanner';
import {
  ButtonFilter,
  // CreativeChallengeIAContent,
  CreativeChallengesStyled,
} from './styles';

const CreativeChallenges: React.FC = () => {
  const [isSignPlanModal, setIsSignPlanModal] = useState<boolean>(false);
  const [currentTagChoose, setCurrentTagChoose] = useState<string>('all');

  const [creativeChallenge, setCreativeChallenge] = useState<
    IListCreativeChallengeData[]
  >([]);
  const [creativeChallengeTag, setCreativeChallengeTag] = useState<
    IListCreativeChallengeTagData[]
  >([]);

  const theme = useTheme();
  const { handleToastfy } = useContext(ToastfyContext);

  const { signOut, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const getListCreativeChallenge = useCallback(async () => {
    await listCreativeChallengeService({
      order: 'DESC',
      orderBy: 'createdAt',
      tag: currentTagChoose === 'all' ? '' : currentTagChoose,
    })
      .then((response) => {
        setCreativeChallenge(response.data);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, currentTagChoose, signOut]);

  const getListCreativeChallengeTag = useCallback(async () => {
    await listCreativeChallengeTagService({
      order: 'DESC',
      orderBy: 'createdAt',
      isActive: true,
    })
      .then((response) => {
        setCreativeChallengeTag(response.data);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    getListCreativeChallenge();
  }, [getListCreativeChallenge]);

  useEffect(() => {
    getListCreativeChallengeTag();
  }, [getListCreativeChallengeTag]);

  return (
    <CreativeChallengesStyled>
      <header className="creative-challenges-header">
        <div className="box-image box-image--1"></div>

        <div className="creative-challenges-header-text">
          <h2 className="creative-challenges-heading-2">
            Crie ideias com potencial transformador
          </h2>
          <p className="creative-challenges-sub-title">
            Podemos promover mudanças usando nossa criatividade. Use um briefing
            inspirador para criar ideias com propósito.
          </p>
        </div>
      </header>

      <section className="creative-challenges-section-1">
        <div className="creative-container-reason-create">
          <div className="text">
            <span>#RazõesParaCriar</span>
            <p>
              Um portfólio com projetos de impacto social é mais valorizado...
            </p>
          </div>
          <div className="box-image box-image--1"></div>
        </div>

        <div className="creative-container-reason-create">
          <div className="box-image box-image--2"></div>
          <div className="text">
            <p>
              ....e ainda fortalece o ecossistema de ideias transformadoras.
            </p>
          </div>
        </div>
      </section>

      <EmailBanner
        setIsSignPlanModal={setIsSignPlanModal}
        isAuthenticated={isAuthenticated}
      />

      <section className="creative-challenges-section-2">
        <h3 className="creative-challenges-heading-3">Escolha um desafio</h3>
        <div className="creative-challenges-filter">
          <ButtonFilter
            isActive={currentTagChoose === 'all'}
            onClick={() => {
              setCurrentTagChoose('all');
            }}
          >
            Todos
          </ButtonFilter>
          {creativeChallengeTag.map((value, index) => {
            return (
              <ButtonFilter
                isActive={currentTagChoose === value.label}
                key={index}
                onClick={() => {
                  setCurrentTagChoose(value.label);
                }}
              >
                {value.label}
              </ButtonFilter>
            );
          })}
        </div>
        <div className="challenges">
          {creativeChallenge.map((value, index) => {
            return (
              <Link
                to={isAuthenticated ? value.link : '#'}
                onClick={() => {
                  if (!isAuthenticated) {
                    setIsSignPlanModal((prev) => !prev);
                  }
                }}
                target={isAuthenticated ? '_blank' : '_self'}
                key={index}
              >
                <div>
                  <span>{value.label}</span>
                  {value.award.trim() !== '' ? (
                    <span>{value.award}</span>
                  ) : null}
                </div>
              </Link>
            );
          })}
        </div>
      </section>

      {isSignPlanModal && (
        <LightboxWithoutLogin
          handleOpenModal={() => setIsSignPlanModal((prev) => !prev)}
          image={<Megaphone />}
        >
          <h3>Cadastre-se para usar nossas ferramentas</h3>
          <p>
            Para utilizar nossas ferramentas criativas, você precisa ter uma
            conta na nossa plataforma. Faça agora mesmo seu cadastro e comece a
            potencializar seu processo criativo.
          </p>
          <Button
            buttonText="Fazer cadastro gratuito"
            variant="contained"
            type="button"
            className="whithout-login-button"
            onClick={() => navigate('/sign-up')}
            customColors={{
              backgroundColor: theme.pallete.colors.common.white,
              hoverBackgroundColor: theme.pallete.colors.common.white,
              hoverTextColor: theme.pallete.colors.common.black,
              textColor: theme.pallete.colors.common.black,
            }}
          />
        </LightboxWithoutLogin>
      )}

      <ShowYourIdeaSection />
      <ProjectWall />
    </CreativeChallengesStyled>
  );
};

export default CreativeChallenges;
