import styled from 'styled-components';

export const MenuMobileContainer = styled.div`
  display: none;
  user-select: none;

  @media ${(props) => props.theme.medias.laptopSmall} {
    display: block;
  }

  .menu-mobile-checkbox {
    display: none;
  }

  .menu-mobile-button {
    display: none;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 6rem;
    width: 6rem;
    text-align: center;
    transition: all 3s ease-in-out;
    z-index: 1500;
    cursor: pointer;

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: flex;
    }
  }

  .menu-mobile-button:hover .menu-mobile-icon::before {
    top: -1rem;
  }

  .menu-mobile-button:hover .menu-mobile-icon::after {
    top: 1rem;
  }

  .menu-mobile-checkbox:checked
    ~ .menu-mobile-button
    .menu-mobile-icon::before {
    top: 0;
    left: 0;
    transform: rotate(135deg);
  }

  .menu-mobile-checkbox:checked ~ .menu-mobile-button .menu-mobile-icon::after {
    top: 0;
    left: 0;
    transform: rotate(-135deg);
  }

  .menu-mobile-checkbox:checked ~ .menu-mobile-button .menu-mobile-icon {
    background-color: transparent;
  }

  .menu-mobile-icon {
    position: relative;
    transition: all 0.2s ease-in-out;
    pointer-events: none;

    &,
    &::before,
    &::after {
      width: 4rem;
      height: 3px;
      border-radius: 100px;
      background-color: ${(props) => props.theme.pallete.colors.common.black};
      display: inline-block;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.2s ease-in-out;
      transform-origin: center;
    }

    &::before {
      top: -0.8rem;
    }

    &::after {
      top: 0.8rem;
    }
  }

  .nav-mobile {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -120%;
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    z-index: 1000;
    transition: all 0.2s ease-in-out;
  }

  .menu-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    height: 100vh;
    width: 100%;
    gap: 4.8rem;
    left: -120%;
    position: relative;
    transition: all 0.2s ease-in-out;
  }

  .menu-mobile-checkbox:checked ~ .nav-mobile {
    left: 0;
  }

  .menu-mobile-checkbox:checked ~ .nav-mobile .menu-mobile {
    left: 0;
  }
`;
