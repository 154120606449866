import styled from 'styled-components';

interface IButtonTextContainer {
  color: string;
}

export const ButtonTextContainer = styled.button<IButtonTextContainer>`
  position: relative;
  background: transparent;
  outline: 0;
  border: none;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
  padding: 0.8rem 2rem;
  width: max-content;
  color: ${(props) => props.color};
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.8rem;
    left: 0;
    height: 3px;
    width: 0.001%;
    background-color: currentColor;
    transition: width 0.4s cubic-bezier(1, 0, 0, 1);
  }

  &:hover::after {
    width: 100%;
  }
`;
