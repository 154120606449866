import React from 'react';
import { ArrowProps } from 'react-multi-carousel';
import { MdArrowForwardIos } from 'react-icons/md';
import { CustomArrowRightContainer } from './styles';

const CustomRightArrow: React.FC<ArrowProps> = ({ onClick, carouselState }) => {
  return (
    <CustomArrowRightContainer className="arrow-right" onClick={onClick}>
      <MdArrowForwardIos size={48} />
    </CustomArrowRightContainer>
  );
};

export default CustomRightArrow;
