import styled from 'styled-components';

export const CustomArrowRightContainer = styled.button`
  position: absolute;
  border: 0;
  background: transparent;
  right: 1.6rem;
  cursor: pointer;

  svg {
    filter: drop-shadow(0px 0px 2px white);
  }
`;
