import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import DashboardLayoutTopBar from '../DashboardLayout/DashboardLayoutTopBar';
import DashboardLayoutSideBarMenu from '../DashboardLayout/DashboardLayoutSideBarMenu';
import { CreateContentsLayoutContainer } from './styles';
import AuthContext from '../../../../contexts/AuthContext';

const CreateContentsLayout: React.FC = () => {
  const year = new Date().getFullYear();
  const { user } = useContext(AuthContext);
  return (
    <CreateContentsLayoutContainer isAdmin={user?.role === 'admin'}>
      <DashboardLayoutTopBar />
      {user?.role === 'admin' && <DashboardLayoutSideBarMenu />}
      <div className="see-edit-contents-content">
        <div className="see-edit-contents-children">
          <Outlet />
        </div>
        <footer className="footer-see-edit-contents">
          <p className="footer-see-edit-contents-paragraph">
            Todos os direitos reservados. Se você é dono de uma
            metodologia/ferramenta, entre em contato.
          </p>
          <span className="footer-see-edit-contents-copy">
            Copyright {year} &copy; Criativistas.
          </span>
        </footer>
      </div>
    </CreateContentsLayoutContainer>
  );
};

export default CreateContentsLayout;
