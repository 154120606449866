import styled from 'styled-components';

export const BrainstormingRoomsListContainer = styled.div`
  .heading-2 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};

    &--margin {
    }
  }

  .brainstorming-list-room-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }

  .confirm-delete-button-group {
    display: flex;
    gap: 3rem;
    justify-content: center;
    margin-top: 3rem;
  }
`;
