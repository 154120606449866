import styled from 'styled-components';

interface IDashboardTabProps {
  isActive: boolean;
}

interface IDashboardTabContentProps {
  backgroundImageLink: string;
}

export const DashboardTabsContainer = styled.div`
  .dashboard-tabs-container {
    display: flex;
    gap: 0.8rem;
    justify-content: space-between;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
    }
  }

  .dashboard-tabs-credits-box {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1.6rem;
    background: transparent;
    white-space: nowrap;
    user-select: none;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }

  .dashboard-tabs-you-have {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
  }

  .dashboard-tabs-you-credits {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    font-size: 1.6rem;
  }

  .tabs {
    display: flex;
    flex-wrap: wrap;
    z-index: 5;
    justify-content: center;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-top: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    border-left: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    border-right: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    border-bottom: 1px solid
      ${(props) => props.theme.pallete.colors.common.white};
    border-style: solid;
    background: ${(props) => props.theme.pallete.colors.common.white};
  }
`;

export const DashboardTab = styled.div<IDashboardTabProps>`
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.isActive
      ? props.theme.pallete.colors.brandPrimary.yellow[1]
      : 'transparent'};
  border-radius: 2rem;
  border: ${(props) =>
    props.isActive
      ? `1px solid ${props.theme.pallete.colors.brandPrimary.yellow[3]}`
      : 'transparent'};
  padding: ${(props) => (props.isActive ? '0.8rem 1.6rem 0.8rem 0' : '1.6rem')};
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  .tab-svg {
    width: 6rem;
  }

  .tab-text {
    display: ${(props) => (props.isActive ? 'inline-block' : 'none')};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[11]};
  }
`;

export const DashboardTabContent = styled.div<IDashboardTabContentProps>`
  min-height: 30rem;
  margin-top: -2px;
  background: ${(props) => props.theme.pallete.colors.common.white};
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border: 1px solid
    ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
  z-index: 4;
  padding: 3rem;

  display: flex;
  gap: 3rem;
  align-items: center;

  @media ${(props) => props.theme.medias.laptopSmall} {
    flex-direction: column-reverse;
    text-align: center;
    border-top-left-radius: 0rem;
  }

  .dashboard-tabs-background {
    background-image: ${(props) => `url(${props.backgroundImageLink})`};
    min-height: 30rem;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &--box-shadow {
      filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
    }
  }

  .dashboard-tabs-content-texts {
    width: 100%;
  }

  .dashboard-tabs-content-heading-3 {
    margin-bottom: 1.2rem;
    font-size: 2.4rem;
  }

  .dashboard-tabs-content-paragraph {
    margin-bottom: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-size: 1.4rem;
  }
`;
