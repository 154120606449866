import styled, { css } from 'styled-components';

interface ITabsRouteContainerProps {
  isCenteredInMobile: boolean;
}

interface ITabProps {
  isRouteActive?: boolean;
}

export const TabsRouteContainer = styled.div<ITabsRouteContainerProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  column-gap: 1.2rem;
  row-gap: 3rem;

  a {
    text-decoration: none;
  }

  ${(props) =>
    props.isCenteredInMobile &&
    css`
      @media ${(props) => props.theme.medias.mobile} {
        justify-content: center;
      }
    `}
`;

export const Tab = styled.span<ITabProps>`
  position: relative;
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  padding: 0.4rem 2rem;
  width: max-content;
  display: inline-block;
  /* border-bottom: 3px solid
      ${(props) => props.theme.pallete.colors.primary.main}; */
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.8rem;
    left: 0;
    height: 3px;
    width: 0.001%;
    background-color: ${(props) => props.theme.pallete.colors.primary.main};
    transition: width 0.4s cubic-bezier(1, 0, 0, 1);

    ${(props) =>
      props.isRouteActive &&
      css`
        width: 100%;
      `}
  }
`;
