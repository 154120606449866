import styled from 'styled-components';

export const CreateNewContentContainer = styled.div`
  .heading-2 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  .form-create-option {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }

  .form-create-item-1 {
    grid-column: 1 / 2;

    @media ${(props) => props.theme.medias.laptopSmall} {
      grid-column: 1 / 3;
    }
  }

  .form-create-item-2 {
    grid-column: 2 / 3;

    @media ${(props) => props.theme.medias.laptopSmall} {
      grid-column: 1 / 3;
    }
  }

  .form-create-item-3 {
    grid-column: 1 / 3;
    text-align: center;
    margin-top: 1.6rem;
  }
`;
