import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import ToastfyContext from '../../contexts/ToastfyContext';
import { getGalleryProjectsService } from '../../services/creativeProjects.service';
import { IListCreativeProjectsData } from '../../services/creativeProjects.types';
import Spinner from '../loadings/Spinner';
import { ProjectWallContainer } from './styles';
import ImagePreview from '../lightboxes/ImagePreview';

const ProjectWall: React.FC = () => {
  const [creativeProjects, setCreativeProjects] = useState<
    IListCreativeProjectsData[]
  >([]);
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [linkImage, setLinkImage] = useState<string>('');
  const [isCreativeProjectsLoading, setIsCreativeProjectsLoading] =
    useState(true);
  const { signOut, isAuthenticated } = useContext(AuthContext);
  const { handleToastfy } = useContext(ToastfyContext);
  const navigate = useNavigate();
  const imagebasePath = process.env.REACT_APP_URL_IMAGE_BASE;

  const listGalleryProjects = useCallback(async () => {
    setIsCreativeProjectsLoading(true);
    await getGalleryProjectsService({ isActive: true, published: true })
      .then((response) => {
        setCreativeProjects(
          response.data.result
            .sort((a, b) => {
              return (
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime()
              );
            })
            .slice(0, 6)
        );
        setIsCreativeProjectsLoading(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    listGalleryProjects();
  }, [setCreativeProjects, isAuthenticated, listGalleryProjects]);

  return (
    <ProjectWallContainer projectsLength={creativeProjects.length}>
      {isCreativeProjectsLoading ? (
        <div className="projects-wall-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <React.Fragment>
          <div className="project-wall-texts">
            <h3 className="project-wall-heading-3">
              Mural de Projetos dos Criativistas
            </h3>
            <p className="project-wall-paragraph">
              Quer saber o que a galera está criando usando nossas ferramentas?
              Dá uma olhada no nosso mural de projetos. Aproveita para enviar e
              divulgar o seu trabalho também.
            </p>
          </div>
          {!!creativeProjects.length ? (
            <div className="project-wall-grid">
              {creativeProjects.map((project, index) => {
                return (
                  <div
                    key={project._id}
                    className={`project-wall-grid-item project-wall-grid-item--${
                      index + 1
                    }`}
                    style={{
                      backgroundImage: `url(${imagebasePath}/${project.file})`,
                    }}
                  >
                    <div className="project-wall-grid-item-content">
                      <p className="project-wall-grid-item-paragraph">
                        Projeto feito pela(o)s Criativista(o)s:
                      </p>
                      <p className="project-wall-grid-item-name">
                        {project.creators}
                      </p>
                      <div className="project-wall-grid-social-container">
                        <span className="project-wall-grid-social-title">
                          Rede Social:
                        </span>
                        <a
                          className="project-wall-grid-social-link"
                          href={project.socialMedia}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {project.socialMedia}
                        </a>
                      </div>
                      <div className="project-wall-grid-social-container">
                        <span className="project-wall-grid-social-title">
                          Portfólio:
                        </span>
                        <a
                          className="project-wall-grid-social-link"
                          href={project.portfolio}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {project.portfolio}
                        </a>
                      </div>
                      <span
                        className="project-wall-see-image"
                        onClick={() => {
                          setIsOpenImage((prev) => !prev);
                          setLinkImage(`${imagebasePath}/${project.file}`);
                        }}
                      >
                        Ver projeto
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="paragraph-any-projects">
              Ainda não há projetos enviados!
            </p>
          )}
        </React.Fragment>
      )}
      {isOpenImage && (
        <ImagePreview linkImage={linkImage} setIsOpenImage={setIsOpenImage} />
      )}
    </ProjectWallContainer>
  );
};

export default ProjectWall;
