import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThermometerIdeasStyled } from './styles';
import ProgressBar from './ProgressBar';
import BannerThermometer from './BannerThermometer';
import Quiz from './Quiz';
import ResultThermometer from './Result';
import { useForm } from 'react-hook-form';
import AuthContext from '../../contexts/AuthContext';
import LightboxWithoutLogin from '../../components/lightboxes/LightboxWithoutLogin';
import Megaphone from '../../components/svgs/Megaphone';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import GeneratedFromIA from './GeneratedFromIA';
import LightBox from '../../components/lightboxes/LightBox';

interface IInputFields {
  name: string;
  description: string;

  question: [
    question1: string,
    question2: string,
    question3: string,
    question4: string,
    question5: string,
    question6: string,
    question7: string,
    question8: string
  ];

  qtdAnswerYes: number;
}

const ThermometerIdeas: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IInputFields>({
    defaultValues: {
      name: '',
      description: '',
      question: ['sim', 'sim', 'sim', 'sim', 'sim', 'sim', 'sim', 'sim'],
      qtdAnswerYes: -1,
    },
  });

  const theme = useTheme();
  const [step, setStep] = useState<string>('banner');
  const [stepAnswer, setStepAnswer] = useState<number>(0);
  const [typeResult, setTypeResult] = useState<number>(4);
  const answer = watch(['name', 'description', 'question']);
  const [isSignPlanModal, setIsSignPlanModal] = useState<boolean>(false);
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const [generatedAnswers, setGeneratedAnswers] = useState<string>('');
  const [isGeneratedAnswersModal, setIsGeneratedAnswersModal] =
    useState<boolean>(false);
  const IARef = useRef<null | HTMLDivElement>(null);

  const [resultFinal, setResultFinal] = useState({
    name: '',
    description: '',

    question1: '',
    question2: '',
    question3: '',
    question4: '',
    question5: '',
    question6: '',
    question7: '',
    question8: '',
  });

  const scrollToIA = () =>
    !!IARef && !!IARef.current && IARef.current.scrollIntoView();

  const handleToDoThermometerAgain = () => {
    setStepAnswer(1);
    setStep('quiz');
  };

  useEffect(() => {
    setResultFinal({
      name: answer[0],
      description: answer[1],
      question1: answer[2][0],
      question2: answer[2][1],
      question3: answer[2][2],
      question4: answer[2][3],
      question5: answer[2][4],
      question6: answer[2][5],
      question7: answer[2][6],
      question8: answer[2][7],
    });
  }, [answer]);

  const handleStartQuiz = () => {
    if (isAuthenticated) {
      setStepAnswer(1);
      setStep('quiz');
      setIsGeneratedAnswersModal(false);
    } else {
      setIsSignPlanModal((prev) => !prev);
    }
  };

  const descriptionStep: Array<string> = [
    'Passo 1: Queremos conhecer sua ideia',
    'Passo 2: Objetivo e Diferencial',
    'Passo 3: Público-alvo',
    'Passo 4: Viabilidade',
    'Passo 5: Perguntas finais',
  ];

  return (
    <ThermometerIdeasStyled>
      {step === 'banner' ? (
        <BannerThermometer
          scrollToIA={scrollToIA}
          setGeneratedAnswers={setGeneratedAnswers}
          setIsGeneratedAnswersModal={setIsGeneratedAnswersModal}
          setIsSignPlanModal={setIsSignPlanModal}
          onClick={() => {
            handleStartQuiz();
          }}
        />
      ) : step === 'quiz' ? (
        <>
          <ProgressBar
            description={descriptionStep[stepAnswer - 1]}
            progress={stepAnswer}
          />
          <Quiz
            step={stepAnswer}
            onClick={() => setStepAnswer((step) => step + 1)}
            setTypeResult={setTypeResult}
            setStep={setStep}
            register={register}
            errors={errors}
            handleSubmit={handleSubmit}
            setValue={setValue}
            watch={watch}
          />
        </>
      ) : (
        <ResultThermometer
          type={typeResult}
          toDoAgain={handleToDoThermometerAgain}
          answer={resultFinal}
          scrollToIA={scrollToIA}
          setGeneratedAnswers={setGeneratedAnswers}
          setIsGeneratedAnswersModal={setIsGeneratedAnswersModal}
        />
      )}
      {isSignPlanModal && (
        <LightboxWithoutLogin
          handleOpenModal={() => setIsSignPlanModal((prev) => !prev)}
          image={<Megaphone />}
        >
          <h3>Cadastre-se para usar nossas ferramentas</h3>
          <p>
            Para utilizar nossas ferramentas criativas, você precisa ter uma
            conta na nossa plataforma. Faça agora mesmo seu cadastro e comece a
            potencializar seu processo criativo.
          </p>
          <Button
            buttonText="Fazer cadastro gratuito"
            variant="contained"
            type="button"
            className="whithout-login-button"
            onClick={() => navigate('/sign-up')}
            customColors={{
              backgroundColor: theme.pallete.colors.common.white,
              hoverBackgroundColor: theme.pallete.colors.common.white,
              hoverTextColor: theme.pallete.colors.common.black,
              textColor: theme.pallete.colors.common.black,
            }}
          />
        </LightboxWithoutLogin>
      )}
      <div ref={IARef}>
        {isGeneratedAnswersModal && (
          <GeneratedFromIA generatedAnswers={generatedAnswers} />
        )}
      </div>
    </ThermometerIdeasStyled>
  );
};

export default ThermometerIdeas;
