import React, { useEffect, useState } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import InstagramBanner from '../Home/InstagramBanner';
import { SuccessPaymentContainer } from './styles';

const SuccessPayment: React.FC = () => {
  const [email, setEmail] = useState('');
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const getParamsEmail = searchParams.get('email') || '';

    if (!getParamsEmail) {
      navigate('/sign-in');
    } else {
      setEmail(getParamsEmail);
    }
  }, [searchParams, navigate]);

  return (
    <SuccessPaymentContainer>
      <section className="content">
        <span className="lamp-idea" />
        <React.Fragment>
          <h2 className="heading-2">Obrigado! :)</h2>
          <div className="icon-box">
            <AiOutlineCheckCircle
              size={56}
              color={theme.pallete.colors.success.main}
            />
          </div>
          <p className="subtitle">
            Seu pagamento foi processado, agora verifique o e-mail enviado para{' '}
            <strong>{email}</strong> e valide sua conta. Após isso você terá
            acesso a ferramentas que irão potencializar seu processo criativo.
          </p>
        </React.Fragment>
      </section>
      <InstagramBanner />
    </SuccessPaymentContainer>
  );
};

export default SuccessPayment;
