import moment from 'moment';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../../../components/Button';
import DashboardConfigCard from '../../../../components/DashboardConfigCard';
import InviteCheckboxSelect from '../../../../components/checkboxes/InviteCheckboxSelect';
import Textfield from '../../../../components/inputs/Textfield';
import LightBox from '../../../../components/lightboxes/LightBox';
import Spinner from '../../../../components/loadings/Spinner';
import AuthContext from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
import {
  cancelEmailConfirmationService,
  createUserService,
  listEmailPendingService,
  listUserService,
  resendEmailConfirmationService,
} from '../../../../services/admin.service';
import { IListEmailPendingUser } from '../../../../services/admin.types';
import { IUser } from '../../../../services/user.types';
import MemberItem from './MemberItem';
import {
  InactiveUsersContainer,
  InvitePeopleContent,
  StaffMembersContainer,
} from './styles';
import SeparateLine from '../../../../components/SeparateLine';
import Select2 from '../../../../components/inputs/Select2';
import ConfirmLightBox from '../../../../components/lightboxes/ConfirmLightBox';

const StaffMembers: React.FC = () => {
  const [listUsers, setListUsers] = useState<IUser[]>([]);
  const [listInvites, setListInvites] = useState<IListEmailPendingUser[]>([]);
  const [isOpenInviteModal, setIsOpenInviteModal] = useState<boolean>(false);
  const [isOpenInactiveUsers, setIsOpenInactiveUsers] =
    useState<boolean>(false);
  const [isSendOrCancelLoading, setIsSendOrCancelLoading] =
    useState<boolean>(false);
  const [isLoadingStaff, setIsLoadingStaff] = useState<boolean>(true);
  const [isLoadingInvites, setIsLoadingInvites] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleSendInvite = async (email: string) => {
    setIsSendOrCancelLoading(true);
    await resendEmailConfirmationService(email)
      .then((response) => {
        handleToastfy({
          message: 'Convite enviado com sucesso!',
          type: 'success',
        });
        getListInvite();
        setIsSendOrCancelLoading(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsSendOrCancelLoading(false);
      });
  };

  const handleCancelInvite = async (email: string) => {
    setIsSendOrCancelLoading(true);
    await cancelEmailConfirmationService(email)
      .then((response) => {
        handleToastfy({
          message: 'Convite cancelado com sucesso!',
          type: 'success',
        });
        getListInvite();
        setIsSendOrCancelLoading(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
    setIsSendOrCancelLoading(false);
  };

  const checkExpirationDate = (date: Date) => {
    const dateNow = moment();
    const expirationDate = moment(date);

    return dateNow > expirationDate;
  };

  const getListUser = useCallback(async () => {
    setIsLoadingStaff(true);
    await listUserService({
      order: 'DESC',
      orderBy: 'createdAt',
      isActive: true,
    })
      .then((response) => {
        setListUsers(response.users);
        setIsLoadingStaff(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  const getListInvite = useCallback(async () => {
    setIsLoadingInvites(true);
    await listEmailPendingService()
      .then((response) => {
        setIsLoadingInvites(false);
        setListInvites(
          response.users.filter(
            (invite) => !!invite.user && !invite.user.isActive && invite
          )
        );
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    getListUser();
    getListInvite();
  }, [getListUser, getListInvite]);

  useEffect(() => {
    searchParams.get('lightbox') && setIsOpenInviteModal(true);
  }, [searchParams]);

  return (
    <StaffMembersContainer>
      <DashboardConfigCard>
        <div className="staff-members-block-text">
          <h3 className="staff-members-heading-3">Membros da equipe</h3>
          <p className="staff-members-paragraph">
            Adicione, visualize e edite todos as pessoas elencadas na sua equipe
            para colaborar com a manutenção da plataforma.
          </p>
          <div className="staff-members-button-group">
            <Button
              variant="contained"
              buttonText="Convidar Pessoas"
              onClick={() => setIsOpenInviteModal((prev) => !prev)}
            />
            <Button
              variant="contained"
              buttonText="Reativar usuários inativos"
              onClick={() => setIsOpenInactiveUsers((prev) => !prev)}
            />
          </div>
        </div>
        {isLoadingStaff ? (
          <div className="staff-members-spinner ">
            <Spinner size={100} />
          </div>
        ) : (
          <MemberItem listUsers={listUsers} getListUser={getListUser} />
        )}
        <div className="staff-members-block-text">
          <h3 className="staff-members-heading-3">Convites pendentes</h3>
          <p className="staff-members-paragraph">
            Adicione, visualize e edite todos as pessoas elencadas na sua equipe
            para colaborar com a manutenção da plataforma.
          </p>
        </div>
        <div className="grid-invite-list">
          {isLoadingInvites ? (
            <div className="staff-members-spinner ">
              <Spinner size={100} />
            </div>
          ) : (
            listInvites.map((invite) => {
              return (
                <div key={invite._id} className="invite-item">
                  <div className="invite-identity">
                    <div className="invite-identity-name-box">
                      <span className="invite-identity-name">
                        {invite.user.username}
                      </span>
                      <span
                        style={{
                          background: checkExpirationDate(
                            invite.tokenExpiration
                          )
                            ? theme.pallete.colors.brandPrimary.gray[5]
                            : theme.pallete.colors.brandPrimary.gray[7],
                        }}
                        className="invite-identity-status"
                      >
                        {checkExpirationDate(invite.tokenExpiration)
                          ? 'Expirado'
                          : 'Pendente'}
                      </span>
                    </div>
                    <span className="invite-identity-email">
                      {invite.user.email}
                    </span>
                  </div>
                  <Button
                    variant="contained"
                    buttonText="Reenviar convite"
                    customColors={{
                      backgroundColor: theme.pallete.colors.common.black,
                      hoverBackgroundColor:
                        theme.pallete.colors.brandPrimary.gray[9],
                      hoverTextColor: theme.pallete.colors.common.white,
                      textColor: theme.pallete.colors.common.white,
                    }}
                    onClick={() => {
                      handleSendInvite(invite.user.email);
                    }}
                    isLoading={isSendOrCancelLoading}
                  />
                  <Button
                    buttonText="Cancelar convite"
                    variant="outlined"
                    customColors={{
                      backgroundColor: theme.pallete.colors.brandPrimary.red[3],
                      hoverBackgroundColor:
                        theme.pallete.colors.brandPrimary.red[3],
                      hoverTextColor: theme.pallete.colors.brandPrimary.red[3],
                      textColor: theme.pallete.colors.brandPrimary.red[3],
                    }}
                    onClick={() => {
                      handleCancelInvite(invite.user.email);
                    }}
                    isLoading={isSendOrCancelLoading}
                  />
                </div>
              );
            })
          )}
        </div>
        {isOpenInviteModal && (
          <InvitePeople
            setIsOpenInviteModal={setIsOpenInviteModal}
            getListInvite={getListInvite}
          />
        )}
        {isOpenInactiveUsers && (
          <InactiveUsers
            getListInvite={getListInvite}
            setIsOpenInactiveUsers={setIsOpenInactiveUsers}
          />
        )}
      </DashboardConfigCard>
    </StaffMembersContainer>
  );
};

export default StaffMembers;

interface IInvitePeople {
  setIsOpenInviteModal: Dispatch<SetStateAction<boolean>>;
  getListInvite: () => Promise<void>;
}

interface IInviteFields {
  email: string;
  username: string;
  password: string;
  passwordConfirm: string;
  role: string;
}

export const InvitePeople: React.FC<IInvitePeople> = ({
  setIsOpenInviteModal,
  getListInvite,
}) => {
  const [isInviteSendLoading, setIsInviteSendLoading] =
    useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IInviteFields>({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
      role: '',
      username: '',
    },
  });
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IInviteFields> = async (data) => {
    setIsInviteSendLoading((prev) => !prev);
    await createUserService({
      email: data.email,
      password: data.password,
      passwordConfirm: data.passwordConfirm,
      role: data.role,
      username: data.username,
    })
      .then((response) => {
        setIsInviteSendLoading((prev) => !prev);
        setIsOpenInviteModal((prev) => !prev);
        handleToastfy({
          message:
            'Cadastro finalizado com sucesso! Verifique o e-mail para ativar a conta.',
          type: 'success',
        });
        getListInvite();
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
          setIsInviteSendLoading((prev) => !prev);
        }
      });
  };

  return (
    <LightBox
      handleOpenModal={() => {
        setIsOpenInviteModal((prev) => !prev);
        navigate('/dashboard/membros-da-equipe');
      }}
    >
      <InvitePeopleContent>
        <h2 className="heading-2">Convidar Pessoa</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Textfield
            label="Usuário"
            type="text"
            error={errors.username?.message}
            placeholder="Ex: fabianoAlves, julianaB123 ..."
            {...register('username', {
              required: 'O campo é requerido.',
            })}
          />
          <Textfield
            label="E-mail"
            type="email"
            error={errors.email?.message}
            placeholder="Ex: emily@email.com"
            {...register('email', {
              required: 'O campo é requerido.',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Digite um e-mail válido',
              },
            })}
          />
          <Textfield
            label="Senha"
            type="password"
            error={errors.passwordConfirm?.message}
            {...register('password', {
              required: 'O campo é requerido.',
              maxLength: {
                value: 40,
                message: 'Limite máximo de 40 caracteres',
              },
              minLength: {
                value: 8,
                message: 'Mínimo de 08 caracteres',
              },
            })}
          />
          <Textfield
            label="Confirmar Senha"
            type="password"
            error={errors.passwordConfirm?.message}
            {...register('passwordConfirm', {
              required: 'O campo é requerido',
              maxLength: {
                value: 40,
                message: 'Limite máximo de 40 caracteres',
              },
              minLength: {
                value: 8,
                message: 'Mínimo de 08 caracteres',
              },
              validate: (confirmPassword: string) => {
                if (watch('password') !== confirmPassword) {
                  return 'As senhas não conferem';
                }
              },
            })}
          />
          <span className="function-title">Função</span>
          <div className="grid-boxes">
            <div className="grid-checkbox-box">
              <InviteCheckboxSelect
                value="user"
                {...register('role', {
                  required: 'O campo é requerido.',
                })}
              />
            </div>
            <div className="grid-texts">
              <span className="grid-texts-title">Usuário</span>
              <p className="grid-texts-paragraph">
                Permitido criar, editar e deletar seus próprios conteúdos no
                site.
              </p>
              {errors.role?.message && (
                <span className="textfield-error">{errors.role.message}</span>
              )}
            </div>
            <div className="grid-checkbox-box">
              <InviteCheckboxSelect
                value="moderator"
                {...register('role', {
                  required: 'O campo é requerido.',
                })}
              />
            </div>
            <div className="grid-texts">
              <span className="grid-texts-title">Moderador</span>
              <p className="grid-texts-paragraph">
                Permitido moderar conteúdos enviados pelos Criativistas,
                deletando impróprios e publicando os enviados. Não permitido
                alterar funções administrativas.
              </p>
              {errors.role?.message && (
                <span className="textfield-error">{errors.role.message}</span>
              )}
            </div>
            <div className="grid-checkbox-box">
              <InviteCheckboxSelect
                value="admin"
                {...register('role', {
                  required: 'O campo é requerido.',
                })}
              />
            </div>
            <div className="grid-texts">
              <span className="grid-texts-title">Administrador</span>
              <p className="grid-texts-paragraph">
                Acesso liberado a todas as funções. Sem restrições.
              </p>
              {errors.role?.message && (
                <span className="textfield-error">{errors.role.message}</span>
              )}
            </div>
          </div>
          <Button
            variant="contained"
            buttonText="Convidar"
            buttonSize={240}
            type="submit"
            isLoading={isInviteSendLoading}
          />
        </form>
      </InvitePeopleContent>
    </LightBox>
  );
};

interface IInactivePeople {
  setIsOpenInactiveUsers: Dispatch<SetStateAction<boolean>>;
  getListInvite: () => Promise<void>;
}

interface ISearchInputFields {
  search: string;
  role: 'user' | 'moderator' | 'admin';
}

export const InactiveUsers: React.FC<IInactivePeople> = ({
  setIsOpenInactiveUsers,
  getListInvite,
}) => {
  const [listUsers, setListUsers] = useState<IUser[]>([]);
  const [resendInviteTo, setResendInviteTo] = useState<string>('');
  const [isLoadingResendInvite, setIsLoadingResendInvite] =
    useState<boolean>(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [isOpenConfirmResendInviteModal, setIsOpenConfirmResendInviteModal] =
    useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ISearchInputFields>({
    defaultValues: {
      role: 'user',
      search: '',
    },
  });
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const roleUser = [
    {
      optionName: 'Usuário',
      optionValue: 'user',
    },
    {
      optionName: 'Moderador',
      optionValue: 'moderator',
    },
    {
      optionName: 'Administrador',
      optionValue: 'admin',
    },
  ];

  const onSubmit: SubmitHandler<ISearchInputFields> = async (data) => {
    setIsLoadingUsers((prev) => !prev);
    await listUserService({
      order: 'DESC',
      orderBy: 'createdAt',
      isActive: false,
      find: data.search,
      role: data.role,
    })
      .then((response) => {
        setListUsers(response.users);
        setIsLoadingUsers((prev) => !prev);
        !response.users.length &&
          handleToastfy({
            message: 'Nenhum usuário encontrado.',
            type: 'error',
          });
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingUsers((prev) => !prev);
      });
  };

  const resendEmail = async () => {
    setIsLoadingResendInvite((prev) => !prev);
    await resendEmailConfirmationService(resendInviteTo)
      .then((response) => {
        handleToastfy({
          message: 'Email de confirmação enviado com sucesso!',
          type: 'success',
        });
        setIsLoadingResendInvite((prev) => !prev);
        setIsOpenInactiveUsers((prev) => !prev);
        setIsOpenConfirmResendInviteModal((prev) => !prev);
        getListInvite();
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingResendInvite((prev) => !prev);
      });
  };

  return (
    <LightBox handleOpenModal={() => setIsOpenInactiveUsers((prev) => !prev)}>
      <InactiveUsersContainer>
        <h3>Envie um novo convite de ativação</h3>
        <SeparateLine
          margin="1.6rem 0 3rem"
          color={theme.pallete.colors.brandPrimary.gray[2]}
        />
        <form className="form-inactive-users" onSubmit={handleSubmit(onSubmit)}>
          <Textfield
            label="Pesquise um usuário"
            type="text"
            error={errors.search?.message}
            {...register('search', {
              required: 'O campo é requerido.',
            })}
          />
          <Select2
            setValue={setValue}
            textLabel="Tipo de usuário"
            error={errors.role?.message}
            options={roleUser.map((role) => {
              return {
                optionName: role.optionName,
                optionValue: role.optionValue,
              };
            })}
            {...register('role', {
              required: 'O campo é requerido.',
            })}
          />
          <Button
            variant="contained"
            buttonText="Pesquisar"
            buttonSize={160}
            type="submit"
            isLoading={isLoadingUsers}
          />
        </form>
        {!!listUsers.length && (
          <React.Fragment>
            <h3>Usuários</h3>
            <SeparateLine
              margin="1.6rem 0 3rem"
              color={theme.pallete.colors.brandPrimary.gray[2]}
            />
            <div className="grid-user-list">
              {listUsers.map((user) => {
                return (
                  <div key={user._id} className="user-item">
                    <div className="user-identity">
                      <div className="user-identity-name-box">
                        <span className="user-identity-name">
                          {user.username}
                        </span>
                      </div>
                      <span className="user-identity-email">{user.email}</span>
                    </div>
                    <Button
                      variant="contained"
                      buttonText="Reenviar convite"
                      customColors={{
                        backgroundColor: theme.pallete.colors.common.black,
                        hoverBackgroundColor:
                          theme.pallete.colors.brandPrimary.gray[9],
                        hoverTextColor: theme.pallete.colors.common.white,
                        textColor: theme.pallete.colors.common.white,
                      }}
                      onClick={() => {
                        setResendInviteTo(user.email);
                        setIsOpenConfirmResendInviteModal((prev) => !prev);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
        {isOpenConfirmResendInviteModal && (
          <ConfirmLightBox
            buttonCancelLabel="Não"
            buttonConfirmlLabel="Sim"
            buttonConfirmFunction={resendEmail}
            isLoading={isLoadingResendInvite}
            setIsOpenModal={setIsOpenConfirmResendInviteModal}
            title="Deseja enviar o convite novamente?"
          />
        )}
      </InactiveUsersContainer>
    </LightBox>
  );
};
