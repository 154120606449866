import styled from 'styled-components';

export const ErrorContainer = styled.section`
  background-color: ${(props) =>
    props.theme.pallete.colors.backgrounds.neutral};
  width: 100%;
  position: relative;
  min-height: max-content;
  padding: 6rem 0;

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 11rem;
  }

  ${(props) => props.theme.mixins.containerGridDefault};

  .error-content {
    grid-column: center-start / center-end;
    display: flex;
    align-items: center;
    gap: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
      gap: 0rem;
    }
  }

  .error-block-right {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media ${(props) => props.theme.medias.laptopSmall} {
      align-items: center;
      text-align: center;
    }
  }

  svg {
    width: 100%;
  }

  .error-block-paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[12]};
    margin-bottom: 1.6rem;
  }

  .error-block-button-group {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    margin-top: 1.6rem;
  }
`;
