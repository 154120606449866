import styled from 'styled-components';

export const SignUpStyled = styled.div`
  padding: 3rem 0 6rem;
  background-image: linear-gradient(#fff 30%, transparent 0);
  position: relative;

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 11rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    min-width: 40rem;
    max-width: 60rem;
    margin: 0 auto 8rem;
    padding: 0 3rem;
  }

  .lamp-idea {
    background-image: url('/static/images/lamp-paper.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 18rem;
    height: 17rem;
  }

  .return-page {
    position: absolute;
    left: 3rem;
    top: 3rem;
    width: max-content;
    height: auto;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    ${(props) => props.theme.mixins.linkUnderlineEffect}
  }

  .return-page-text {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
  }

  .texts-box {
  }

  .heading-2 {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5.8rem;
    text-align: center;
  }

  .subtitle {
    width: 80%;
    margin: 0 auto;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-size: 1.6rem;
    line-height: 3rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    text-align: center;
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .sign-in {
    line-height: 5rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    a {
      text-decoration: none;
    }

    .link {
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
      font-size: 1.6rem;
      position: relative;
      margin-left: 0.8rem;

      ${(props) => props.theme.mixins.linkUnderlineEffect};
    }
  }

  .button {
    margin-top: 2rem;
  }
`;
