import styled from 'styled-components';

export const SubMenuUserContainer = styled.div`
  position: fixed;
  top: 8rem;
  right: 1rem;
  width: 30rem;
  background: ${(props) => props.theme.pallete.colors.backgrounds.neutral};
  border-radius: 2rem;
  border: 1px solid
    ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
  padding: 3rem 0;
  margin-left: 3rem;
  z-index: 1000;

  @media ${(props) => props.theme.medias.mobile} {
    width: 40rem;
  }

  .dashboard-aside-card-header {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 0 1.6rem;
  }

  .dashboard-aside-card-paragraphs {
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
  }

  .dashboard-aside-card-username,
  .dashboard-aside-card-you-have {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
  }

  .dashboard-aside-card-credits {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    font-size: 1.6rem;
  }

  .dashboard-aside-card-carrer {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    font-size: 1.6rem;
  }

  .dashboard-section-menu-current-plan {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.2rem;
  }

  .dashboard-aside-card-header-bottom {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1.6rem;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }

  .dashboard-section-menu-item-box {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 1.6rem;
  }

  .dashboard-section-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
    cursor: pointer;

    span {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
      font-size: 1.6rem;
      transition: all 0.2rem;
    }
  }

  .dashboard-section-menu-exit:hover {
    span {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.red[3]};
    }
    svg {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.red[3]};
    }
  }

  .brainstorming-sub-menu-avatar {
    border-radius: 100%;
    width: 6rem;
    height: 6rem;
    background-image: url('/static/images/profile.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;
