import styled from 'styled-components';

export const PlansStyled = styled.section`
  ${(props) => props.theme.mixins.containerGridDefault};
  padding: 8rem 0;

  @media ${(props) => props.theme.medias.laptopSmall} {
    padding: 4rem 0;
  }

  .content {
    grid-column: center-start / center-end;
  }

  .heading-2 {
    text-align: center;
    margin-bottom: 6rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
  }

  .grid-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, 40rem);
    justify-content: center;
    gap: 3.2rem;
    margin-bottom: 6rem;
  }

  .card {
    background-color: ${(props) =>
      props.theme.pallete.colors.brandPrimary.gray[1]};
    border-radius: 2rem;
    box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    min-height: 60rem;
  }

  .card-text-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.6rem;
  }

  .card-title {
    font-size: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[10]};
    text-transform: uppercase;
    margin: 0;
  }

  .card-text {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    text-align: center;
  }

  .card-list {
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    gap: 2rem;
  }

  .card-list-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .card-list-item-text {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .price-box {
    display: flex;
    justify-content: center;
    margin-top: auto;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[10]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    gap: 0.8rem;
  }

  .price-symbol {
    margin: auto 0;
  }

  .price {
    font-size: 5rem;
  }

  .comming-soon {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    margin-bottom: 2.4rem;
  }

  .link {
    text-align: center;
  }
  .button {
    margin: 0 auto;
  }

  .plans-paragraph {
    line-height: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    text-align: center;
  }

  .after-text {
    width: 75%;
    margin: 0 auto;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }
`;
