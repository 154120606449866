import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { VscError } from 'react-icons/vsc';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../components/Button';
import Textfield from '../../components/inputs/Textfield';
import Spinner from '../../components/loadings/Spinner';
import ToastfyContext from '../../contexts/ToastfyContext';
import { resetPasswordService } from '../../services/user.service';
import InstagramBanner from '../Home/InstagramBanner';
import { ResetPasswordContainer } from './styles';

export type IResetPasswordnputs = {
  password: string;
  passwordConfirm: string;
};

const ResetPassword: React.FC = () => {
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(true);
  const [isConfirmed, setIsConfimed] = useState<boolean>(true);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [token, setToken] = useState<string>('');
  const { handleToastfy } = useContext(ToastfyContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IResetPasswordnputs>();

  const onSubmit: SubmitHandler<IResetPasswordnputs> = async (data) => {
    setIsButtonLoading((prev) => !prev);
    await resetPasswordService({
      ...data,
      token,
    })
      .then((response) => {
        setIsButtonLoading((prev) => !prev);
        handleToastfy({ message: response.message, type: 'success' });
        navigate('/sign-in');
      })
      .catch((error) => {
        console.log(error);
        setIsConfimed((prev) => !prev);
        setIsButtonLoading((prev) => !prev);
        handleToastfy({ message: error.response.data.message, type: 'error' });
        window.scrollTo(0, 0);
      });
  };

  useEffect(() => {
    const getParamsToken = searchParams.get('token') || '';

    if (!getParamsToken) {
      navigate('/sign-in');
    } else if (getParamsToken?.length > 55 && getParamsToken?.length < 65) {
      setToken(getParamsToken);
      setIsLoginLoading(false);
    } else {
      setIsConfimed(false);
      setIsLoginLoading(true);
    }
  }, [searchParams, navigate]);

  return (
    <ResetPasswordContainer>
      {isLoginLoading ? (
        <div className="email-verification-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <section className="content">
          <span className="lamp-idea" />
          {isConfirmed ? (
            <React.Fragment>
              <h2 className="heading-2">Estamos quase lá!</h2>
              <p className="subtitle">Resete sua senha abaixo.</p>
              <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Textfield
                  label="Senha"
                  type="password"
                  error={errors.passwordConfirm?.message}
                  {...register('password', {
                    required: 'O campo é requerido.',
                    maxLength: {
                      value: 40,
                      message: 'Limite máximo de 40 caracteres',
                    },
                    minLength: {
                      value: 8,
                      message: 'Mínimo de 08 caracteres',
                    },
                  })}
                />
                <Textfield
                  label="Confirmar Senha"
                  type="password"
                  error={errors.passwordConfirm?.message}
                  {...register('passwordConfirm', {
                    required: 'O campo é requerido',
                    maxLength: {
                      value: 40,
                      message: 'Limite máximo de 40 caracteres',
                    },
                    minLength: {
                      value: 8,
                      message: 'Mínimo de 08 caracteres',
                    },
                    validate: (confirmPassword: string) => {
                      if (watch('password') !== confirmPassword) {
                        return 'As senhas não conferem';
                      }
                    },
                  })}
                />
                <Button
                  buttonText="Resetar senha"
                  variant="contained"
                  isFullWidth
                  className="button"
                  type="submit"
                  isLoading={isButtonLoading}
                />
              </form>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h2 className="heading-2">Token Inválido!</h2>
              <div className="icon-box">
                <VscError size={56} color={theme.pallete.colors.error.main} />
              </div>
              <p className="subtitle">
                Poxa parece que seu token é inválido,&nbsp;
                <Link to="/esqueci-minha-senha">
                  <span className="link">clique aqui</span>
                </Link>
                &nbsp;para solicitar uma nova senha.
              </p>
            </React.Fragment>
          )}
        </section>
      )}
      <InstagramBanner />
    </ResetPasswordContainer>
  );
};

export default ResetPassword;
