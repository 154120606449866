import React from 'react';
import ProjectWall from '../../../../components/ProjectWall';
import ShowYourIdeaSection from '../../../../components/ShowYourIdeaSection';
import CommonQuestions from '../../../Home/CommonQuestions';
import InstagramBanner from '../../../Home/InstagramBanner';
import { DashboardContainer } from './styles';
import DashboardTabs from './DashboardTabs';

const Dashboard: React.FC = () => {
  return (
    <DashboardContainer>
      <section className="dashboard-section">
        <DashboardTabs />
      </section>
      <ProjectWall />
      <ShowYourIdeaSection />
      <InstagramBanner />
      <CommonQuestions />
    </DashboardContainer>
  );
};

export default Dashboard;
