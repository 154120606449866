import React, { useState } from 'react';
import { CommonQuestionsStyled } from './styles';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Collapse } from 'react-collapse';

interface ITexts {
  id: string;
  text: React.ReactNode | string;
  title: string;
}

const CommonQuestions: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  className,
}) => {
  const [isCollapse, setIsCollapse] = useState({
    id: '',
    open: false,
  });

  const textsAndTitle: ITexts[] = [
    {
      id: '1',
      title: 'Como funciona a plataforma da Criativistas?',
      text: 'A nossa plataforma une ferramentas criativas, comunidade e inteligência artificial para potencializar suas ideias e seu processo criativo. Basta se cadastrar e escolher o seu plano, gratuito ou pago, para começar a usar as funcionalidades.',
    },
    {
      id: '2',
      title: 'O que são os Gatilhos Criativos?',
      text: 'Gatilhos Criativos são perguntas ou provocações que estimulam nosso cérebro a sair das rotinas de pensamento lineares, nos desafiando a ter pensamentos laterais (os chamados: fora da caixinha) e fazer mais conexões diferentes para gerar insights diferentes.',
    },
    {
      id: '3',
      title: 'Como funciona a Inteligência Artificial da Criativistas?',
      text: 'Nós integramos a API do ChatGPT, desenvolvido pela OpenAI, com parâmetros testados e criados por nós para que as respostas sejam cada vez mais criativas e diferentes, entregando insights fora da caixinha para te ajudar no brainstorming. Os assinantes do plano pago terão acesso a essa integração, fazendo uso dos créditos disponíveis para as solicitações.',
    },
    {
      id: '4',
      title: 'Como funciona o plano de assinatura da Criativistas?',
      text: 'Ao escolher nosso plano de assinatura, você pagará um valor mensal que dará acesso a todas as funcionalidades e benefícios da plataforma, especialmente a integração com a inteligência artificial. Ao assinar, você receberá 250 créditos de IA mensalmente para serem gastos nas suas solicitações. Todo mês seus créditos serão zerados e renovados. Os créditos da IA não são cumulativos',
    },
    {
      id: '5',
      title:
        'Por mês, quantas solicitações de IA eu consigo fazer com meus créditos?',
      text: 'Em torno de 250 solicitações mensais para a IA. O valor pode não ser exato devido a possíveis complexidades das respostas, que podem envolver mais ou menos texto. Todo mês seus créditos serão zerados e renovados. Os créditos da IA não são cumulativos.',
    },
    {
      id: '6',
      title:
        'O que eu faço se meus créditos de IA acabarem antes da renovação mensal?',
      text: (
        <p>
          Você tem duas opções: aguardar a renovação mensal ou solicitar mais
          créditos em caráter de urgência. Ao solicitar, você irá pagar o valor
          referente aos créditos e eles ficarão disponíveis até a renovação
          mensal. A solicitação de urgência deverá ser feita diretamente com a
          gente, através do nosso e-mail:{' '}
          <a
            href="mailto:vemsercriativista@gmail.com"
            className="link-email"
          >
            vemsercriativista@gmail.com
          </a>
          .
        </p>
      ),
    },
    {
      id: '10',
      title: 'Como funciona a política de cancelamento?',
      text: (
        <React.Fragment>
          <p>
            Na Plataforma da Criativistas, valorizamos sua liberdade e
            flexibilidade. Se, por algum motivo, você decidir cancelar sua
            assinatura, pode fazer isso a qualquer momento.
          </p>
          <p>
            Ao cancelar, sua assinatura permanecerá ativa até o final do período
            de assinatura atual. Isso significa que você pode continuar usando
            os créditos de IA restantes até o final deste período. Infelizmente,
            não oferecemos reembolsos parciais para o período de assinatura não
            utilizado
          </p>
          <p>
            Para cancelar sua assinatura, basta acessar as configurações da sua
            conta, selecionar a opção 'Assinatura' e seguir as etapas para
            cancelamento.
          </p>
          <p>
            Se precisar de qualquer ajuda ou tiver dúvidas sobre o processo de
            cancelamento, não hesite em entrar em contato conosco.
          </p>
        </React.Fragment>
      ),
    },
    {
      id: '11',
      title: 'Com quem posso entrar em contato?',
      text: (
        <p>
          Você pode enviar um e-mail para{' '}
          <a
            href="mailto:vemsercriativista@gmail.com"
            className="link-email"
          >
            vemsercriativista@gmail.com
          </a>
          .
        </p>
      ),
    },
  ];

  return (
    <CommonQuestionsStyled className={className} id="perguntas">
      <div className="content">
        <h2 className="heading-2">Perguntas Frequentes</h2>
        <div className="grid-cards">
          {textsAndTitle.map((text) => {
            return (
              <div className="card" key={text.id}>
                <div className="card-question">
                  <p className="question">{text.title}</p>
                  <span
                    className="label-icon"
                    onClick={() => {
                      const collapse =
                        isCollapse.id === text.id
                          ? {
                              id: text.id,
                              open: !isCollapse.open,
                            }
                          : {
                              id: text.id,
                              open: true,
                            };
                      setIsCollapse(collapse);
                    }}
                  >
                    {isCollapse.id === text.id && isCollapse.open ? (
                      <AiOutlineMinus size={32} />
                    ) : (
                      <AiOutlinePlus size={32} />
                    )}
                  </span>
                </div>
                <Collapse
                  isOpened={isCollapse.id === text.id && isCollapse.open}
                >
                  <div className="card-answer card-answer--question-2">
                    {text.text}
                  </div>
                </Collapse>
              </div>
            );
          })}
        </div>
      </div>
    </CommonQuestionsStyled>
  );
};

export default CommonQuestions;
