import axios from 'axios';
import {
  ICheckLoginResponse,
  IEmailConfirmResponse,
  IForgotPasswordResponse,
  IResetPassword,
  IResetPasswordResponse,
  ISignInResponse,
  ISignUpService,
  ISignUpServiceResponse,
  IUpdatePasswordRequest,
  IUpdatePasswordResponse,
  IUpdateProfileResponse,
} from './user.types';
import { api } from './api.axios';

export const signInService = async (
  email: string,
  password: string
): Promise<ISignInResponse> => {
  const response = await axios.post<ISignInResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/sessions/`,
    {
      email: email.trim(),
      password: password.trim(),
    }
  );

  return response.data;
};

//Token Bearer it was inserted in AuthContext
export const checkLoginService = async (
  token: string
): Promise<ICheckLoginResponse> => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.get<ICheckLoginResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/sessions/check`,
    config
  );

  return response.data;
};

export const forgotPasswordService = async (
  email: string
): Promise<IForgotPasswordResponse> => {
  const response = await axios.post<IForgotPasswordResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/password/forgot`,
    {
      email: email.trim(),
    }
  );

  return response.data;
};

// export const rememberPasswordService = async (
//   token: string
// ): Promise<> => {
//   const response = await axios.get<>(
//     `${process.env.REACT_APP_URL_API_BASE}/password/forgot`,
//     {
//       token,
//     }
//   );

//   return response.data;
// };

export const resetPasswordService = async ({
  ...props
}: IResetPassword): Promise<IResetPasswordResponse> => {
  const response = await axios.put<IResetPasswordResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/password/reset`,
    {
      token: props.token,
      password: props.password.trim(),
      passwordConfirm: props.passwordConfirm.trim(),
    }
  );

  return response.data;
};

export const signUpService = async ({
  ...props
}: ISignUpService): Promise<ISignUpServiceResponse> => {
  const response = await axios.post<ISignUpServiceResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/users/sign-up`,
    {
      username: props.username,
      email: props.email.trim(),
      password: props.password.trim(),
      passwordConfirm: props.passwordConfirm.trim(),
      profession: props.profession,
      foundUsThrough: props.foundUsThrough,
      plan: props.plan,
    }
  );

  return response.data;
};

export const emailConfirmService = async (
  token: string
): Promise<IEmailConfirmResponse> => {
  const response = await axios.post<IEmailConfirmResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/users/registry-confirm`,
    {
      token,
    }
  );

  return response.data;
};

export const updateProfileService = async (
  formData: any
): Promise<IUpdateProfileResponse> => {
  const response = await api.put<IUpdateProfileResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/profile`,
    formData
  );

  return response.data;
};

export const updatePasswordService = async ({
  currentPassword,
  password,
  passwordConfirmation,
}: IUpdatePasswordRequest): Promise<IUpdatePasswordResponse> => {
  const response = await api.put<IUpdatePasswordResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/profile/password`,
    { currentPassword, password, passwordConfirmation }
  );

  return response.data;
};
