import styled from 'styled-components';

export const PasswordContainer = styled.div`
  ${(props) => props.theme.mixins.containerGridDefault};

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 11rem;
  }

  .password-card {
    grid-column: center-start / center-end;
    padding: 6rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      padding: 3rem;
    }
  }

  .password-content {
    margin-top: 5.6rem;
  }

  .password-content-heading-3 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
  }

  .password-content-heading-3,
  .password-content-subtitle {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
  }

  .password-form {
    margin-top: 5.6rem;
    display: grid;
    grid-template-columns: repeat(
      12,
      [col-start] minmax(min-content, 1fr) [col-end]
    );
    gap: 3rem;
  }

  .form-span {
    grid-column: col-start 1 / col-end 3;
    white-space: nowrap;
    align-self: center;

    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};

    @media ${(props) => props.theme.medias.mobile} {
      grid-column: col-start 1 / col-end 12;
    }
  }

  .form-item-margin {
    margin-bottom: 6rem;

    @media ${(props) => props.theme.medias.mobile} {
      margin-bottom: 0rem;
    }
  }

  .form-input {
    grid-column: col-start 4 / col-end 9;
    width: 100%;

    @media ${(props) => props.theme.medias.laptopSmall} {
      grid-column: col-start 4 / col-end 12;
    }

    @media ${(props) => props.theme.medias.mobile} {
      grid-column: col-start 1 / col-end 12;
    }
  }

  .form-button-submit {
    grid-column: col-start 1 / col-end 12;
    justify-self: center;
    margin: 4rem 0;
  }
`;
