import styled, { css } from 'styled-components';

interface IProps {
  isActive: boolean;
  cursor: 'true' | 'false';
}

export const CardPlanContainer = styled.div<IProps>`
  background-color: ${(props) =>
    props.theme.pallete.colors.brandPrimary.gray[1]};
  border-radius: 2rem;
  box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  min-height: 30rem;

  border: 5px solid
    ${(props) =>
      props.isActive ? props.theme.pallete.colors.primary.main : 'transparent'};

  ${(props) =>
    Boolean(props.cursor) &&
    css`
      cursor: pointer;
    `};

  .card-text-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.6rem;
  }

  .card-title {
    text-transform: uppercase;
    font-size: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[10]};
  }

  .card-text {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    text-align: center;
  }

  .card-list {
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    gap: 2rem;
  }

  .card-list-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .card-list-item-text {
    font-size: 1.4rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .price-symbol {
    margin: auto 0;
  }

  .price {
    font-size: 5rem;
  }

  .card-list-comming-soon {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[10]};
    text-align: center;
    margin-top: auto;
    margin-bottom: 1.2rem;
  }

  .price-box {
    display: flex;
    justify-content: center;
    margin-top: auto;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[10]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    gap: 0.8rem;
  }
`;
