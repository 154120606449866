import lodash from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import AttachFiles from '../../../components/AttachmentFiles';
import BrainstormingCommentMessage from '../../../components/BrainstormingCommentMessage';
import Button from '../../../components/Button';
import TextArea from '../../../components/inputs/TextArea';
import ImagePreview from '../../../components/lightboxes/ImagePreview';
import LightBox from '../../../components/lightboxes/LightBox';
import AuthContext from '../../../contexts/AuthContext';
import ToastfyContext from '../../../contexts/ToastfyContext';
import { addMessageRoomService } from '../../../services/brainstormingRoom.service';
import { IShowBrainstormingRoomData } from '../../../services/brainstormingRoom.types';
import { SendMessagesContainer } from './styles';

interface IProps {
  setIsOpenLightbox: Dispatch<SetStateAction<boolean>>;
  brainstormingRoom: IShowBrainstormingRoomData;
  showBrainstormingRoom: (id: string) => Promise<void>;
}

interface IInputFields {
  message: string;
}

interface IImages {
  fileId: string;
  file: File;
  imagePreview: string;
}

const SendMessages: React.FC<IProps> = ({
  setIsOpenLightbox,
  brainstormingRoom,
  showBrainstormingRoom,
}) => {
  const [images, setImages] = useState<IImages[]>([]);
  const [revokeList, setRevokeList] = useState<string[]>([]);
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const [isSendMessageLoading, setIsSendMessageLoading] =
    useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IInputFields>({
    defaultValues: {
      message: '',
    },
  });
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [isSend, setIsSend] = useState<boolean>(true);
  const [openImageLink, setOpenImageLink] = useState<string>('');
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCallbackArchive = (file: File[]) => {
    const objectURL = URL.createObjectURL(file[0]);

    setImages([
      ...images,
      { fileId: uuidv4(), file: file[0], imagePreview: objectURL },
    ]);
    setRevokeList((prev) => [...prev, objectURL]);
  };

  const validateFileSize = (file: File) => {
    if (file.size > 800000) {
      return {
        code: 'size-too-large',
        message: 'O arquivo é maior que 800 Kbytes',
      };
    }

    return null;
  };

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsSendMessageLoading((prev) => !prev);

    if (!!brainstormingRoom._id) {
      let formData = new FormData();

      formData.append('message', `${data.message}`);
      formData.append('brainstormingRoomId', `${brainstormingRoom._id}`);

      if (!!images.length) {
        images.forEach((image) => {
          formData.append('images', image.file);
        });
      }

      await addMessageRoomService(formData)
        .then((response) => {
          handleToastfy({
            message: 'Comentário efetuado com sucesso!',
            type: 'success',
          });
          setIsOpenLightbox((prev) => !prev);
          setIsSendMessageLoading((prev) => !prev);
          showBrainstormingRoom(brainstormingRoom._id);
          revokeList.map((list) => URL.revokeObjectURL(list));
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          setIsSendMessageLoading((prev) => !prev);
        });
    } else {
      handleToastfy({
        message: 'Id da sala não localizado!',
        type: 'error',
      });
    }
  };

  const handleOpenImage = (imageLink: string) => {
    setIsOpenImage((prev) => !prev);
    setOpenImageLink(imageLink);
  };

  const handleRemoveImage = (fileId: string) => {
    setImages((prev) =>
      prev.filter((imageObject) => imageObject.fileId !== fileId)
    );
  };

  useEffect(() => {
    watch((value) => {
      setIsSend(lodash.values(value).every(lodash.isEmpty));
    });
  }, [watch]);

  return (
    <LightBox handleOpenModal={() => setIsOpenLightbox((prev) => !prev)}>
      <SendMessagesContainer>
        <div className="brainstorming-create-room">
          <BrainstormingCommentMessage
            buttonText={'participar da sala'.toUpperCase()}
            removeButton
            roomData={brainstormingRoom}
          />
          <form
            className="brainstorming-create-room"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextArea
              label=""
              maxCharacter={300}
              placeholder="Escreva sua contribuição"
              {...register('message', {
                required: 'O campo é requerido.',
              })}
              error={errors.message?.message}
              customCountCharacters={watch(`message`)?.length}
            />
            <AttachFiles
              handleCallbackArchive={handleCallbackArchive}
              validator={validateFileSize}
              acceptTypeFiles={{
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg'],
              }}
            />
            {!!images.length && (
              <div className="brainstorming-create-room-images">
                {images.map((image) => {
                  return (
                    <div
                      key={image.fileId}
                      className="brainstorming-create-room-image-box"
                    >
                      <div
                        className="brainstorming-create-room-image"
                        style={{
                          backgroundImage: `url(${image.imagePreview})`,
                        }}
                        onClick={() => handleOpenImage(image.imagePreview)}
                      >
                        <div className="insights-card-image-background">
                          <span>Visualizar</span>
                        </div>
                      </div>
                      <div className="brainstorming-create-room-image-icon">
                        <FaTrashAlt
                          color={theme.pallete.colors.brandPrimary.red[2]}
                          size={24}
                          onClick={() => handleRemoveImage(image.fileId)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="brainstorming-create-room-button-group">
              <Button
                buttonText="Cancelar"
                variant="contained"
                className="room-button-write-contribution"
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                type="button"
                buttonSize={160}
                onClick={() => setIsOpenLightbox((prev) => !prev)}
              />
              <Button
                buttonText="Enviar"
                variant="contained"
                buttonSize={160}
                className="room-button-write-contribution"
                isLoading={isSendMessageLoading}
                isDisabled={isSend}
              />
            </div>
          </form>
        </div>
        {isOpenImage && (
          <ImagePreview
            setIsOpenImage={setIsOpenImage}
            linkImage={openImageLink}
          />
        )}
      </SendMessagesContainer>
    </LightBox>
  );
};

export default SendMessages;
