import InputMask from 'react-input-mask';
import Textfield from '../Textfield';

export const TextFieldMask = (props: any) => (
  <InputMask
    mask={props.mask}
    value={props.value}
    onChange={props.onChange}
    alwaysShowMask={false}
    maskChar={null}
    maskPlaceholder={null}
  >
    <Textfield
      type={props.type}
      label={props.label}
      placeholder={props.placeholder}
      required={props.required}
    />
  </InputMask>
);
