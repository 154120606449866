import styled, { css } from 'styled-components';

interface ISelectProps {
  isOpen: boolean;
  isInvertMenu?: boolean;
}

export const Select2Container = styled.div<ISelectProps>`
  position: relative;
  user-select: none;

  .label {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5rem;
    font-size: 1.6rem;
    user-select: none;
  }

  .select-input-false {
    width: 100%;
    height: 6rem;
    outline: 0;
    display: block;
    background: none;
    font-size: inherit;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;

    padding: 1.6rem 2rem;
    font-family: inherit;
    background-color: ${(props) => props.theme.pallete.colors.common.white};

    ${(props) =>
      props.isOpen
        ? props.isInvertMenu
          ? css`
              border-bottom-left-radius: 3rem;
              border-bottom-right-radius: 3rem;
              border-bottom: 1px solid
                ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
              border-left: 1px solid
                ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
              border-right: 1px solid
                ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
            `
          : css`
              border-top-left-radius: 3rem;
              border-top-right-radius: 3rem;
              border-top: 1px solid
                ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
              border-left: 1px solid
                ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
              border-right: 1px solid
                ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
            `
        : css`
            border: 1px solid
              ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
            border-radius: 100px;
          `}

    div {
      position: absolute;
      width: 65%;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .select-input-false::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: ${(props) =>
      props.isOpen ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)'};
    right: 3rem;
    z-index: 1;
    width: 0.8rem;
    height: 0.8rem;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-top: 0.8rem solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    pointer-events: none;
  }

  .select-options {
    list-style-type: none;
    width: 100%;
    outline: 0;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    background: none;
    font-size: inherit;
    font-family: inherit;
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    overflow: hidden;
    max-height: 30rem;
    overflow-y: auto;
    z-index: 1;

    position: absolute;
    left: 0;

    ${(props) =>
      props.isInvertMenu
        ? css`
            bottom: 50%;
            border-top: 1px solid
              ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
            border-left: 1px solid
              ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
            border-right: 1px solid
              ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
            border-top-left-radius: 3rem;
            border-top-right-radius: 3rem;
          `
        : css`
            border-bottom: 1px solid
              ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
            border-left: 1px solid
              ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
            border-right: 1px solid
              ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
            border-bottom-left-radius: 3rem;
            border-bottom-right-radius: 3rem;
          `}

    li {
      padding: 1.6rem 2rem;
      transition: all 0.2s;
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};

      &:hover {
        background: ${(props) =>
          props.theme.pallete.colors.brandPrimary.blue[5]};
        color: ${(props) => props.theme.pallete.colors.common.white};
      }

      svg {
        color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
      }

      &:hover > svg {
        color: ${(props) => props.theme.pallete.colors.common.white};
      }
    }
  }

  .textfield-error {
    color: ${(props) => props.theme.pallete.colors.error.main};
    padding: 0.4rem 2rem 0;
  }

  input {
    display: none;
  }

  .custom-option {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
  }
`;
