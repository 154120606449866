import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Textfield from '../../components/inputs/Textfield';
import AuthContext from '../../contexts/AuthContext';
import ToastfyContext from '../../contexts/ToastfyContext';
import InstagramBanner from '../Home/InstagramBanner';
import { SignInStyled } from './styles';

type IInputs = {
  email: string;
  password: string;
};

const SignIn: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IInputs>();
  const { signIn } = useContext(AuthContext);
  const { handleToastfy } = useContext(ToastfyContext);
  const navigate = useNavigate();
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    setIsLoginLoading((prev) => !prev);
    signIn(data.email, data.password)
      .then((response) => {
        setIsLoginLoading((prev) => !prev);
        handleToastfy({ message: response.message, type: 'success' });
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
        handleToastfy({ message: error.response.data.message, type: 'error' });
        setIsLoginLoading((prev) => !prev);
      });
  };

  return (
    <SignInStyled>
      <section className="login">
        <div className="text-box">
          <h2 className="heading-2">Oi, pode entrar! :)</h2>
          <p className="heading-subtitle">
            E quando entrar, pode sair da caixinha.
          </p>
        </div>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Textfield
            label="E-mail"
            type="email"
            error={errors.email?.message}
            {...register('email', {
              required: 'O campo é requerido.',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Digite um e-mail válido',
              },
            })}
          />
          <Textfield
            label="Senha"
            type="password"
            error={errors.password?.message}
            maxLength={40}
            {...register('password', {
              required: 'O campo é requerido.',
            })}
          />
          <Link className="forgot-password-link" to="/esqueci-minha-senha">
            <span className="forgot-password">Redefinir minha senha</span>
          </Link>
          <Button
            buttonText="Entrar"
            type="submit"
            isFullWidth
            isLoading={isLoginLoading}
            variant="contained"
          />
        </form>
        <p className="sign-up">
          Ainda não tem conta?
          <Link to="/sign-up">
            <span className="link">Cadastre-se</span>
          </Link>
        </p>
      </section>
      <InstagramBanner />
    </SignInStyled>
  );
};

export default SignIn;
