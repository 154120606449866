import styled from 'styled-components';

export const EmailBannerStyled = styled.section`
  ${(props) => props.theme.mixins.containerGridDefault};
  padding-top: 4rem;

  @media ${(props) => props.theme.medias.laptopSmall} {
    padding: 4rem 0;
  }

  .banner-container {
    grid-column: center-start / center-end;
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    background: ${(props) => props.theme.pallete.colors.brandPrimary.gray[15]};
    border-radius: 2rem;
    padding: 3.8rem 6rem;
    position: relative;
    box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};
    overflow: hidden;

    @media ${(props) => props.theme.medias.laptopSmall} {
      text-align: center;
    }
  }

  .banner-image {
    width: 60rem;
    height: 20rem;
    display: grid;
    align-items: center;

    background-image: url('/static/images/send-gmail.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: none;
    }
  }

  .image {
    width: 100%;
  }

  .banner-text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${(props) => props.theme.medias.tablet} {
      margin: 0;
      align-items: center;
      text-align: center;
    }
  }

  .heading-3 {
    font-size: 3.2rem;
    color: ${(props) => props.theme.pallete.colors.common.white};
    line-height: 3.9rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    margin-bottom: 1.2rem;
  }

  .banner-paragraph {
    font-size: 1.4rem;
    color: ${(props) => props.theme.pallete.colors.common.white};
  }

  .button {
    margin-top: 2.4rem;
  }
`;
