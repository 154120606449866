import styled from 'styled-components';

export const UsersRegisteredsContainer = styled.div`
  ${(props) => props.theme.mixins.containerGridDefault};

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 11rem;
  }

  .users-member-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
  }

  .users-registereds-block-text {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-top: 6rem;

    @media ${(props) => props.theme.medias.mobile} {
      align-items: center;
    }
  }

  .users-registereds-heading-3 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    margin: 0;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
  }

  .users-registereds-paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    line-height: 3.6rem;
  }

  .users-registereds-searching-heading-3 {
    margin: 3rem 0 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
  }

  .users-registereds-block-search {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    max-width: 60%;
    width: 100%;

    @media ${(props) => props.theme.medias.laptopSmall} {
      max-width: 100%;
    }

    @media ${(props) => props.theme.medias.mobile} {
      flex-direction: column;
    }
  }

  .grid-users-registereds-list {
    margin: 3rem 0 0;

    & > :not(:last-child) {
      border-bottom: 1px solid
        ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    }
  }

  .users-registereds-identity {
    display: flex;
    flex-direction: column;
    margin-right: auto;

    @media ${(props) => props.theme.medias.mobile} {
      margin-right: initial;
    }
  }

  .users-registereds-identity-name {
    font-size: 2rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    margin-bottom: 1.2rem;
  }

  .users-registereds-identity-email {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .users-registereds-credits {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    margin-top: 0.8rem;
  }

  .users-registereds-isactive {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    margin-top: 0.8rem;
  }

  .users-registereds-identity {
    position: relative;
    margin-right: auto;
  }

  .users-registereds-item {
    display: flex;
    align-items: center;
    gap: 3rem;
    padding: 3rem 0;

    @media ${(props) => props.theme.medias.mobile} {
      flex-direction: column;
    }
  }

  .users-registereds-identity-name-box {
    display: flex;
    align-items: baseline;
    gap: 3rem;
  }

  .button-load-more {
    background: transparent;
    outline: 0;
    border: none;
    margin: 0 auto;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[10]};
    padding: 1.2rem 2rem;
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    cursor: pointer;

    span {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[10]};
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .button-control {
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .confirm-delete-room-h3 {
    font-size: 2.4rem;
  }

  .confirm-delete-button-group {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    gap: 1.6rem;
  }

  .buttons-group-control-user {
    display: flex;
    justify-content: space-between;
    gap: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
  }

  .button-group-control-user {
    display: flex;
    margin-top: 3rem;
    gap: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
      align-items: center;
    }
  }

  .credits-label {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    line-height: 5rem;
  }

  .credits-name {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    margin-right: 1.6rem;
    margin-left: 0.8rem;
  }
`;
