import styled, { css } from 'styled-components';
import { pixelsToRemUnit } from '../../utils/sizesCalcs';
import { getLuminance } from 'polished';

export interface ICustomColors {
  textColor: string;
  hoverTextColor: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
}

interface IButtonProps {
  isLoading?: boolean;
  buttonSize?: number;
  isFullWidth?: boolean;
  variant?: 'contained' | 'outlined';
  customColors?: ICustomColors;
  isDisabled?: boolean;
  noShadows?: boolean;
}

export const ButtonContainer = styled.button<IButtonProps>`
  ${(props) => {
    if (props.isDisabled) {
      return css`
        background: ${`linear-gradient(
          ${props.theme.pallete.colors.brandPrimary.gray[3]},
          ${props.theme.pallete.colors.brandPrimary.gray[3]}
        )`};
        ${!props.noShadows &&
        css`
          box-shadow: ${(props) => props.theme.pallete.shadows.default.n4};
        `}
        min-height: 5rem;
        width: ${props.isFullWidth
          ? '100%'
          : !!props.buttonSize
          ? pixelsToRemUnit(props.buttonSize)
          : 'max-content'};
        min-width: min-content;
        padding: 1.65rem 3rem;
        border-radius: 100px;
        border: none;
        letter-spacing: 1px;
        transition: all 0.2 ease-in-out;
        position: relative;
        white-space: nowrap;
        user-select: none;
        cursor: pointer;

        &:hover .text {
          color: ${props.customColors?.hoverTextColor
            ? props.customColors.hoverTextColor
            : props.theme.pallete.colors.common.black};
        }

        .text {
          color: ${props.customColors?.textColor
            ? props.customColors.textColor
            : props.theme.pallete.colors.common.black};
          font-weight: ${props.theme.typeFaceWeight.bold};
          text-transform: initial;
          border-radius: 100px;
          font-size: 1.4rem;
          display: block;
          transition: all 0.2 ease-in-out;
          opacity: ${props.isLoading ? 0 : 1};
          user-select: none;
        }

        .spinner {
          ${(props) => props.theme.mixins.absoluteCenter};
          display: ${props.isLoading ? 'block' : 'none'};
        }
      `;
    } else {
      if (props.variant === 'contained') {
        return css`
          background: ${props.isLoading
            ? `linear-gradient(${props.theme.pallete.colors.brandPrimary.gray[3]}, ${props.theme.pallete.colors.brandPrimary.gray[3]})`
            : props.customColors?.backgroundColor
            ? `linear-gradient(${props.customColors?.backgroundColor}, ${props.customColors?.backgroundColor})`
            : props.theme.components.button.backgrounds.gradient};
          ${!props.noShadows &&
          css`
            box-shadow: ${(props) => props.theme.pallete.shadows.default.n4};
          `}
          min-height: 5rem;
          width: ${props.isFullWidth
            ? '100%'
            : !!props.buttonSize
            ? pixelsToRemUnit(props.buttonSize)
            : 'max-content'};
          min-width: min-content;
          padding: 1.65rem 3rem;
          border-radius: 100px;
          border: none;
          letter-spacing: 1px;
          transition: all 0.2 ease-in-out;
          position: relative;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            ${!props.isLoading
              ? props.customColors?.hoverBackgroundColor
                ? `background: linear-gradient(
            ${props.customColors.hoverBackgroundColor},
            ${props.customColors.hoverBackgroundColor}
            );`
                : `background: linear-gradient(
            ${props.theme.pallete.colors.primary.main},
            ${props.theme.pallete.colors.primary.main}
          );`
              : undefined}
            ${!props.noShadows &&
            css`
              box-shadow: ${!props.isLoading &&
              props.theme.pallete.shadows.default.n12};
            `}
          }

          &:hover .text {
            color: ${props.customColors?.hoverTextColor
              ? props.customColors.hoverTextColor
              : props.theme.pallete.colors.common.black};
          }

          &:active {
            transform: ${!props.isLoading && css`scale(0.95) translateY(2px)`};
          }

          .text {
            color: ${props.customColors?.textColor
              ? props.customColors.textColor
              : props.theme.pallete.colors.common.black};
            font-weight: ${props.theme.typeFaceWeight.bold};
            text-transform: initial;
            border-radius: 100px;
            font-size: 1.4rem;
            display: block;
            transition: all 0.2 ease-in-out;
            opacity: ${props.isLoading ? 0 : 1};
            user-select: none;
          }

          .spinner {
            ${(props) => props.theme.mixins.absoluteCenter};
            display: ${props.isLoading ? 'block' : 'none'};
          }
        `;
      } else {
        return css`
          ${props.isLoading
            ? css`
                background: linear-gradient(
                  ${props.theme.pallete.colors.brandPrimary.gray[3]},
                  ${props.theme.pallete.colors.brandPrimary.gray[3]}
                );
              `
            : css`
                background: transparent;
              `}
          /* box-shadow: ${(props) =>
            props.theme.pallete.shadows.default.n4}; */
        min-height: 5rem;
          width: ${props.isFullWidth
            ? '100%'
            : !!props.buttonSize
            ? pixelsToRemUnit(props.buttonSize)
            : 'max-content'};
          min-width: min-content;
          padding: 1.65rem 3rem;
          border-radius: 100px;
          border: none;
          letter-spacing: 1px;
          transition: all 0.2 ease-in-out;
          position: relative;
          white-space: nowrap;
          cursor: pointer;

          &::before {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 100px;
            padding: 2px;
            background: ${props.customColors?.backgroundColor
              ? props.customColors.backgroundColor
              : props.theme.components.button.backgrounds.gradient};
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
          }

          &:hover {
            ${!props.isLoading
              ? props.customColors?.hoverBackgroundColor
                ? `background-image: linear-gradient(
              ${props.customColors.hoverBackgroundColor},
            ${props.customColors.hoverBackgroundColor}
          );`
                : `background-image: linear-gradient(
            ${props.theme.pallete.colors.primary.main},
            ${props.theme.pallete.colors.primary.main}
            );`
              : undefined}

            box-shadow: ${!props.isLoading &&
            props.theme.pallete.shadows.default.n12};
          }

          &:hover::before {
            border-image: none;
          }

          &:hover .text {
            background: ${!!props.customColors?.backgroundColor
              ? getLuminance(props.customColors.backgroundColor) > 0.7
                ? '#000'
                : '#fff'
              : '#fff'};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &:hover > p {
            background: ${!!props.customColors?.backgroundColor
              ? getLuminance(props.customColors.backgroundColor) > 0.7
                ? '#000'
                : '#fff'
              : '#fff'};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &:active {
            transform: ${!props.isLoading && css`scale(0.95) translateY(2px)`};
          }

          .text {
            background: ${props.customColors?.textColor
              ? props.customColors.textColor
              : `linear-gradient(
              ${props.theme.pallete.colors.primary.main},
              ${props.theme.pallete.colors.primary.main}
            );`};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: ${props.theme.typeFaceWeight.bold};
            text-transform: initial;
            border-radius: 100px;
            font-size: 1.4rem;
            display: block;
            transition: all 0.2 ease-in-out;
            opacity: ${props.isLoading ? 0 : 1};
            user-select: none;
          }

          .spinner {
            ${(props) => props.theme.mixins.absoluteCenter};
            display: ${props.isLoading ? 'block' : 'none'};
          }
        `;
      }
    }
  }}
`;
