import React, {
  useMemo,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { BrainstormingRoomPostsContainer } from './styles';
import Table from '../../../../components/Table';
import SeparateLine from '../../../../components/SeparateLine';
import { useTheme } from 'styled-components';
import BreadCrumb from '../../../../components/BreadCrumb';
import { Column } from 'react-table';
import { FaRegTrashAlt } from 'react-icons/fa';
import AuthContext from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  deleteBrainstormingRoomService,
  deleteMessageRoomService,
  showBrainstormingRoomService,
} from '../../../../services/brainstormingRoom.service';
import {
  IImagesMessageRoom,
  IShowBrainstormingRoomData,
} from '../../../../services/brainstormingRoom.types';
import Spinner from '../../../../components/loadings/Spinner';
import moment from 'moment';
import { BsEye } from 'react-icons/bs';
import LightBox from '../../../../components/lightboxes/LightBox';
import Button from '../../../../components/Button';
import ImagePreview from '../../../../components/lightboxes/ImagePreview';

interface IComments {
  username: string;
  message: string;
  createdAt: string;
  read: React.ReactNode;
  delete: React.ReactNode;
}

interface ISeeMessage {
  username: string;
  message: string;
  messageCode: string;
  images: IImagesMessageRoom[];
}

const BrainstormingRoomPosts: React.FC = () => {
  const [brainstormingRoom, setBrainstormingRoom] =
    useState<IShowBrainstormingRoomData>({} as IShowBrainstormingRoomData);
  const [seeMessage, setSeeMessage] = useState<ISeeMessage>({} as ISeeMessage);
  const [openContent, setOpenContent] = useState<boolean>(false);
  const [isConfirmDeleteMessage, setIsConfirmDeleteMessage] =
    useState<boolean>(false);
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [openImageLink, setOpenImageLink] = useState<string>('');
  const [isDeleteMessageLoading, setIsDeleteMessageLoading] =
    useState<boolean>(false);
  const [isLoadingBrainstormingRoom, setIsLoadingBrainstormingRoom] =
    useState<boolean>(true);
  const [isConfirmDeleteRoom, setIsConfirmDeleteRoom] =
    useState<boolean>(false);
  const [isDeleteRoomLoading, setIsDeleteRoomLoading] =
    useState<boolean>(false);
  const { signOut } = useContext(AuthContext);
  const { handleToastfy } = useContext(ToastfyContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const commentsColumns: Column<IComments>[] = useMemo(
    (): Column<IComments>[] => [
      {
        Header: 'Nome',
        accessor: 'username',
      },
      {
        Header: 'Conteúdo',
        accessor: 'message',
      },
      {
        Header: 'Data de Postagem',
        accessor: 'createdAt',
      },
      {
        Header: 'Ver',
        accessor: 'read',
        disableSortBy: true,
      },
      {
        Header: 'Excluir',
        accessor: 'delete',
        disableSortBy: true,
      },
    ],
    []
  );

  const commentsData = useMemo(
    (): IComments[] =>
      !!brainstormingRoom.messages
        ? brainstormingRoom.messages
            .sort((a, b) => {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            })
            .map((room) => {
              return {
                username: room.user.username,
                message: room.message,
                createdAt: moment(room.createdAt)
                  .locale('pt-br')
                  .format('DD/MM/YYYY'),
                read: (
                  <BsEye
                    size={20}
                    onClick={() =>
                      handleSeeComment({
                        message: room.message,
                        messageCode: room.code,
                        username: room.user.username,
                        images: room.images,
                      })
                    }
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                  />
                ),
                delete: (
                  <FaRegTrashAlt
                    size={20}
                    color={theme.pallete.colors.brandPrimary.red[3]}
                    onClick={() => {
                      setSeeMessage({
                        message: room.message,
                        messageCode: room.code,
                        username: room.user.username,
                        images: room.images,
                      });
                      setIsConfirmDeleteMessage((prev) => !prev);
                    }}
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                  />
                ),
              };
            })
        : [
            {
              username: '',
              message: '',
              createdAt: 'DD/MM/YYYY',
              read: (
                <BsEye
                  size={20}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                />
              ),
              delete: (
                <FaRegTrashAlt
                  size={20}
                  color={theme.pallete.colors.brandPrimary.red[3]}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                />
              ),
            },
          ],
    [brainstormingRoom.messages, theme.pallete.colors.brandPrimary.red]
  );

  const handleSeeComment = ({
    message,
    messageCode,
    username,
    images,
  }: ISeeMessage) => {
    setSeeMessage({ username, message, messageCode, images });
    setOpenContent((prev) => !prev);
  };

  const handleRemoveMessage = async () => {
    setIsDeleteMessageLoading((prev) => !prev);
    await deleteMessageRoomService({
      brainstormingRoomId: brainstormingRoom._id,
      messageCode: seeMessage.messageCode,
    })
      .then((response) => {
        setIsDeleteMessageLoading(false);
        setIsConfirmDeleteMessage(false);
        setOpenContent(false);
        showBrainstormingRoom(brainstormingRoom._id);
        handleToastfy({
          message: 'Mensagem excluída com sucesso!',
          type: 'success',
        });
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsDeleteMessageLoading(false);
        setIsConfirmDeleteMessage(false);
        setOpenContent(false);
      });
  };

  const handleRemoveRoom = async () => {
    setIsDeleteRoomLoading((prev) => !prev);

    await deleteBrainstormingRoomService(brainstormingRoom._id)
      .then((response) => {
        handleToastfy({
          message: 'Sala deletada com sucesso!',
          type: 'success',
        });
        setIsDeleteRoomLoading((prev) => !prev);
        setIsConfirmDeleteRoom((prev) => !prev);
        navigate('/dashboard/salas-de-brainstorming/');
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
          setIsDeleteRoomLoading((prev) => !prev);
          setIsConfirmDeleteRoom((prev) => !prev);
        }
      });
  };

  const handleOpenImage = (imageLink: string) => {
    setIsOpenImage((prev) => !prev);
    setOpenImageLink(imageLink);
  };

  const showBrainstormingRoom = useCallback(
    async (id: string) => {
      setIsLoadingBrainstormingRoom(true);
      await showBrainstormingRoomService(id)
        .then((response) => {
          setBrainstormingRoom(response.data);
          setIsLoadingBrainstormingRoom(false);
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
        });
    },
    [handleToastfy, navigate, signOut]
  );

  useEffect(() => {
    const id = searchParams.get('id');
    !!id && showBrainstormingRoom(id);
  }, [searchParams, showBrainstormingRoom]);

  return (
    <BrainstormingRoomPostsContainer>
      <BreadCrumb />
      <SeparateLine
        margin="0 0 5rem"
        color={theme.pallete.colors.brandPrimary.yellow[6]}
        lineHeight="3px"
      />
      {isLoadingBrainstormingRoom ? (
        <div className="brainstorming-list-room-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <React.Fragment>
          <div className="brainstorming-list-room-header">
            <div className="brainstorming-list-room-date">
              {moment(brainstormingRoom.createdAt)
                .locale('pt-br')
                .format('DD/MM/YYYY')}
            </div>
            <div className="brainstorming-list-room-username">
              {brainstormingRoom.user.username}
            </div>
            <p className="brainstorming-list-room-description">
              {brainstormingRoom.description}
            </p>
          </div>
          <h4 className="brainstorming-comments">
            Comentários (
            {!!brainstormingRoom.messages
              ? brainstormingRoom.messages.length
              : 0}
            )
          </h4>
          {!!brainstormingRoom.messages ? (
            <React.Fragment>
              <Table columns={commentsColumns} data={commentsData} />
            </React.Fragment>
          ) : (
            <p>Ainda não há comentários nesta sala!</p>
          )}
          <div className="brainstorming-list-room-button-container">
            <Button
              buttonText="Excluir sala"
              variant="outlined"
              type="button"
              className="brainstorming-list-room-delete-button"
              customColors={{
                backgroundColor: theme.pallete.colors.brandPrimary.red[3],
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.red[3],
                hoverTextColor: theme.pallete.colors.brandPrimary.red[3],
                textColor: theme.pallete.colors.brandPrimary.red[3],
              }}
              onClick={() => setIsConfirmDeleteRoom((prev) => !prev)}
              buttonSize={180}
            />
          </div>
        </React.Fragment>
      )}
      {openContent && (
        <LightBox
          handleOpenModal={() => setOpenContent((prev) => !prev)}
          customLightboxWidth="72rem"
        >
          <h3>Mensagem</h3>
          <SeparateLine
            margin="0.8rem 0"
            color={theme.pallete.colors.brandPrimary.gray[2]}
            lineHeight="1px"
          />
          <div className="room-post-lightbox-content">
            <span className="room-post-lightbox-title">Usuário</span>
            <p className="room-post-lightbox-text">{seeMessage.username}</p>
            <span className="room-post-lightbox-title">Mensagem</span>
            <p className="room-post-lightbox-text">{seeMessage.message}</p>
            {!!seeMessage.images.length && (
              <div className="room-post-content-images">
                {seeMessage.images.map((image) => {
                  return (
                    <div
                      key={image.code}
                      className="room-post-content-image-box"
                    >
                      <div
                        className="room-post-content-image"
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_URL_IMAGE_BASE}/${image.image})`,
                        }}
                        onClick={() =>
                          handleOpenImage(
                            `${process.env.REACT_APP_URL_IMAGE_BASE}/${image.image}`
                          )
                        }
                      >
                        <div className="room-post-image-background">
                          <span>Visualizar</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="room-post-lightbox-button-group">
              <Button
                buttonText="Voltar"
                variant="contained"
                className="room-post-lightbox-button"
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                type="button"
                onClick={() => setOpenContent((prev) => !prev)}
                buttonSize={180}
              />
              <Button
                buttonText="Excluir mensagem"
                variant="outlined"
                type="button"
                className="room-post-lightbox-button"
                customColors={{
                  backgroundColor: theme.pallete.colors.brandPrimary.red[3],
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.red[3],
                  hoverTextColor: theme.pallete.colors.brandPrimary.red[3],
                  textColor: theme.pallete.colors.brandPrimary.red[3],
                }}
                onClick={() => setIsConfirmDeleteMessage((prev) => !prev)}
              />
            </div>
          </div>
        </LightBox>
      )}
      {isConfirmDeleteMessage && (
        <LightBox
          handleOpenModal={() => setIsConfirmDeleteMessage((prev) => !prev)}
          customLightboxWidth="60rem"
        >
          <h3>Deseja realmente excluir a mensagem?</h3>
          <div className="confirm-delete-button-group">
            <Button
              buttonText="Não"
              variant="contained"
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
              buttonSize={160}
              onClick={() => setIsConfirmDeleteMessage((prev) => !prev)}
            />
            <Button
              buttonText="Sim"
              variant="contained"
              buttonSize={160}
              onClick={() => handleRemoveMessage()}
              isLoading={isDeleteMessageLoading}
            />
          </div>
        </LightBox>
      )}
      {isConfirmDeleteRoom && (
        <LightBox
          handleOpenModal={() => setIsConfirmDeleteRoom((prev) => !prev)}
          customLightboxWidth="60rem"
        >
          <h3 className="confirm-delete-room-h3">
            Deseja realmente excluir a sala?
          </h3>
          <div className="confirm-delete-button-group">
            <Button
              buttonText="Não"
              variant="contained"
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
              buttonSize={160}
              onClick={() => setIsConfirmDeleteRoom((prev) => !prev)}
            />
            <Button
              buttonText="Sim"
              variant="contained"
              buttonSize={160}
              onClick={() => handleRemoveRoom()}
              isLoading={isDeleteRoomLoading}
            />
          </div>
        </LightBox>
      )}
      {isOpenImage && (
        <ImagePreview
          setIsOpenImage={setIsOpenImage}
          linkImage={openImageLink}
        />
      )}
    </BrainstormingRoomPostsContainer>
  );
};

export default BrainstormingRoomPosts;
