import lodash from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { IoMdArrowBack } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../components/Button';
import CardPlan from '../../components/cards/CardPlan';
import { TextFieldMask } from '../../components/inputs/TextFieldMask';
import Textfield from '../../components/inputs/Textfield';
import LightBox from '../../components/lightboxes/LightBox';
import Spinner from '../../components/loadings/Spinner';
import AuthContext from '../../contexts/AuthContext';
import ToastfyContext from '../../contexts/ToastfyContext';
import {
  createSignatureService,
  getAddressByCEP,
  getPaymentsPlansService,
} from '../../services/payments.service';
import {
  IGetAddressResponse,
  IGetPaymentsPlansData,
} from '../../services/payments.types';
import { signUpService } from '../../services/user.service';
import { ISignUpInputs } from '../SignUp';
import { ChoosePlanContainer } from './styles';
import { IErrorPagBank, encryptCardPagSeguro } from '../../utils/pagBank';

interface IProps {
  signUpData: ISignUpInputs;
}

export type IInfoPagBankInputs = {
  planId: string;
  name: string;
  email: string;
  name_card: string;
  security_code: string;
  country: string;
  number: string;
};

interface IAddress {
  street: string;
  complement: string;
  locality: string;
  city: string;
  region_code: string;
}

const ChoosePlan: React.FC<IProps> = ({ signUpData }) => {
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm<IInfoPagBankInputs>();
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState<boolean>(false);
  const [cep, setCep] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [dateExp, setDateExp] = useState<string>('');
  const [numberPhone, setNumberPhone] = useState<string>('');
  const [numberCard, setNumberCard] = useState<string>('');

  const [address, setAddress] = useState<IAddress>({
    city: '',
    complement: '',
    locality: '',
    region_code: '',
    street: '',
  });

  const [isCreateAccountLoading, setIsCreateAccountLoading] =
    useState<boolean>(false);
  const [isPaymentFormLoading, setIsPaymentFormLoading] =
    useState<boolean>(false);
  const [isCreateButtonLoading, setIsCreateButtonLoading] =
    useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<IGetPaymentsPlansData>(
    {} as IGetPaymentsPlansData
  );
  const [paymentsPlans, setPaymentsPlans] = useState<IGetPaymentsPlansData[]>(
    []
  );
  const [isLoadingPaymentPlans, setIsLoadingPaymentPlans] =
    useState<boolean>(true);
  const { signOut } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleToastfy } = useContext(ToastfyContext);

  const createAccount = () => {
    const sendData = {
      username: signUpData.username,
      email: signUpData.email,
      password: signUpData.password,
      passwordConfirm: signUpData.passwordConfirm,
      profession: signUpData.profession,
      foundUsThrough:
        signUpData.foundUsThrough === 'outros meios'
          ? signUpData.otherWay
          : signUpData.foundUsThrough,
    };
    setIsCreateButtonLoading((prev) => !prev);
    setIsCreateAccountLoading((prev) => !prev);
    signUpService({ ...sendData, plan: selectedPlan.id.toString() })
      .then((response) => {
        if (selectedPlan.value > 0) {
          setIsOpenPaymentModal((prev) => !prev);
          handleToastfy({ message: response.message, type: 'success' });
        } else {
          handleToastfy({
            message:
              'Cadastro efetuado com sucesso! Verifique seu e-mail para validar a conta.',
            type: 'success',
          });
          navigate('/sign-in');
        }

        setIsCreateAccountLoading((prev) => !prev);
      })
      .catch((error) => {
        if (
          error.response.data.code === 'EXPIRED_TOKEN' ||
          error.response.data.code === 'TOKEN_REVOKED' ||
          error.response.data.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.response.data.message || error.response.data.code
                ? error.response.data.message || error.response.data.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsCreateButtonLoading((prev) => !prev);
      });
  };

  const isValidExpDate = (cvv: string) => {
    if (cvv.length !== 7) {
      handleToastfy({
        message:
          'Formato de validade inválido. Formato correto, exemplo: 12/2022',
        type: 'error',
      });
      return false;
    }

    return true;
  };

  const onSubmit: SubmitHandler<IInfoPagBankInputs> = async (data) => {
    if (isValidExpDate(dateExp)) {
      setIsPaymentFormLoading(true);

      const [area, number] = numberPhone.split(' ');
      const [birth_day, birth_month, birth_year] = birthDate.split('/');
      const [exp_month, exp_year] = dateExp.split('/');
      const birth_date = `${birth_year}-${birth_month}-${birth_day}`;
      const tax_id = cpf.replaceAll('.', '').replace('-', '');

      const card = encryptCardPagSeguro({
        expMonth: exp_month,
        expYear: exp_year,
        holder: data.name_card,
        number: numberCard.replaceAll(' ', ''),
        securityCode: data.security_code,
      });

      if (!card.hasError) {
        await createSignatureService({
          customer: {
            name: data.name,
            email: signUpData.email,
            tax_id,
            phones: [
              {
                country: '55',
                number: number.replace('-', ''),
                area: area.replace('(', '').replace(')', ''),
              },
            ],
            birth_date,
            billing_info: [
              {
                type: 'CREDIT_CARD',
                card: {
                  encrypted: card.encryptedCard,
                  holder: {
                    name: data.name_card,
                  },
                  security_code: data.security_code,
                },
              },
            ],
          },
          planId: selectedPlan.id.toString(),
          address: {
            city: address.city,
            complement: address.complement,
            country: data.country,
            locality: address.locality,
            number: data.number.trim(),
            postal_code: cep.replace('-', '').replace('.', ''),
            region_code: address.region_code,
            street: address.street,
          },
        })
          .then((response) => {
            // console.log(response);
            navigate(`/obrigado?email=${signUpData.email}`);
            setIsPaymentFormLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsPaymentFormLoading(false);
            if (error.code === 'CREATE_PREAPPROVAL_FAILS') {
              handleToastfy({
                message: 'Falha ao criar a assinatura',
                type: 'error',
              });
            }

            if (
              error.response.data.code === 'EXPIRED_TOKEN' ||
              error.response.data.code === 'TOKEN_REVOKED' ||
              error.response.data.code === 'TOKEN_NOT_FOUND'
            ) {
              handleToastfy({
                message: 'Sessão expirada!',
                type: 'error',
              });
              signOut();
              navigate('/sign-in');
            } else {
              handleToastfy({
                message:
                  !!error.response.data.message || error.response.data.code
                    ? error.response.data.message || error.response.data.code
                    : 'Ocorreu um erro ao processar a requisição.',
                type: 'error',
              });
            }

            console.log('error-after-payment', error);
          });
      } else {
        card.errors.forEach((error: IErrorPagBank) => {
          handleToastfy({
            message: error.message,
            type: 'error',
          });
        });
      }
    }
  };

  const getPaymentsPlans = useCallback(async () => {
    setIsLoadingPaymentPlans(true);
    await getPaymentsPlansService()
      .then((response) => {
        setPaymentsPlans(response.data);
        setIsLoadingPaymentPlans(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    getPaymentsPlans();
  }, [getPaymentsPlans]);

  useEffect(() => {
    const newValueCEP = cep.replace('-', '').replace('.', '');

    if (newValueCEP.length === 8) {
      getAddressByCEP({ cep: newValueCEP }).then(
        (addressData: IGetAddressResponse) => {
          setAddress({
            city: addressData.localidade,
            complement: addressData.complemento,
            locality: addressData.bairro,
            region_code: addressData.uf,
            street: addressData.logradouro,
          });
        }
      );
    }
  }, [cep]);

  return (
    <ChoosePlanContainer>
      <div className="content">
        <span className="lamp-idea" />
        <Link to="/sign-in">
          <span className="return-page">
            <IoMdArrowBack size={24} />
            <span className="return-page-text">voltar</span>
          </span>
        </Link>
        <div className="texts-box">
          <h2 className="heading-2">Escolha o seu plano</h2>
          <p className="subtitle">
            Para ter acesso a plataforma você precisa escolher o seu plano.
          </p>
        </div>
        {isLoadingPaymentPlans ? (
          <div className="plans-box-spinner">
            <Spinner size={100} />
          </div>
        ) : (
          <div className="plans-box">
            <div className="grid-plans">
              {paymentsPlans.map((plans, index) => {
                const idInput = uuidv4();
                return (
                  <React.Fragment key={index}>
                    <label htmlFor={idInput} style={{ display: 'flex' }}>
                      <CardPlan
                        isActive={selectedPlan.id === plans.id ? true : false}
                        onClick={() => setSelectedPlan(plans)}
                        listItems={plans.descriptions.map((description) => {
                          return {
                            icon: (
                              <BsFillCheckCircleFill
                                size={24}
                                color={
                                  theme.pallete.colors.brandPrimary.green[3]
                                }
                              />
                            ),
                            text: (
                              <React.Fragment>
                                <strong>{description.title}</strong>
                                {!!description.subtitle &&
                                  ` - ${description.subtitle}`}
                              </React.Fragment>
                            ),
                          };
                        })}
                        titleCard={plans.title}
                        price={plans.valueString}
                        subtitleCard={plans.description}
                        cursor
                      />
                    </label>
                    <input
                      type="radio"
                      name="select-plan"
                      id={idInput}
                      value={plans.id}
                      style={{ display: 'none' }}
                    />
                  </React.Fragment>
                );
              })}
            </div>
            <p className="plans-paragraph">
              *Cada crédito de IA equivale a mais ou menos uma solicitação. A
              contagem dos créditos pode variar caso a solicitação gere
              respostas que ultrapassem o valor do token pré-estabelecido pela
              OpenAI.
            </p>
            <Button
              variant="contained"
              buttonText="Criar conta"
              className="button"
              isDisabled={lodash.values(selectedPlan).every(lodash.isEmpty)}
              isLoading={isCreateButtonLoading}
              onClick={() => createAccount()}
            />
          </div>
        )}
        <p className="sign-in">
          Já possui uma conta?
          <Link to="/sign-in">
            <span className="link">Entrar</span>
          </Link>
        </p>
      </div>
      {isOpenPaymentModal && (
        <LightBox
          handleOpenModal={() => {
            navigate('/sign-in');
            handleToastfy({
              message:
                'Pagamento cancelado, tente novamente nas configurações de sua conta.',
              type: 'error',
            });
          }}
        >
          {isCreateAccountLoading ? (
            <div className="plans-box-spinner">
              <Spinner size={100} />
            </div>
          ) : (
            <div className="payment-modal">
              <h2 className="payment-modal-heading-2">
                Conta criada com sucesso!
              </h2>
              <div className="payment-modal-icon-box">
                <AiOutlineCheckCircle
                  size={56}
                  color={theme.pallete.colors.success.main}
                />
              </div>
              <p className="payment-modal-subtitle">
                Você receberá um e-mail para ativar a sua conta, mas antes
                efetue o pagamento para ativar o seu plano.
              </p>
              <div className="payment-modal-inputs">
                <div className="texts-group">
                  <span className="texts-label">Plano mensal escolhido:</span>
                  <span className="texts-value">{selectedPlan.title}</span>
                </div>
                <div className="texts-group">
                  <span className="texts-label">Detalhes do plano mensal:</span>
                  <span className="texts-value">
                    {selectedPlan.description}
                  </span>
                </div>
                <div className="texts-group">
                  <span className="texts-label">Valor a pagar:</span>
                  <span className="texts-value">
                    R$ {selectedPlan.valueString}/mês
                  </span>
                </div>
              </div>
              <div>
                <form
                  className="form-choose-plan"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="input-group">
                    <span className="texts-value">Informação pessoal</span>

                    <div className="inputs">
                      <Textfield
                        label="Nome completo"
                        type="text"
                        {...register('name', {
                          required: 'O campo é requerido.',
                        })}
                        required
                      />
                      <Textfield
                        label="Seu e-mail"
                        type="email"
                        value={signUpData.email}
                        readOnly
                        required
                      />
                      <TextFieldMask
                        label="Data de nascimento"
                        type="text"
                        mask={'99/99/9999'}
                        onChange={(e: any) => setBirthDate(e.target.value)}
                        value={birthDate}
                        required
                      />

                      <TextFieldMask
                        label="CPF"
                        type="text"
                        mask={'999.999.999-99'}
                        onChange={(e: any) => setCpf(e.target.value)}
                        value={cpf}
                        required
                      />

                      <TextFieldMask
                        label="Celular"
                        type="text"
                        mask={'(99) 99999-9999'}
                        onChange={(e: any) => setNumberPhone(e.target.value)}
                        value={numberPhone}
                        required
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <span className="texts-value">Pagamento</span>
                    <div className="inputs">
                      <Textfield
                        label="Nome impresso no cartão"
                        type="text"
                        {...register('name_card', {
                          required: 'O campo é requerido.',
                        })}
                        required
                      />
                      <TextFieldMask
                        label="Número do Cartão"
                        type="text"
                        onChange={(e: any) => {
                          setNumberCard(e.target.value);
                        }}
                        mask={'9999 9999 9999 9999'}
                        required
                      />

                      <TextFieldMask
                        label="Validade"
                        type="text"
                        mask={'99/9999'}
                        onChange={(e: any) => setDateExp(e.target.value)}
                        value={dateExp}
                        required
                      />

                      <Textfield
                        label="CVV"
                        type="text"
                        minLength={3}
                        maxLength={3}
                        {...register('security_code', {
                          required: 'O campo é requerido.',
                        })}
                        required
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <span className="texts-value">Endereço</span>

                    <div className="inputs">
                      <Textfield
                        label="País"
                        type="text"
                        {...register('country', {
                          required: 'O campo é requerido.',
                        })}
                        required
                      />
                      <TextFieldMask
                        label="CEP"
                        type="text"
                        mask={'99.999-999'}
                        value={cep}
                        onChange={(e: any) => {
                          setCep(e.target.value);
                        }}
                        required
                      />
                      <Textfield
                        label="UF"
                        type="text"
                        value={address.region_code}
                        readOnly
                        required
                      />
                      <Textfield
                        label="Cidade"
                        type="text"
                        value={address.city}
                        readOnly
                        required
                      />
                      <Textfield
                        label="Rua"
                        type="text"
                        value={address.street}
                        readOnly
                        required
                      />
                      <Textfield
                        label="Número"
                        type="text"
                        {...register('number', {
                          required: 'O campo é requerido.',
                        })}
                        required
                      />
                      <Textfield
                        label="Bairro"
                        type="text"
                        value={address.locality}
                        readOnly
                        required
                      />
                      <Textfield
                        label="Complemento"
                        type="text"
                        value={address.complement}
                        readOnly
                        // required
                      />
                    </div>
                  </div>
                  <div className="footer-form">
                    <Button
                      buttonText="Confirmar Assinatura"
                      type="submit"
                      buttonSize={160}
                      className="form-button-submit-plan"
                      isLoading={isPaymentFormLoading}
                      variant="contained"
                    />

                    <img
                      src="//assets.pagseguro.com.br/ps-integration-assets/banners/seguranca/seguranca_125x125.gif"
                      alt="Banner PagSeguro"
                      title="Compre com PagSeguro e fique sossegado"
                    ></img>
                  </div>
                </form>
              </div>
            </div>
          )}
        </LightBox>
      )}
    </ChoosePlanContainer>
  );
};

export default ChoosePlan;
