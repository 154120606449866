import styled from 'styled-components';

export const ReportMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  .brainstorming-create-room {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .room-button-write-contribution {
    margin: 0 auto;

    @media ${(props) => props.theme.medias.laptopSmall} {
      width: 18rem;
    }
  }

  .report-messages-button-group {
    display: flex;
    gap: 3rem;
    justify-content: center;

    @media ${(props) => props.theme.medias.mobile} {
      flex-direction: column-reverse;
    }
  }
`;
