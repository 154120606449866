import styled from 'styled-components';

export const TableContainer = styled.div`
  .table-wrapper {
    border-radius: 1rem;
    border: 2px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    background: ${(props) => props.theme.pallete.colors.common.white};
    overflow-x: auto;
  }

  table {
    position: relative;
    border-collapse: collapse;
    width: 100%;
    border-style: hidden;
    background-color: transparent;
    min-width: 60rem;
  }

  thead {
    th {
      background-color: ${(props) => props.theme.pallete.colors.common.white};
      position: sticky;
      top: 0;
      border: 2px solid
        ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
      padding: 1.2rem;
      white-space: nowrap;
      text-align: center;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      color: ${(props) => props.theme.pallete.colors.common.black};
      font-size: 1.4rem;
      user-select: none;
    }
    tr {
    }
  }

  tbody {
    tr {
    }
    td {
      border: 2px solid
        ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
      padding: 1.2rem;
      text-align: center;
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
      font-size: 1.4rem;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.6rem;
    margin: 1.6rem 0;

    .pagination-button-group {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
    }

    button {
      padding: 0.8rem;
      background: ${(props) => props.theme.pallete.colors.common.white};
      outline: 0;
      border: 1px solid ${(props) => props.theme.pallete.colors.common.black};
      border-radius: 4px;
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
      font-size: 1.6rem;
      width: 4rem;
      height: 4rem;

      &:not(:disabled) {
        cursor: pointer;
      }

      &:not(:disabled):hover {
        box-shadow: ${(props) => props.theme.pallete.shadows.default.n4};
      }

      &:disabled {
        background: ${(props) =>
          props.theme.pallete.colors.brandPrimary.gray[14]};
        border: 1px solid
          ${(props) => props.theme.pallete.colors.brandPrimary.gray[14]};
        color: ${(props) => props.theme.pallete.colors.common.white};
      }
    }
    .select-show-quantity-box {
      position: relative;
    }

    .select-show-quantity-arrow {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 2rem;
      width: 0.8rem;
      height: 0.8rem;
      border-left: 0.8rem solid transparent;
      border-right: 0.8rem solid transparent;
      border-top: 0.8rem solid
        ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
      pointer-events: none;
    }

    .select-show-quantity {
      width: 15rem;
      height: 4.8rem;
      padding: 1rem 4rem 1rem 2rem;
      outline: 0;
      display: block;
      font-size: 1.4rem;
      border: 1px solid #d7d7d7;
      border-radius: 100px;
      font-family: inherit;
      background-color: #ffffff;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
      background-position: calc(100% - 2.75rem) center !important;
      -moz-appearance: none !important;
      -webkit-appearance: none !important;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
    }

    span {
      font-size: 1.6rem;
    }
  }
`;
