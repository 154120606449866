import React, {
  InputHTMLAttributes,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import { UploadContainer } from './styles';
import { AiOutlineUpload } from 'react-icons/ai';
import { Accept, useDropzone } from 'react-dropzone';

interface IValidator {
  code: string;
  message: string;
}

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelColor?: string;
  defaultValue?: string | number;
  disabled?: boolean;
  value?: string | number;
  error?: string | undefined;
  acceptTypeFiles?: Accept;
  validator?: (file: File) => IValidator | null;
  handleCallbackArchive: (files: File[]) => void;
}

const Upload: React.ForwardRefRenderFunction<HTMLInputElement, IProps> = (
  {
    label,
    type,
    defaultValue,
    disabled,
    value,
    error,
    labelColor,
    handleCallbackArchive,
    acceptTypeFiles,
    validator,
    ...props
  },
  ref
) => {
  const [filesNames, setFilesNames] = useState<string[]>([]);

  const onDrop = useCallback(
    (files: File[]) => {
      const names: string[] = [];

      files.forEach((element) => {
        names.push(element.name);
      });

      setFilesNames(names);
      handleCallbackArchive(files);
    },
    [handleCallbackArchive]
  );

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    validator,
    accept: acceptTypeFiles,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return errors.map((e) => (
      <span key={e.code} className="textfield-error">
        {e.message}
      </span>
    ));
  });

  return (
    <UploadContainer labelColor={labelColor}>
      <label className="label">{label}</label>
      <div className="input-box" {...getRootProps()}>
        <input
          className="input"
          autoComplete="off"
          placeholder={props.placeholder}
          ref={ref}
          value={filesNames.join()}
          readOnly
          {...props}
        />
        <input {...getInputProps()} style={{ display: 'none' }} />
        <div className="icon-box">
          <AiOutlineUpload size={24} />
        </div>
      </div>
      {!!error && <span className="textfield-error">{error}</span>}
      {fileRejectionItems}
    </UploadContainer>
  );
};

export default forwardRef(Upload);
