import styled from 'styled-components';

interface IProps {
  color: string;
}

export const RadioColorsContainer = styled.div<IProps>`
  input {
    display: none;
  }

  input:checked ~ label::after {
    display: block;
  }

  label {
    position: relative;
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    background: ${(props) => props.color};
    border-radius: 50%;
    cursor: pointer;

    @media ${(props) => props.theme.medias.mobile} {
      width: 2rem;
      height: 2rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 4rem;
      height: 4rem;
      border: 2px solid ${(props) => props.color};
      border-radius: 50%;
      display: none;
    }
  }
`;
