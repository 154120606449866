import styled from 'styled-components';

export const GeneratedFromIAContainer = styled.div`
  padding: 9.6rem 0;
  ${(props) => props.theme.mixins.containerGridDefault};

  @media ${(props) => props.theme.medias.laptopSmall} {
    padding: 6rem 0;
  }

  @media ${(props) => props.theme.medias.mobile} {
    padding: 6rem 0 3rem;
  }

  .generated-from-ia-content {
    grid-column: center-start / center-end;
  }

  .generated-from-ia-heading-2 {
    @media ${(props) => props.theme.medias.laptopSmall} {
      text-align: center;
    }
  }

  .generated-from-ia-paragraph {
    font-size: 2rem;
    line-height: 2.8rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
    margin-bottom: 4rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      text-align: center;
    }
  }

  .generated-from-ia-content-box {
    display: flex;
    align-items: center;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column-reverse;
    }
  }

  .generated-from-ia-svg {
    min-width: 24rem;
    margin-left: -9rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      margin: 3rem 0;
      min-width: 24rem;
    }
  }

  .generated-from-ia-title {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  .generated-from-ia-copy-box {
    cursor: pointer;

    span {
      color: ${(props) => props.theme.pallete.colors.common.black};
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    }
  }

  .generated-from-ia-content-inner {
    background: ${(props) => props.theme.pallete.colors.common.white};
    padding: 3rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .generated-from-ia-title-box {
    display: flex;
    gap: 0.8rem;
    justify-content: space-between;
  }

  .generated-from-ia-copy-box {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    svg {
      transition: all 0.2s;
      color: ${(props) => props.theme.pallete.colors.common.black};
    }

    span {
      transition: all 0.2s;
    }
  }

  .generated-from-ia-copy-box:hover {
    span,
    svg {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.red[3]};
    }
  }

  .generated-from-ia-question,
  .generated-from-ia-answer {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .generated-from-ia-answer {
    white-space: pre-wrap;
    padding-right: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      padding-right: 0;
    }
  }

  .generated-from-ia-bellow {
    display: flex;
    gap: 0.8rem;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .generated-from-ia-warning-box {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    svg {
      flex: 0 0 auto;
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    }

    span {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.medium};

      @media ${(props) => props.theme.medias.laptopSmall} {
        text-align: center;
      }
    }

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
    }
  }

  .generated-from-ia-button {
    grid-column: center-start / center-end;
  }
`;
