import React, {
  HTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { v4 as uuidV4 } from 'uuid';
import { Select2Container } from './styles';

interface IOptions {
  optionName: string;
  optionValue: number | string;
}

interface IProps extends HTMLAttributes<HTMLInputElement> {
  setValue: any;
  textLabel: string;
  options: IOptions[];
  disabled?: boolean;
  error?: string;
  customOption?: React.ReactNode;
  isInvertMenu?: boolean;
  initialValue?: IOptions;
}

const Select2: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IProps & UseFormRegisterReturn
> = (
  {
    setValue,
    error,
    textLabel,
    options,
    disabled,
    customOption,
    isInvertMenu,
    initialValue,
    ...props
  },
  ref
) => {
  const [selected, setSelected] = useState<IOptions>({
    optionName: 'Selecione uma opção',
    optionValue: '',
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const refSubMenu = useRef<HTMLDivElement>(null);
  const labelId = uuidV4();

  useEffect(() => {
    const handleClickOutside = (evt: any) => {
      if (refSubMenu.current && !refSubMenu.current.contains(evt.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setValue(props.name, selected.optionValue);
  }, [selected, setValue, props.name]);

  useEffect(() => {
    !!initialValue && setSelected(initialValue);
  }, [initialValue]);

  return (
    <Select2Container
      isOpen={isOpen}
      ref={refSubMenu}
      isInvertMenu={isInvertMenu}
    >
      <label className="label" htmlFor={labelId}>
        {textLabel}
      </label>
      <div
        className="select-input-false"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div>{selected.optionName}</div>
      </div>
      <ul className="select-options">
        <li
          onClick={() => {
            setSelected({
              optionName: 'Selecione uma opção',
              optionValue: '',
            });
            setIsOpen((prev) => !prev);
          }}
        >
          Selecione uma opção
        </li>
        {options.map((option) => {
          return (
            <React.Fragment key={option.optionValue}>
              <li
                onClick={() => {
                  setSelected({
                    optionName: option.optionName,
                    optionValue: option.optionValue,
                  });
                  setIsOpen((prev) => !prev);
                }}
              >
                {option.optionName}
              </li>
            </React.Fragment>
          );
        })}
        {!!customOption && customOption}
      </ul>
      <input id={labelId} {...props} ref={ref} value={selected.optionValue} />
      {!!error && <span className="textfield-error">{error}</span>}
    </Select2Container>
  );
};

export default forwardRef(Select2);
