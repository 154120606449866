import styled from 'styled-components';

export const MemberItemContainer = styled.div`
  margin: 3rem 0 0;

  .member-item-select {
    width: max-content;
  }

  .member-item {
    display: flex;
    align-items: center;
    gap: 3rem;
    border-bottom: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    padding: 3rem 0;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
    }
  }

  .member-identity,
  .invite-identity {
    display: flex;
    flex-direction: column;
    margin-right: auto;

    @media ${(props) => props.theme.medias.laptopSmall} {
      margin-right: initial;
    }
  }

  .member-identity-name,
  .invite-identity-name {
    font-size: 2rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    margin-bottom: 1.2rem;
  }

  .member-identity-email,
  .invite-identity-email {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .grid-member-icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .confirm-delete-heading-3 {
    font-size: 2.4rem;
  }

  .confirm-delete-button-group {
    display: flex;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
`;
