import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../../../components/Button';
import SeparateLine from '../../../../components/SeparateLine';
import Select2 from '../../../../components/inputs/Select2';
import Textfield from '../../../../components/inputs/Textfield';
import { CreateNewContentContainer } from './styles';

interface IInputs {
  title: string;
  categoryMarket: string;
}

const CreateNewContent: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IInputs>({});
  const theme = useTheme();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    if (data.categoryMarket === 'creative-trigger') {
      navigate(
        `/dashboard/criar-gatilho-criativo?title=${data.title}&categoryContent=${data.categoryMarket}`
      );
    } else {
      navigate('/em-desenvolvimento');
    }
  };

  return (
    <CreateNewContentContainer>
      <h2 className="heading-2 heading-2--margin">Criar novo conteúdo</h2>
      <SeparateLine
        margin="0 0 5rem"
        color={theme.pallete.colors.brandPrimary.yellow[6]}
        lineHeight="3px"
      />
      <form className="form-create-option" onSubmit={handleSubmit(onSubmit)}>
        <Textfield
          className="form-create-item-1"
          label="Título"
          type="text"
          error={errors.title?.message}
          placeholder="E se sua ideia tivesse algo musical?"
          {...register('title', {
            required: 'O campo é requerido.',
          })}
        />
        <div className="form-create-item-2">
          <Select2
            setValue={setValue}
            textLabel="Selecione o segmento do mercado"
            error={errors.categoryMarket?.message}
            options={[
              {
                optionName: 'Gatilhos Criativos',
                optionValue: 'creative-trigger',
              },
            ]}
            {...register('categoryMarket', {
              required: 'O campo é requerido.',
            })}
          />
        </div>
        <div className="form-create-item-3">
          <Button
            buttonText="Criar"
            variant="contained"
            type="submit"
            buttonSize={180}
          />
        </div>
      </form>
    </CreateNewContentContainer>
  );
};

export default CreateNewContent;
