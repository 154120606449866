import styled from 'styled-components';

export const QuizStyled = styled.header`
  background-color: ${(props) =>
    props.theme.pallete.colors.backgrounds.neutral};
  width: 100%;
  min-height: 70vh;
  display: block;
  position: relative;

  @media ${(props) => props.theme.medias.laptopSmall} {
    /* height: calc(100vh - 16rem); */
    /* margin-top: 11rem; */
    padding-top: 3rem;
  }

  .grid-checkbox-box {
    margin-top: 1rem;
  }

  .content {
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 65vh;
    padding: 0 12rem;
    gap: 3;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
      padding: 0 6rem;
      /* height: 75vh; */
      width: 100%;
    }

    @media ${(props) => props.theme.medias.mobile} {
      padding: 0 3rem;
    }
  }

  .heading-3 {
    font-size: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
  }

  .box-content {
    margin: auto 0;
    min-width: 50%;
    width: 100%;
    padding-bottom: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 100%;
      text-align: center;
      /* height: 65vh; */
      /* margin: 6rem 0 0; */
      margin-bottom: 10rem;
      width: 100%;
      /* 
      form {
        width: 100%;
      } */
    }
  }

  .buttons-box {
    display: flex;
    align-items: center;
    gap: 3.2rem;
    margin-top: 2rem;
  }

  .grid-texts-title {
    display: block;
    color: #5b5b5b;

    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    /* line-height: 50px; */
  }

  .grid-option {
    display: flex;
    gap: 1rem;
  }

  .question {
    margin-top: 1rem;
    @media ${(props) => props.theme.medias.laptopSmall} {
      display: flex;
      align-items: start;
      flex-direction: column;
    }
  }

  .grid-texts-observation {
    font-size: 1.6rem;
    font-style: italic;
    font-weight: 300;
    /* line-height: 50px; */
  }

  .box-image {
    height: fit-content;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    border-radius: 2rem;
    width: 100%;
    height: 60vh;
    margin: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: none;
    }

    &--1 {
      background-image: url('/static/images/people/14.png');
    }
    &--2 {
      background-image: url('/static/images/people/15.png');
    }
    &--3 {
      background-image: url('/static/images/people/16.png');
    }
  }
`;
