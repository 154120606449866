import styled from 'styled-components';

export const ForgotPasswordStyled = styled.div`
  padding: 6rem 0 0;
  background-image: linear-gradient(#fff 33.3%, transparent 0);

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 11rem;
  }

  .forgot-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 40rem;
    max-width: 56rem;
    margin: 0 auto 8rem;
    padding: 0 6rem;
  }

  .text-box {
    padding: 0 3rem;
    margin-bottom: 6rem;
  }

  .heading-2 {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-size: 3.6rem;
    line-height: 5.8rem;
    text-align: center;
  }

  .heading-subtitle {
    font-size: 1.6rem;
    line-height: 3rem;
    text-align: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    margin-bottom: 3rem;

    a {
      text-decoration: none;
    }
  }

  .link {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    position: relative;
    margin-left: 0.8rem;

    ${(props) => props.theme.mixins.linkUnderlineEffect};
  }

  .sign-in {
    line-height: 5rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    user-select: none;

    a {
      text-decoration: none;
      user-select: none;
    }
  }
`;
