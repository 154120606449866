import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { useTheme } from 'styled-components';
import SeparateLine from '../../../../components/SeparateLine';
import TableAsync from '../../../../components/TableAsync';
import Spinner from '../../../../components/loadings/Spinner';
import AuthContext from '../../../../contexts/AuthContext';
import { ToastfyContext } from '../../../../contexts/ToastfyContext';
import { listMarketSegmentService } from '../../../../services/marketSegments.service';
import { IListMarketSegment } from '../../../../services/marketSegments.types';
import { MarketCategoriesContainer, StatusMarketCategories } from './styles';

interface IMarketCategories {
  categories: string;
  tools: string;
  edit: React.ReactNode;
  status: React.ReactNode;
}

const MarketCategories: React.FC = () => {
  const [segmentsMarket, setSegmentsMarket] = useState<IListMarketSegment>(
    {} as IListMarketSegment
  );
  const { handleToastfy } = useContext(ToastfyContext);
  const [pageSizeAsync, setPageSizeAsync] = useState<number>(10);
  const [pageIndexAsync, setPageIndexAsync] = useState<number>(0);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isLoadingSegments, setIsLoadingSegments] = useState<boolean>(true);
  const { signOut } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const marketColumns: Column<IMarketCategories>[] = useMemo(
    (): Column<IMarketCategories>[] => [
      {
        Header: 'Categorias',
        accessor: 'categories',
      },
      {
        Header: 'Ferramentas',
        accessor: 'tools',
      },
      {
        Header: 'Editar',
        accessor: 'edit',
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
      },
    ],
    []
  );

  const marketData = useMemo(
    (): IMarketCategories[] =>
      segmentsMarket.data?.map((segment) => {
        return {
          categories: segment.label,
          tools: 'Gatilhos Criativos',
          edit:
            segment._id === '64398faf9de288a87d01099c' ||
            segment.label === 'Genérica' ? (
              ''
            ) : (
              <FiEdit3
                size={20}
                onClick={() =>
                  navigate(`/dashboard/categoria-de-mercado?id=${segment._id}`)
                }
                style={{ cursor: 'pointer', userSelect: 'none' }}
              />
            ),
          status: (
            <React.Fragment>
              <StatusMarketCategories
                backgroundColor={
                  segment.isActive
                    ? theme.pallete.colors.brandPrimary.yellow[6]
                    : theme.pallete.colors.brandPrimary.gray[4]
                }
              >
                {segment.isActive ? 'Ativado' : 'Desativado'}
              </StatusMarketCategories>
              <span className="status-market-categories-none">
                {segment.isActive ? 'Ativado' : 'Desativado'}
              </span>
            </React.Fragment>
          ),
        };
      }),
    [segmentsMarket, theme, navigate]
  );

  const getSegmentsMarket = useCallback(async () => {
    setIsTableLoading(true);
    await listMarketSegmentService({
      order: 'DESC',
      orderBy: 'createdAt',
      perPage: pageSizeAsync,
      page: pageIndexAsync + 1,
    })
      .then((response) => {
        setSegmentsMarket(response);
        setIsLoadingSegments(false);
        setIsTableLoading(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsTableLoading(false);
      });
  }, [pageSizeAsync, pageIndexAsync, handleToastfy, signOut, navigate]);

  useEffect(() => {
    getSegmentsMarket();
  }, [getSegmentsMarket]);

  return (
    <MarketCategoriesContainer>
      <h2 className="heading-2 heading-2--margin">Categorias do mercado</h2>
      <SeparateLine
        margin="0 0 5rem"
        color={theme.pallete.colors.brandPrimary.yellow[6]}
        lineHeight="3px"
      />
      {isLoadingSegments ? (
        <div className="segments--list-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <React.Fragment>
          <h4 className="heading-4">
            Você pode ativar ou desativar os segmentos.
          </h4>
          <TableAsync
            columns={marketColumns}
            data={marketData}
            pageSizeAsync={pageSizeAsync}
            setPageSizeAsync={setPageSizeAsync}
            pageIndexAsync={pageIndexAsync}
            setPageIndexAsync={setPageIndexAsync}
            totalItems={segmentsMarket.total}
            isTableLoading={isTableLoading}
          />
        </React.Fragment>
      )}
    </MarketCategoriesContainer>
  );
};

export default MarketCategories;
