import React, { Dispatch, SetStateAction, useContext } from 'react';
import { FiMessageSquare } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import Button from '../Button';
import { BrainstormingCommentMessageContainer } from './styles';
import {
  IMessageRoom,
  IUserBrainstormingRoom,
} from '../../services/brainstormingRoom.types';
import moment from 'moment';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import AuthContext from '../../contexts/AuthContext';
import Avatar from '../Avatar';

interface IMessageBrainstormingRoomData {
  _id: string;
  userId: string;
  label: string;
  description: string;
  isActive: boolean;
  messages?: IMessageRoom[];
  createdAt: Date;
  updatedAt: Date;
  createdAtMessage: string;
  user: IUserBrainstormingRoom;
  totalMessages?: number;
}

interface IProps {
  buttonText: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isBordered?: boolean;
  removeButton?: boolean;
  roomData?: IMessageBrainstormingRoomData;
  setIsEditRoom?: Dispatch<SetStateAction<boolean>>;
}

const BrainstormingCommentMessage: React.FC<IProps> = ({
  buttonText,
  isBordered,
  removeButton,
  roomData,
  onClick,
  setIsEditRoom,
}) => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  const calcRoomCreatedTime = (createdAt: Date) => {
    return moment().diff(createdAt, 'minutes');
  };

  const diffTime = (date: moment.MomentInput) => {
    const createAt = moment(date);
    const now = moment();
    const diffDays = now.diff(createAt, 'days');
    const diffHours = now.diff(createAt, 'hours');
    const diffMinutes = now.diff(createAt, 'minutes');

    if (diffDays > 0) {
      return `${diffDays} ${diffDays === 1 ? 'dia' : 'dias'}`;
    }
    if (diffHours > 0) {
      return `${diffHours} ${diffHours === 1 ? 'hora' : 'horas'}`;
    }
    return `${diffMinutes} ${diffMinutes === 1 ? 'minuto' : 'minutos'}`;
  };

  return (
    <BrainstormingCommentMessageContainer isBordered={isBordered || false}>
      <div className="brainstorming-message-header">
        <div className="brainstorming-message-avatar-box">
          {roomData?.user?.avatar ? (
            <Avatar
              backgroundImageLink={`${process.env.REACT_APP_URL_IMAGE_BASE}/${roomData.user.avatar}`}
              height="6rem"
              width="6rem"
            />
          ) : (
            <span className="brainstorming-message-avatar" />
          )}
          <span className="brainstorming-message-name">
            {!!roomData?.user?.username
              ? roomData?.user?.username
              : 'Sem nome de usuário'}
          </span>
          {calcRoomCreatedTime(roomData?.createdAt || new Date()) > 5 ? (
            <span className="brainstorming-message-time">
              &#8226;&nbsp; há{' '}
              {!!roomData?.createdAtMessage
                ? roomData?.createdAtMessage
                : diffTime(roomData?.createdAt)}
            </span>
          ) : (
            <span className="brainstorming-message-time-new-room">
              &#8226;&nbsp; nova sala
            </span>
          )}
        </div>
        {!removeButton && (
          <Button
            variant="outlined"
            buttonText={buttonText}
            onClick={onClick}
            customColors={{
              backgroundColor: theme.pallete.colors.common.black,
              hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
              hoverTextColor: theme.pallete.colors.common.black,
              textColor: theme.pallete.colors.common.black,
            }}
          />
        )}
      </div>
      <h3 className="brainstorming-message-heading">{roomData?.label}</h3>
      <p className="brainstorming-message-text">{roomData?.description}</p>
      <div className="brainstorming-message-group-icon">
        {roomData?.userId === user?._id && !!setIsEditRoom && (
          <div
            className="brainstorming-message-text-edit"
            onClick={() => setIsEditRoom((prev) => !prev)}
          >
            <MdOutlineModeEditOutline size={24} />
            <span>Editar</span>
          </div>
        )}
        <div className="brainstorming-message-count">
          <FiMessageSquare size={26} />
          <span className="brainstorming-message-number">
            {!!roomData?.totalMessages
              ? roomData.totalMessages
              : !!roomData?.messages
              ? roomData?.messages.length
              : 0}
          </span>
        </div>
      </div>
    </BrainstormingCommentMessageContainer>
  );
};

export default BrainstormingCommentMessage;
