import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BsGearFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../../../components/Button';
import DashboardConfigCard from '../../../../components/DashboardConfigCard';
import StatusUserSmall from '../../../../components/StatusUserSmall';
import Textfield from '../../../../components/inputs/Textfield';
import ConfirmLightBox from '../../../../components/lightboxes/ConfirmLightBox';
import LightBox from '../../../../components/lightboxes/LightBox';
import LightboxRemoveCredits from '../../../../components/lightboxes/LightboxRemoveCredits';
import LightboxSendCredits from '../../../../components/lightboxes/LightboxSendCredits';
import Spinner from '../../../../components/loadings/Spinner';
import AuthContext from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
import {
  listUserService,
  updateUserService,
} from '../../../../services/admin.service';
import { IUser } from '../../../../services/user.types';
import { UsersRegisteredsContainer } from './styles';

const UsersRegistereds: React.FC = () => {
  const [listUsers, setListUsers] = useState<IUser[]>([]);
  const [searchUser, setSearchUser] = useState<string>('');
  const [pageUsers, setPageUsers] = useState<number>(1);
  const [editUser, setEditUser] = useState<IUser>({} as IUser);
  const [isControlUser, setIsControlUser] = useState<boolean>(false);
  const [isLoadingEditUser, setIsLoadingEditUser] = useState<boolean>(false);
  const [openLightboxCredits, setOpenLightboxCredits] =
    useState<boolean>(false);
  const [openLightboxRemoveCredits, setOpenLightboxRemoveCredits] =
    useState<boolean>(false);
  const [isActivateUser, setIsActivateUser] = useState<boolean>(false);
  const [isBanUser, setIsBanUser] = useState<boolean>(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);
  const [isLoadingMoreUsers, setIsLoadingMoreUsers] = useState<boolean>(true);
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const [totalItems, setTotalItems] = useState<number>(0);
  const perPageUser = 6;

  const handleActivateUser = async () => {
    setIsLoadingEditUser((prev) => !prev);
    await updateUserService({
      email: editUser.email,
      isActive: true,
      role: editUser.role,
    })
      .then((response) => {
        handleToastfy({
          message: 'Usuário reativado com sucesso!',
          type: 'success',
        });
        getListUser();
        setIsActivateUser((prev) => !prev);
        setIsControlUser((prev) => !prev);
        setIsLoadingEditUser((prev) => !prev);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingEditUser((prev) => !prev);
      });
  };

  const handleRemoveUser = async () => {
    setIsLoadingEditUser((prev) => !prev);
    await updateUserService({
      email: editUser.email,
      isActive: false,
      role: editUser.role,
    })
      .then((response) => {
        handleToastfy({
          message: 'Usuário banido com sucesso!',
          type: 'success',
        });
        getListUser();
        setIsControlUser((prev) => !prev);
        setIsBanUser((prev) => !prev);
        setIsLoadingEditUser((prev) => !prev);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingEditUser((prev) => !prev);
      });
  };

  const loadMoreUsers = async (page?: number) => {
    setIsLoadingMoreUsers(true);
    await listUserService({
      order: 'DESC',
      orderBy: 'createdAt',
      role: 'user',
      page: page,
      perPage: perPageUser,
      withSubUser: false,
      find: searchUser,
    })
      .then((response) => {
        setIsLoadingMoreUsers(false);
        !!page && setPageUsers((prev) => prev + 1);
        setListUsers((prev) => [...prev, ...response.users]);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
    setIsLoadingMoreUsers(false);
  };

  const handleSearchUser = async () => {
    setIsLoadingUsers(true);
    setIsLoadingMoreUsers(true);
    await listUserService({
      order: 'DESC',
      orderBy: 'createdAt',
      role: 'user',
      page: 1,
      perPage: perPageUser,
      withSubUser: false,
      find: searchUser,
    })
      .then((response) => {
        setListUsers(response.users);
        setIsLoadingUsers(false);
        setIsLoadingMoreUsers(false);
        setTotalItems(response.total);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  };

  const getListUser = useCallback(async () => {
    setIsLoadingUsers(true);
    setIsLoadingMoreUsers(true);
    await listUserService({
      order: 'DESC',
      orderBy: 'createdAt',
      role: 'user',
      page: 1,
      perPage: perPageUser,
      withSubUser: false,
    })
      .then((response) => {
        setListUsers(response.users);
        setIsLoadingUsers(false);
        setIsLoadingMoreUsers(false);
        setTotalItems(response.total);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    getListUser();
  }, [getListUser]);

  return (
    <UsersRegisteredsContainer>
      <DashboardConfigCard>
        <div className="users-registereds-block-text">
          <h3 className="users-registereds-heading-3">Usuários Cadastrados</h3>
          <p className="users-registereds-paragraph">
            Adicione, visualize e edite todos as pessoas elencadas na sua equipe
            para colaborar com a manutenção da plataforma.
          </p>
        </div>
        <h3 className="users-registereds-searching-heading-3">
          Pesquisar usuário
        </h3>
        <div className="users-registereds-block-search">
          <Textfield
            label=""
            type="text"
            value={searchUser}
            onChange={(event) => setSearchUser(event.target.value)}
          />
          <Button
            buttonText="Buscar"
            variant="contained"
            type="button"
            onClick={handleSearchUser}
            isLoading={isLoadingUsers}
          />
        </div>
        {isLoadingUsers ? (
          <div className="users-member-spinner">
            <Spinner size={100} />
          </div>
        ) : (
          <div className="grid-users-registereds-list">
            {listUsers.map((user) => {
              return (
                <div className="users-registereds-item" key={user._id}>
                  <div className="users-registereds-identity">
                    <div className="users-registereds-identity-name-box">
                      <span className="users-registereds-identity-name">
                        {user.username}
                      </span>
                      <StatusUserSmall
                        type={
                          user.currentPlan === 'free'
                            ? 'gratuito'
                            : 'criativista'
                        }
                      />
                    </div>
                    <span className="users-registereds-identity-email">
                      {user.email}
                    </span>
                    <span className="users-registereds-credits">
                      <strong>O usuário possui:</strong>{' '}
                      {!!user.balance ? user.balance : 0} créditos
                    </span>
                    <span className="users-registereds-isactive">
                      Ativo:{' '}
                      <span
                        style={{
                          color: user.isActive
                            ? theme.pallete.colors.success.main
                            : theme.pallete.colors.error.main,
                        }}
                      >
                        {user.isActive ? 'Sim' : 'Não'}
                      </span>
                    </span>
                  </div>
                  <BsGearFill
                    size={24}
                    color={theme.pallete.colors.brandPrimary.gray[9]}
                    className="button-control"
                    onClick={() => {
                      setEditUser(user);
                      setIsControlUser((prev) => !prev);
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
        {Math.ceil(totalItems / perPageUser) > pageUsers && (
          <button
            className="button-load-more"
            onClick={() => {
              loadMoreUsers(pageUsers + 1);
            }}
          >
            {isLoadingMoreUsers ? (
              <Spinner size={24} />
            ) : (
              <span>Carregar mais...</span>
            )}
          </button>
        )}
        {isControlUser && (
          <LightBox handleOpenModal={() => setIsControlUser((prev) => !prev)}>
            <h3 className="confirm-delete-room-h3">Controle de usuário</h3>
            <div className="input-group">
              <span className="credits-label">Usuário:</span>
              <span className="credits-name">{editUser.username}</span>
            </div>
            <div className="input-group">
              <span className="credits-label">Email:</span>
              <span className="credits-name">{editUser.email}</span>
            </div>
            <div className="input-group">
              <span className="credits-label">O usuário possui:</span>
              <span className="credits-name">
                {!!editUser.balance ? editUser.balance : 0} créditos
              </span>
            </div>
            <div className="input-group">
              <span className="credits-label">O usuário está ativo:</span>
              <span
                className="credits-name"
                style={{
                  color: editUser.isActive
                    ? theme.pallete.colors.success.main
                    : theme.pallete.colors.error.main,
                  fontWeight: theme.typeFaceWeight.bold,
                }}
              >
                {editUser.isActive ? 'Sim' : 'Não'}
              </span>
            </div>
            <div className="buttons-group-control-user">
              <div className="button-group-control-user">
                {editUser.isActive && (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      buttonText="Enviar créditos"
                      customColors={{
                        backgroundColor: theme.pallete.colors.common.black,
                        hoverBackgroundColor:
                          theme.pallete.colors.brandPrimary.gray[9],
                        hoverTextColor: theme.pallete.colors.common.white,
                        textColor: theme.pallete.colors.common.white,
                      }}
                      onClick={() => {
                        setOpenLightboxCredits((prev) => !prev);
                      }}
                    />
                    <Button
                      buttonText="Remover créditos"
                      variant="outlined"
                      customColors={{
                        backgroundColor:
                          theme.pallete.colors.brandPrimary.red[3],
                        hoverBackgroundColor:
                          theme.pallete.colors.brandPrimary.red[3],
                        hoverTextColor:
                          theme.pallete.colors.brandPrimary.red[3],
                        textColor: theme.pallete.colors.brandPrimary.red[3],
                      }}
                      onClick={() => {
                        setOpenLightboxRemoveCredits((prev) => !prev);
                      }}
                    />
                  </React.Fragment>
                )}
              </div>
              <div className="button-group-control-user">
                {!editUser.isActive && (
                  <Button
                    buttonText="Reativar usuário"
                    variant="contained"
                    customColors={{
                      backgroundColor: theme.pallete.colors.common.black,
                      hoverBackgroundColor:
                        theme.pallete.colors.brandPrimary.gray[9],
                      hoverTextColor: theme.pallete.colors.common.white,
                      textColor: theme.pallete.colors.common.white,
                    }}
                    buttonSize={160}
                    onClick={() => setIsActivateUser((prev) => !prev)}
                  />
                )}
                <Button
                  buttonText="Banir usuário"
                  variant="outlined"
                  customColors={{
                    backgroundColor: theme.pallete.colors.brandPrimary.red[3],
                    hoverBackgroundColor:
                      theme.pallete.colors.brandPrimary.red[3],
                    hoverTextColor: theme.pallete.colors.brandPrimary.red[3],
                    textColor: theme.pallete.colors.brandPrimary.red[3],
                  }}
                  onClick={() => {
                    setIsBanUser((prev) => !prev);
                  }}
                />
              </div>
            </div>
          </LightBox>
        )}
        {openLightboxCredits && (
          <LightboxSendCredits
            setOpenLightboxCredits={setOpenLightboxCredits}
            setIsControlUser={setIsControlUser}
            user={editUser}
            getListUser={getListUser}
          />
        )}
        {openLightboxRemoveCredits && (
          <LightboxRemoveCredits
            setOpenLightboxRemoveCredits={setOpenLightboxRemoveCredits}
            setIsControlUser={setIsControlUser}
            user={editUser}
            getListUser={getListUser}
          />
        )}
        {isActivateUser && (
          <ConfirmLightBox
            buttonCancelLabel="Não"
            buttonConfirmlLabel="Sim"
            buttonConfirmFunction={() => handleActivateUser()}
            isLoading={isLoadingEditUser}
            setIsOpenModal={setIsActivateUser}
            title="Deseja Reativar o usuário?"
          />
        )}
        {isBanUser && (
          <ConfirmLightBox
            buttonCancelLabel="Não"
            buttonConfirmlLabel="Sim"
            buttonConfirmFunction={() => handleRemoveUser()}
            isLoading={isLoadingEditUser}
            setIsOpenModal={setIsBanUser}
            title="Deseja banir o usuário?"
          />
        )}
      </DashboardConfigCard>
    </UsersRegisteredsContainer>
  );
};

export default UsersRegistereds;
