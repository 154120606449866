import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BsEye } from 'react-icons/bs';
import { FiEdit3 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { useTheme } from 'styled-components';
import SeparateLine from '../../../../components/SeparateLine';
import TableAsync from '../../../../components/TableAsync';
import Spinner from '../../../../components/loadings/Spinner';
import AuthContext from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
// import { listCreativeChallengeService } from '../../../../services/creativeChallenges.service';
// import { IListCreativeChallengeResponse } from '../../../../services/creativeChallenges.types';
import { listCreativeChallengeService } from '../../../../services/creativeChallenge.service';
import { IListCreativeChallenge } from '../../../../services/creativeChallenge.types';
import { listCreativeChallengeTagService } from '../../../../services/creativeChallengeTag.service';
import { IListCreativeChallengeTag } from '../../../../services/creativeChallengeTag.types';
import {
  CreativeChallengeListContainer,
  StatusCreativeChallenge,
} from './styles';
import LightboxTag from '../../../../components/lightboxes/LightboxTag';

interface ICreativeChallengeTagData {
  created_at: string;
  label: string;
  description: string;
  edit: React.ReactNode;
  status: React.ReactNode;
}

interface ICreativeChallengeData {
  created_at: string;
  label: string;
  tag: string;
  edit: React.ReactNode;
  read: React.ReactNode;
}

const CreativeChallengeList: React.FC = () => {
  const { handleToastfy } = useContext(ToastfyContext);

  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const [pageSizeCreativeChallengeAsync, setPageSizeCreativeChallengeAsync] =
    useState<number>(10);
  const [pageIndexCreativeChallengeAsync, setPageIndexCreativeChallengeAsync] =
    useState<number>(0);
  const [isTagOpen, setIsTagOpen] = useState<boolean>(false);

  const [idTag, setIdTag] = useState<string>('');
  const [
    pageSizeCreativeChallengeTagAsync,
    setPageSizeCreativeChallengeTagAsync,
  ] = useState<number>(10);
  const [
    pageIndexCreativeChallengeTagAsync,
    setPageIndexCreativeChallengeTagAsync,
  ] = useState<number>(0);

  const [isCreativeChallengeLoading, setIsCreativeChallengeLoading] =
    useState<boolean>(true);
  const [isCreativeChallengeTagLoading, setIsCreativeChallengeTagLoading] =
    useState<boolean>(true);

  const [creativeChallenge, setCreativeChallenge] =
    useState<IListCreativeChallenge>({} as IListCreativeChallenge);
  const [creativeChallengeTag, setCreativeChallengeTag] =
    useState<IListCreativeChallengeTag>({} as IListCreativeChallengeTag);

  const creativeChallengeTagColumns: Column<ICreativeChallengeTagData>[] =
    useMemo(
      (): Column<ICreativeChallengeTagData>[] => [
        {
          Header: 'Data da Criação',
          accessor: 'created_at',
        },
        {
          Header: 'Título',
          accessor: 'label',
        },
        {
          Header: 'Descrição',
          accessor: 'description',
        },
        {
          Header: 'Editar',
          accessor: 'edit',
          disableSortBy: true,
        },
        {
          Header: 'Status',
          accessor: 'status',
          disableSortBy: true,
        },
      ],
      []
    );

  const creativeChallengeColumns: Column<ICreativeChallengeData>[] = useMemo(
    (): Column<ICreativeChallengeData>[] => [
      {
        Header: 'Data da Criação',
        accessor: 'created_at',
      },
      {
        Header: 'Título',
        accessor: 'label',
      },
      {
        Header: 'Tags',
        accessor: 'tag',
      },

      {
        Header: 'Editar',
        accessor: 'edit',
        disableSortBy: true,
      },
      {
        Header: 'Ver',
        accessor: 'read',
        disableSortBy: true,
      },
    ],
    []
  );

  const creativeChallengeData = useMemo(
    (): ICreativeChallengeData[] =>
      creativeChallenge.data?.map((value) => {
        return {
          created_at: moment(value.createdAt)
            .locale('pt-br')
            .format('DD/MM/YYYY'),
          label: value.label,
          tag: value.tag,
          // totalMessages: room.totalMessages,
          read: (
            <BsEye
              size={20}
              onClick={() =>
                navigate(`/dashboard/ver-desafio-criativista?id=${value._id}`)
              }
              style={{ cursor: 'pointer', userSelect: 'none' }}
            />
          ),
          edit: (
            <FiEdit3
              size={20}
              color={theme.pallete.colors.brandPrimary.red[3]}
              onClick={() => {
                navigate(
                  `/dashboard/editar-desafio-criativista?id=${value._id}`
                );
              }}
              style={{ cursor: 'pointer', userSelect: 'none' }}
            />
          ),
        };
      }),
    [creativeChallenge, theme.pallete.colors.brandPrimary.red, navigate]
  );

  const creativeChallengeTagData = useMemo(
    (): ICreativeChallengeTagData[] =>
      creativeChallengeTag.data?.map((value) => {
        return {
          created_at: moment(value.createdAt)
            .locale('pt-br')
            .format('DD/MM/YYYY'),
          label: value.label,
          description: value.description,

          // tag: value.tag,
          // totalMessages: room.totalMessages,

          edit: (
            <FiEdit3
              size={20}
              color={theme.pallete.colors.brandPrimary.red[3]}
              onClick={() => {
                setIdTag(value._id);
                setIsTagOpen(true);
              }}
              style={{ cursor: 'pointer', userSelect: 'none' }}
            />
          ),
          status: (
            <React.Fragment>
              <StatusCreativeChallenge
                backgroundColor={
                  value.isActive
                    ? theme.pallete.colors.brandPrimary.yellow[6]
                    : theme.pallete.colors.brandPrimary.gray[4]
                }
              >
                {value.isActive ? 'Ativado' : 'Desativado'}
              </StatusCreativeChallenge>
              <span className="status-market-categories-none">
                {value.isActive ? 'Ativado' : 'Desativado'}
              </span>
            </React.Fragment>
          ),
        };
      }),
    [
      creativeChallengeTag,
      theme.pallete.colors.brandPrimary.red,
      theme.pallete.colors.brandPrimary.yellow,
      theme.pallete.colors.brandPrimary.gray,
      // navigate,
    ]
  );

  const getListCreativeChallenge = useCallback(async () => {
    // setLoadingTriggerList(true);
    setIsCreativeChallengeLoading(true);
    await listCreativeChallengeService({
      order: 'DESC',
      orderBy: 'createdAt',
      perPage: pageSizeCreativeChallengeAsync,
      page: pageIndexCreativeChallengeAsync + 1,
    })
      .then((response) => {
        setCreativeChallenge(response);
        setIsCreativeChallengeLoading(false);
        // setIsCreativeTriggerTableLoading(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsCreativeChallengeLoading(false);
      });
  }, [
    handleToastfy,
    navigate,
    pageIndexCreativeChallengeAsync,
    pageSizeCreativeChallengeAsync,
    signOut,
  ]);

  const getListCreativeChallengeTag = useCallback(async () => {
    // setLoadingTriggerList(true);
    setIsCreativeChallengeTagLoading(true);
    await listCreativeChallengeTagService({
      order: 'DESC',
      orderBy: 'createdAt',
      perPage: pageSizeCreativeChallengeTagAsync,
      page: pageIndexCreativeChallengeTagAsync + 1,
    })
      .then((response) => {
        setCreativeChallengeTag(response);
        setIsCreativeChallengeTagLoading(false);
        // setIsCreativeTriggerTableLoading(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsCreativeChallengeLoading(false);
      });
  }, [
    handleToastfy,
    navigate,
    pageIndexCreativeChallengeTagAsync,
    pageSizeCreativeChallengeTagAsync,
    signOut,
  ]);

  useEffect(() => {
    getListCreativeChallenge();
  }, [getListCreativeChallenge]);

  useEffect(() => {
    getListCreativeChallengeTag();
  }, [getListCreativeChallengeTag]);

  return (
    <CreativeChallengeListContainer>
      <h2 className="see-edit-heading-2 see-edit-heading-2--margin">
        Desafios Criativistas
      </h2>
      <SeparateLine
        margin="0 0 5rem"
        color={theme.pallete.colors.brandPrimary.yellow[6]}
        lineHeight="3px"
      />
      {isCreativeChallengeLoading ? (
        <div className="creative-challenge-list-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <TableAsync
          columns={creativeChallengeColumns}
          data={creativeChallengeData}
          pageSizeAsync={pageSizeCreativeChallengeAsync}
          setPageSizeAsync={setPageSizeCreativeChallengeAsync}
          pageIndexAsync={pageIndexCreativeChallengeAsync}
          setPageIndexAsync={setPageIndexCreativeChallengeAsync}
          totalItems={creativeChallenge.total}
          isTableLoading={isCreativeChallengeLoading}
        />
      )}
      <h2 className="see-edit-heading-2 see-edit-heading-2--margin">Tags</h2>
      <SeparateLine
        margin="0 0 5rem"
        color={theme.pallete.colors.brandPrimary.yellow[6]}
        lineHeight="3px"
      />
      {isCreativeChallengeTagLoading ? (
        <div className="creative-challenge-list-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <TableAsync
          columns={creativeChallengeTagColumns}
          data={creativeChallengeTagData}
          pageSizeAsync={pageSizeCreativeChallengeTagAsync}
          setPageSizeAsync={setPageSizeCreativeChallengeTagAsync}
          pageIndexAsync={pageIndexCreativeChallengeTagAsync}
          setPageIndexAsync={setPageIndexCreativeChallengeTagAsync}
          totalItems={creativeChallengeTag.total}
          isTableLoading={isCreativeChallengeTagLoading}
        />
      )}

      {isTagOpen && (
        <LightboxTag
          handleOpenModal={() => setIsTagOpen((prev) => !prev)}
          setIsTagOpen={setIsTagOpen}
          id={idTag}
        />
      )}
    </CreativeChallengeListContainer>
  );
};

export default CreativeChallengeList;
