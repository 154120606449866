import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  FieldErrors,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../../../components/Button';
import SeparateLine from '../../../../components/SeparateLine';
import RadioColors from '../../../../components/inputs/RadioColors';
import Select from '../../../../components/inputs/Select';
import Select2 from '../../../../components/inputs/Select2';
import TextArea from '../../../../components/inputs/TextArea';
import Textfield from '../../../../components/inputs/Textfield';
import Upload from '../../../../components/inputs/Upload';
import ImagePreview from '../../../../components/lightboxes/ImagePreview';
import LightboxCategoryMarket from '../../../../components/lightboxes/LightboxCategoryMarket';
import { AuthContext } from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
import { createCreativeTriggerService } from '../../../../services/creativeTriggers.service';
import { listMarketSegmentService } from '../../../../services/marketSegments.service';
import { IListMarketSegmentData } from '../../../../services/marketSegments.types';
import { CreateCreativeTriggerContainer } from './styles';

interface IInsights {
  labelReference: string;
  position: string;
  link: string;
  description: string;
  image: string;
  imagePreview?: string;
  file?: File[];
}

interface IInputFields {
  label: string;
  marketSegmentId: string;
  color: string;
  description: string;
  insights: IInsights[];
  categoryContent?: string;
}

const CreateCreativeTrigger: React.FC = () => {
  const theme = useTheme();
  const [marketSegments, setMarketSegments] = useState<
    IListMarketSegmentData[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [revokeList, setRevokeList] = useState<string[]>([]);
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [openImageLink, setOpenImageLink] = useState<string>('');
  const navigate = useNavigate();
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [isNewCategoryOpen, setIsNewCategoryOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    setValue,
    watch,
    clearErrors,
  } = useForm<IInputFields>({
    defaultValues: {
      insights: [
        {
          description: '',
          labelReference: '',
          link: '',
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray<IInputFields, 'insights'>({
    control,
    name: 'insights',
  });
  const maximumFilesSize = 9000000; // Equal 9MB

  const onError = (errors: FieldErrors<IInputFields>) => {
    handleToastfy({
      message: 'Existem campos que não foram preenchidos.',
      type: 'error',
    });
  };

  const handleOpenImage = (link: string | undefined) => {
    setIsOpenImage((prev) => !prev);
    setOpenImageLink(link || '');
  };

  const onSubmit: SubmitHandler<IInputFields> = async (submitFormData) => {
    setIsLoading((prev) => !prev);
    const filesFromSubmit: File[] = [];
    let formData = new FormData();
    submitFormData.insights.forEach(
      (insight) =>
        !!insight.file?.length && filesFromSubmit.push(insight.file[0])
    );

    const sanitizeInsights = submitFormData.insights.map((insight) => {
      return {
        label: insight.labelReference,
        position: insight.position,
        link: insight.link,
        description: insight.description,
      };
    });

    const dataSend = {
      label: submitFormData.label,
      color: submitFormData.color,
      marketSegmentId: submitFormData.marketSegmentId,
      description: submitFormData.description,
      insights: sanitizeInsights,
    };

    formData.append('data', JSON.stringify(dataSend));
    !!filesFromSubmit.length &&
      filesFromSubmit.map((file) => formData.append('images', file));

    const imageTotalSize = !!filesFromSubmit.length
      ? filesFromSubmit
          .map((image) => image.size)
          .reduce((previousSize, currentSize) => previousSize + currentSize)
      : 0;

    if (imageTotalSize < maximumFilesSize) {
      await createCreativeTriggerService(formData)
        .then((response) => {
          setIsLoading((prev) => !prev);
          handleToastfy({
            message: 'Gatilho criativo criado com sucesso!',
            type: 'success',
          });
          revokeList.map((list) => URL.revokeObjectURL(list));
          navigate('/dashboard/gatilhos-criativos');
        })
        .catch((error) => {
          setIsLoading((prev) => !prev);
          if (error.code === 'LABEL_ALREADY_EXISTS') {
            setError('label', {
              type: 'custom',
              message: 'O título já existe.',
            });
          }
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao enviar os dados.',
              type: 'error',
            });
          }
        });
    } else {
      handleToastfy({
        message: `Tamanho total de arquivos ${imageTotalSize} bytes, é superior a ${maximumFilesSize} bytes. Tente enviar arquivos menores.`,
        type: 'error',
      });
      setIsLoading((prev) => !prev);
    }
  };

  const handleFiles = (file: File[], fieldId: string, insightID: number) => {
    const objectURL = URL.createObjectURL(file[0]);

    setRevokeList((prev) => [...prev, objectURL]);
    setValue(`insights.${insightID}.imagePreview`, objectURL);
    setValue(`insights.${insightID}.file`, file);
    clearErrors(`insights.${insightID}.image`);
  };

  const addInsight = () => {
    append({
      description: '',
      image: '',
      labelReference: '',
      link: '',
      position: '',
    });
  };

  const removeInsight = (idToRemove: number, fieldId: string) => {
    remove(idToRemove);
  };

  const validateFileSize = (file: File) => {
    if (file.size > 800000) {
      return {
        code: 'size-too-large',
        message: 'O arquivo é maior que 800 Kbytes',
      };
    }

    return null;
  };

  const getMarketSegments = useCallback(async () => {
    const listMarketSegments = await listMarketSegmentService({
      order: 'DESC',
      orderBy: 'createdAt',
      perPage: 200,
    });
    !!listMarketSegments.data
      ? setMarketSegments(listMarketSegments.data)
      : handleToastfy({
          message: 'Ocorreu um erro ao buscar os segmentos de mercado.',
          type: 'error',
        });
  }, [handleToastfy]);

  useEffect(() => {
    getMarketSegments();
  }, [getMarketSegments]);

  useEffect(() => {
    const title = searchParams.get('title');
    // const categoryContent = searchParams.get('categoryContent');
    setValue('label', title || '');
    // setValue('')
    //Caso o title seja substituído, remova o setValue e insira o exhaustive-deps para remover o aviso da linha
  }, [searchParams, setValue]);

  useEffect(() => {
    watch((values) => {
      !!values.marketSegmentId && clearErrors('marketSegmentId');
    });
  }, [clearErrors, watch]);

  return (
    <CreateCreativeTriggerContainer>
      <h2 className="heading-2 heading-2--margin">Criar Gatilho Criativo</h2>
      <SeparateLine
        margin="0 0 5rem"
        color={theme.pallete.colors.brandPrimary.yellow[6]}
        lineHeight="3px"
      />
      <form className="form-grid" onSubmit={handleSubmit(onSubmit, onError)}>
        <Textfield
          label="Título"
          error={errors.label?.message}
          type="text"
          {...register('label', {
            required: 'O campo é requerido.',
          })}
        />
        <Select
          textLabel="Selecione a categoria do conteúdo"
          options={[
            {
              optionName: 'Gatilhos Criativos',
              optionValue: 'creative-trigger',
            },
            {
              optionName: 'Salas de Brainstorming',
              optionValue: 'brainstorming-rooms',
            },
          ]}
          value={'creative-trigger'}
          onChange={() => {}}
          disabled
        />
        <Select2
          setValue={setValue}
          textLabel="Selecione o segmento do mercado"
          error={errors.marketSegmentId?.message}
          options={marketSegments.map((marketSegment) => {
            return {
              optionName: marketSegment.label,
              optionValue: marketSegment._id,
            };
          })}
          {...register('marketSegmentId', {
            required: 'O campo é requerido.',
          })}
          customOption={
            <li
              className="custom-option"
              onClick={() => setIsNewCategoryOpen((prev) => !prev)}
            >
              <AiOutlinePlus size={14} />
              Novo segmento
            </li>
          }
        />
        <div className="radio-group-container">
          <span className="label-radio-group">Selecione a cor do card</span>
          <div className="radio-group">
            <RadioColors
              type="radio"
              value={theme.pallete.colors.brandPrimary.blue[1]}
              {...register('color', {
                required: 'O campo é requerido.',
              })}
            />
            <RadioColors
              type="radio"
              value={theme.pallete.colors.brandPrimary.blue[2]}
              {...register('color', {
                required: 'O campo é requerido.',
              })}
            />
            <RadioColors
              type="radio"
              value={theme.pallete.colors.brandPrimary.purple[1]}
              {...register('color', {
                required: 'O campo é requerido.',
              })}
            />
            <RadioColors
              type="radio"
              value={theme.pallete.colors.brandPrimary.purple[2]}
              {...register('color', {
                required: 'O campo é requerido.',
              })}
            />
            <RadioColors
              type="radio"
              value={theme.pallete.colors.brandPrimary.pink[1]}
              {...register('color', {
                required: 'O campo é requerido.',
              })}
            />
            <RadioColors
              type="radio"
              value={theme.pallete.colors.brandPrimary.red[3]}
              {...register('color', {
                required: 'O campo é requerido.',
              })}
            />
            <RadioColors
              type="radio"
              value={theme.pallete.colors.brandPrimary.yellow[4]}
              {...register('color', {
                required: 'O campo é requerido.',
              })}
            />
            <RadioColors
              type="radio"
              value={theme.pallete.colors.brandPrimary.yellow[6]}
              {...register('color', {
                required: 'O campo é requerido.',
              })}
            />
            <RadioColors
              type="radio"
              value={theme.pallete.colors.brandPrimary.green[1]}
              {...register('color', {
                required: 'O campo é requerido.',
              })}
            />
            <RadioColors
              type="radio"
              value={theme.pallete.colors.brandPrimary.green[2]}
              {...register('color', {
                required: 'O campo é requerido.',
              })}
            />
          </div>
          {!!errors.color?.message && (
            <span className="textfield-error">{errors.color?.message}</span>
          )}
        </div>
        <TextArea
          label="Descrição"
          maxCharacter={300}
          error={errors.description?.message}
          customCountCharacters={watch('description')?.length}
          {...register('description', {
            required: 'O campo é requerido.',
          })}
        />
        <div className="create-creative-trigger-line">
          <span>Referência</span>
          <SeparateLine
            margin="0"
            color={theme.pallete.colors.common.black}
            lineHeight="1px"
          />
        </div>
        <div>
          {fields.map((field, index) => {
            return (
              <div className="reference-content" key={field.id}>
                <Textfield
                  label="Título de referência"
                  type="text"
                  error={
                    !!errors?.insights
                      ? errors?.insights[index]?.labelReference?.message
                      : ''
                  }
                  {...register(`insights.${index}.labelReference`, {
                    required: 'O campo é requerido.',
                  })}
                />
                <Select
                  textLabel="Posição"
                  options={fields.map((field, index) => {
                    return {
                      optionName: `${index + 1}`,
                      optionValue: `${index + 1}`,
                    };
                  })}
                  error={
                    !!errors?.insights
                      ? errors?.insights[index]?.position?.message
                      : ''
                  }
                  {...register(`insights.${index}.position`, {
                    required: 'O campo é requerido.',
                    validate: (value) => {
                      const position = watch('insights').map(
                        (insight) => insight.position
                      );
                      const isAllUniqueItems = position.every(
                        (value, index, array) => {
                          return array.indexOf(value) === index;
                        }
                      );

                      isAllUniqueItems &&
                        watch('insights').forEach((insight, index) =>
                          clearErrors(`insights.${index}.position`)
                        );

                      if (!isAllUniqueItems) {
                        return 'Existe uma posição igual.';
                      }
                    },
                  })}
                />
                <Upload
                  label="Inserir imagem"
                  type="text"
                  validator={validateFileSize}
                  acceptTypeFiles={{
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpeg'],
                  }}
                  handleCallbackArchive={(file) =>
                    handleFiles(file, field.id, index)
                  }
                  {...register(`insights.${index}.image`, {
                    required: 'O campo é requerido.',
                  })}
                  error={
                    !!errors?.insights
                      ? errors?.insights[index]?.image?.message
                      : undefined
                  }
                />
                <Textfield
                  label="Acrescentar link"
                  type="text"
                  placeholder="https://www.link.com"
                  {...register(`insights.${index}.link`)}
                />
                <div className="creative-trigger-reference-text-group">
                  <span className="creative-trigger-reference-text">
                    Pré-visualização da imagem
                  </span>
                  {!!watch(`insights.${index}.imagePreview`) ? (
                    <div
                      className="creative-trigger-reference-image-container"
                      onClick={() =>
                        handleOpenImage(watch(`insights.${index}.imagePreview`))
                      }
                    >
                      <div style={{ position: 'relative', display: 'flex' }}>
                        <img
                          className="creative-trigger-reference-image"
                          src={watch(`insights.${index}.imagePreview`)}
                          alt="reference-1"
                        />
                        <div className="creative-trigger-reference-image-background">
                          <span>Visualizar imagem</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span className="creative-trigger-without-image">
                      Imagem não inserida
                    </span>
                  )}
                </div>
                <TextArea
                  label="Descrição"
                  maxCharacter={300}
                  {...register(`insights.${index}.description`, {
                    required: 'O campo é requerido.',
                  })}
                  customCountCharacters={
                    watch(`insights.${index}.description`)?.length
                  }
                  error={
                    !!errors?.insights
                      ? errors?.insights[index]?.description?.message
                      : ''
                  }
                />
                {fields.length > 1 && (
                  <div
                    className="create-creative-trigger-remove-insight-box"
                    onClick={() => removeInsight(index, field.id)}
                  >
                    <IoIosRemoveCircleOutline size={20} />
                    <span>Remover insight</span>
                  </div>
                )}
                <SeparateLine
                  margin="0"
                  color={theme.pallete.colors.brandPrimary.gray[2]}
                  lineHeight="1px"
                />
              </div>
            );
          })}
        </div>
        <div
          className="create-creative-trigger-new-insight-box"
          onClick={addInsight}
        >
          <AiOutlinePlus size={20} />
          <span>Adicionar novo insight</span>
        </div>
        <Button
          buttonText="Salvar conteúdo"
          variant="contained"
          type="submit"
          className="create-creative-trigger-save-button"
          isLoading={isLoading}
          // onClick={() => navigate('/dashboard/ver-conteudo-gatilho-criativo')}
        />
      </form>
      {isNewCategoryOpen && (
        <LightboxCategoryMarket
          handleOpenModal={() => setIsNewCategoryOpen((prev) => !prev)}
          setIsNewCategoryOpen={setIsNewCategoryOpen}
        />
      )}
      {isOpenImage && (
        <ImagePreview
          setIsOpenImage={setIsOpenImage}
          linkImage={openImageLink}
        />
      )}
    </CreateCreativeTriggerContainer>
  );
};

export default CreateCreativeTrigger;
