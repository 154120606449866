import styled from 'styled-components';

export const AttachFilesContainer = styled.div`
  button {
    background: ${(props) => props.theme.pallete.colors.brandPrimary.gray[3]};
    box-shadow: ${(props) => props.theme.pallete.shadows.default.n4};
    min-height: 5rem;
    width: 'max-content';
    min-width: min-content;
    padding: 1.6rem;
    border-radius: 100px;
    border: none;
    letter-spacing: 1px;
    transition: all 0.2 ease-in-out;
    position: relative;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      box-shadow: ${(props) => props.theme.pallete.shadows.default.n12};
    }

    &:active {
      transform: scale(0.95);
    }
  }

  input {
    display: none;
  }

  .textfield-error {
    color: ${(props) => props.theme.pallete.colors.error.main};
  }
`;
