import styled from 'styled-components';

export interface IProps {
  isActive: boolean;
}

export const CheckboxSelectContainer = styled.div`
  input {
    display: none;
  }

  input:checked ~ label {
    background-color: ${(props) => props.theme.pallete.colors.primary.main};
    box-shadow: ${(props) => props.theme.pallete.shadows.default.n12};
  }

  label {
    position: relative;
    display: inline-block;
    width: 1.563rem;
    height: 1.563rem;
    background: #fff;
    border-radius: 50%;
    transition: all 0.2s;
    border: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[17]};
    cursor: pointer;

    @media ${(props) => props.theme.medias.mobile} {
      width: 2rem;
      height: 2rem;
    }
  }
`;
