import React from 'react';
import { PolicyPrivacyContainer } from './styles';
import { Link } from 'react-router-dom';

const PolicyPrivacy: React.FC = () => {
  return (
    <PolicyPrivacyContainer>
      <div className="policy-background" />
      <div className="policy-content">
        <h2>Política de privacidade</h2>
        <p className="policy-paragraph">
          Nesta Política de Privacidade, vamos explicar aos nossos visitantes e
          usuários, todas as formas e meios que protegemos e tratamos os dados
          coletados em nossa plataforma.
        </p>
        <p className="policy-paragraph">
          Nós levamos extremamente a sério a proteção dos dados pessoais
          coletados e armazenados, e neste documento, vamos te explicar
          detalhadamente como e porque fazemos esta coleta, e como você pode
          pedir para retirar, alterar ou excluir seus dados de nossos
          servidores.
        </p>
        <p className="policy-paragraph">
          Em caso de dúvidas de quaisquer tipos, você pode entrar em contato
          diretamente com nossa equipe pela aba de contato.
        </p>
        <p className="policy-paragraph">
          Este documento foi criado pelo{' '}
          <strong>Advogado Diego Castro (OAB/PI 15.613)</strong>, e adaptado
          para utilização neste website.
        </p>
        <h3 className="policy-heading-3">
          1 – Sobre a Origem dos seus dados Pessoais
        </h3>
        <p className="policy-paragraph">
          Nosso site pode coletar alguns dados pessoais seus para diferentes
          objetivos. Para coletarmos estes dados, usamos as seguintes
          tecnologias:
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>Website:</strong> Dentro do nosso site você
          poderá preencher formulários, enviar comentários e dúvidas, entrar em
          contato com nossa equipe ou se cadastrar na nossa plataforma, e seus
          dados serão salvos para posterior atendimento.
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>Mensagens e comunicações:</strong> Quando
          você se comunica com nossa equipe, seja pelo Whatsapp, por SMS, por
          E-mail ou quaisquer outros tipos de chat, alguns dados podem ficar
          salvos para futura conferência e facilitar a comunicação com nossos
          clientes e usuários.
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>De Modo Offline:</strong> Alguns dados
          podem ser coletados offline, como, por exemplo, em eventos e
          organizações, onde podemos coletar seu e-mail ou outros dados, para
          manter comunicação e informações necessárias que você precisa ter
          conhecimento.
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>Dados de Terceiros:</strong> Algumas vezes,
          terceiros podem enviar para nossa empresa alguns dados seus, como, por
          exemplo, dados recebidos por mídias sociais (Como Facebook, instagram,
          Tiktok ou outras redes sociais), do governo ou intermediadoras de
          pagamentos.
        </p>
        <h3 className="policy-heading-3">
          2 – Como e porque coletamos seus dados:
        </h3>
        <p className="policy-paragraph">
          É importante que você, nosso usuário ou visitante, saiba como e quais
          dados pessoais coletamos e utilizamos quando você visita nosso site.
          Destacamos que sempre tentamos coletar o mínimo de dados possíveis
          para sua maior segurança, porém, alguns dados são essenciais para
          podermos entregar o melhor serviço possível e até para o funcionamento
          do site, conforme descrevemos abaixo:
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>Marketing:</strong> Quando você preenche
          formulários e se cadastra no site, você poderá receber e-mails com
          promoções e novidades do site. Podemos coletar nome, e-mail e
          telefone.
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>Navegação ao Site:</strong> quando você
          navega pelo nosso site, coletamos automaticamente dados como o seu
          endereço IP, tipo de navegador, páginas acessadas, tempo gasto em cada
          página e outras informações.
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>Informações de contato:</strong> quando
          você entra em contato conosco, seja por e-mail, telefone ou chat,
          coletamos as suas informações de contato, como o seu nome, e-mail,
          número de telefone e a mensagem que você nos enviou.
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>Informações de uso dos serviços:</strong>{' '}
          quando você usa os nossos serviços, coletamos dados sobre as suas
          atividades, como as pesquisas que você realiza, os vídeos que você
          assiste, as páginas que você visita e as compras que você faz e quando
          você compartilha conosco fotos, comentários, sugestões, perguntas,
          respostas, informações, textos, arquivos, gráficos, vídeos ou outros
          materiais, coletamos as informações que você nos enviar.
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>Informações de localização:</strong> quando
          você usa os nossos serviços, coletamos dados de localização precisos
          para fornecer os serviços que você solicitou. Por exemplo, quando você
          solicita um serviço de entrega, coletamos seu endereço para enviar a
          entrega para o local certo.
        </p>
        <h3 className="policy-heading-3">3 – Sobre o uso de policy</h3>
        <p className="policy-paragraph">
          Usamos policy no nosso website e aplicativos para melhorar a sua
          experiência de navegação no nosso website. Um cookie é um pequeno
          arquivo de texto que um site armazena no seu computador ou dispositivo
          móvel quando você visita o site.
        </p>
        <p className="policy-paragraph">
          Os policy nos permitem reconhecer o seu navegador e fornecer-lhe a
          melhor experiência ao navegar no nosso site. Além disso, os policy nos
          ajudam a compreender quais seções do site são mais interessantes para
          você e quais conteúdos podem ser recomendados para você, site com o
          google ou bing, utilizam policy, e ao visitar cada um, você deve
          verificar a politica referente a eles.
        </p>
        <p className="policy-paragraph">
          Você pode a qualquer momento bloquear os policy no seu navegador, ou
          limpar o cache para retirar os mesmos.
        </p>
        <p className="policy-paragraph">
          É importante destacar que tentamos minimizar o uso de policy não
          essenciais, mas utilizamos os necessários para entregar um melhor
          serviço, conforme a previsão de legítimo interesse na{' '}
          <strong>LGPD</strong>. Você pode a qualquer momento desativar os
          mesmos em seu navegador.
        </p>
        <h3 className="policy-heading-3">
          4 – Com quem compartilhamos os seus dados pessoais:
        </h3>
        <p className="policy-paragraph">
          <strong>Prestadores de Serviços:</strong> compartilhamos os seus dados
          pessoais com os nossos prestadores de serviços, que prestam serviços
          de marketing, análise de dados, pagamentos, envio de e-mails e outros
          serviços para nós. Esses prestadores de serviços só podem usar os seus
          dados pessoais para realizar esses serviços para nós e conforme as
          nossas instruções.
        </p>
        <p className="policy-paragraph">
          <strong>Anunciantes e Parceiros Comerciais:</strong> também é possível
          que compartilhemos os seus dados pessoais com empresas que realizem
          anuncio, ou parceiros comerciais. No entanto, só compartilhamos os
          seus dados pessoais conforme as suas instruções.
        </p>
        <p className="policy-paragraph">
          <strong>Comunidades Online:</strong> quando você se junta a uma das
          nossas comunidades online, os seus dados pessoais podem ser vistos por
          outros membros dessa comunidade.
        </p>
        <p className="policy-paragraph">
          <strong>Investidores:</strong> podemos compartilhar os seus dados
          pessoais com os nossos investidores. No entanto, só compartilhamos os
          seus dados pessoais conforme as suas instruções.
        </p>
        <p className="policy-paragraph">
          <strong>Filiais e Subsidiárias:</strong> podemos compartilhar os seus
          dados pessoais com as nossas filiais e subsidiárias. No entanto, só
          compartilhamos os seus dados pessoais conforme as suas instruções.
        </p>
        <p className="policy-paragraph">
          <strong>Autoridades Governamentais e outras Partes:</strong> às vezes,
          podemos ser obrigados judicialmente a liberar ou divulgar os seus
          dados às autoridades governamentais, ou outras partes, conforme
          exigido por lei. Ou por outros motivos, como:
        </p>
        <p className="policy-paragraph-item">
          <strong>(i)</strong> Cumprir uma obrigação legal;
        </p>
        <p className="policy-paragraph-item">
          <strong>(ii)</strong> Proteger os direitos, a propriedade ou a
          segurança da Página;
        </p>
        <p className="policy-paragraph-item">
          <strong>(iii)</strong> Evitar um dano real ou potencial aos bens, ou à
          segurança das pessoas;
        </p>
        <p className="policy-paragraph-item">
          <strong>(iv)</strong> De outra forma, conforme permitido por lei.
        </p>
        <h3 className="policy-heading-3">
          5- Sobre seus Direitos em relação aos Dados coletados
        </h3>
        <p className="policy-paragraph">
          O presente texto tem por objetivo esclarecer os nossos leitores e
          visitantes sobre quais são os seus direitos em relação aos dados
          pessoais.
        </p>
        <p className="policy-paragraph">
          De acordo com a Lei Geral de Proteção de Dados Pessoais (LGPD), toda
          pessoa tem o direito à informação, à transparência, à liberdade e à
          autonomia na tomada de decisões.
        </p>
        <p className="policy-paragraph">
          Com relação aos dados pessoais, a pessoa tem o direito ao acesso, à
          correção, à eliminação, à portabilidade, à limitação do tratamento e à
          oposição.
        </p>
        <p className="policy-paragraph">
          A pessoa também tem o direito de negar a qualquer momento que seus
          dados pessoais sejam utilizados para fins de marketing e ao
          recebimento de comunicações comerciais.
        </p>
        <p className="policy-paragraph">
          Para exercer os seus direitos, basta entrar em contato com a nossa
          equipe.
        </p>
        <p className="policy-paragraph">
          Esclarecemos que os dados pessoais são tratados de forma confidencial
          e que tomamos todas e quaisquer medidas de segurança necessárias para
          garantir a proteção dos dados.
        </p>
        <h3 className="policy-heading-3">
          6 – Sobre a segurança que aplicamos no tratamento dos seus dados
        </h3>
        <p className="policy-paragraph">
          A segurança é um dos pilares fundamentais da nossa política de
          privacidade.
        </p>
        <p className="policy-paragraph">
          Todos os dados pessoais que você fornecer ao site serão tratados com
          total confidencialidade e segurança, de acordo com as normas legais
          aplicáveis.
        </p>
        <p className="policy-paragraph">
          Para garantir a proteção dos seus dados pessoais, o site utiliza
          diversas medidas de segurança, como por exemplo:
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;Criptografia SSL (Secure Socket Layer);
        </p>
        <p className="policy-paragraph-item">&#8226;&nbsp;&nbsp;Firewalls;</p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;Sistemas de detecção de invasões;
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;Monitoramento constante das redes;
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;Uso de senhas seguras;
        </p>
        <p className="policy-paragraph-item">
          &#8226;&nbsp;&nbsp;Verificação periódica da segurança dos sistemas.
        </p>
        <p className="policy-paragraph">
          Todas as informações pessoais são armazenadas em servidores seguros,
          protegidos contra acessos não autorizados, utilizando os mais modernos
          e avançados sistemas de segurança.
        </p>
        <p className="policy-paragraph">
          Nossa equipe de segurança está constantemente monitorando os sistemas
          e atualizando as medidas de proteção, para garantir que seus dados
          estejam sempre seguros.
        </p>
        <p className="policy-paragraph">
          Caso você tenha alguma dúvida ou preocupação em relação à segurança
          dos seus dados pessoais, entre em contato conosco
        </p>
        <h3 className="policy-heading-3">
          7 – Pedido de modificação, remoção ou alteração
        </h3>
        <p className="policy-paragraph">
          Entendemos que você possa, eventualmente, querer modificar, remover ou
          alterar seus dados pessoais.
        </p>
        <p className="policy-paragraph">
          Se você deseja fazer uma alteração, uma remoção ou uma modificação,
          entre em contato conosco e nós faremos o possível para atender à sua
          solicitação. No entanto, tenha em mente que, em alguns casos, pode não
          ser possível atender à sua solicitação, especialmente se for
          necessária para cumprir nossas obrigações legais ou contratuais.
        </p>
        <p className="policy-paragraph">
          Além disso, lembre-se de que, se você solicitar a remoção de seus
          dados pessoais do nossos banco de dados, isso pode afetar o uso de
          nosso site e serviços. Se você não puder fazer uma alteração ou uma
          modificação, ou se desejar excluir seus dados pessoais, entre em
          contato conosco para podermos discutir outras opções, clicando na aba
          contato.
        </p>
        <h3 className="policy-heading-3">8 - Canais de Contato</h3>
        <p className="policy-paragraph">
          Disponibilizamos canais de contato para o nosso usuário/cliente em
          busca de termos um relacionamento claro e cordial, nos
          disponibilizando para esclarecer qualquer dúvida sobre o nosso site,
          bem como sobre o modo como realizamos o tratamento de seus dados.
        </p>
        <p className="policy-paragraph">
          E-mail:{' '}
          <Link to="mailto:contato@blog.criativistas.com.br">
            contato@blog.criativistas.com.br
          </Link>
        </p>
      </div>
    </PolicyPrivacyContainer>
  );
};

export default PolicyPrivacy;
