import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainRoutes from './routes';
import { ThemeProvider } from 'styled-components';
import themes from './styles/theme';
import GlobalStyle from './styles/globalStyle';
import { AuthProvider } from './contexts/AuthContext';
import { ToastfyProvider } from './contexts/ToastfyContext';
import { TermsAndPolicyPopUpProvider } from './contexts/TermsAndPolicyPopUpContext';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={themes.themeLight}>
      <GlobalStyle />
      <Router>
        <ToastfyProvider>
          <AuthProvider>
            <TermsAndPolicyPopUpProvider>
              <MainRoutes />
            </TermsAndPolicyPopUpProvider>
          </AuthProvider>
        </ToastfyProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
