import styled, { css } from 'styled-components';

export interface IStatusUserSmallProps {
  type: 'gratuito' | 'criativista';
}

const statusColors = {
  gratuito: css`
    background-color: ${(props) =>
      props.theme.pallete.colors.brandPrimary.gray[2]};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
  `,
  criativista: css`
    background-color: ${(props) =>
      props.theme.pallete.colors.brandPrimary.yellow[2]};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
  `,
};

export const StatusUserSmallContainer = styled.div<IStatusUserSmallProps>`
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
  border-radius: 100px;
  font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  /* background-color: ${(props) =>
    props.theme.pallete.colors.brandPrimary.gray[7]};
  color: ${(props) => props.theme.pallete.colors.common.white}; */
  line-height: 1.2rem;

  ${(props) => statusColors[props.type]}
`;
