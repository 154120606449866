import styled, { css } from 'styled-components';

interface IProps {
  isActive?: boolean;
}

export const MenuDropdown = styled.div`
  display: flex;
  position: relative;

  .drop {
    display: none;
    position: fixed;
    top: 4rem;
    left: 0rem;
    width: 20rem;
    background: ${(props) => props.theme.pallete.colors.backgrounds.neutral};
    border-radius: 8px;
    border: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    padding: 2rem 1rem 3rem 1rem;
    /* margin-left: 3rem; */
    z-index: 1000;
    flex-direction: column;
    gap: 1rem;
  }

  &:hover .drop {
    display: flex;
    /* display: initial; */
  }
`;

export const NavigationContainer = styled.nav`
  display: flex;
  gap: 3rem;
  align-items: center;
  min-height: 12rem;
  padding: 2rem 2.4rem;
  background-color: ${(props) =>
    props.theme.pallete.colors.backgrounds.neutral};

  @media ${(props) => props.theme.medias.laptopSmall} {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
  }

  .logo {
    display: flex;
    transition: ${(props) => props.theme.transitions('default', 0.15)};
    cursor: pointer;

    @media ${(props) => props.theme.medias.laptopSmall} {
      position: absolute;
      left: 50%;
      top: 50%;
      transition: none;
      transform: translate(-50%, -50%);
    }
  }

  .logo-full {
    display: flex;

    &:hover {
      transform: scale(1.05);
    }

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: none;
    }
  }

  .logo-half {
    width: 6rem;
    height: 6rem;
    display: none;

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: flex;
    }
  }

  .menu {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    padding-left: 1rem;
    margin-left: auto;

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: none;
    }
  }

  .menu-signed-icons {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    svg {
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .mobile-nav-menu {
    margin-right: auto;
  }

  .menu-item-link,
  .menu-item-link:link,
  .menu-item-link:visited {
    color: ${(props) => props.theme.pallete.colors.common.black};
    text-decoration: none;
  }

  .brainstorming-nav-avatar {
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    background-image: url('/static/images/profile.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const MenuItem = styled.span<IProps>`
  position: relative;
  font-weight: ${(props) => props.theme.typeFaceWeight.regular};
  color: ${(props) => props.theme.pallete.colors.common.black};
  position: relative;
  user-select: none;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    `}

  &::after {
    content: '';
    position: absolute;
    bottom: -0.8rem;
    left: 0;
    height: 3px;
    width: 0.001%;
    background-color: ${(props) => props.theme.pallete.colors.primary.main};
    transition: width 0.4s cubic-bezier(1, 0, 0, 1);
    ${(props) =>
      props.isActive &&
      css`
        width: 100%;
      `}
  }

  &:hover::after {
    width: 100%;
  }

  &:hover {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  .menu-item-span {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    opacity: 0;
    user-select: none;
    width: 100%;
    display: inline-block;
    padding: 0.8rem;
  }

  .menu-item-link,
  .menu-item-link:link,
  .menu-item-link:visited {
    color: ${(props) => props.theme.pallete.colors.common.black};
    text-decoration: none;
    padding: 0.8rem;
    display: inline-block;
    width: 100%;
    z-index: 1;

    ${(props) => props.theme.mixins.absoluteCenter};
  }
`;

export const MenuMobileItem = styled.span<IProps>`
  font-size: 2.4rem;
  padding: 1.6rem;
  font-weight: ${(props) => props.theme.typeFaceWeight.regular};
  color: ${(props) => props.theme.pallete.colors.common.black};
  position: relative;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    `}

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: ${(props) => (props.isActive ? '100%' : '0.001%')};
    background-color: ${(props) => props.theme.pallete.colors.primary.main};
    transition: width 0.4s cubic-bezier(1, 0, 0, 1);
  }

  &:hover::after {
    width: 100%;
  }

  &:hover {
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
  }
`;
