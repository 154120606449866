import styled from 'styled-components';

export const LightboxInternParamContainer = styled.div`
  h3 {
    font-size: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .form-paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .lightbox-intern-param-button {
    margin-left: auto;
    margin-top: 2rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      margin-right: auto;
    }
  }
`;
