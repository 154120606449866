import styled from 'styled-components';

export const CreativeTriggerListContainer = styled.div`
  .heading-2 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};

    &--margin {
    }
  }

  .creative-trigger-list-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }
`;
