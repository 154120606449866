import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import AttachFiles from '../../../components/AttachmentFiles';
import Button from '../../../components/Button';
import TextArea from '../../../components/inputs/TextArea';
import ImagePreview from '../../../components/lightboxes/ImagePreview';
import LightBox from '../../../components/lightboxes/LightBox';
import AuthContext from '../../../contexts/AuthContext';
import ToastfyContext from '../../../contexts/ToastfyContext';
import {
  deleteImageMessageRoomService,
  deleteMessageRoomService,
  updateMessageRoomService,
} from '../../../services/brainstormingRoom.service';
import { IMessageRoom } from '../../../services/brainstormingRoom.types';
import { EditMessagesContainer } from './styles';
import SeparateLine from '../../../components/SeparateLine';
import lodash from 'lodash';

interface IProps {
  setIsEditComment: Dispatch<SetStateAction<boolean>>;
  brainstormingRoomId: string;
  showBrainstormingRoom: (id: string) => Promise<void>;
  message: IMessageRoom;
}

interface IInputFields {
  message: string;
}

interface IImages {
  fileId: string;
  file: File;
  imagePreview: string;
}

const EditMessages: React.FC<IProps> = ({
  setIsEditComment,
  brainstormingRoomId,
  showBrainstormingRoom,
  message,
}) => {
  const [messageRoom, setMessageRoom] = useState<IMessageRoom>(message);
  const [images, setImages] = useState<IImages[]>([]);
  const [revokeList, setRevokeList] = useState<string[]>([]);
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const [isSendMessageLoading, setIsSendMessageLoading] =
    useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IInputFields>({
    defaultValues: {
      message: message.message,
    },
  });
  const [openImageLink, setOpenImageLink] = useState<string>('');
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [isDeleteImageLoading, setIsDeleteImageLoading] =
    useState<boolean>(false);
  const [isDeleteMessageLoading, setIsDeleteMessageLoading] =
    useState<boolean>(false);
  const [isConfirmDeleteImage, setIsConfirmDeleteImage] =
    useState<boolean>(false);
  const [isConfirmDeleteMessage, setIsConfirmDeleteMessage] =
    useState<boolean>(false);
  const [imageForDelete, setImageForDelete] = useState('');
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCallbackArchive = (file: File[]) => {
    const objectURL = URL.createObjectURL(file[0]);

    setImages([
      ...images,
      { fileId: uuidv4(), file: file[0], imagePreview: objectURL },
    ]);
    setRevokeList((prev) => [...prev, objectURL]);
  };

  const validateFileSize = (file: File) => {
    if (file.size > 800000) {
      return {
        code: 'size-too-large',
        message: 'O arquivo é maior que 800 Kbytes',
      };
    }

    return null;
  };

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsSendMessageLoading((prev) => !prev);

    if (!!brainstormingRoomId) {
      let formData = new FormData();

      formData.append('message', `${data.message}`);
      formData.append('brainstormingRoomId', `${brainstormingRoomId}`);

      if (!!images.length) {
        images.forEach((image) => {
          formData.append('images', image.file);
        });
      }

      await updateMessageRoomService({
        formData,
        messageCode: messageRoom.code,
      })
        .then((response) => {
          handleToastfy({
            message: 'Comentário atualizado com sucesso!',
            type: 'success',
          });
          setIsEditComment((prev) => !prev);
          setIsSendMessageLoading((prev) => !prev);
          showBrainstormingRoom(brainstormingRoomId);
          revokeList.map((list) => URL.revokeObjectURL(list));
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          setIsSendMessageLoading((prev) => !prev);
        });
    } else {
      handleToastfy({
        message: 'Id da sala não localizado!',
        type: 'error',
      });
    }
  };

  const handleOpenImage = (imageLink: string) => {
    setIsOpenImage((prev) => !prev);
    setOpenImageLink(imageLink);
  };

  const handleRemoveImage = async () => {
    setIsDeleteImageLoading((prev) => !prev);

    await deleteImageMessageRoomService({
      brainstormingRoomId: brainstormingRoomId,
      imageCode: imageForDelete,
      messageCode: messageRoom.code,
    })
      .then((response) => {
        setMessageRoom({
          ...messageRoom,
          images: messageRoom.images.filter(
            (image) => image.code !== imageForDelete
          ),
        });
        setIsConfirmDeleteImage((prev) => !prev);
        setIsDeleteImageLoading((prev) => !prev);
        showBrainstormingRoom(brainstormingRoomId);
        handleToastfy({
          message: 'Imagem excluída com sucesso!',
          type: 'success',
        });
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsSendMessageLoading((prev) => !prev);
      });
  };

  const handleRemoveMessage = async () => {
    setIsDeleteMessageLoading((prev) => !prev);
    await deleteMessageRoomService({
      brainstormingRoomId: brainstormingRoomId,
      messageCode: message.code,
    })
      .then((response) => {
        setIsDeleteMessageLoading((prev) => !prev);
        setIsEditComment((prev) => !prev);
        showBrainstormingRoom(brainstormingRoomId);
        handleToastfy({
          message: 'Mensagem excluída com sucesso!',
          type: 'success',
        });
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsSendMessageLoading((prev) => !prev);
      });
  };

  const handleRemoveNewImages = (fileId: string) => {
    setImages((prev) =>
      prev.filter((imageObject) => imageObject.fileId !== fileId)
    );
  };

  useEffect(() => {
    watch((value) => {
      setIsEdit(lodash.isEqual({ message: message.message }, value));
    });
  }, [watch, message]);

  return (
    <LightBox handleOpenModal={() => setIsEditComment((prev) => !prev)}>
      <EditMessagesContainer>
        <h3 className="brainstorming-create-room-edit-h3">Editar mensagem</h3>
        <SeparateLine
          margin="1.6rem 0"
          color={theme.pallete.colors.brandPrimary.gray[2]}
        />
        <div className="brainstorming-create-room">
          <form
            className="brainstorming-create-room"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextArea
              label=""
              maxCharacter={300}
              placeholder="Escreva sua contribuição"
              {...register('message', {
                required: 'O campo é requerido.',
              })}
              error={errors.message?.message}
              customCountCharacters={watch(`message`)?.length}
            />
            <AttachFiles
              handleCallbackArchive={handleCallbackArchive}
              validator={validateFileSize}
              acceptTypeFiles={{
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg'],
              }}
            />
            {!!images.length && (
              <div className="brainstorming-create-room-images">
                <h3 className="brainstorming-create-room-new-images">
                  Novas imagens
                </h3>
                <SeparateLine
                  margin="0 0 3rem"
                  color={theme.pallete.colors.brandPrimary.gray[2]}
                  lineHeight="1px"
                />
                {images.map((image) => {
                  return (
                    <div
                      key={image.fileId}
                      className="brainstorming-create-room-image-box"
                    >
                      <div
                        className="brainstorming-create-room-image brainstorming-create-room-image--new-image"
                        style={{
                          backgroundImage: `url(${image.imagePreview})`,
                        }}
                        onClick={() => handleOpenImage(image.imagePreview)}
                      >
                        <div className="insights-card-image-background">
                          <span>Visualizar</span>
                        </div>
                      </div>
                      <div className="brainstorming-create-room-image-icon">
                        <FaTrashAlt
                          color={theme.pallete.colors.brandPrimary.red[2]}
                          size={24}
                          onClick={() => handleRemoveNewImages(image.fileId)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {!!images.length && (
              <SeparateLine
                margin="0.8rem 0"
                color={theme.pallete.colors.brandPrimary.gray[2]}
                lineHeight="1px"
              />
            )}
            {!!messageRoom.images.length && (
              <div className="brainstorming-create-room-images">
                {messageRoom.images.map((image) => {
                  return (
                    <div
                      key={image.code}
                      className="brainstorming-create-room-image-box"
                    >
                      <div
                        className="brainstorming-create-room-image"
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_URL_IMAGE_BASE}/${image.image})`,
                        }}
                        onClick={() =>
                          handleOpenImage(
                            `${process.env.REACT_APP_URL_IMAGE_BASE}/${image.image}`
                          )
                        }
                      >
                        <div className="insights-card-image-background">
                          <span>Visualizar</span>
                        </div>
                      </div>
                      <div className="brainstorming-create-room-image-icon">
                        <FaTrashAlt
                          color={theme.pallete.colors.brandPrimary.red[2]}
                          size={24}
                          onClick={() => {
                            setImageForDelete(image.code);
                            setIsConfirmDeleteImage((prev) => !prev);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="brainstorming-create-room-button-group">
              <Button
                buttonText="Cancelar"
                variant="contained"
                className="room-button-write-contribution"
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                type="button"
                buttonSize={200}
                onClick={() => setIsEditComment((prev) => !prev)}
              />
              <Button
                buttonText="Editar"
                variant="contained"
                className="room-button-write-contribution"
                isLoading={isSendMessageLoading}
                type="submit"
                buttonSize={200}
                isDisabled={isEdit && !images.length}
              />
            </div>
            <Button
              buttonText="Excluir mensagem"
              variant="outlined"
              type="button"
              className="creative-trigger-delete-button"
              customColors={{
                backgroundColor: theme.pallete.colors.brandPrimary.red[3],
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.red[3],
                hoverTextColor: theme.pallete.colors.brandPrimary.red[3],
                textColor: theme.pallete.colors.brandPrimary.red[3],
              }}
              onClick={() => setIsConfirmDeleteMessage((prev) => !prev)}
            />
          </form>
        </div>
        {isOpenImage && (
          <ImagePreview
            setIsOpenImage={setIsOpenImage}
            linkImage={openImageLink}
          />
        )}
        {isConfirmDeleteImage && (
          <LightBox
            handleOpenModal={() => setIsConfirmDeleteImage((prev) => !prev)}
            customLightboxWidth="60rem"
          >
            <h3>Deseja realmente excluir a imagem?</h3>
            <div className="confirm-delete-button-group">
              <Button
                buttonText="Não"
                variant="contained"
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                buttonSize={160}
                onClick={() => setIsConfirmDeleteImage((prev) => !prev)}
              />
              <Button
                buttonText="Sim"
                variant="contained"
                buttonSize={160}
                onClick={() => handleRemoveImage()}
                isLoading={isDeleteImageLoading}
              />
            </div>
          </LightBox>
        )}
        {isConfirmDeleteMessage && (
          <LightBox
            handleOpenModal={() => setIsConfirmDeleteMessage((prev) => !prev)}
            customLightboxWidth="60rem"
          >
            <h3>Deseja realmente excluir a mensagem?</h3>
            <div className="confirm-delete-button-group">
              <Button
                buttonText="Não"
                variant="contained"
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                buttonSize={160}
                onClick={() => setIsConfirmDeleteMessage((prev) => !prev)}
              />
              <Button
                buttonText="Sim"
                variant="contained"
                buttonSize={160}
                onClick={() => handleRemoveMessage()}
                isLoading={isDeleteMessageLoading}
              />
            </div>
          </LightBox>
        )}
      </EditMessagesContainer>
    </LightBox>
  );
};

export default EditMessages;
