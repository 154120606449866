import lodash from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../../../components/Button';
import DashboardConfigCard from '../../../../components/DashboardConfigCard';
import Textfield from '../../../../components/inputs/Textfield';
import AuthContext from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
import { updatePasswordService } from '../../../../services/user.service';
import { PasswordContainer } from './styles';

interface IInputFields {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

const Password: React.FC = () => {
  const [isUpdatePasswordSubmit, setIsUpdatePasswordSubmit] =
    useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<IInputFields>({
    defaultValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsUpdatePasswordSubmit((prev) => !prev);
    await updatePasswordService({
      currentPassword: data.currentPassword,
      password: data.password.trim(),
      passwordConfirmation: data.passwordConfirmation.trim(),
    })
      .then((response) => {
        handleToastfy({
          message: 'Senha atualizada com sucesso!',
          type: 'success',
        });
        setIsUpdatePasswordSubmit((prev) => !prev);
        reset();
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsUpdatePasswordSubmit((prev) => !prev);
      });
  };

  useEffect(() => {
    watch((value) => {
      setIsEdit(lodash.values(value).every(lodash.isEmpty));
    });
  }, [watch]);

  return (
    <PasswordContainer>
      <DashboardConfigCard className="password-card">
        <div className="password-content">
          <h3 className="password-content-heading-3">Senha</h3>
          <p className="password-content-subtitle">
            Confira os seus dados pessoais e atualize se necessário.
          </p>
          <form className="password-form" onSubmit={handleSubmit(onSubmit)}>
            <span className="form-span form-item-margin">Senha Atual</span>
            <Textfield
              className="form-input form-item-margin"
              label=""
              type="password"
              error={errors.currentPassword?.message}
              {...register('currentPassword', {
                required: 'O campo é requerido.',
              })}
            />
            <span className="form-span">Nova senha</span>
            <Textfield
              className="form-input"
              label=""
              type="password"
              error={errors.password?.message}
              {...register('password', {
                required: 'O campo é requerido.',
                maxLength: {
                  value: 40,
                  message: 'Limite máximo de 40 caracteres',
                },
                minLength: {
                  value: 8,
                  message: 'Mínimo de 08 caracteres',
                },
              })}
            />
            <span className="form-span">Confirmar a nova senha</span>
            <Textfield
              className="form-input"
              label=""
              type="password"
              error={errors.passwordConfirmation?.message}
              {...register('passwordConfirmation', {
                required: 'O campo é requerido.',
                maxLength: {
                  value: 40,
                  message: 'Limite máximo de 40 caracteres',
                },
                minLength: {
                  value: 8,
                  message: 'Mínimo de 08 caracteres',
                },
                validate: (confirmPassword: string) => {
                  if (watch('password') !== confirmPassword) {
                    return 'As senhas não conferem';
                  }
                },
              })}
            />
            <Button
              buttonText="Salvar dados"
              variant="contained"
              type="submit"
              className="form-button-submit"
              buttonSize={200}
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
              isLoading={isUpdatePasswordSubmit}
              isDisabled={isEdit}
            />
          </form>
        </div>
      </DashboardConfigCard>
    </PasswordContainer>
  );
};

export default Password;
