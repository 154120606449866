import styled from 'styled-components';

export const BrainstormingMessageContainer = styled.div`
  .brainstorming-message-content-image {
    width: 100%;
    max-width: 20rem;
    height: 20rem;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .brainstorming-message-content-image:hover
    .brainstorming-message-image-background {
    opacity: 1;
  }

  .brainstorming-message-image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;

    span {
      text-align: center;
      font-size: 2.4rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      color: ${(props) => props.theme.pallete.colors.common.white};
    }
  }

  .brainstorming-message-content {
    display: flex;
    gap: 3rem;
  }

  .brainstorming-message-content-avatar {
    min-width: 6rem;

    img {
      height: 7rem;
      width: 7rem;
      border-radius: 50%;
    }
  }

  .brainstorming-message-content-box {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    width: 100%;
  }

  .brainstorming-message-content-texts {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .brainstorming-message-content-title {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[6]};

    span {
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[11]};
    }
  }

  .brainstorming-message-content-paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    line-height: 2.4rem;
    word-break: break-all;
  }

  //************************ Images *********************************/

  .brainstorming-message-content-images {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
  }

  .brainstorming-message-content-image-box {
    width: 20rem;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .brainstorming-message-content-image {
    border-radius: 1rem;
  }
`;
