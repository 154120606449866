import styled from 'styled-components';

export const ConfirmLightBoxContainer = styled.div`
  /* & > {
    z-index: 3000;
  } */
  .confirm-title-h3 {
    font-size: 2.4rem;
  }

  .confirm-button-group {
    display: flex;
    gap: 3rem;
    justify-content: center;
    margin-top: 3rem;
  }
`;
