import lodash from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineCheckCircle, AiOutlinePlus } from 'react-icons/ai';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../../components/Button';
import ButtonText from '../../../../components/ButtonText';
import DashboardConfigCard from '../../../../components/DashboardConfigCard';
import CardPlan from '../../../../components/cards/CardPlan';
import { TextFieldMask } from '../../../../components/inputs/TextFieldMask';
import Textfield from '../../../../components/inputs/Textfield';
import ConfirmLightBox from '../../../../components/lightboxes/ConfirmLightBox';
import LightBox from '../../../../components/lightboxes/LightBox';
import Spinner from '../../../../components/loadings/Spinner';
import AuthContext from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
import {
  alterMethodPaymentService,
  changeSignatureService,
  createSignatureService,
  deleteSignatureService,
  getAddressByCEP,
  getCurrentSignature,
  getDataCustomerSignature,
  getPaymentsPlansService,
} from '../../../../services/payments.service';
import {
  IGetAddressResponse,
  IGetPaymentsPlansData,
} from '../../../../services/payments.types';
import { updateProfileService } from '../../../../services/user.service';
import { IErrorPagBank, encryptCardPagSeguro } from '../../../../utils/pagBank';
import { ProfileContainer } from './styles';

interface IInputFields {
  username: string;
  name: string;
  avatar: string;
  email: string;
  profession: string;
  planId: string;
  country: string;
  number: string;
}

interface IAddress {
  street: string;
  complement: string;
  locality: string;
  city: string;
  region_code: string;
}

interface IPaymentMethodCard {
  token: string;
  brand: string;
  first_digits: string;
  last_digits: string;
  exp_month: string;
  exp_year: string;
  holder: {
    name: string;
  };
}

const Profile: React.FC = () => {
  const [cep, setCep] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');
  const [dateExp, setDateExp] = useState<string>('');
  const [numberPhone, setNumberPhone] = useState<string>('');
  const [numberCard, setNumberCard] = useState<string>('');
  const [nameCard, setNameCard] = useState<string>('');
  const [cvv, setCvv] = useState<string>('');

  const [address, setAddress] = useState<IAddress>({
    city: '',
    complement: '',
    locality: '',
    region_code: '',
    street: '',
  });
  const [revokeList, setRevokeList] = useState<string[]>([]);
  const [imageLink, setImageLink] = useState<string>('');
  const [isUpdateProfile, setIsUpdateProfile] = useState<boolean>(false);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(true);
  const [isLoadingCancelSignature, setIsLoadingCancelSignature] =
    useState<boolean>(false);
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState<boolean>(false);
  const [isPaymentFormLoading, setIsPaymentFormLoading] =
    useState<boolean>(false);

  const [isOpenAlterPlanModal, setIsOpenAlterPlanModal] =
    useState<boolean>(false);
  const [isOpenAlterPaymentModal, setIsOpenAlterPaymentModal] =
    useState<boolean>(false);
  const [dataCustomerPayment, setDataCustomerPayment] =
    useState<IPaymentMethodCard>({
      token: '',
      brand: '',
      first_digits: '',
      last_digits: '',
      exp_month: '',
      exp_year: '',
      holder: {
        name: '',
      },
    });
  const [isOpenConfirmSignature, setIsOpenConfirmSignature] =
    useState<boolean>(false);
  const [avatar, setAvatar] = useState<File>();
  const [selectedPlan, setSelectedPlan] = useState<IGetPaymentsPlansData>(
    {} as IGetPaymentsPlansData
  );
  const [paymentsPlans, setPaymentsPlans] = useState<IGetPaymentsPlansData[]>(
    []
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut, isAuthenticated, user, isValidUser } =
    useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IInputFields>({
    defaultValues: {
      email: user?.email,
      name: user?.name,
      username: user?.username,
      profession: user?.profession,
    },
  });

  const onDrop = useCallback((files: File[]) => {
    const names: string[] = [];
    const objectURL = URL.createObjectURL(files[0]);

    files.forEach((element) => {
      names.push(element.name);
    });

    setAvatar(files[0]);
    setImageLink(objectURL);
    setRevokeList((prev) => [...prev, objectURL]);
  }, []);

  const validator = (file: File) => {
    if (file.size > 500000) {
      return {
        code: 'size-too-large',
        message: 'O arquivo é maior que 500 Kbytes',
      };
    }

    return null;
  };

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    validator,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
    },
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return errors.map((e) => (
      <span key={e.code} className="textfield-error">
        {e.message}
      </span>
    ));
  });

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsUpdateProfile((prev) => !prev);
    const formData = new FormData();

    formData.append('username', `${data.username}`);
    formData.append('name', `${data.name}`);
    formData.append('profession', `${data.profession}`);
    !!avatar && formData.append('avatar', avatar);

    await updateProfileService(formData)
      .then((response) => {
        revokeList.map((list) => URL.revokeObjectURL(list));
        handleToastfy({
          message: 'Perfil atualizado com sucesso!',
          type: 'success',
        });
        validateUser();
        setIsUpdateProfile((prev) => !prev);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsUpdateProfile((prev) => !prev);
      });
  };

  const validateUser = () => {
    setIsLoadingForm(true);
    isValidUser()
      .then((response) => {
        setIsLoadingForm(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingForm(false);
        setIsUpdateProfile((prev) => !prev);
      });
  };

  const handleCancelSignature = async () => {
    setIsLoadingCancelSignature((prev) => !prev);
    await deleteSignatureService()
      .then((response) => {
        setIsLoadingCancelSignature((prev) => !prev);
        setIsOpenConfirmSignature((prev) => !prev);
        handleToastfy({
          message: 'Você cancelou seu plano com sucesso.',

          type: 'success',
        });
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingCancelSignature((prev) => !prev);
      });
  };

  const isValidExpDate = (dateExp: string) => {
    if (dateExp.length !== 7) {
      handleToastfy({
        message:
          'Formato de validade inválido. Formato correto, exemplo: 12/2022',
        type: 'error',
      });
      return false;
    }

    return true;
  };

  const onSubmitCreateSignature: SubmitHandler<IInputFields> = async (data) => {
    if (isValidExpDate(dateExp)) {
      setIsPaymentFormLoading(true);

      const [area, number] = numberPhone.split(' ');
      const [birth_day, birth_month, birth_year] = birthDate.split('/');
      const [exp_month, exp_year] = dateExp.split('/');
      const birth_date = `${birth_year}-${birth_month}-${birth_day}`;
      const tax_id = cpf.replaceAll('.', '').replace('-', '');

      const card = encryptCardPagSeguro({
        expMonth: exp_month,
        expYear: exp_year,
        holder: nameCard,
        number: numberCard.replaceAll(' ', ''),
        securityCode: cvv,
      });

      if (!card.hasError) {
        await createSignatureService({
          customer: {
            name: data.name,
            email: data.email,
            tax_id,
            phones: [
              {
                country: '55',
                number: number.replace('-', ''),
                area: area.replace('(', '').replace(')', ''),
              },
            ],
            birth_date,
            billing_info: [
              {
                type: 'CREDIT_CARD',
                card: {
                  encrypted: card.encryptedCard,
                  holder: {
                    name: nameCard,
                  },
                  security_code: cvv,
                },
              },
            ],
          },
          planId: selectedPlan.id.toString(),
          address: {
            city: address.city,
            complement: address.complement ?? '.',
            country: data.country,
            locality: address.locality,
            number: data.number,
            postal_code: cep.replace('-', '').replace('.', ''),
            region_code: address.region_code,
            street: address.street,
          },
        })
          .then((response) => {
            handleToastfy({
              message: 'Plano alterado com sucesso!',
              type: 'success',
            });
            setIsOpenPaymentModal((prev) => !prev);
            setIsOpenAlterPlanModal((prev) => !prev);
            setSelectedPlan({} as IGetPaymentsPlansData);
            validateUser();
            setIsPaymentFormLoading(false);
          })
          .catch((error) => {
            setIsPaymentFormLoading(false);
            console.log(error);

            if (!error.reponse.data) return;

            if (
              error.response.data.code === 'EXPIRED_TOKEN' ||
              error.response.data.code === 'TOKEN_REVOKED' ||
              error.response.data.code === 'TOKEN_NOT_FOUND'
            ) {
              handleToastfy({
                message: 'Sessão expirada!',
                type: 'error',
              });
              signOut();
              navigate('/sign-in');
            } else {
              handleToastfy({
                message:
                  !!error.response.data.message || error.response.data.code
                    ? error.response.data.message || error.response.data.code
                    : 'Ocorreu um erro ao processar a requisição.',
                type: 'error',
              });
            }

            console.log('error-after-payment', error);
          });
      } else {
        card.errors.forEach((error: IErrorPagBank) => {
          handleToastfy({
            message: error.message,
            type: 'error',
          });
        });
      }
    }
  };

  const onSubmitAlterMethodPayment = async () => {
    if (isValidExpDate(dateExp)) {
      setIsPaymentFormLoading(true);

      const [exp_month, exp_year] = dateExp.split('/');

      const card = encryptCardPagSeguro({
        expMonth: exp_month,
        expYear: exp_year,
        holder: nameCard,
        number: numberCard
          .replaceAll('-', '')
          .replaceAll('.', '')
          .replaceAll(' ', ''),
        securityCode: cvv,
      });

      if (!card.hasError) {
        await alterMethodPaymentService({
          billingInfo: [
            {
              type: 'CREDIT_CARD',
              card: {
                encrypted: card.encryptedCard,
                holder: {
                  name: nameCard,
                },
                security_code: cvv,
              },
            },
          ],
        })
          .then((response) => {
            console.log(response);
            handleToastfy({
              message: 'Dados do cartão alterado com sucesso!',
              type: 'success',
            });
            setIsOpenAlterPaymentModal((prev) => !prev);
            setIsPaymentFormLoading(false);
          })
          .catch((error) => {
            setIsPaymentFormLoading(false);
            console.log(error);

            if (!error.reponse.data) return;

            if (
              error.response.data.code === 'EXPIRED_TOKEN' ||
              error.response.data.code === 'TOKEN_REVOKED' ||
              error.response.data.code === 'TOKEN_NOT_FOUND'
            ) {
              handleToastfy({
                message: 'Sessão expirada!',
                type: 'error',
              });
              signOut();
              navigate('/sign-in');
            } else {
              handleToastfy({
                message:
                  !!error.response.data.message || error.response.data.code
                    ? error.response.data.message || error.response.data.code
                    : 'Ocorreu um erro ao processar a requisição.',
                type: 'error',
              });
            }

            console.log('error-after-payment', error);
          });
      } else {
        card.errors.forEach((error: IErrorPagBank) => {
          handleToastfy({
            message: error.message,
            type: 'error',
          });
        });
      }
    }
  };

  const onSubmitChangeSignature = async (e: any) => {
    e.preventDefault();

    setIsPaymentFormLoading(true);

    if (user?.currentPlan === 'free') {
      if (isValidExpDate(dateExp)) {
        await createSignatureService({
          customer: {
            id: user.customerId,
            email: user.email,
            billing_info: [
              {
                type: 'CREDIT_CARD',
                card: {
                  security_code: cvv,
                },
              },
            ],
          },
          planId: selectedPlan.id.toString(),
        })
          .then((response) => {
            handleToastfy({
              message: 'Plano alterado com sucesso!',
              type: 'success',
            });
            setIsOpenPaymentModal((prev) => !prev);
            setIsOpenAlterPlanModal((prev) => !prev);
            setSelectedPlan({} as IGetPaymentsPlansData);
            validateUser();
            setIsPaymentFormLoading(false);
          })
          .catch((error) => {
            setIsPaymentFormLoading(false);
            console.log(error);

            if (!error.reponse.data) return;

            if (
              error.response.data.code === 'EXPIRED_TOKEN' ||
              error.response.data.code === 'TOKEN_REVOKED' ||
              error.response.data.code === 'TOKEN_NOT_FOUND'
            ) {
              handleToastfy({
                message: 'Sessão expirada!',
                type: 'error',
              });
              signOut();
              navigate('/sign-in');
            } else {
              handleToastfy({
                message:
                  !!error.response.data.message || error.response.data.code
                    ? error.response.data.message || error.response.data.code
                    : 'Ocorreu um erro ao processar a requisição.',
                type: 'error',
              });
            }

            console.log('error-after-payment', error);
          });
      } else {
        setIsPaymentFormLoading(false);
      }
    } else {
      const responseSignature = await getCurrentSignature();

      await changeSignatureService({
        email: user?.email ?? '',
        planId: selectedPlan.id.toString(),
        subscriptionId: responseSignature.data.pagBank.id,
      })
        .then((response) => {
          // console.log(response);
          handleToastfy({
            message: 'Plano alterado com sucesso!',
            type: 'success',
          });
          setIsOpenPaymentModal((prev) => !prev);
          setIsOpenAlterPlanModal((prev) => !prev);
          setSelectedPlan({} as IGetPaymentsPlansData);
          validateUser();
          setIsPaymentFormLoading(false);
        })
        .catch((error) => {
          setIsPaymentFormLoading(false);
          console.log('error-after-payment', error);
          if (!error.response.data) {
            return;
          }
          if (
            error.response.data.code === 'EXPIRED_TOKEN' ||
            error.response.data.code === 'TOKEN_REVOKED' ||
            error.response.data.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.response.data.message || error.response.data.code
                  ? error.response.data.message || error.response.data.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
        });
    }
  };

  useEffect(() => {
    const newValueCEP = cep.replace('-', '').replace('.', '');

    if (newValueCEP.length === 8) {
      getAddressByCEP({ cep: newValueCEP }).then(
        (addressData: IGetAddressResponse) => {
          setAddress({
            city: addressData.localidade,
            complement: addressData.complemento,
            locality: addressData.bairro,
            region_code: addressData.uf,
            street: addressData.logradouro,
          });
        }
      );
    }
  }, [cep]);

  const getPaymentsPlans = useCallback(async () => {
    await getPaymentsPlansService()
      .then((response) => {
        setPaymentsPlans(response.data.filter((plan) => plan.value > 0));
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    setIsLoadingForm(true);

    if (isAuthenticated) {
      !!user?.avatar &&
        setImageLink(`${process.env.REACT_APP_URL_IMAGE_BASE}/${user?.avatar}`);
      reset({
        email: user?.email,
        avatar: user?.avatar,
        name: user?.name,
        profession: user?.profession,
        username: user?.username,
      });
      setIsLoadingForm(false);
    }
  }, [isAuthenticated, reset, user]);

  useEffect(() => {
    getPaymentsPlans();
    getDataCustomerSignature()
      .then((response) => {
        // console.log(response);
        setDataCustomerPayment(response.data.pagBank.payment_method[0].card);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getPaymentsPlans, isPaymentFormLoading]);

  return (
    <ProfileContainer>
      <DashboardConfigCard>
        <div className="account-content">
          <h3 className="account-content-heading-3">Perfil</h3>
          <p className="account-content-subtitle">
            Confira os seus dados pessoais e atualize se necessário.
          </p>
          {isLoadingForm ? (
            <div className="loading-form-spinner">
              <Spinner size={100} />
            </div>
          ) : (
            <form className="account-form" onSubmit={handleSubmit(onSubmit)}>
              <span className="form-span">Nome do usuário</span>
              <Textfield
                className="form-input"
                label=""
                type="text"
                error={errors.username?.message}
                {...register('username', {
                  required: 'O campo é requerido.',
                })}
              />
              <span className="form-span">Nome</span>
              <Textfield
                className="form-input"
                label=""
                type="text"
                error={errors.name?.message}
                {...register('name', {
                  required: 'O campo é requerido.',
                })}
              />
              <span className="form-span">Foto de perfil</span>
              <div className="form-input form-input--input-send-box">
                {imageLink ? (
                  <span
                    className="form-input-avatar"
                    style={{
                      backgroundImage: `url(${imageLink})`,
                    }}
                  />
                ) : (
                  <span className="form-input-avatar-without-image">
                    <AiOutlinePlus
                      size={36}
                      color={theme.pallete.colors.common.black}
                    />
                  </span>
                )}
                <div className="form-input-send-container">
                  <div className="form-input-send" {...getRootProps()}>
                    <input {...getInputProps()} style={{ display: 'none' }} />
                    <FiUploadCloud
                      size={24}
                      color={theme.pallete.colors.brandPrimary.gray[7]}
                    />
                    <span className="form-input-send-text">
                      <strong>Clique</strong>&nbsp; para subir uma nova imagem
                      de perfil.
                    </span>
                  </div>
                  {fileRejectionItems}
                </div>
              </div>
              <span className="form-span">E-mail</span>
              <Textfield
                className="form-input"
                label=""
                type="email"
                error={errors.email?.message}
                {...register('email', {
                  required: 'O campo é requerido.',
                })}
                readOnly
              />
              <span className="form-span">Profissão</span>
              <Textfield
                className="form-input"
                label=""
                type="text"
                error={errors.profession?.message}
                {...register('profession', {
                  required: 'O campo é requerido.',
                })}
              />

              {dataCustomerPayment && (
                <>
                  <span className="form-span-title">Dados da Assinatura</span>
                  <span className="form-span">Nome impresso no cartão</span>
                  <span className="form-group-plan-span">
                    {dataCustomerPayment.holder.name}
                  </span>
                  <span className="form-span">Número</span>
                  <span className="form-group-plan-span">
                    {dataCustomerPayment.first_digits} **** ****{' '}
                    {dataCustomerPayment.last_digits}
                  </span>
                  <span className="form-span">Validade</span>
                  <span className="form-group-plan-span">
                    {dataCustomerPayment.exp_month}/
                    {dataCustomerPayment.exp_year}
                  </span>
                </>
              )}

              {user?.role === 'user' && (
                <React.Fragment>
                  <span className="form-span">Plano atual</span>
                  <div className="form-group-plan-box">
                    <span className="form-group-plan-span">
                      {user.currentPlan === 'free'
                        ? 'Plano Gratuito'
                        : user.currentPlan}
                    </span>
                  </div>

                  <span className="form-span"></span>
                  <div className="button-plan-group">
                    {user.currentPlan !== 'free' && (
                      <ButtonText
                        buttonText="Cancelar plano"
                        color={theme.pallete.colors.brandPrimary.red[1]}
                        type="button"
                        onClick={() =>
                          setIsOpenConfirmSignature((prev) => !prev)
                        }
                      />
                    )}

                    {user?.customerId && (
                      <ButtonText
                        buttonText="Alterar dados de pagamento"
                        color={theme.pallete.colors.brandPrimary.yellow[9]}
                        type="button"
                        onClick={() => {
                          setIsOpenAlterPaymentModal((prev) => !prev);
                        }}
                      />
                    )}

                    <ButtonText
                      buttonText="Alterar plano"
                      color={theme.pallete.colors.brandPrimary.yellow[9]}
                      type="button"
                      onClick={() => {
                        setIsOpenAlterPlanModal((prev) => !prev);
                      }}
                    />
                  </div>
                </React.Fragment>
              )}
              <Button
                buttonText="Salvar dados"
                variant="contained"
                type="submit"
                className="form-button-submit"
                buttonSize={200}
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                isLoading={isUpdateProfile}
              />
              {/* <ButtonText
                className="form-button-delete"
                buttonText="Excluir conta"
                color={theme.pallete.colors.brandPrimary.red[4]}
              /> */}
            </form>
          )}
        </div>

        {isOpenConfirmSignature && (
          <ConfirmLightBox
            buttonCancelLabel="Não"
            buttonConfirmlLabel="Sim"
            buttonConfirmFunction={() => handleCancelSignature()}
            isLoading={isLoadingCancelSignature}
            setIsOpenModal={setIsOpenConfirmSignature}
            title="Deseja realmente cancelar o plano?"
          />
        )}
        {isOpenAlterPlanModal && (
          <LightBox
            handleOpenModal={() => setIsOpenAlterPlanModal((prev) => !prev)}
          >
            <h2 className="plans-box-heading">Selecione um novo plano</h2>
            <div className="plans-box">
              <div className="grid-plans">
                {paymentsPlans.map((plans, index) => {
                  const idInput = uuidv4();
                  return (
                    <React.Fragment key={index}>
                      <label htmlFor={idInput} style={{ display: 'flex' }}>
                        <CardPlan
                          isActive={selectedPlan.id === plans.id ? true : false}
                          onClick={() => setSelectedPlan(plans)}
                          listItems={plans.descriptions.map((description) => {
                            return {
                              icon: (
                                <BsFillCheckCircleFill
                                  size={24}
                                  color={
                                    theme.pallete.colors.brandPrimary.green[3]
                                  }
                                />
                              ),
                              text: (
                                <React.Fragment>
                                  <strong>{description.title}</strong>
                                  {!!description.subtitle &&
                                    ` - ${description.subtitle}`}
                                </React.Fragment>
                              ),
                            };
                          })}
                          titleCard={plans.title}
                          price={plans.valueString}
                          subtitleCard={plans.description}
                          cursor
                        />
                      </label>
                      <input
                        type="radio"
                        name="select-plan"
                        id={idInput}
                        value={plans.id}
                        style={{ display: 'none' }}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
              <Button
                variant="contained"
                buttonText="Selecionar plano"
                className="button-confirm-account"
                isDisabled={lodash.values(selectedPlan).every(lodash.isEmpty)}
                onClick={() => setIsOpenPaymentModal((prev) => !prev)}
              />
            </div>
          </LightBox>
        )}
        {isOpenPaymentModal && (
          <LightBox
            handleOpenModal={() => setIsOpenPaymentModal((prev) => !prev)}
          >
            <div className="payment-modal">
              <div className="payment-modal-icon-box">
                <AiOutlineCheckCircle
                  size={56}
                  color={theme.pallete.colors.success.main}
                />
              </div>
              <div className="payment-modal-inputs">
                <div className="texts-group">
                  <span className="texts-label">Plano mensal escolhido:</span>
                  <span className="texts-value">{selectedPlan.title}</span>
                </div>
                <div className="texts-group">
                  <span className="texts-label">Detalhes do plano mensal:</span>
                  <span className="texts-value">
                    {selectedPlan.description}
                  </span>
                </div>
                <div className="texts-group">
                  <span className="texts-label">Valor a pagar:</span>
                  <span className="texts-value">
                    R$ {selectedPlan.valueString}/mês
                  </span>
                </div>
              </div>

              {!user?.customerId ? (
                <div className="payment-modal-inputs">
                  <form
                    className="form-choose-plan"
                    onSubmit={handleSubmit(onSubmitCreateSignature)}
                  >
                    <div className="input-group">
                      <span className="input-label">Informação pessoal</span>

                      <div className="inputs">
                        <Textfield
                          label="Nome completo"
                          type="text"
                          {...register('name', {
                            required: 'O campo é requerido.',
                          })}
                          required
                        />
                        <Textfield
                          label="Seu e-mail"
                          type="email"
                          {...register('email', {
                            required: 'O campo é requerido.',
                          })}
                          required
                          readOnly
                        />

                        <TextFieldMask
                          label="Data de Nascimento"
                          type="text"
                          mask={'99/99/9999'}
                          onChange={(e: any) => setBirthDate(e.target.value)}
                          value={birthDate}
                          required
                        />

                        <TextFieldMask
                          label="CPF"
                          type="text"
                          mask={'999.999.999-99'}
                          onChange={(e: any) => setCpf(e.target.value)}
                          value={cpf}
                          required
                        />

                        <TextFieldMask
                          label="Celular"
                          type="text"
                          mask={'(99) 99999-9999'}
                          onChange={(e: any) => setNumberPhone(e.target.value)}
                          value={numberPhone}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <span className="input-label">Pagamento</span>
                      <div className="inputs">
                        <Textfield
                          label="Nome impresso no cartão"
                          type="text"
                          onChange={(e: any) => {
                            setNameCard(e.target.value);
                          }}
                          required
                        />
                        <TextFieldMask
                          label="Número do Cartão"
                          type="text"
                          onChange={(e: any) => {
                            setNumberCard(e.target.value);
                          }}
                          mask={'9999 9999 9999 9999'}
                          required
                        />
                        <TextFieldMask
                          label="Validade"
                          type="text"
                          mask={'99/9999'}
                          onChange={(e: any) => setDateExp(e.target.value)}
                          value={dateExp}
                          required
                        />
                        <Textfield
                          label="Confirme o CVV"
                          type="text"
                          minLength={3}
                          maxLength={3}
                          onChange={(e) => {
                            setCvv(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <span className="input-label">Endereço</span>

                      <div className="inputs">
                        <Textfield
                          label="País"
                          type="text"
                          {...register('country', {
                            required: 'O campo é requerido.',
                          })}
                          required
                        />
                        <TextFieldMask
                          label="CEP"
                          type="text"
                          mask={'99.999-999'}
                          value={cep}
                          onChange={(e: any) => {
                            setCep(e.target.value);
                          }}
                          required
                        />
                        <Textfield
                          label="UF"
                          type="text"
                          value={address.region_code}
                          readOnly
                          required
                        />
                        <Textfield
                          label="Cidade"
                          type="text"
                          value={address.city}
                          readOnly
                          required
                        />
                        <Textfield
                          label="Rua"
                          type="text"
                          value={address.street}
                          readOnly
                          required
                        />
                        <Textfield
                          label="Número"
                          type="text"
                          {...register('number', {
                            required: 'O campo é requerido.',
                          })}
                          required
                        />
                        <Textfield
                          label="Bairro"
                          type="text"
                          value={address.locality}
                          readOnly
                          required
                        />
                        <Textfield
                          label="Complemento"
                          type="text"
                          value={address.complement}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="footer-form">
                      <Button
                        buttonText="Confirmar Assinatura"
                        type="submit"
                        buttonSize={160}
                        className="form-button-submit-plan"
                        isLoading={isPaymentFormLoading}
                        variant="contained"
                      />
                      {/* <img
                        src="/static/images/logo-pag-seguro-pci.png"
                        alt=""
                      /> */}
                      <img
                        src="/static/images/payments/pagseguro_form.jpg"
                        alt="Banner PagSeguro"
                        title="Compre com PagSeguro e fique sossegado"
                        width={150}
                      ></img>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="payment-modal-inputs">
                  <div className="form-choose-plan">
                    {user.currentPlan === 'free' && (
                      <div className="input-group">
                        <span className="input-label">Dados do Pagamento</span>
                        <div className="inputs">
                          <div>
                            <span className="texts-value">
                              Nome impresso no cartão
                            </span>
                            <p>{dataCustomerPayment.holder.name}</p>
                          </div>
                          <div>
                            <span className="texts-value">
                              Número do Cartão
                            </span>
                            <p>
                              {dataCustomerPayment.first_digits} **** ****
                              {dataCustomerPayment.last_digits}
                            </p>
                          </div>
                          <div>
                            <span className="texts-value">Validade</span>
                            <p>
                              {dataCustomerPayment.exp_month}/
                              {dataCustomerPayment.exp_year}
                            </p>
                          </div>

                          <Textfield
                            label="Confirme o CVV"
                            type="text"
                            minLength={3}
                            maxLength={3}
                            onChange={(e) => {
                              setCvv(e.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                    )}
                    <div className="footer-form">
                      <Button
                        buttonText="Confirmar Assinatura"
                        type="button"
                        buttonSize={160}
                        className="form-button-submit-plan"
                        isLoading={isPaymentFormLoading}
                        variant="contained"
                        onClick={(e) => onSubmitChangeSignature(e)}
                      />

                      <img
                        src="/static/images/payments/pagseguro_form.jpg"
                        alt="Banner PagSeguro"
                        title="Compre com PagSeguro e fique sossegado"
                        width={150}
                      ></img>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </LightBox>
        )}
        {isOpenAlterPaymentModal && (
          <LightBox
            handleOpenModal={() => setIsOpenAlterPaymentModal((prev) => !prev)}
          >
            <div className="payment-modal">
              <div className="payment-modal-inputs">
                <div className="form-choose-plan">
                  <div className="input-group">
                    <span className="input-label">
                      Alterar dados do pagamento
                    </span>
                    <div className="inputs">
                      <Textfield
                        label="Nome impresso no cartão"
                        type="text"
                        onChange={(e) => {
                          setNameCard(e.target.value);
                        }}
                        required
                      />
                      <TextFieldMask
                        label="Número do Cartão"
                        type="text"
                        onChange={(e: any) => {
                          setNumberCard(e.target.value);
                        }}
                        mask={'9999 9999 9999 9999'}
                        required
                      />
                      <TextFieldMask
                        label="Validade"
                        type="text"
                        mask={'99/9999'}
                        onChange={(e: any) => setDateExp(e.target.value)}
                        value={dateExp}
                        required
                      />
                      <Textfield
                        label="Confirme o CVV"
                        type="text"
                        minLength={3}
                        maxLength={3}
                        onChange={(e) => {
                          setCvv(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>

                  <div className="footer-form">
                    <Button
                      buttonText="Confirmar Alteração"
                      type="button"
                      buttonSize={160}
                      className="form-button-submit-plan"
                      isLoading={isPaymentFormLoading}
                      variant="contained"
                      onClick={(e) => onSubmitAlterMethodPayment()}
                    />

                    <img
                      src="/static/images/payments/pagseguro_form.jpg"
                      alt="Banner PagSeguro"
                      title="Compre com PagSeguro e fique sossegado"
                      width={150}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </LightBox>
        )}
      </DashboardConfigCard>
    </ProfileContainer>
  );
};

export default Profile;
