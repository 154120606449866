import styled, { css } from 'styled-components';

interface IProps {
  isAdmin: boolean;
}

export const CreateContentsLayoutContainer = styled.div<IProps>`
  .see-edit-contents-content {
    ${(props) =>
      props.isAdmin
        ? css`
            padding: 18rem 6rem 0 39rem;
          `
        : css`
            padding: 18rem 6rem 0;
          `};
    min-height: calc(100vh - 18rem);

    @media ${(props) => props.theme.medias.laptopSmall} {
      padding: 18rem 3rem 0 3rem;
    }
  }

  .see-edit-contents-children {
  }

  .footer-see-edit-contents-paragraph,
  .footer-see-edit-contents-copy {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .footer-see-edit-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    padding: 12rem 0 3rem;

    @media ${(props) => props.theme.medias.mobile} {
      text-align: center;
      gap: 1.6rem;
    }
  }
`;
