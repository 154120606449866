import styled, { css } from 'styled-components';

interface IProjectsWallContainerProps {
  projectsLength: number;
}

const projectLengthClass = {
  0: css``,
  1: css`
    &--1 {
      grid-column: col-start 1 / col-end 6;
      grid-row: 1 / 12;
      background-image: url('/static/images/projects/blood-send.jpg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
        grid-row: row-start 1 / row-end 12;
        height: 30rem;
      }
    }
  `,
  2: css`
    &--1 {
      grid-column: col-start 1 / col-end 6;
      grid-row: row-start 1 / row-end 12;
      background-image: url('/static/images/projects/blood-send.jpg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }

    &--2 {
      grid-column: col-start 7 / col-end 12;
      grid-row: row-start 1 / row-end 12;
      background-image: url('/static/images/projects/help-project.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
  `,
  3: css`
    &--1 {
      grid-column: col-start 1 / col-end 4;
      grid-row: row-start 1 / row-end 12;
      background-image: url('/static/images/projects/blood-send.jpg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
        grid-row: row-start 1 / row-end 4;
      }
    }

    &--2 {
      grid-column: col-start 5 / col-end 8;
      grid-row: row-start 1 / row-end 12;
      background-image: url('/static/images/projects/help-project.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 7 / col-end 12;
        grid-row: row-start 1 / row-end 12;
      }
    }

    &--3 {
      grid-column: col-start 9 / col-end 12;
      grid-row: row-start 1 / row-end 12;
      background-image: url('/static/images/projects/mito-sobre-os-dentes.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
        grid-row: row-start 5 / row-end 12;
      }
    }
  `,
  4: css`
    &--1 {
      grid-column: col-start 1 / col-end 4;
      grid-row: row-start 1 / row-end 4;
      background-image: url('/static/images/projects/blood-send.jpg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
        grid-row: row-start 1 / row-end 4;
      }
    }

    &--2 {
      grid-column: col-start 5 / col-end 8;
      grid-row: row-start 1 / row-end 8;
      background-image: url('/static/images/projects/help-project.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 7 / col-end 12;
        grid-row: row-start 1 / row-end 6;
      }
    }

    &--3 {
      grid-column: col-start 9 / col-end 12;
      grid-row: row-start 1 / row-end 5;
      background-image: url('/static/images/projects/mito-sobre-os-dentes.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
        grid-row: row-start 5 / row-end 12;
      }
    }

    &--4 {
      grid-column: col-start 1 / col-end 4;
      grid-row: row-start 5 / row-end 12;
      background-image: url('/static/images/projects/room-project.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 7 / col-end 12;
        grid-row: row-start 7 / row-end 12;
      }
    }
  `,
  5: css`
    &--1 {
      grid-column: col-start 1 / col-end 4;
      grid-row: row-start 1 / row-end 4;
      background-image: url('/static/images/projects/blood-send.jpg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
        grid-row: row-start 1 / row-end 4;
      }
    }

    &--2 {
      grid-column: col-start 5 / col-end 8;
      grid-row: row-start 1 / row-end 6;
      background-image: url('/static/images/projects/help-project.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 7 / col-end 12;
        grid-row: row-start 1 / row-end 6;
      }
    }

    &--3 {
      grid-column: col-start 9 / col-end 12;
      grid-row: row-start 1 / row-end 5;
      background-image: url('/static/images/projects/mito-sobre-os-dentes.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
        grid-row: row-start 5 / row-end 8;
      }
    }

    &--4 {
      grid-column: col-start 1 / col-end 4;
      grid-row: row-start 5 / row-end 12;
      background-image: url('/static/images/projects/room-project.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 7 / col-end 12;
        grid-row: row-start 7 / row-end 12;
      }
    }

    &--5 {
      grid-column: col-start 5 / col-end 8;
      grid-row: row-start 7 / row-end 12;
      background-image: url('/static/images/projects/instagram-project.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
        grid-row: row-start 9 / row-end 12;
      }
    }
  `,
  6: css`
    &--1 {
      grid-column: col-start 1 / col-end 4;
      grid-row: row-start 1 / row-end 4;
      background-image: url('/static/images/projects/blood-send.jpg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
        grid-row: row-start 1 / row-end 4;
      }
    }

    &--2 {
      grid-column: col-start 5 / col-end 8;
      grid-row: row-start 1 / row-end 6;
      background-image: url('/static/images/projects/help-project.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 7 / col-end 12;
        grid-row: row-start 1 / row-end 6;
      }
    }

    &--3 {
      grid-column: col-start 9 / col-end 12;
      grid-row: row-start 1 / row-end 5;
      background-image: url('/static/images/projects/mito-sobre-os-dentes.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
        grid-row: row-start 5 / row-end 8;
      }
    }

    &--4 {
      grid-column: col-start 1 / col-end 4;
      grid-row: row-start 5 / row-end 12;
      background-image: url('/static/images/projects/room-project.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 7 / col-end 12;
        grid-row: row-start 7 / row-end 10;
      }
    }

    &--5 {
      grid-column: col-start 5 / col-end 8;
      grid-row: row-start 7 / row-end 12;
      background-image: url('/static/images/projects/instagram-project.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
        grid-row: row-start 9 / row-end 12;
      }
    }

    &--6 {
      grid-column: col-start 9 / col-end 12;
      grid-row: row-start 6 / row-end 12;
      background-image: url('/static/images/projects/teeth.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 7 / col-end 12;
        grid-row: row-start 11 / row-end 12;
      }
    }
  `,
};

export const ProjectWallContainer = styled.div<IProjectsWallContainerProps>`
  position: relative;
  margin: 9.6rem 0;

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin: 6rem 0;
  }

  ${(props) => props.theme.mixins.containerGridDefault};

  .projects-wall-spinner {
    grid-column: center-start / center-end;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
  }

  .project-wall-texts {
    grid-column: center-start / center-end;
    text-align: center;
  }

  .project-wall-heading-3 {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 2.4rem;
    line-height: 5rem;
  }

  .project-wall-paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    line-height: 130%;
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
  }

  .project-wall-grid {
    grid-column: center-start / center-end;

    display: grid;
    grid-template-columns: repeat(
      12,
      [col-start] minmax(min-content, 9.6rem) [col-end]
    );
    grid-template-rows: repeat(
      auto-fit,
      [row-start] minmax(min-content, 2.58rem) [row-end]
    );
    gap: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      gap: 1.6rem;
    }

    @media ${(props) => props.theme.medias.mobile} {
      display: flex;
      flex-direction: column;
      margin: 3rem 0;
    }
  }

  .project-wall-grid-item {
    ${(props) =>
      projectLengthClass[
        props.projectsLength as keyof typeof projectLengthClass
      ]}

    @media ${(props) => props.theme.medias.mobile} {
      height: 30rem;
    }

    &:hover .project-wall-grid-item-content {
      opacity: 1;
    }

    .project-wall-grid-item-content {
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      padding: 2.4rem 1.6rem;

      display: flex;
      flex-direction: column;
      justify-content: end;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    .project-wall-grid-item-paragraph {
      color: ${(props) => props.theme.pallete.colors.common.white};
      font-weight: ${(props) => props.theme.typeFaceWeight.medium};
      font-size: 1.3rem;
      line-height: 1.6rem;
    }

    .project-wall-grid-item-name {
      color: ${(props) => props.theme.pallete.colors.common.white};
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
      font-size: 2rem;
      line-height: 2.2rem;
      margin-bottom: 1.6rem;
    }

    .project-wall-grid-item-social {
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;
    }

    .social-media-box {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      svg {
        color: ${(props) => props.theme.pallete.colors.common.white};
      }
    }

    .social-media {
      color: ${(props) => props.theme.pallete.colors.common.white};
      font-weight: ${(props) => props.theme.typeFaceWeight.medium};
      font-size: 1.3rem;
      line-height: 1.6rem;
    }

    .project-wall-grid-social-container {
      display: flex;
      gap: 0.8rem;
      margin-bottom: 0.8rem;
    }

    .project-wall-grid-social-title {
      color: ${(props) => props.theme.pallete.colors.common.white};
      font-weight: ${(props) => props.theme.typeFaceWeight.medium};
      font-size: 1.4rem;
      line-height: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      white-space: nowrap;
    }

    .project-wall-grid-social-link {
      color: ${(props) => props.theme.pallete.colors.common.white};
      font-weight: ${(props) => props.theme.typeFaceWeight.medium};
      font-size: 1.3rem;
      line-height: 1.6rem;
      word-break: break-all;
    }

    .project-wall-see-image {
      color: ${(props) => props.theme.pallete.colors.common.white};
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
      font-size: 2rem;
      text-decoration: underline;
      line-height: 2.2rem;
      cursor: pointer;
    }
  }

  .paragraph-any-projects {
    grid-column: center-start / center-end;
    text-align: center;
    font-size: 2rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
  }
`;
