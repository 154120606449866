import styled from 'styled-components';

export const BrainstormingRoomPostsContainer = styled.div`
  .brainstorming-list-room-header {
    margin-bottom: 4.8rem;
  }

  .brainstorming-list-room-date {
    display: inline-block;
    margin-right: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .brainstorming-list-room-username {
    display: inline-block;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
  }

  .brainstorming-list-room-description {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[5]};
    margin-top: 0.8rem;
  }

  .brainstorming-comments {
    margin-bottom: 3rem;
  }

  .room-post-lightbox-content {
    display: flex;
    flex-direction: column;
  }

  .brainstorming-list-room-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }

  .room-post-lightbox-title {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5rem;
    font-size: 1.6rem;
    user-select: none;
  }

  .room-post-lightbox-text {
    font-size: 1.6rem;
    font-weight: 400;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[10]};
    line-height: 2.4rem;
  }

  .room-post-lightbox-button {
    margin: 3rem auto 0;
  }

  .room-post-lightbox-button-group {
    display: flex;

    @media ${(props) => props.theme.medias.mobile} {
      flex-direction: column;
    }
  }

  .confirm-delete-button-group {
    display: flex;
    margin-top: 3rem;
    justify-content: center;
    gap: 3rem;
  }

  .brainstorming-list-room-delete-button {
    margin: 3rem auto 0;
  }

  .brainstorming-list-room-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  //************************ Images *********************************/

  .room-post-content-images {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin: 3rem 0;

    @media ${(props) => props.theme.medias.mobile} {
      justify-content: center;
    }
  }

  .room-post-content-image-box {
    width: 20rem;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .room-post-content-image {
    border-radius: 1rem;
  }

  .room-post-content-image-icon-box {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .room-post-content-image {
    width: 100%;
    max-width: 20rem;
    height: 20rem;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .room-post-content-image:hover .room-post-image-background {
    opacity: 1;
  }

  .room-post-content-image:hover .room-post-image-background {
    opacity: 1;
  }

  .room-post-image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;

    span {
      text-align: center;
      font-size: 2.4rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      color: ${(props) => props.theme.pallete.colors.common.white};
    }
  }
`;
