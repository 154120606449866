import styled from 'styled-components';

export const ShowYourIdeaSectionContainer = styled.section`
  position: relative;
  margin: 9.6rem 0;

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin: 6rem 0;
  }

  ${(props) => props.theme.mixins.containerGridDefault};

  .send-creative-spinner {
    grid-column: center-start / center-end;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
  }

  .form-idea {
    grid-column: center-start / center-end;

    background-color: ${(props) =>
      props.theme.pallete.colors.brandPrimary.yellow[10]};
    padding: 6rem 3rem;
    border-radius: 2rem;
  }

  .form-idea-title-box {
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.8rem;

    @media ${(props) => props.theme.medias.tablet} {
      flex-direction: column;
      /* align-items: baseline; */
    }
  }

  .form-idea-heading-2 {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-size: 3.2rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    margin: 0;
    text-align: center;

    @media ${(props) => props.theme.medias.tablet} {
      margin-right: 0;
    }
  }

  .form-idea-paragraph {
    color: ${(props) => props.theme.pallete.colors.common.black};
    margin-bottom: 3rem;
    text-align: center;
  }

  .form-idea-points-container {
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    border-radius: 100px;

    display: flex;
    align-items: center;
    gap: 0.8rem;
    width: max-content;
    padding: 0.8rem 1.6rem;
  }

  .form-idea-points-image {
    width: 100%;
    max-width: 4rem;
  }

  .form-idea-points-text {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    min-width: 10rem;
    word-break: keep-all;
  }

  .form-idea-form {
    display: grid;
    grid-template-columns: repeat(
      12,
      [col-start] minmax(min-content, 9.6rem) [col-end]
    );
    column-gap: 1.6rem;
    row-gap: 0.8rem;
  }

  .form-idea-form-input {
    &:nth-child(1) {
      grid-column: col-start 1 / col-end 4;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }
    &:nth-child(2) {
      grid-column: col-start 5 / col-end 8;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }
    &:nth-child(3) {
      grid-column: col-start 9 / col-end 12;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }
    &:nth-child(4) {
      grid-column: col-start 1 / col-end 6;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    &:nth-child(5) {
      grid-column: col-start 7 / col-end 12;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }
  }

  .form-idea-bottom {
    grid-column: col-start 1 / col-end 12;
    margin-top: 3rem;
    gap: 3rem;
    display: flex;
    align-items: center;

    @media ${(props) => props.theme.medias.tablet} {
      flex-direction: column;
    }
  }

  .form-idea-checkbox-container {
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .form-idea-checkbox-text {
    color: ${(props) => props.theme.pallete.colors.common.black};
    user-select: none;
  }
`;
