import React, { InputHTMLAttributes, forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RadioColorsContainer } from './styles';

const RadioColors: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
> = ({ ...props }, ref) => {
  const idInput = uuidv4();

  return (
    <RadioColorsContainer color={`${props.value}`}>
      <input id={idInput} {...props} ref={ref} />
      <label htmlFor={idInput} />
    </RadioColorsContainer>
  );
};

export default forwardRef(RadioColors);
