import { useEffect, Dispatch, SetStateAction } from 'react';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { TableAsyncContainer } from './styles';
import Spinner from '../loadings/Spinner';

interface IProps<T extends object> {
  data: T[];
  columns: Column<T>[];
  pageSizeAsync: number;
  setPageSizeAsync: Dispatch<SetStateAction<number>>;
  pageIndexAsync: number;
  setPageIndexAsync: Dispatch<SetStateAction<number>>;
  totalItems: number;
  isTableLoading: boolean;
}

const TableAsync = <T extends object>({
  columns,
  data,
  totalItems,
  pageIndexAsync,
  setPageIndexAsync,
  pageSizeAsync,
  setPageSizeAsync,
  isTableLoading,
}: IProps<T>) => {
  console.log(data);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageIndexAsync,
        pageSize: pageSizeAsync,
      } as any,
      manualPagination: true,
      pageCount: Math.ceil(totalItems / pageSizeAsync),
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    !!pageSize && setPageSizeAsync(pageSize);
  }, [pageSize, setPageSizeAsync]);

  useEffect(() => {
    setPageIndexAsync(pageIndex);
  }, [pageIndex, setPageIndexAsync]);

  return (
    <TableAsyncContainer isTableLoading={isTableLoading}>
      <div className="table-wrapper">
        <div className="table-loading">
          <span className="table-spinner">
            <Spinner size={50} />
          </span>
        </div>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <span>&nbsp;&#8595;</span>
                          ) : (
                            <span>&nbsp;&#8593;</span>
                          )
                        ) : (
                          column.canSort && <span>&nbsp;&#8645;</span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      style={{ position: 'relative' }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="pagination-button-group">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>
        </div>
        <span>
          Página&nbsp;
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>
        </span>
        <div className="select-show-quantity-box">
          <div className="select-show-quantity-arrow" />
          <select
            className="select-show-quantity"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </TableAsyncContainer>
  );
};

export default TableAsync;
