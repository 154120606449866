import React, { HTMLAttributes } from 'react';

const Logo: React.FC<HTMLAttributes<SVGElement>> = ({ ...props }) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
      {...props}
    >
      <g id="Background">
        <path
          id="Path 0"
          style={{ fill: '#fdca40' }}
          d="m144.5 26.6c-4.4 0.8-12.7 3.1-18.5 5-5.8 1.9-15.2 6-21 9-5.8 3-14.3 8.3-19 11.8-4.7 3.5-11.4 9.4-15 13.2-3.6 3.8-8.4 9.6-10.6 12.9-2.3 3.3-5.9 9.6-8.1 14-2.2 4.4-5.2 11.6-6.6 16-1.4 4.4-3.3 11.8-4.2 16.5-0.9 5.2-1.5 14.2-1.5 23 0 8.6 0.6 18.2 1.5 23.5 0.9 4.9 2.9 12.9 4.5 17.8 1.7 4.8 4.9 12.4 7.1 16.9 2.3 4.6 6 11 8.3 14.3 2.2 3.3 6.8 8.9 10.1 12.4 3.3 3.5 9.6 9.2 14 12.5 4.4 3.4 9.8 7.3 12 8.7 2.2 1.5 7.7 4.5 12.3 6.8 4.5 2.2 12.1 5.4 16.9 7 4.9 1.6 12.9 3.7 17.8 4.6 7 1.3 12.6 1.6 25 1.2 13.3-0.4 17.6-0.9 25.5-3.1 5.2-1.5 13.1-4.1 17.5-5.9 4.4-1.8 12-5.7 16.8-8.7 4.9-3 11.1-7.6 13.8-10.3 2.7-2.6 6.1-7.3 7.6-10.5 2.2-4.5 2.7-7.1 2.7-12.7-0.1-4.7-0.8-8.8-2.3-12.5-1.2-3-4-8.7-6.3-12.5-2.4-3.8-6.7-9.5-9.8-12.7-3.4-3.4-7.8-6.7-11.5-8.4-4.4-2-7.6-2.8-12-2.8-4.9 0-7.5 0.7-14 3.8-4.4 2-9.3 4.8-11 6-1.7 1.3-5.3 3.1-8 4-2.8 1-7.7 2-11 2.3-4 0.4-8.1 0-12.5-1.1-3.6-1-8.7-2.9-11.5-4.4-2.7-1.5-6.8-4.5-9-6.7-2.3-2.2-5.4-6.7-7-10-2.8-5.6-3-6.7-3-18 0-11.5 0.2-12.4 3.1-18.5 1.9-3.9 4.9-8 7.5-10.3 2.4-2.1 7.1-5.2 10.4-6.8 3.3-1.6 9.2-3.5 13-4.1 5.4-0.9 8.6-0.9 14 0 4.5 0.8 8.6 2.3 11.5 4.2 2.5 1.6 7.7 4.6 11.5 6.6 5.8 3.1 8.3 3.8 14.5 4.1 6.9 0.4 8 0.2 14-2.8 3.6-1.9 8.9-5.8 12-8.9 3-3 7.4-8.4 9.8-12 2.4-3.6 5.3-9.7 6.4-13.5 1.5-5.1 1.9-8.6 1.6-13-0.3-3.6-1.6-8.1-3.2-11.3-1.4-2.8-4.5-7.1-6.9-9.5-2.3-2.4-8.9-7.1-14.7-10.5-5.8-3.4-13.7-7.6-17.5-9.2-3.8-1.6-11.5-4.1-17-5.6-8.5-2.3-12.4-2.7-26-3-11.3-0.2-18.4 0.1-24 1.2z"
        />
        <path
          id="Path 1"
          style={{ fill: '#32373b' }}
          d="m227 127.9c-1.9 0.4-4.8 1.4-6.5 2.3-1.9 0.9-3.7 3.1-4.8 5.7-1.2 3-1.7 6.8-1.7 13.8 0 7.6 0.5 10.7 2 14 1.2 2.6 3.3 5 5.3 6 2.5 1.4 5.7 1.8 14.7 1.8 9.7 0 12-0.3 15-2 1.9-1.2 4.4-3.8 5.5-6 1.6-3.2 2-5.9 1.9-14 0-8.1-0.4-10.8-2.1-14-1.3-2.5-3.3-4.6-5.7-5.8-2.1-1-6.9-1.9-11.8-2.2-4.6-0.2-9.9-0.1-11.8 0.4z"
        />
      </g>
    </svg>
  );
};

export default Logo;
