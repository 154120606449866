import React from 'react';
import { Outlet } from 'react-router-dom';
import { Content } from './styles';
import DashboardLayoutTopBar from './DashboardLayoutTopBar';
import Footer from '../../Footer';

const DashboardLayout: React.FC = () => {
  return (
    <React.Fragment>
      <DashboardLayoutTopBar />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </React.Fragment>
  );
};

export default DashboardLayout;
