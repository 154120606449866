import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useRef,
} from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import {
  NavigationContainer,
  MenuItem,
  MenuMobileItem,
  MenuDropdown,
} from './styles';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ButtonDefault from '../../../components/Button';
import LogoFull from '../../../components/svgs/LogoFull';
import SubMenuUser from '../../dashboard/user/Dashboard/SubMenuUser';
import MenuMobile from '../../../components/MenuMobile';
import Logo from '../../../components/svgs/Logo';
import Avatar from '../../../components/Avatar';

const Navigation: React.FC = () => {
  const { pathname } = useLocation();
  const [routeSplitted, setRouteSplitted] = useState<string>('');
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const { isAuthenticated, user } = useContext(AuthContext);
  const refSubMenu = useRef<HTMLDivElement>(null);
  const refSubMenuTools = useRef<HTMLDivElement>(null);
  const openMenuRef = useRef<any>(null);
  const openMenuToolsRef = useRef<any>(null);

  const routeSplit = useCallback(() => {
    let route = pathname.split('/', 4);
    setRouteSplitted(route[1]);
  }, [pathname]);

  useEffect(() => {
    routeSplit();
    setShowSubMenu(false);
  }, [pathname, routeSplit]);

  useEffect(() => {
    const handleClickOutside = (evt: any) => {
      if (
        refSubMenu.current &&
        !refSubMenu.current.contains(evt.target) &&
        !openMenuRef.current.contains(evt.target)
      ) {
        setShowSubMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    showSubMenu
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [showSubMenu]);

  useEffect(() => {
    const handleClickOutside = (evt: any) => {
      if (
        refSubMenuTools.current &&
        !refSubMenuTools.current.contains(evt.target) &&
        openMenuToolsRef
      ) {
        setShowSubMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   showSubMenuTools
  //     ? (document.body.style.overflow = 'hidden')
  //     : (document.body.style.overflow = 'visible');
  // }, [showSubMenuTools]);

  return (
    <NavigationContainer>
      <MenuMobile className="mobile-nav-menu">
        {/* <MenuDropdown>
          <Link className="menu-item-link" to="#">
            <MenuMobileItem
              className="menu-mobile-item"
              isActive={
                routeSplitted === 'gatilhos-criativos' ||
                routeSplitted === 'termometro-de-ideias'
              }
              onClick={(event) => {
                setShowSubMenuTools((prev) => !prev);
              }}
            >
              Ferramentas
            </MenuMobileItem>
          </Link>

          {showSubMenuTools && (
            <div
              // ref={refSubMenuTools}
              style={{ position: 'absolute', userSelect: 'none' }}
              className="drop"
            >
              <Link className="menu-item-link" to="/gatilhos-criativos">
                <MenuMobileItem className="menu-mobile-item" isActive={false}>
                  Gatilhos Criativos
                </MenuMobileItem>
              </Link>
              <Link className="menu-item-link" to="/termometro-de-ideias">
                <MenuMobileItem className="menu-mobile-item" isActive={false}>
                  Termômetro de Ideias
                </MenuMobileItem>
              </Link>
            </div>
          )}
        </MenuDropdown> */}
        <Link className="menu-item-link" to="/gatilhos-criativos">
          <MenuMobileItem
            className="menu-mobile-item"
            isActive={routeSplitted === 'gatilhos-criativos'}
          >
            Gatilhos Criativos
          </MenuMobileItem>
        </Link>
        <Link className="menu-item-link" to="/salas-de-brainstorming">
          <MenuMobileItem
            className="menu-mobile-item"
            isActive={routeSplitted === 'salas-de-brainstorming'}
          >
            Salas de Brainstorming
          </MenuMobileItem>
        </Link>
        <Link className="menu-item-link" to="/desafios-criativistas">
          <MenuMobileItem
            className="menu-mobile-item"
            isActive={routeSplitted === 'desafios-criativistas'}
          >
            Desafios Criativistas
          </MenuMobileItem>
        </Link>
        <Link className="menu-item-link" to="/termometro-de-ideias">
          <MenuMobileItem
            className="menu-mobile-item"
            isActive={routeSplitted === 'termometro-de-ideias'}
          >
            Termômetro de Ideias
          </MenuMobileItem>
        </Link>
        <Link
          className="menu-item-link"
          to="https://vemsercriativista.substack.com/"
          target="_blank"
        >
          <MenuMobileItem
            className="menu-mobile-item"
            isActive={routeSplitted === 'newsletter'}
          >
            Newsletter
          </MenuMobileItem>
        </Link>
        <Link
          className="menu-item-link"
          to="https://umapenca.com/criativistas/"
          target="_blank"
        >
          <MenuMobileItem
            className="menu-mobile-item"
            isActive={routeSplitted === 'loja'}
          >
            Loja
          </MenuMobileItem>
        </Link>
        {isAuthenticated && (
          <Link className="menu-item-link" to="/dashboard">
            <MenuMobileItem
              className="menu-mobile-item"
              isActive={routeSplitted === 'dashboard'}
            >
              Dashboard
            </MenuMobileItem>
          </Link>
        )}
        {!isAuthenticated && (
          <Link className="menu-item-link" to="/sign-in">
            <ButtonDefault
              buttonText="Cadastro | Login"
              className="button"
              variant="contained"
              buttonSize={300}
            />
          </Link>
        )}
      </MenuMobile>
      <Link className="menu-item-link" to="/">
        <div className="logo">
          <LogoFull className="logo-full" />
          <Logo className="logo-half" />
        </div>
      </Link>
      <div className="menu">
        <MenuDropdown>
          <MenuItem
            // ref={openMenuToolsRef}
            isActive={
              routeSplitted === 'gatilhos-criativos' ||
              routeSplitted === 'termometro-de-ideias'
            }
          >
            <Link to="#" className="menu-item-link">
              Ferramentas
            </Link>
            <span className="menu-item-span">Ferramentas</span>
          </MenuItem>
          {/* {showSubMenuTools && ( */}
          <div
            // ref={refSubMenuTools}
            style={{ position: 'absolute', userSelect: 'none' }}
            className="drop"
          >
            <MenuItem isActive={false}>
              <Link className="menu-item-link" to="/gatilhos-criativos">
                Gatilhos Criativos
              </Link>
              <span className="menu-item-span">Gatilhos Criativos</span>
            </MenuItem>
            <MenuItem isActive={false}>
              <Link className="menu-item-link" to="/termometro-de-ideias">
                Termômetro de Ideias
              </Link>
              <span className="menu-item-span">Termômetro de Ideias</span>
            </MenuItem>
          </div>
          {/* )} */}
        </MenuDropdown>
        {/* <MenuItem isActive={routeSplitted === 'gatilhos-criativos'}>
          <Link className="menu-item-link" to="/gatilhos-criativos">
            Gatilhos Criativos
          </Link>
          <span className="menu-item-span">Gatilhos Criativos</span>
        </MenuItem> */}
        <MenuItem isActive={routeSplitted === 'salas-de-brainstorming'}>
          <Link className="menu-item-link" to="/salas-de-brainstorming">
            Salas de Brainstorming
          </Link>
          <span className="menu-item-span">Salas de Brainstorming</span>
        </MenuItem>
        <MenuItem isActive={routeSplitted === 'desafios-criativistas'}>
          <Link className="menu-item-link" to="/desafios-criativistas">
            Desafios Criativistas
          </Link>
          <span className="menu-item-span">Desafios Criativistas</span>
        </MenuItem>
        {/* <MenuItem isActive={routeSplitted === 'termometro-de-ideias'}>
          <Link className="menu-item-link" to="/termometro-de-ideias">
            Termômetro de Ideias
          </Link>
          <span className="menu-item-span">Termômetro de Ideias</span>
        </MenuItem> */}
        <MenuItem isActive={routeSplitted === 'newsletter'}>
          <Link
            className="menu-item-link"
            to="https://vemsercriativista.substack.com/"
            target="_blank"
          >
            Newsletter
          </Link>
          <span className="menu-item-span">Newsletter</span>
        </MenuItem>
        <MenuItem isActive={routeSplitted === 'loja'}>
          <Link
            className="menu-item-link"
            to="https://umapenca.com/criativistas/"
            target="_blank"
          >
            Loja
          </Link>
          <span className="menu-item-span">Newsletter</span>
        </MenuItem>
        {!isAuthenticated && (
          <Link to="/sign-in">
            <ButtonDefault
              variant="contained"
              buttonText="Cadastro | Login"
              className="button"
            />
          </Link>
        )}
      </div>
      {isAuthenticated && (
        <React.Fragment>
          <div className="menu-signed-icons">
            {!!user?.avatar ? (
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                ref={openMenuRef}
              >
                <Avatar
                  backgroundImageLink={`${process.env.REACT_APP_URL_IMAGE_BASE}/${user.avatar}`}
                  height="4.8rem"
                  width="4.8rem"
                  onClick={(event) => {
                    setShowSubMenu((prev) => !prev);
                  }}
                />
              </span>
            ) : (
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                ref={openMenuRef}
              >
                <span
                  className="brainstorming-nav-avatar"
                  onClick={(event) => {
                    setShowSubMenu((prev) => !prev);
                  }}
                />
              </span>
            )}
          </div>
          {showSubMenu && (
            <span
              ref={refSubMenu}
              style={{ position: 'absolute', userSelect: 'none' }}
            >
              <SubMenuUser />
            </span>
          )}
        </React.Fragment>
      )}
    </NavigationContainer>
  );
};

export default Navigation;
