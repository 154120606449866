import styled from 'styled-components';

export const SeeEditContentsContainer = styled.div`
  .see-edit-heading-2 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};

    &--margin {
      margin-top: 6rem;
    }
  }

  .creative-trigger-list-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
  }

  .confirm-delete-room-h3 {
    font-size: 2.4rem;
  }

  .confirm-delete-button-group {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    gap: 3rem;
  }
`;
