import React from 'react';
import { InstagramBannerStyled } from './styles';
import Button from '../../../components/Button';
import { Link } from 'react-router-dom';

const InstagramBanner: React.FC = () => {
  return (
    <InstagramBannerStyled>
      <div className="banner-container">
        <div className="banner-text">
          <h3 className="heading-3">Você conhece nossa página no Instagram?</h3>
          <p className="banner-paragraph">
            Siga <strong>@vemsercriativista</strong> para mais conteúdos
            criativos e dicas que irão potencializar seu processo criativo.
          </p>
          <Link
            to="https://www.instagram.com/vemsercriativista/"
            target="_blank"
          >
            <Button
              className="button"
              variant="contained"
              buttonText="Seguir @vemsercriativista"
              noShadows
            />
          </Link>
        </div>
        <div className="banner-image"></div>
      </div>
    </InstagramBannerStyled>
  );
};

export default InstagramBanner;
