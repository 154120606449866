import styled from 'styled-components';

export const CustomArrowLeftContainer = styled.button`
  position: absolute;
  border: 0;
  background: transparent;
  left: 3.2rem;
  cursor: pointer;

  svg {
    filter: drop-shadow(0px 0px 2px white);
  }
`;
