import React from 'react';
import { TermsContainer } from './styles';
import { Link } from 'react-router-dom';

const Terms: React.FC = () => {
  return (
    <TermsContainer>
      <div className="terms-background" />
      <div className="terms-content">
        <h2 className="terms-heading-2">Termos de uso</h2>
        <p className="terms-paragraph">
          Seja bem-vindo ao nosso site. Leia com atenção todos os termos abaixo.
        </p>
        <p className="terms-paragraph">
          Este documento, e todo o conteúdo do site é oferecido por&nbsp;
          <strong>CRIATIVISTAS</strong>, neste termo representado apenas por{' '}
          <strong>“EMPRESA”</strong>, que regulamenta todos os direitos e
          obrigações com todos que acessam o site, denominado neste termo como{' '}
          <strong>“VISITANTE”</strong>, reguardado todos os direitos previstos
          na legislação, trazem as cláusulas abaixo como requisito para acesso e
          visita do mesmo, situado no endereço&nbsp;
          <Link to="www.criativistas.com.br" target="_blank">
            www.criativistas.com.br
          </Link>
          .
        </p>
        <p className="terms-paragraph">
          A permanência no website implica-se automaticamente na leitura e
          aceitação tácita do presente termos de uso a seguir. Este termo foi
          atualizado pela última vez em 16 de junho de 2023.
        </p>
        <h3 className="terms-heading-3">1. DA FUNÇÃO DO SITE</h3>
        <p className="terms-paragraph">
          Este site foi criado e desenvolvido com a função de trazer ferramentas
          e conteúdo informativo de alta qualidade, a venda de produtos físicos,
          digitais e a divulgação de prestação de serviço. A{' '}
          <strong>EMPRESA</strong> busca através das ferramentas digitais e
          criação de conteúdo de alta qualidade, desenvolvido por profissionais
          da área, trazer o conhecimento ao alcance de todos, assim como a
          divulgação dos próprios serviços.
        </p>
        <p className="terms-paragraph">
          Nesta plataforma, poderá ser realizado tanto a divulgação de material
          original de alta qualidade, assim como a divulgação de produtos de
          e-commerce.
        </p>
        <p className="terms-paragraph">
          Todo o conteúdo presente neste site foi desenvolvido buscando fontes e
          materiais de confiabilidade, assim como são baseados em estudos sérios
          e respeitados, através de pesquisa de alta nível.
        </p>
        <p className="terms-paragraph">
          Todo o conteúdo é atualizado periodicamente, porém, pode conter em
          algum artigo, vídeo ou imagem, alguma informação que não reflita a
          verdade atual, não podendo a <strong>EMPRESA</strong> ser
          responsabilizada de nenhuma forma ou meio por qualquer conteúdo que
          não esteja devidamente atualizado.
        </p>
        <p className="terms-paragraph">
          É de responsabilidade do usuário de usar todas as informações
          presentes no site com senso crítico, utilizando apenas como fonte de
          informação, e sempre buscando especialistas da área para a solução
          concreta do seu conflito.
        </p>
        <h3 className="terms-heading-3">2. DO ACEITE DOS TERMOS</h3>
        <p className="terms-paragraph">
          Este documento, chamado <strong>“Termos de Uso”</strong>, aplicáveis a
          todos os visitantes do site, foi desenvolvido por{' '}
          <strong>Diego Castro Advogado – OAB/PI 15.613</strong>, modificado com
          permissão para este site.
        </p>
        <p className="terms-paragraph">
          Este termo especifica e exige que todo usuário ao acessar o site da
          EMPRESA, leia e compreenda todas as cláusulas do mesmo, visto que ele
          estabelece entre a <strong>EMPRESA</strong> e o{' '}
          <strong>VISITANTE</strong> direitos e obrigações entre ambas as
          partes, aceitos expressamente pelo <strong>VISITANTE</strong> a
          permanecer navegando no site da <strong>EMPRESA</strong>.
        </p>
        <p className="terms-paragraph">
          Ao continuar acessando o site, o <strong>VISITANTE</strong> expressa
          que aceita e entende todas as cláusulas, assim como concorda
          integralmente com cada uma delas, sendo este aceite imprescindível
          para a permanência na mesma. Caso o <strong>VISITANTE</strong>{' '}
          discorde de alguma cláusula ou termo deste contrato, o mesmo deve
          imediatamente interromper sua navegação de todas as formas e meios.
        </p>
        <p className="terms-paragraph">
          Este termo pode e irá ser atualizado periodicamente pela{' '}
          <strong>EMPRESA</strong>, que se resguarda no direito de alteração,
          sem qualquer tipo de aviso prévio e comunicação. É importante que o{' '}
          <strong>VISITANTE</strong> confira sempre se houve movimentação e qual
          foi a última atualização do mesmo no começo da página.
        </p>
        <h3 className="terms-heading-3">3. DO GLOSSÁRIO</h3>
        <p className="terms-paragraph">
          Este termo pode conter algumas palavras específicas que podem não se
          de conhecimento geral. Entre elas:
        </p>
        <p className="terms-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>VISITANTE:</strong> Todo e qualquer usuário
          do site, de qualquer forma e meio, que acesse através de computador,
          notebook, tablet, celular ou quaisquer outros meios, o website ou
          plataforma da empresa.
        </p>
        <p className="terms-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>NAVEGAÇÃO:</strong> O ato de visitar
          páginas e conteúdo do website ou plataforma da empresa.
        </p>
        <p className="terms-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>COOKIES:</strong> Pequenos arquivos de
          textos gerados automaticamente pelo site e transmitido para o
          navegador do visitante, que servem para melhorar a usabilidade do
          visitante.
        </p>
        <p className="terms-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>LOGIN:</strong> Dados de acesso do
          visitante ao realizar o cadastro junto a <strong>EMPRESA</strong>,
          dividido entre usuário e senha, que dá acesso a funções restritas do
          site.
        </p>
        <p className="terms-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>HIPERLINKS:</strong> São links clicáveis
          que podem aparecer pelo site ou no conteúdo, que levam para outra
          página da <strong>EMPRESA</strong> ou site externo.
        </p>
        <p className="terms-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>OFFLINE:</strong> Quando o site ou
          plataforma se encontra indisponível, não podendo ser acessado
          externamente por nenhum usuário.
        </p>
        <p className="terms-paragraph">
          Em caso de dúvidas sobre qualquer palavra utilizada neste termo, o
          VISITANTE deverá entrar em contato com a <strong>EMPRESA</strong>{' '}
          através dos canais de comunicação encontradas no site.
        </p>
        <h3 className="terms-heading-3">4. DO ACESSO AO SITE</h3>
        <p className="terms-paragraph">
          O Site e plataforma funcionam normalmente 24 (vinte e quatro) horas
          por dia, porém podem ocorrer pequenas interrupções de forma temporária
          para ajustes, manutenção, mudança de servidores, falhas técnicas ou
          por ordem de força maior, que podem deixar o site indisponível por
          tempo limitado.
        </p>
        <p className="terms-paragraph">
          A <strong>EMPRESA</strong> não se responsabiliza por nenhuma perda de
          oportunidade ou prejuízos que esta indisponibilidade temporária possa
          gerar aos usuários.
        </p>
        <p className="terms-paragraph">
          Em caso de manutenção que exigirem um tempo maior, a{' '}
          <strong>EMPRESA</strong> irá informar previamente aos clientes da
          necessidade e do tempo previsto em que o site ou plataforma ficará
          offline.
        </p>
        <p className="terms-paragraph">
          O acesso ao site só é permitido a maiores de 18 anos de idade ou que
          possuírem capacidade civil plena. Para acesso de menores de idade, é
          necessária a expressa autorização dos pais ou tutores, ficando o mesmo
          responsáveis sobre qualquer compra ou acesso efetuados pelo mesmo.
        </p>
        <p className="terms-paragraph">
          Caso seja necessário realizar um cadastro junto a plataforma, onde
          o&nbsp;
          <strong>VISITANTE</strong> deverá preencher um formulário com seus
          dados e informações, para ter acesso a alguma parte restrita, ou
          realizar alguma compra.
        </p>
        <p className="terms-paragraph">
          Todos os dados estão protegidos conforme a Lei Geral de Proteção de
          Dados, e ao realizar o cadastro junto ao site, o{' '}
          <strong>VISITANTE</strong> concorda integralmente com a coleta de
          dados conforme a Lei e com a{' '}
          <Link to="/politica-de-privacidade" target="_blank">
            Política de Privacidade
          </Link>{' '}
          da <strong>EMPRESA</strong>.
        </p>
        <h3 className="terms-heading-3">5. DA LICENÇA DE USO E CÓPIA</h3>
        <p className="terms-paragraph">
          O visitante poderá acessar todo o conteúdo do website, como
          ferramentas, vídeos, imagens, produtos e serviços, não significando
          nenhum tipo de cessão de direito ou permissão de uso, ou de cópia dos
          mesmo.
        </p>
        <p className="terms-paragraph">
          Todos os direitos são preservados, conforme a legislação brasileira,
          principalmente na Lei de Direitos Autorais (regulamentada na{' '}
          <strong>Lei nº 9.610/18</strong>), assim como no Código Civil
          brasileiro (regulamentada na <strong>Lei nº 10.406/02</strong>), ou
          quaisquer outras legislações aplicáveis.
        </p>
        <p className="terms-paragraph">
          Todo o conteúdo do site é protegido por direitos autorais, e seu uso,
          cópia, transmissão, venda, cessão ou revenda, deve seguir a lei
          brasileira, tendo a <strong>EMPRESA</strong> todos os seus direitos
          reservados, e não permitindo a cópia ou utilização de nenhuma forma e
          meio, sem autorização expressa e por escrita da mesma.
        </p>
        <p className="terms-paragraph">
          A <strong>EMPRESA</strong> poderá em casos concretos permitir
          pontualmente exceções a este direito, que serão claramente destacados
          no mesmo, com a forma e permissão de uso do conteúdo protegido. Este
          direito é revogável e limitado as especificações de cada caso
        </p>
        <h3 className="terms-heading-3">6. DAS OBRIGAÇÕES</h3>
        <p className="terms-paragraph">
          O <strong>VISITANTE</strong> ao utilizar o website da EMPRESA,
          concorda integralmente em:
        </p>
        <p className="terms-paragraph-item">
          &#8226;&nbsp;&nbsp;De nenhuma forma ou meio realizar qualquer tipo de
          ação que tente invadir, hacker, destruir ou prejudicar a estrutura do
          site, plataforma da <strong>EMPRESA</strong> ou de seus parceiros
          comerciais. Incluindo-se, mas não se limitando, ao envio de vírus de
          computador, de ataques de <strong>DDOS</strong>, de acesso indevido
          por falhas da mesma ou quaisquer outras forma e meio.
        </p>
        <p className="terms-paragraph-item">
          &#8226;&nbsp;&nbsp;De não realizar divulgação indevida nos comentários
          do site de conteúdo de <strong>SPAM</strong>, empresas concorrentes,
          vírus, conteúdo que não possua direitos autorais ou quaisquer outros
          que não seja pertinente a discussão daquele texto, vídeo ou imagem.
        </p>
        <p className="terms-paragraph-item">
          &#8226;&nbsp;&nbsp;Da proibição em reproduzir qualquer conteúdo do
          site ou plataforma sem autorização expressa, podendo responder civil e
          criminalmente pelo mesmo.
        </p>
        <p className="terms-paragraph-item">
          &#8226;&nbsp;&nbsp;Com a Política de Privacidade do site, assim como
          tratamos os dados referentes ao cadastro e visita no site, podendo a
          qualquer momento e forma, requerer a exclusão dos mesmos, através do
          formulário de contato.
        </p>
        <h3 className="terms-heading-3">7. DA MONETIZAÇÃO E PUBLICIDADE</h3>
        <p className="terms-paragraph">
          A EMPRESA pode alugar ou vender espaços publicitários na plataforma,
          ou no site, diretamente aos anunciantes, ou através de empresas
          especializadas com o Adsense (<strong>Google</strong>), Taboola ou
          outras plataformas.
        </p>
        <p className="terms-paragraph">
          Essas publicidades não significam nenhuma forma de endosso ou
          responsabilidade pelos mesmos, ficando o <strong>VISITANTE</strong>{' '}
          responsável pelas compras, visitas, acessos ou quaisquer ações
          referentes as estas empresas
        </p>
        <p className="terms-paragraph">
          Todas as propagandas no site ou plataforma serão claramente destacadas
          como publicidade, como forma de disclaimer da EMPRESA e de
          conhecimento do <strong>VISITANTE</strong>.
        </p>
        <p className="terms-paragraph">
          Estes anúncios podem ser selecionados pela empresa de publicidade
          automaticamente, conforme as visitas recentes do{' '}
          <strong>VISITANTE</strong>, assim como baseado no seu histórico de
          busca, conforme as políticas de acesso da plataforma
        </p>
        <h3 className="terms-heading-3">8. DOS TERMOS GERAIS</h3>
        <p className="terms-paragraph">
          O Site irá apresentar hiperlinks durante toda a sua navegação, que
          podem levar diretamente para outra página da <strong>EMPRESA</strong>{' '}
          ou para sites externos.
        </p>
        <p className="terms-paragraph">
          Apesar da <strong>EMPRESA</strong> apenas criar links para sites
          externos de extrema confiança, caso o usuário acesse um site externo,
          a <strong>EMPRESA</strong> não tem nenhuma responsabilidade pelo meio,
          sendo uma mera indicação de complementação de conteúdo, ficando o
          mesmo responsável pelo acesso, assim como sobre quaisquer ações que
          venham a realizar neste site
        </p>
        <p className="terms-paragraph">
          Em caso que ocorra eventuais conflitos judiciais entre o{' '}
          <strong>VISITANTE</strong> e a&nbsp;<strong>EMPRESA</strong>, o foro
          elegido para a devida ação será o da comarca da Empresa, mesmo que
          haja outro mais privilegiado.
        </p>
        <p className="terms-paragraph">
          Este Termo de uso é valido a partir de 16 de junho de 2023.
        </p>
      </div>
    </TermsContainer>
  );
};

export default Terms;
