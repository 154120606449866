import styled from 'styled-components';

export const ProfileContainer = styled.div`
  ${(props) => props.theme.mixins.containerGridDefault};

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 11rem;
  }

  .loading-form-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }

  .payment-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-button-submit-plan {
      margin-top: 4rem;
    }
  }

  .form-choose-plan {
    .input-group .inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    }

    .input-group {
      margin-top: 2rem;
    }
  }

  .account-content {
    margin-top: 5.6rem;
  }

  .account-content-heading-3 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
  }

  .account-content-heading-3,
  .account-content-subtitle {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
  }

  .account-form {
    margin-top: 5.6rem;
    display: grid;
    grid-template-columns: repeat(
      12,
      [col-start] minmax(min-content, 1fr) [col-end]
    );
    gap: 3rem;
  }

  .form-group {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    align-items: center;
  }

  .form-span {
    grid-column: col-start 1 / col-end 3;
    white-space: nowrap;
    align-self: center;

    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};

    @media ${(props) => props.theme.medias.mobile} {
      grid-column: col-start 1 / col-end 12;
    }
  }

  .form-span-title {
    grid-column: col-start 1 / col-end 3;
    white-space: nowrap;
    align-self: center;

    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};

    @media ${(props) => props.theme.medias.mobile} {
      grid-column: col-start 1 / col-end 12;
    }
  }

  .form-group-plan-box {
    grid-column: col-start 4 / col-end 9;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    justify-content: space-between;

    @media ${(props) => props.theme.medias.laptopSmall} {
      grid-column: col-start 4 / col-end 12;
    }

    @media ${(props) => props.theme.medias.mobile} {
      grid-column: col-start 1 / col-end 12;
    }
  }

  .button-plan-group {
    grid-column: col-start 4 / col-end 9;
    display: flex;
    justify-content: space-between;
  }

  .form-group-plan-span {
    width: 200px;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-size: 1.6rem;
  }

  .form-input {
    grid-column: col-start 4 / col-end 9;
    width: 100%;

    @media ${(props) => props.theme.medias.laptopSmall} {
      grid-column: col-start 4 / col-end 12;
    }

    @media ${(props) => props.theme.medias.mobile} {
      grid-column: col-start 1 / col-end 12;
    }

    .form-input-avatar {
      border-radius: 100px;
      width: 9rem;
      height: 9rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .form-input-avatar-without-image {
      background-color: ${(props) =>
        props.theme.pallete.colors.brandPrimary.yellow[3]};
      border-radius: 100px;
      width: 9rem;
      height: 9rem;
      display: flex;
      align-items: center;
      justify-content: center;

      flex: 0 0 auto;
    }

    &--input-send-box {
      display: flex;
      align-items: flex-start;
      gap: 1.6rem;

      @media ${(props) => props.theme.medias.tablet} {
        flex-direction: column;
        align-items: center;
      }

      .form-input-send-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.8rem;
      }

      .form-input-send {
        border: 1px solid
          ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
        color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
        border-radius: 100px;
        height: 9rem;
        width: 100%;
        text-align: center;
        padding: 0 3rem;

        display: flex;
        gap: 0.8rem;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
      }

      .form-input-send-text {
        color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
      }

      .textfield-error {
        color: ${(props) => props.theme.pallete.colors.error.main};
        padding: 0.4rem 2rem 0;
      }

      svg {
        margin: 0 auto;
      }
    }
  }

  .form-button-submit {
    grid-column: col-start 1 / col-end 12;
    margin: 4rem 0;
    justify-self: center;
  }

  .form-button-delete {
    font-size: 2rem;
    grid-column: col-start 1 / col-end 12;
    justify-self: center;
  }

  .plans-box-heading {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5.8rem;
    text-align: center;
    margin-bottom: 3rem;
  }

  .plans-box {
    display: grid;
    gap: 3rem;
    width: 100%;
  }

  .grid-plans {
    display: grid;
    grid-template-columns: repeat(auto-fit, 42rem);
    justify-content: center;
    gap: 3.2rem;
    margin-bottom: 6rem;
  }

  .button-confirm-account {
    width: 50%;
    margin: 0 auto;
  }

  .plans-box-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
  }

  .payment-modal-subtitle {
    width: 80%;
    margin: 0 auto;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-size: 1.6rem;
    line-height: 3rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    text-align: center;

    a {
      &,
      &:active,
      &:visited {
        text-decoration: none;
        color: ${(props) => props.theme.pallete.colors.common.black};
        position: relative;
        font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      }

      ${(props) => props.theme.mixins.linkUnderlineDefaultEffect}
    }
  }

  .payment-modal-icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.6rem;
  }

  .payment-modal-link {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    position: relative;

    ${(props) => props.theme.mixins.linkUnderlineEffect};
  }

  .payment-modal-inputs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0.8rem;
    margin-top: 3rem;
    padding: 0 1.6rem;
    width: 100%;
  }

  .texts-group {
    display: flex;
    align-items: baseline;
    flex-direction: column;
  }

  .texts-label {
    font-size: 2rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[15]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    white-space: nowrap;
  }

  .texts-value {
    font-size: 1.8rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
  }

  .input-group {
    display: flex;
    flex-direction: column;
  }

  .input-label {
    font-size: 2.2rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    line-height: 5rem;
  }

  .input-value {
    font-size: 2rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    margin-right: 1.6rem;
    margin-left: 0.8rem;
  }

  .form-choose-plan {
    margin-top: 3rem;
    width: 100%;

    .footer-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 3rem;

      img {
        /* width: 150px; */
      }
    }

    .input-group .inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    }

    .input-group {
      margin-top: 2rem;
    }
  }

  .payment-error-container {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }

  .button-payment-error {
    margin: 3rem auto 0;
  }

  .payment-modal-heading-2 {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5.8rem;
    margin: 0;
    text-align: center;
  }

  .payment-error-container {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }

  .button-payment-error {
    margin: 3rem auto 0;
  }
`;
