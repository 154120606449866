import styled from 'styled-components';

interface IStatusCreativeChallengeProps {
  backgroundColor: string;
}

export const CreativeChallengeListContainer = styled.div`
  .heading-2 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};

    &--margin {
      margin-top: 2rem;
    }
  }

  .creative-challenge-list-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }
`;

export const StatusCreativeChallenge = styled.span<IStatusCreativeChallengeProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.backgroundColor};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.pallete.colors.common.black};
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
`;
