import axios, { AxiosError, AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import { checkLoginService } from './user.service';

const timeout = 300000; // Equal 5 minutes, 1000 === 1 second

export const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API_BASE,
});

const nameToken = '@Creative:token';

const isValidToken = () => {
  const storagedToken = localStorage.getItem(nameToken);

  if (!storagedToken) {
    return false;
  }

  const decodedToken: { exp: number; iat: number; uid: string } = jwt_decode(
    storagedToken || ''
  );
  const currentDate = Math.floor(Date.now() / 1000);

  return decodedToken.exp > currentDate;
};

const isValidUser = async () => {
  const storagedToken = localStorage.getItem(nameToken);

  const user = await checkLoginService(storagedToken || '');

  if (!!user.user) {
    return true;
  } else {
    return false;
  }
};

api.interceptors.request.use(
  async (config) => {
    if (!isValidToken() || !(await isValidUser())) {
      localStorage.removeItem(nameToken);
      delete api.defaults.headers.common.Authorization;
      return config;
    }

    config.timeout = timeout;

    return config;
  },
  (error) => {
    console.error('request', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      const { data, status, headers } = error.response;
      console.error('Response Error:', data);
      console.error('Status:', status);
      console.error('Headers:', headers);

      return Promise.reject(data);
    } else if (error.request) {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        error.message = `Timeout excedeu ${timeout}ms`;
        return Promise.reject(error);
      }

      return Promise.reject(error.request);
    } else {
      console.error('Error:', error.message);

      return Promise.reject(error.message);
    }
  }
);
