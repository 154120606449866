import React, {
  InputHTMLAttributes,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import { v4 as uuidV4 } from 'uuid';
import { TextAreaContainer } from './styles';

interface IProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  labelColor?: string;
  defaultValue?: string | number;
  disabled?: boolean;
  value?: string | number;
  error?: string;
  maxCharacter: number;
  customCountCharacters?: number;
}

const TextArea: React.ForwardRefRenderFunction<HTMLTextAreaElement, IProps> = (
  {
    label,
    defaultValue,
    disabled,
    value,
    error,
    labelColor,
    maxCharacter,
    customCountCharacters,
    ...props
  },
  ref
) => {
  const labelId = uuidV4();
  const [countCharacters, setCountCharacters] = useState<number>(0);

  const handleCharacterChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const count = event.target.value.length;
    setCountCharacters(count);
  };

  useEffect(() => {
    setCountCharacters(customCountCharacters || 0);
  }, [customCountCharacters]);

  return (
    <TextAreaContainer labelColor={labelColor}>
      <div className="text-area-content">
        <label className="label" htmlFor={labelId}>
          {label}
        </label>
        <textarea
          className="text-area"
          id={labelId}
          ref={ref}
          onChange={handleCharacterChange}
          maxLength={maxCharacter}
          {...props}
        />
        <div className="character-box">
          <span className="current-character">{countCharacters}</span>
          <span className="maximum-character">/ {maxCharacter}</span>
        </div>
      </div>
      {!!error && <span className="textfield-error">{error}</span>}
    </TextAreaContainer>
  );
};

export default forwardRef(TextArea);
