import styled from 'styled-components';

export const CommonQuestionsStyled = styled.section`
  ${(props) => props.theme.mixins.containerGridDefault};
  padding: 8rem 0;

  @media ${(props) => props.theme.medias.laptopSmall} {
    padding: 4rem 0;
  }

  .content {
    grid-column: center-start / center-end;
  }

  .heading-2 {
    text-align: center;
    margin-bottom: 6rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
  }

  .grid-cards {
    display: flex;
    flex-direction: column;
    gap: 3.4rem;
  }

  .label-icon {
    user-select: none;
  }

  .card-question {
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    padding: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    z-index: 5;
    position: relative;

    svg {
      cursor: pointer;
    }
  }

  .question {
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .card-answer {
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    padding: 0 3rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
  }

  .checkbox {
    display: none;

    &--question-1:checked ~ .card-answer--question-1 {
    }
  }

  .link-email {
    text-decoration: none;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    position: relative;
    transition: all 0.3rem;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.pallete.colors.common.black};
    }
  }
`;
