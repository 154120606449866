import { api } from './api.axios';
import {
  IGenerateFeedbackIARequest,
  IGenerateFeedbackIAResponse,
} from './thermometerIdeas.types';

export const generateFeedbackIAService = async ({
  channel,
  currentState,
  description,
  goals,
  specificAnswer,
  targetAudience,
  title,
}: IGenerateFeedbackIARequest): Promise<IGenerateFeedbackIAResponse> => {
  const response = await api.get<IGenerateFeedbackIAResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/thermometer-ideas/ia?title=${title}
    &channel=${channel}
    &targetAudience=A${targetAudience}
    &currentState=${currentState}
    description=${description}
    &goals=${goals}
    &specificAnswer=${specificAnswer}
   `
  );

  return response.data;
};
