import styled from 'styled-components';

export const Content = styled.div`
  min-height: 75vh;
  margin-top: 16rem;

  .background {
    background-image: linear-gradient(#fff 50%, transparent 0);
    height: 100vh;
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;
