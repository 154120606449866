import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Spinner from '../../../../components/loadings/Spinner';
import AuthContext from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
import { Link } from 'react-router-dom';
import { showCreativeChallengeService } from '../../../../services/creativeChallenge.service';
import { IListCreativeChallengeData } from '../../../../services/creativeChallenge.types';
import { ReadCreativeChallengeContainer } from './styles';

const ReadCreativeChallenge: React.FC = () => {
  const [creativeChallenge, setCreativeChallenge] =
    useState<IListCreativeChallengeData>();

  const { signOut } = useContext(AuthContext);
  const { handleToastfy } = useContext(ToastfyContext);
  const [isloadingCreativeChallenge, setIsLoadingCreativeChallenge] =
    useState<boolean>(true);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const getCreativeChallenge = useCallback(
    async (id: string) => {
      await showCreativeChallengeService(id)
        .then((response) => {
          setCreativeChallenge(response.data);
          setIsLoadingCreativeChallenge(false);
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
            setIsLoadingCreativeChallenge(false);
          }
        });
    },
    [handleToastfy, signOut, navigate]
  );

  useEffect(() => {
    const id = searchParams.get('id');

    if (!!id) {
      getCreativeChallenge(id);
    }
  }, [searchParams, getCreativeChallenge]);

  return (
    <ReadCreativeChallengeContainer>
      {isloadingCreativeChallenge ? (
        <div className="read-creative-challenge-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <React.Fragment>
          <div className="read-creative-challenge-heading-box">
            <h3 className="read-creative-challenge-heading-3">
              {creativeChallenge?.label}
            </h3>
            <div
              className="read-creative-challenge-edit"
              onClick={() =>
                navigate(
                  `/dashboard/editar-desafio-criativista?id=${creativeChallenge?._id}`
                )
              }
            >
              <FiEdit3 size={20} />
              <span>Editar</span>
            </div>
          </div>
          <div className="read-creative-challenge-grid-content-1">
            <div className="read-creative-challenge-label-group">
              <span className="read-creative-challenge-label">Link:</span>
              <Link to={creativeChallenge?.link ? creativeChallenge.link : '#'}>
                <span className="read-creative-challenge-answer">
                  {creativeChallenge?.link}
                </span>
              </Link>
            </div>
            <div className="read-creative-challenge-label-group">
              <span className="read-creative-challenge-label">Tags</span>
              <span className="read-creative-challenge-answer">
                {creativeChallenge?.tag}
              </span>
            </div>
            <div className="read-creative-challenge-label-group">
              <span className="read-creative-challenge-label">Tags</span>
              <span className="read-creative-challenge-answer">
                {creativeChallenge?.tag}
              </span>
            </div>

            <div className="read-creative-challenge-label-group">
              <span className="read-creative-challenge-label">
                Data da Criação:
              </span>
              <span className="read-creative-challenge-answer">
                {moment(creativeChallenge?.createdAt)
                  .locale('pt-br')
                  .format('DD/MM/YYYY')}
              </span>
            </div>
          </div>
          <div className="read-creative-challenge-grid-content-1">
            <div className="read-creative-challenge-label-group">
              <span className="read-creative-challenge-label">Prêmios</span>
              <span className="read-creative-challenge-answer">
                {creativeChallenge?.award}
              </span>
            </div>
          </div>
        </React.Fragment>
      )}
    </ReadCreativeChallengeContainer>
  );
};

export default ReadCreativeChallenge;
