import React from 'react';
import { Container } from './styles';
//Components
import BannerHome from './BannerHome';
import SectionTools from './SectionTools';
// import HowItWorks from './HowItWorks';
import VideoSection from './VideoSection';
// import CollectPoints from './CollectPoints';
import Plans from './Plans';
import InstagramBanner from './InstagramBanner';
import CommonQuestions from './CommonQuestions';

const Home: React.FC = () => {
  return (
    <Container>
      <BannerHome />
      <SectionTools />
      {/* <HowItWorks /> */}
      <VideoSection />
      {/* <CollectPoints /> */}
      <Plans />
      <InstagramBanner />
      <CommonQuestions />
    </Container>
  );
};

export default Home;
