import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AuthContext from '../../../contexts/AuthContext';
import ToastfyContext from '../../../contexts/ToastfyContext';
import Button from '../../Button';
import SeparateLine from '../../SeparateLine';
import Textfield from '../../inputs/Textfield';
import LightBox from '../LightBox';
import { LightboxInternParamContainer } from './styles';
import {
  createInternParamService,
  listInternParamService,
  updateInternParamService,
} from '../../../services/internParams.service';

interface IInputFields {
  textIa: string;
}

interface IProps {
  handleOpenModal: () => void;
  setIsInternParamOpen: Dispatch<SetStateAction<boolean>>;
  name: string;
  title: string;
}

const LightboxInternParam: React.FC<IProps> = ({
  handleOpenModal,
  setIsInternParamOpen,
  name,
  title,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IInputFields>();
  const [isRegisterInternParam, setIsRegisterInternParam] =
    useState<boolean>(false);
  const [isEditParam, setIsEditParam] = useState<boolean>(false);
  const [idInternParam, setIdInternParam] = useState<string>('');
  const theme = useTheme();
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await listInternParamService({
      order: 'DESC',
      orderBy: 'createdAt',
      perPage: 200,
      isActive: true,
      name,
    })
      .then((response) => {
        const createOptions = response.data.map((internParam) => {
          return {
            optionName: internParam.textIa,
            optionValue: internParam._id,
          };
        });

        if (createOptions && createOptions.length > 0) {
          setIsEditParam(true);
          setIdInternParam(createOptions[0].optionValue);
          setValue('textIa', createOptions[0].optionName);
        }
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        // setIsRegisterInternParam((prev) => !prev);
        console.log(error.response.data);
      });
  };

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsRegisterInternParam((prev) => !prev);

    if (isEditParam) {
      await updateInternParamService({
        id: idInternParam,
        isActive: true,
        name,
        textIa: data.textIa,
      })
        .then((response) => {
          handleToastfy({
            message: 'Parâmetro interno editado com sucesso!',
            type: 'success',
          });
          setIsRegisterInternParam((prev) => !prev);
          setIsInternParamOpen(false);
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          setIsRegisterInternParam((prev) => !prev);
          console.log(error.response.data);
        });
    } else {
      await createInternParamService({
        name,
        textIa: data.textIa,
      })
        .then((response) => {
          handleToastfy({
            message: 'Parâmetro interno cadastrado com sucesso!',
            type: 'success',
          });
          setIsRegisterInternParam((prev) => !prev);
          setIsInternParamOpen(false);
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          setIsRegisterInternParam((prev) => !prev);
          console.log(error.response.data);
        });
    }
  };

  return (
    <LightBox handleOpenModal={handleOpenModal}>
      <LightboxInternParamContainer>
        <h3>
          {title} - {isEditParam ? 'Alterar' : 'Adicionar'} texto para a IA
        </h3>
        <SeparateLine
          margin="2.4rem 0"
          color={theme.pallete.colors.brandPrimary.gray[2]}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Textfield
            label="Texto para a IA"
            type="text"
            placeholder=""
            error={errors.textIa?.message}
            {...register('textIa', {
              required: 'O campo é requerido.',
            })}
          />
          <Button
            buttonText="Salvar"
            variant="contained"
            buttonSize={160}
            className="lightbox-intern-param-button"
            isLoading={isRegisterInternParam}
          />
        </form>
      </LightboxInternParamContainer>
    </LightBox>
  );
};

export default LightboxInternParam;
