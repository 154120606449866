import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import Button from '../../../components/Button';
import CheckboxSelect from '../../../components/checkboxes/CheckboxSelect';
import TextArea from '../../../components/inputs/TextArea';
import Textfield from '../../../components/inputs/Textfield';
import { QuizStyled } from './styles';
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

interface IProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  step: number;
  setTypeResult: React.Dispatch<React.SetStateAction<number>>;
  setStep: React.Dispatch<React.SetStateAction<string>>;
  register: UseFormRegister<IInputFields>;
  errors: FieldErrors<IInputFields>;
  setValue: UseFormSetValue<IInputFields>;
  handleSubmit: UseFormHandleSubmit<IInputFields, any>;
  watch: UseFormWatch<IInputFields>;
}

interface IInputFields {
  name: string;
  description: string;

  question: [
    question1: string,
    question2: string,
    question3: string,
    question4: string,
    question5: string,
    question6: string,
    question7: string,
    question8: string
  ];

  qtdAnswerYes: number;
}

const Quiz: React.FC<IProps> = ({
  onClick,
  step,
  setTypeResult,
  setStep,
  register,
  errors,
  handleSubmit,
  setValue,
  watch,
}) => {
  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    const answerYes = data.question.filter(
      (question: string) => question === 'sim'
    );

    setValue('qtdAnswerYes', answerYes.length);

    if (answerYes.length >= 0 && answerYes.length < 2) {
      setTypeResult(1);
    } else if (answerYes.length >= 2 && answerYes.length < 4) {
      setTypeResult(2);
    } else if (answerYes.length >= 4 && answerYes.length < 6) {
      setTypeResult(3);
    } else {
      setTypeResult(4);
    }

    setStep('result');
  };

  return (
    <QuizStyled>
      <div className="content">
        <div className="box-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            {step === 1 ? (
              <>
                <Textfield
                  label="Dê um nome para a sua ideia:"
                  type="text"
                  placeholder="Escreva aqui"
                  error={errors.description?.message}
                  {...register(`name`, {
                    required: 'O campo é requerido.',
                  })}
                />
                <TextArea
                  label="Descreva sua ideia de forma resumida:"
                  maxCharacter={600}
                  placeholder="Escreva aqui"
                  error={errors.description?.message}
                  {...register('description', {
                    required: 'O campo é requerido.',
                  })}
                  customCountCharacters={watch(`description`)?.length}
                />
              </>
            ) : step === 2 ? (
              <>
                <h1 className="heading-3">
                  Marque as opções que atendem aos requisitos:
                </h1>
                <div className="question">
                  <span className="grid-texts-title">
                    1. Sua ideia atende bem ao objetivo proposto no briefing ou
                    desafio?
                  </span>
                  <div className="grid-checkbox-box">
                    <div className="grid-option">
                      <CheckboxSelect
                        value="sim"
                        {...register('question.0', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Sim</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="um pouco"
                        {...register('question.0', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Um pouco</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="não"
                        {...register('question.0', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Não</span>
                    </div>
                  </div>
                </div>
                <div className="question">
                  <span className="grid-texts-title">
                    2. Sua ideia se diferencia bem do que já existe? Ela não
                    causa déjà-vu?
                  </span>
                  <div className="grid-checkbox-box">
                    <div className="grid-option">
                      <CheckboxSelect
                        value="sim"
                        {...register('question.1', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Sim</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="um pouco"
                        {...register('question.1', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Um pouco</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="não"
                        {...register('question.1', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Não</span>
                    </div>
                  </div>
                </div>
              </>
            ) : step === 3 ? (
              <>
                <h1 className="heading-3">
                  Marque as opções que atendem aos requisitos:
                </h1>
                <div className="question">
                  <span className="grid-texts-title">
                    3. Sua ideia gera identificação com o público-alvo?
                  </span>
                  <div className="grid-checkbox-box">
                    <div className="grid-option">
                      <CheckboxSelect
                        value="sim"
                        {...register('question.2', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Sim</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="um pouco"
                        {...register('question.2', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Um pouco</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="não"
                        {...register('question.2', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Não</span>
                    </div>
                  </div>
                </div>
                <div className="question">
                  <span className="grid-texts-title">
                    4. Sua ideia gera alguma grande emoção no público-alvo?
                  </span>
                  <span className="grid-texts-observation">
                    (Pode ser alegria, risos, choro, surpresa, curiosidade etc.)
                  </span>
                  <div className="grid-checkbox-box">
                    <div className="grid-option">
                      <CheckboxSelect
                        value="sim"
                        {...register('question.3', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Sim</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="um pouco"
                        {...register('question.3', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Um pouco</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="não"
                        {...register('question.3', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Não</span>
                    </div>
                  </div>
                </div>
              </>
            ) : step === 4 ? (
              <>
                <h1 className="heading-3">
                  Marque as opções que atendem aos requisitos:
                </h1>
                <div className="question">
                  <span className="grid-texts-title">
                    5. É possível executar sua ideia dentro do prazo
                    estabelecido?
                  </span>
                  <div className="grid-checkbox-box">
                    <div className="grid-option">
                      <CheckboxSelect
                        value="sim"
                        {...register('question.4', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Sim</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="um pouco"
                        {...register('question.4', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Um pouco</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="não"
                        {...register('question.4', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Não</span>
                    </div>
                  </div>
                </div>
                <div className="question">
                  <span className="grid-texts-title">
                    6. Se for necessário orçamento, você tem o suficiente para
                    implementá-la, mesmo que parcialmente?
                  </span>

                  <div className="grid-checkbox-box">
                    <div className="grid-option">
                      <CheckboxSelect
                        value="sim"
                        {...register('question.5', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Sim</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="um pouco"
                        {...register('question.5', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Um pouco</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="não"
                        {...register('question.5', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Não</span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h1 className="heading-3">
                  Marque as opções que atendem aos requisitos:
                </h1>
                <div className="question">
                  <span className="grid-texts-title">
                    7. Sua ideia tem potencial para mudar comportamentos ou
                    causar impacto?
                  </span>
                  <div className="grid-checkbox-box">
                    <div className="grid-option">
                      <CheckboxSelect
                        value="sim"
                        {...register('question.6', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Sim</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="um pouco"
                        {...register('question.6', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Um pouco</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="não"
                        {...register('question.6', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Não</span>
                    </div>
                  </div>
                </div>
                <div className="question">
                  <span className="grid-texts-title">
                    8. Sua ideia te empolga? Você gosta realmente dela? Ela faz
                    seus olhos brilharem?
                  </span>

                  <div className="grid-checkbox-box">
                    <div className="grid-option">
                      <CheckboxSelect
                        value="sim"
                        {...register('question.7', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Sim</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="um pouco"
                        {...register('question.7', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Um pouco</span>
                    </div>
                    <div className="grid-option">
                      <CheckboxSelect
                        value="não"
                        {...register('question.7', {
                          required: 'O campo é requerido.',
                        })}
                      />
                      <span>Não</span>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="buttons-box">
              {step === 6 ? (
                <Button
                  variant="contained"
                  buttonText="Finalizar termômetro"
                  type="submit"
                // onClick={onClick}
                />
              ) : (
                <Button
                  variant="contained"
                  buttonText={'Continuar'}
                  type="button"
                  onClick={onClick}
                />
              )}
            </div>
          </form>
        </div>
        {step === 1 ? (
          <div className="box-image box-image--1"></div>
        ) : step === 2 ? (
          <div className="box-image box-image--2"></div>
        ) : (
          <div className="box-image box-image--3"></div>
        )}
      </div>
    </QuizStyled>
  );
};

export default Quiz;
