import styled from 'styled-components';

export interface IStyledProps {
  progress: number;
}

export const ProgressBarStyled = styled.div<IStyledProps>`
  background-color: ${(props) =>
    props.theme.pallete.colors.backgrounds.neutral};
  width: 100%;
  /* min-height: 70vh;
  display: block; */
  position: relative;
  display: ${(props) => (props.progress === 0 ? 'none' : 'block')};
  padding-top: 3rem;

  @media ${(props) => props.theme.medias.laptopSmall} {
    /* height: calc(100vh - 16rem); */
    margin-top: 11rem;
  }

  .content {
    display: flex;
    width: 100%;
    /* margin: 0 auto; */
    /* height: 65vh; */
    padding: 0 12rem;
    gap: 3;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
      padding: 0 6rem;
      /* height: 75vh; */
    }

    @media ${(props) => props.theme.medias.mobile} {
      padding: 0 0;
    }
  }

  .box-content {
    /* margin: auto 0; */
    min-width: 50%;
    width: 100%;
    /* padding-bottom: 3rem; */

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 100%;
      text-align: center;
      /* height: 65vh; */
      /* margin: 6rem 0 0; */
    }
  }

  .paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    margin-bottom: 1rem;
    line-height: 2.8rem;
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
  }

  .progress {
    content: '';
    height: 12px;
    width: 50%;
    background-color: ${(props) =>
      props.theme.pallete.colors.brandPrimary.gray[17]};
    border-radius: 20px;
    position: relative;

    @media ${(props) => props.theme.medias.mobile} {
      width: 80%;
    }
  }

  .progress::after {
    content: '';
    height: 12px;
    width: ${(props) =>
      props.progress === 0
        ? '0%'
        : props.progress === 1
        ? '20%'
        : props.progress === 2
        ? '40%'
        : props.progress === 3
        ? '60%'
        : props.progress === 4
        ? '80%'
        : '100%'};
    background-color: ${(props) =>
      props.theme.pallete.colors.brandPrimary.yellow[6]};
    border-radius: 20px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    left: 0%;
  }
`;
