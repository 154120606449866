import styled from 'styled-components';

export const LightboxCloseButtonOutContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  ${(props) => props.theme.mixins.containerGridDefault};

  .light-box-content {
    grid-column: center-start / center-end;
    position: relative;
    width: 100%;
    min-height: 20rem;
    max-height: 85vh;
    background-color: ${(props) =>
      props.theme.pallete.colors.backgrounds.neutral};
    border-radius: 2rem;
    padding: 3rem;
    /* overflow-y: auto; */
    z-index: 12000;
  }

  .light-box-children {
    overflow-y: auto;
    overflow-x: hidden;
    word-break: break-all;
    min-height: 20rem;
    max-height: 75vh;
  }

  .icon-box {
    position: absolute;
    top: -5.2rem;
    right: 0.8rem;
    padding: 0rem;
    cursor: pointer;

    @media ${(props) => props.theme.medias.mobile} {
      top: -6.2rem;
    }

    svg {
      color: ${(props) => props.theme.pallete.colors.common.white};
    }
  }

  .light-box-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 11000;
  }
`;
