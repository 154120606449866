import styled from 'styled-components';

export const BreadCrumbContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1.6rem 0;

  .breadcrumb-link {
    text-decoration: none;
  }

  .breadcrumb-item {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    font-size: 1.6rem;
    text-transform: capitalize;

    &:hover {
      transform: scale(1.02);
    }
  }
`;
