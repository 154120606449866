import React, { useState, useContext, Dispatch, SetStateAction } from 'react';
import { useTheme } from 'styled-components';
import Button from '../../Button';
import Textfield from '../../inputs/Textfield';
import LightBox from '../LightBox';
import { LightboxSendCreditsContainer } from './styles';
import { IUser } from '../../../services/user.types';
import { removeUserCreditsService } from '../../../services/admin.service';
import { useNavigate } from 'react-router-dom';
import ToastfyContext from '../../../contexts/ToastfyContext';
import AuthContext from '../../../contexts/AuthContext';
import { SubmitHandler, useForm } from 'react-hook-form';

interface IProps {
  setOpenLightboxRemoveCredits: Dispatch<SetStateAction<boolean>>;
  setIsControlUser: Dispatch<SetStateAction<boolean>>;
  getListUser: () => void;
  user: IUser;
}

interface IInputFields {
  credit: number;
}

const LightboxRemoveCredits: React.FC<IProps> = ({
  setOpenLightboxRemoveCredits,
  setIsControlUser,
  getListUser,
  user,
}) => {
  const [removeCredits, setRemoveCredits] = useState<number>(0);
  const [isConfirmLightbox, setIsConfirmLightbox] = useState<boolean>(false);
  const [isLoadingRemoveCredits, setIsLoadingRemoveCredits] =
    useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IInputFields>({
    defaultValues: {
      credit: 0,
    },
  });
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleOpenConfirmModal = () => {
    setIsConfirmLightbox((prev) => !prev);
    setOpenLightboxRemoveCredits((prev) => !prev);
  };

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsConfirmLightbox((prev) => !prev);
    setRemoveCredits(data.credit);
  };

  const handleRemoveCredits = async () => {
    setIsLoadingRemoveCredits((prev) => !prev);
    await removeUserCreditsService({ userId: user._id, value: removeCredits })
      .then(() => {
        handleToastfy({
          message: 'Creditos removidos com sucesso!',
          type: 'success',
        });
        setIsLoadingRemoveCredits((prev) => !prev);
        setIsControlUser((prev) => !prev);
        handleOpenConfirmModal();
        getListUser();
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingRemoveCredits((prev) => !prev);
      });
  };

  return (
    <LightboxSendCreditsContainer>
      <LightBox
        handleOpenModal={() => setOpenLightboxRemoveCredits((prev) => !prev)}
        customLightboxWidth="80rem"
      >
        <div className="credits-content">
          <h3 className="credits-heading-3">Remover créditos</h3>
          <div className="input-group">
            <span className="credits-label">Usuário:</span>
            <span className="credits-name">{user.username}</span>
            <span className="credits-email">{user.email}</span>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group">
              <span className="credits-label">Quantidade de créditos</span>
              <Textfield
                label=""
                type="text"
                error={errors.credit?.message}
                {...register('credit', {
                  required: 'O campo é requerido.',
                })}
              />
            </div>
            <div className="credits-button">
              <Button
                buttonText="Enviar"
                variant="contained"
                buttonSize={180}
                type="submit"
              />
            </div>
          </form>
        </div>
        {isConfirmLightbox && (
          <LightBox handleOpenModal={handleOpenConfirmModal}>
            <span className="credits-confirm-title">
              Você tem certeza que irá remover{' '}
              <span className="credits-confirm-value">{removeCredits}</span>{' '}
              créditos?
            </span>
            <div className="credits-confirm-button-group">
              <Button
                buttonText="Não"
                variant="contained"
                type="button"
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                buttonSize={160}
                onClick={handleOpenConfirmModal}
              />
              <Button
                buttonText="Sim"
                variant="contained"
                type="button"
                buttonSize={160}
                onClick={() => handleRemoveCredits()}
                isLoading={isLoadingRemoveCredits}
              />
            </div>
          </LightBox>
        )}
      </LightBox>
    </LightboxSendCreditsContainer>
  );
};

export default LightboxRemoveCredits;
