import React from 'react';
import UnderDevelopmentSVG from '../../components/svgs/UnderDevelopmentSVG';
import { UnderDevelopmentStyled } from './styles';
import { Link } from 'react-router-dom';

const UnderDevelopment: React.FC = () => {
  return (
    <UnderDevelopmentStyled>
      <div className="under-development-content">
        <UnderDevelopmentSVG />
        <div className="under-development-texts">
          <h2 className="under-development-heading-2">
            Opa! Você encontrou uma área com Criativistas trabalhando.
          </h2>
          <p className="under-development-paragraph">
            Essa funcionalidade está sendo implementada em nosso laboratório
            criativo. Nossos Criativistas estão trabalhando a todo vapor para
            trazer ferramentas inovadoras que vão alavancar seu processo
            criativo.
          </p>
          <p className="under-development-paragraph">
            Mas fique tranquilo, não vamos deixar você sem notícias! Para
            acompanhar essa e outras novidades incríveis, siga nosso Instagram
            <Link
              to="https://www.instagram.com/vemsercriativista/"
              target="_blank"
            >
              <span>
                <strong>@vemsercriativista.</strong>
              </span>
            </Link>
            Lá, nós compartilhamos tudo e você vai ser o primeiro a saber quando
            essa funcionalidade estiver pronta para decolar!
          </p>
          <p className="under-development-paragraph">
            Até lá, continue explorando e criando. :)
          </p>
          <p className="under-development-paragraph">Equipe Criativistas.</p>
        </div>
      </div>
    </UnderDevelopmentStyled>
  );
};

export default UnderDevelopment;
