import React from 'react';
import { CardPlanContainer } from './styles';

interface IListItems {
  icon: React.ReactNode;
  text: React.ReactNode;
}

interface IProps {
  titleCard: string;
  subtitleCard: React.ReactNode | string;
  isActive?: boolean;
  listItems: IListItems[];
  price?: string;
  cursor: boolean;
  onClick?: () => void;
}

const CardPlan: React.FC<IProps> = ({
  listItems,
  price,
  subtitleCard,
  titleCard,
  isActive,
  cursor,
  onClick,
}) => {
  return (
    <CardPlanContainer
      isActive={isActive || false}
      cursor={cursor ? 'true' : 'false'}
      onClick={onClick}
    >
      <div className="card-text-box">
        <h2 className="card-title">{titleCard}</h2>
        <div className="card-text">{subtitleCard}</div>
      </div>
      <div className="card-list">
        {listItems.map((listItem, index) => {
          return (
            <div key={index} className="card-list-item">
              <div>{listItem.icon}</div>
              <span className="card-list-item-text">{listItem.text}</span>
            </div>
          );
        })}
      </div>
      {!!price ? (
        <span className="price-box">
          <span className="price-symbol">R$</span>
          <span className="price">{price}</span>
          <span className="month">/mês</span>
        </span>
      ) : (
        <span className="card-list-comming-soon">Em breve</span>
      )}
    </CardPlanContainer>
  );
};

export default CardPlan;
