import React, { createContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IProps {
  children: React.ReactNode;
}

interface IType {
  type: 'info' | 'success' | 'warning' | 'error';
  message: string | undefined;
}

interface IToastfyContext {
  handleToastfy: ({ message, type }: IType) => void;
}

export const ToastfyContext = createContext<IToastfyContext>(
  {} as IToastfyContext
);

export const ToastfyProvider: React.FC<IProps> = ({ children }) => {
  const handleToastfy = ({ message, type }: IType) => {
    toast[type](message, {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  return (
    <ToastfyContext.Provider value={{ handleToastfy }}>
      <ToastContainer />
      {children}
    </ToastfyContext.Provider>
  );
};

export default ToastfyContext;
