import styled from 'styled-components';

export const SignInStyled = styled.div`
  padding: 6rem 0 0;
  background-image: linear-gradient(#fff 33.3%, transparent 0);

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 11rem;
  }

  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 40rem;
    max-width: 56rem;
    margin: 0 auto 8rem;
    padding: 0 6rem;
  }

  .text-box {
    padding: 0 3rem;
    margin-bottom: 6rem;
  }

  .heading-2 {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-size: 3.6rem;
    line-height: 5.8rem;
  }

  .heading-subtitle {
    font-size: 1.6rem;
    line-height: 3rem;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    margin-bottom: 3rem;

    a {
      text-decoration: none;
    }
  }

  .forgot-password-link {
    width: max-content;
    text-decoration: none;
  }

  .forgot-password {
    position: relative;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    ${(props) => props.theme.mixins.linkUnderlineEffect};
    user-select: none;
  }

  .sign-up {
    line-height: 5rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    user-select: none;
    a {
      text-decoration: none;
      user-select: none;
    }
  }

  .link {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    position: relative;
    margin-left: 0.8rem;

    ${(props) => props.theme.mixins.linkUnderlineEffect};
  }

  //Checkbox

  .keep-connect-box {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .checkbox {
    display: none;
  }

  .checkbox:checked ~ .checkbox-icon-box {
    background-color: ${(props) =>
      props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .checkbox:checked ~ .checkbox-icon-box svg {
    opacity: 1;
  }

  .checkbox-icon-box {
    width: 3rem;
    height: 3rem;
    border-radius: 5px;
    border: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${(props) => props.theme.pallete.colors.common.white};
      opacity: 0;
    }
  }
  .checkbox-icon-box,
  .keep-connect-label {
    cursor: pointer;
  }

  .keep-connect-label {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    font-size: 1.6rem;

    //To Avoid word selection
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }
`;
