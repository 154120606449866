import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../../../components/Button';
import SeparateLine from '../../../../components/SeparateLine';
import Select from '../../../../components/inputs/Select';
import Textfield from '../../../../components/inputs/Textfield';
import Spinner from '../../../../components/loadings/Spinner';
import AuthContext from '../../../../contexts/AuthContext';
import { ToastfyContext } from '../../../../contexts/ToastfyContext';
import {
  showMarketSegmentService,
  updateMarketSegmentService,
} from '../../../../services/marketSegments.service';
import { IUpdateMarketSegmentRequest } from '../../../../services/marketSegments.types';
import { EditMarketCategoryContainer } from './styles';

interface IInputFields {
  description: string;
  label: string;
  status: string;
  textIa: string;
}

const EditMarketCategory: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [isUpdatedLoading, setIsUpdatedLoading] = useState<boolean>(false);
  const [isLoadingCategoryMarket, setIsLoadingCategoryMarket] =
    useState<boolean>(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IInputFields>({
    defaultValues: {
      label: '',
      status: '',
    },
  });
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    const id = searchParams.get('id');
    if (!!id) {
      setIsUpdatedLoading((prev) => !prev);
      const sendMarketSegmentData: IUpdateMarketSegmentRequest = {
        description: data.description,
        id: id,
        isActive: data.status.toLowerCase() === 'true' ? true : false,
        label: data.label,
        textIa: data.textIa,
      };
      await updateMarketSegmentService(sendMarketSegmentData)
        .then((response) => {
          handleToastfy({
            message: 'Categoria de mercado atualizada com sucesso!',
            type: 'info',
          });
          setIsUpdatedLoading((prev) => !prev);
          navigate('/dashboard/categorias-de-mercado');
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          setIsUpdatedLoading((prev) => !prev);
          console.log(error.response.data);
        });
    } else {
      handleToastfy({
        message: 'Erro ao atualizar os dados, "ID" não localizado.',
        type: 'error',
      });
    }
  };

  const getCategoryMarket = useCallback(
    async (id: string) => {
      await showMarketSegmentService(id)
        .then((response) => {
          setIsLoadingCategoryMarket(false);
          reset({
            label: response.data.label,
            status: response.data.isActive.toString(),
            description: response.data.description,
            textIa: response.data.textIa,
          });
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          console.log(error.response.data);
        });
    },
    [handleToastfy, navigate, reset, signOut]
  );

  useEffect(() => {
    const id = searchParams.get('id');
    !!id && getCategoryMarket(id);
  }, [getCategoryMarket, searchParams]);

  return (
    <EditMarketCategoryContainer>
      <h2 className="heading-2 heading-2--margin">
        Editar categoria de mercado
      </h2>
      <SeparateLine
        margin="0 0 5rem"
        color={theme.pallete.colors.brandPrimary.yellow[6]}
        lineHeight="3px"
      />
      {isLoadingCategoryMarket ? (
        <div className="market-category-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Textfield
            label="Nome da categoria do mercado"
            type="text"
            {...register('label', {
              required: 'O campo é requerido.',
            })}
            error={errors.label?.message}
          />
          <Textfield
            label="Descrição da categoria"
            type="text"
            {...register('description', {
              required: 'O campo é requerido.',
            })}
            error={errors.description?.message}
          />
          <Select
            textLabel="Status"
            options={[
              { optionName: 'Ativado', optionValue: 'true' },
              { optionName: 'Desativado', optionValue: 'false' },
            ]}
            {...register('status', {
              required: 'O campo é requerido.',
            })}
            error={errors.status?.message}
          />
          <Textfield
            label="Texto para a IA"
            type="text"
            {...register('textIa', {
              required: 'O campo é requerido.',
            })}
            placeholder='Ex: Vamos criar 5 ideias para "%1" um briefing usando um Gatilho criativo da categoria "%2"'
            error={errors.textIa?.message}
          />
          <p className="form-paragraph">
            <strong>Observação:</strong>&nbsp;O Item <strong>"%2"</strong> deve
            ficar na posição do texto do título do segmento de mercado, pois
            esse item é o título da categoria atual e isso será usado para
            melhoria da busca da IA. Exemplo: Vamos criar 5 ideias para um
            briefing usando um Gatilho Criativo da categoria{' '}
            <strong>"%2"</strong>.
          </p>
          <p className="form-paragraph">
            O item <strong>"%1"</strong> é opcional, no lugar dele será
            substituído o formato de resposta que IA irá gerar.
          </p>
          <Button
            buttonText="Salvar"
            variant="contained"
            type="submit"
            buttonSize={160}
            isLoading={isUpdatedLoading}
            className="edit-market-category-button"
          />
        </form>
      )}
    </EditMarketCategoryContainer>
  );
};

export default EditMarketCategory;
