import React, { useState, useEffect, useCallback, useContext } from 'react';
import { RiShareBoxFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import SeparateLine from '../../../../../components/SeparateLine';
import { DashboardLayoutSideBarMenuContainer } from './styles';
import { AiOutlinePlus } from 'react-icons/ai';
import LightboxCategoryMarket from '../../../../../components/lightboxes/LightboxCategoryMarket';
import { listMarketSegmentService } from '../../../../../services/marketSegments.service';
import { IListMarketSegmentData } from '../../../../../services/marketSegments.types';
import ToastfyContext from '../../../../../contexts/ToastfyContext';
import AuthContext from '../../../../../contexts/AuthContext';
import LightboxInternParam from '../../../../../components/lightboxes/LightboxInternParam';
import LightboxTag from '../../../../../components/lightboxes/LightboxTag';
import { listCreativeChallengeTagService } from '../../../../../services/creativeChallengeTag.service';
import { IListCreativeChallengeTagData } from '../../../../../services/creativeChallengeTag.types';

const DashboardLayoutSideBarMenu: React.FC = () => {
  const [isNewCategoryOpen, setIsNewCategoryOpen] = useState<boolean>(false);
  const [isNewTagOpen, setIsNewTagOpen] = useState<boolean>(false);
  const [
    isInternParamThermometerIdeasOpen,
    setIsInternParamThermometerIdeasOpen,
  ] = useState<boolean>(false);
  const [
    isInternParamChatBrainstormingRoomOpen,
    setIsInternParamChatBrainstormingRoomOpen,
  ] = useState<boolean>(false);
  const [listMarket, setIsListMarket] = useState<IListMarketSegmentData[]>([]);
  const [listCreativeChallengeTag, setIsListCreativeChallengeTag] = useState<
    IListCreativeChallengeTagData[]
  >([]);
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const getSegmentMarket = useCallback(async () => {
    await listMarketSegmentService({
      order: 'DESC',
      orderBy: 'createdAt',
      isActive: true,
    })
      .then((response) => {
        setIsListMarket(response.data.slice(0, 4));
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message: !!error.message
              ? error.message
              : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    getSegmentMarket();
  }, [getSegmentMarket]);

  const getCreativeChallengeTags = useCallback(async () => {
    await listCreativeChallengeTagService({
      order: 'DESC',
      orderBy: 'createdAt',
      isActive: true,
    })
      .then((response) => {
        setIsListCreativeChallengeTag(response.data.slice(0, 4));
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message: !!error.message
              ? error.message
              : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    getCreativeChallengeTags();
  }, [getCreativeChallengeTags]);

  return (
    <React.Fragment>
      <DashboardLayoutSideBarMenuContainer>
        <div className="menu-items-block">
          <div className="menu-item">
            <span
              className="menu-item-text"
              onClick={() => navigate('ver-editar-conteudos')}
            >
              Visualizar conteúdos
            </span>
            <RiShareBoxFill size={18} />
          </div>
          <div className="menu-sub-items">
            <span
              className="menu-sub-item-text"
              onClick={() => navigate('gatilhos-criativos')}
            >
              Gatilhos Criativos
            </span>
            <span
              className="menu-sub-item-text"
              onClick={() => navigate('salas-de-brainstorming')}
            >
              Salas de Brainstorming
            </span>
            <span
              className="menu-sub-item-text"
              onClick={() => navigate('desafio-criativista')}
            >
              Desafio Criativista
            </span>
            <span
              className="menu-sub-item-text menu-sub-item-text-new-content"
              onClick={() => navigate('criar-novo-conteudo')}
            >
              <AiOutlinePlus size={14} /> Novo conteúdo
            </span>
          </div>
        </div>
        <SeparateLine
          margin="0"
          color={theme.pallete.colors.brandPrimary.gray[2]}
          lineHeight="2px"
        />
        <div className="menu-items-block">
          <div className="menu-item">
            <span
              className="menu-item-text"
              onClick={() => navigate('categorias-de-mercado')}
            >
              Segmento do mercado
            </span>
            <RiShareBoxFill size={18} />
          </div>
          <div className="menu-sub-items">
            {listMarket.map((itemListMarket) => {
              return (
                <span
                  key={itemListMarket._id}
                  className="menu-sub-item-text"
                  onClick={() => navigate('categorias-de-mercado')}
                >
                  {itemListMarket.label}
                </span>
              );
            })}
            <span
              className="menu-sub-item-text menu-sub-item-text-new-content"
              onClick={() => setIsNewCategoryOpen((prev) => !prev)}
            >
              <AiOutlinePlus size={14} /> Novo segmento
            </span>
          </div>
        </div>
        <SeparateLine
          margin="0"
          color={theme.pallete.colors.brandPrimary.gray[2]}
          lineHeight="2px"
        />
        <div className="menu-items-block">
          <div className="menu-item">
            <span
              className="menu-item-text"
              onClick={() => navigate('ver-editar-conteudos')}
            >
              Desafios criativistas
            </span>
            <RiShareBoxFill size={18} />
          </div>
          <div className="menu-sub-items">
            {listCreativeChallengeTag.map((itemListChallengeTag) => {
              return (
                <span
                  key={itemListChallengeTag._id}
                  className="menu-sub-item-text"
                  onClick={() => navigate('desafio-criativista')}
                >
                  {itemListChallengeTag.label}
                </span>
              );
            })}

            <span
              className="menu-sub-item-text menu-sub-item-text-new-content"
              onClick={() => setIsNewTagOpen((prev) => !prev)}
            >
              <AiOutlinePlus size={14} /> Nova tag
            </span>
            <span
              className="menu-sub-item-text menu-sub-item-text-new-content"
              onClick={() => navigate('criar-desafio-criativista')}
            >
              <AiOutlinePlus size={14} /> Novo conteúdo
            </span>
          </div>
        </div>
        <SeparateLine
          margin="0"
          color={theme.pallete.colors.brandPrimary.gray[2]}
          lineHeight="2px"
        />
        <div className="menu-items-block">
          <div className="menu-item">
            <span
              className="menu-item-text"
              // onClick={() =>
              //   setIsInternParamThermometerIdeasOpen((prev) => !prev)
              // }
            >
              Parâmetros interno da IA
            </span>
            {/* <RiShareBoxFill size={18} /> */}
          </div>
          <div className="menu-sub-items">
            <span
              className="menu-sub-item-text"
              onClick={() =>
                setIsInternParamThermometerIdeasOpen((prev) => !prev)
              }
            >
              Termômetro de Ideias
            </span>
            <span
              className="menu-sub-item-text"
              onClick={() =>
                setIsInternParamChatBrainstormingRoomOpen((prev) => !prev)
              }
            >
              Chat da Sala de Brainstorming
            </span>
          </div>
        </div>

        <SeparateLine
          margin="0"
          color={theme.pallete.colors.brandPrimary.gray[2]}
          lineHeight="2px"
        />
        <div className="menu-items-block">
          <div className="menu-item">
            <span
              className="menu-item-text"
              onClick={() => navigate('membros-da-equipe')}
            >
              Membros da equipe
            </span>
            <RiShareBoxFill size={18} />
          </div>
        </div>
        <SeparateLine
          margin="0"
          color={theme.pallete.colors.brandPrimary.gray[2]}
          lineHeight="2px"
        />
        <div className="menu-items-block">
          <div className="menu-item">
            <span
              className="menu-item-text"
              onClick={() => navigate('usuarios-cadastrados')}
            >
              Usuários cadastrados
            </span>
            <RiShareBoxFill size={18} />
          </div>
        </div>
        <SeparateLine
          margin="0"
          color={theme.pallete.colors.brandPrimary.gray[2]}
          lineHeight="2px"
        />
      </DashboardLayoutSideBarMenuContainer>
      {isNewCategoryOpen && (
        <LightboxCategoryMarket
          handleOpenModal={() => setIsNewCategoryOpen((prev) => !prev)}
          setIsNewCategoryOpen={setIsNewCategoryOpen}
        />
      )}
      {isNewTagOpen && (
        <LightboxTag
          handleOpenModal={() => setIsNewTagOpen((prev) => !prev)}
          setIsTagOpen={setIsNewTagOpen}
        />
      )}
      {isInternParamThermometerIdeasOpen && (
        <LightboxInternParam
          handleOpenModal={() =>
            setIsInternParamThermometerIdeasOpen((prev) => !prev)
          }
          setIsInternParamOpen={setIsInternParamThermometerIdeasOpen}
          name="thermometer-ideas"
          title="Termômetro de Ideias"
        />
      )}
      {isInternParamChatBrainstormingRoomOpen && (
        <LightboxInternParam
          handleOpenModal={() =>
            setIsInternParamChatBrainstormingRoomOpen((prev) => !prev)
          }
          setIsInternParamOpen={setIsInternParamChatBrainstormingRoomOpen}
          name="chat-brainstorming-room"
          title="Chat com a IA da sala de Brainstorming"
        />
      )}
    </React.Fragment>
  );
};

export default DashboardLayoutSideBarMenu;
