import React, { useContext } from 'react';
import { CiWarning } from 'react-icons/ci';
import { FiCopy } from 'react-icons/fi';
import RobotFace from '../../../components/svgs/RobotFace';
import ToastfyContext from '../../../contexts/ToastfyContext';
import { GeneratedFromIAContainer } from './styles';
import Button from '../../../components/Button';

interface IProps {
  generatedAnswers: string;
  marketSegmentGeneratedName: string;
  isLoadingAnswersIA: boolean;
  regenerateIaAnswers: () => Promise<void>;
}

const GeneratedFromIA: React.FC<IProps> = ({
  generatedAnswers,
  marketSegmentGeneratedName,
  isLoadingAnswersIA,
  regenerateIaAnswers,
}) => {
  const { handleToastfy } = useContext(ToastfyContext);

  return (
    <GeneratedFromIAContainer>
      <div className="generated-from-ia-content">
        <h2 className="generated-from-ia-heading-2">
          Ideias geradas pela Inteligência Artificial
        </h2>
        <p className="generated-from-ia-paragraph">
          Inspire-se nas ideias da IA e tenha mais insights para o seu desafio.
          Lembre-se: o(a) criativo(a) é você. A IA está aqui apenas para te dar
          uma ajudinha.
        </p>
        <div className="generated-from-ia-content-box">
          <div className="generated-from-ia-content-inner">
            <span className="generated-from-ia-title-box">
              <span className="generated-from-ia-title">Respostas:</span>
              <span
                className="generated-from-ia-copy-box"
                onClick={() => {
                  navigator.clipboard.writeText(generatedAnswers);
                  handleToastfy({
                    message: 'Texto copiado com sucesso!',
                    type: 'success',
                  });
                }}
              >
                <FiCopy size={24} />
                <span>Copiar texto</span>
              </span>
            </span>
            <span className="generated-from-ia-question">
              Aqui estão algumas ideias usando o gatilho criativo “
              {marketSegmentGeneratedName}”
            </span>
            <span className="generated-from-ia-answer">{generatedAnswers}</span>
            <div className="generated-from-ia-bellow">
              <div className="generated-from-ia-warning-box">
                <CiWarning size={26} />
                <span>
                  Recomendamos que você copie a resposta e cole em um app de
                  texto de sua preferência.
                </span>
              </div>
              <span
                className="generated-from-ia-copy-box"
                onClick={() => {
                  navigator.clipboard.writeText(generatedAnswers);
                  handleToastfy({
                    message: 'Texto copiado com sucesso!',
                    type: 'success',
                  });
                }}
              >
                <FiCopy size={24} />
                <span>Copiar texto</span>
              </span>
            </div>
          </div>
          <RobotFace className="generated-from-ia-svg" />
        </div>
      </div>
      <Button
        className="generated-from-ia-button"
        buttonText={`Gerar novas ideias (-${1} crédito)`}
        variant="contained"
        onClick={() => regenerateIaAnswers()}
        isLoading={isLoadingAnswersIA}
      />
    </GeneratedFromIAContainer>
  );
};

export default GeneratedFromIA;
