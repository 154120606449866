import React, { forwardRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FormCheckboxContainer } from './styles';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  isActive: boolean;
  children: React.ReactNode;
  onClick: () => void;
  error?: string;
}
const FormCheckbox: React.ForwardRefRenderFunction<HTMLInputElement, IProps> = (
  { isActive, onClick, children, error, ...props },
  ref
) => {
  const id = uuidv4();

  return (
    <FormCheckboxContainer>
      <div className="keep-connect-box">
        <input
          className="checkbox"
          type="checkbox"
          id={id}
          checked={isActive}
          onClick={onClick}
          readOnly
          ref={ref}
          {...props}
        />
        <label className="checkbox-icon-box" htmlFor={id}>
          <AiOutlineClose size={18} />
        </label>
        <label htmlFor={id}>{children}</label>
      </div>
      {!!error && <span className="checkbox-error">{error}</span>}
    </FormCheckboxContainer>
  );
};

export default forwardRef(FormCheckbox);
