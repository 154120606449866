import {
  Document,
  Image,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React, { useContext, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useTheme } from 'styled-components';
import Button from '../../../components/Button';
import DownloadSVG from '../../../components/svgs/DownloadSVG';
import AuthContext from '../../../contexts/AuthContext';
import { ResultThermometerStyled, ModalFeedback } from './styles';
import LightboxWithoutLogin from '../../../components/lightboxes/LightboxWithoutLogin';
import SeparateLine from '../../../components/SeparateLine';
import { SubmitHandler, useForm } from 'react-hook-form';
import Textfield from '../../../components/inputs/Textfield';
import TextArea from '../../../components/inputs/TextArea';
import LightBox from '../../../components/lightboxes/LightBox';
import { useNavigate } from 'react-router-dom';
import ToastfyContext from '../../../contexts/ToastfyContext';
import { IGenerateFeedbackIARequest } from '../../../services/thermometerIdeas.types';
import { generateFeedbackIAService } from '../../../services/thermometerIdeas.service';
import Spinner from '../../../components/loadings/Spinner';

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type: number;
  toDoAgain: () => void;
  answer: IAnswer;
  scrollToIA: () => void;
  setGeneratedAnswers: (value: string) => void;
  setIsGeneratedAnswersModal: (value: boolean) => void;
}

interface IAnswer {
  name: string;
  description: string;
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
  question6: string;
  question7: string;
  question8: string;
}

const ResultThermometer: React.FC<IProps> = ({ type, toDoAgain, answer }) => {
  const theme = useTheme();

  return (
    <ResultThermometerStyled>
      {type === 1 ? (
        <div className="content">
          <div className="box-content">
            <div className="progress-bar-result progress-bar-result--1"></div>
            <h1 className="heading-3">RESULTADO</h1>
            <p className="paragraph-black">
              É um bom caminho, mas que tal pensar mais um pouco pra ficar
              massa?
            </p>
            <p className="paragraph-gray">
              Muitas vezes uma ideia está no caminho certo e pode até atender ao
              objetivo, mas não tem elementos que fariam ela se destacar das
              outras.
            </p>
            <p className="paragraph-gray">
              Que tal analisar essa ideia e ver se vale a pena deixar ela ainda
              melhor? Ou, se preferir, testar outras ideias a partir dessa. Quem
              sabe não sai uma ideia ainda mais legal?
            </p>
            <p className="paragraph-gray">
              Não esqueça de descansar a mente e, se precisar, pode trocar ideia
              com a gente. ;)
            </p>

            {/* <div className="feedback">
              <div className="box-image-ia box-image-ia--1"></div>

              <div className="box-text">
                <h1 className="heading-3">
                  PEÇA FEEDBACK PERSONALIZADO PARA IA
                </h1>
                <p className="paragraph-gray">
                  Nossa Diretora Criativa com miolos eletrônicos vai analisar
                  sua ideia, dar feedbacks, sugerir melhorias e novos
                  desdobramentos.
                </p>
                <div className="buttons-box">
                  <div className="button-points-box">
                    <Button
                      variant="contained"
                      buttonText="PEDIR FEEDBACK PERSONALIZADO"
                      type="button"
                      onClick={() => {
                        if (!user?.balance) {
                          handleToastfy({
                            message: 'Ops, você não tem créditos suficiente',
                            type: 'error',
                          });
                        } else {
                          setIsModalConfirmFeedback((prev) => !prev);
                        }
                      }}
                    />
                    {user?._id && (
                      <span className="button-points-span">
                        Você tem {!!user?.balance ? user.balance : 0} créditos
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="box-content">
            <div className="box-image box-image--1"></div>
            <div className="buttons-box">
              <Button
                variant="contained"
                buttonText="Refazer termômetro"
                onClick={() => toDoAgain()}
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor: theme.pallete.colors.common.black,
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
              />
              <div className="link">
                <PDFDownloadLink
                  document={
                    <ResultThermometerPDF type={type} answer={answer} />
                  }
                  fileName="resultado_termometro_ideias.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      'Carregando documento...'
                    ) : (
                      <>
                        {' '}
                        <DownloadSVG />
                        <span className="link-span">Baixar resultado</span>
                      </>
                    )
                  }
                </PDFDownloadLink>
              </div>
            </div>
          </div>
        </div>
      ) : type === 2 ? (
        <div className="content">
          <div className="box-content">
            <div className="progress-bar-result progress-bar-result--2"></div>
            <h1 className="heading-3">RESULTADO</h1>
            <p className="paragraph-black">
              Ideia legal! Vamos tentar melhorar mais um pouco pra ficar massa?
            </p>
            <p className="paragraph-gray">
              Você tem uma ideia legal no papel, com potencial para se tornar
              massa.
            </p>
            <p className="paragraph-gray">
              Que tal analisar os pontos que você marcou “não” e “um pouco” e
              tentar melhorá-los? Às vezes, durante esse processo, sua ideia
              pode tomar um novo rumo.
            </p>
            <p className="paragraph-gray">
              Mas não se preocupe, isso é ótimo! Você vai ter mais ideias para
              analisar e testar. Procure por referências de ideias que
              executaram bem todos os pontos ou trace estratégias para sua ideia
              chegar mais próxima de atender aos requisitos. E não desanime,
              hein! As melhores ideias são aquelas que estão em constante
              evolução.
            </p>

            {/* <div className="feedback">
              <div className="box-image-ia box-image-ia--1"></div>

              <div className="box-text">
                <h1 className="heading-3">
                  PEÇA FEEDBACK PERSONALIZADO PARA IA
                </h1>
                <p className="paragraph-gray">
                  Nossa Diretora Criativa com miolos eletrônicos vai analisar
                  sua ideia, dar feedbacks, sugerir melhorias e novos
                  desdobramentos.
                </p>
                <div className="buttons-box">
                  <div className="button-points-box">
                    <Button
                      variant="contained"
                      buttonText="PEDIR FEEDBACK PERSONALIZADO"
                      type="button"
                      onClick={() => {
                        if (!user?.balance) {
                          handleToastfy({
                            message: 'Ops, você não tem créditos suficiente',
                            type: 'error',
                          });
                        } else {
                          setIsModalConfirmFeedback((prev) => !prev);
                        }
                      }}
                    />
                    {user?._id && (
                      <span className="button-points-span">
                        Você tem {!!user?.balance ? user.balance : 0} créditos
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="box-content">
            <div className="box-image box-image--2"></div>
            <div className="buttons-box">
              <Button
                variant="contained"
                buttonText="Refazer termômetro"
                onClick={() => {
                  toDoAgain();
                }}
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor: theme.pallete.colors.common.black,
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
              />
              <div className="link">
                <PDFDownloadLink
                  document={
                    <ResultThermometerPDF type={type} answer={answer} />
                  }
                  fileName="resultado_termometro_ideias.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      'Carregando documento...'
                    ) : (
                      <>
                        {' '}
                        <DownloadSVG />
                        <span className="link-span">Baixar resultado</span>
                      </>
                    )
                  }
                </PDFDownloadLink>
              </div>
            </div>
          </div>
        </div>
      ) : type === 3 ? (
        <div className="content">
          <div className="box-content">
            <div className="progress-bar-result progress-bar-result--3"></div>
            <h1 className="heading-3">RESULTADO</h1>
            <p className="paragraph-black">
              Boa ideia! E pode ficar ainda melhor.
            </p>
            <p className="paragraph-gray">
              Parabéns, você tem uma boa ideia pra tirar do papel!
            </p>
            <p className="paragraph-gray">
              E já que estamos tão perto de torná-la ainda mais massa, que tal
              analisar alguns pontos? Mas, calma, não vamos sair alterando tudo.
            </p>
            <p className="paragraph-gray">
              Analise o que pode ser melhorado ou potencializado. Quem sabe se
              você traçar um plano de execução possa ficar mais fácil perceber
              como melhorar. Se sentir segurança na sua ideia, você pode começar
              a executá-la de forma mínima, pra testar. Nem tudo precisa ser
              perfeito, este é um exercício provocativo, com o intuito de
              extrair o máximo das nossas ideias.
            </p>
            <p className="paragraph-gray">E aí? Vamos nessa?!</p>

            {/* <div className="feedback">
              <div className="box-image-ia box-image-ia--1"></div>

              <div className="box-text">
                <h1 className="heading-3">
                  PEÇA FEEDBACK PERSONALIZADO PARA IA
                </h1>
                <p className="paragraph-gray">
                  Nossa Diretora Criativa com miolos eletrônicos vai analisar
                  sua ideia, dar feedbacks, sugerir melhorias e novos
                  desdobramentos.
                </p>
                <div className="buttons-box">
                  <div className="button-points-box">
                    <Button
                      variant="contained"
                      buttonText="PEDIR FEEDBACK PERSONALIZADO"
                      type="button"
                      onClick={() => {
                        if (!user?.balance) {
                          handleToastfy({
                            message: 'Ops, você não tem créditos suficiente',
                            type: 'error',
                          });
                        } else {
                          setIsModalConfirmFeedback((prev) => !prev);
                        }
                      }}
                    />
                    {user?._id && (
                      <span className="button-points-span">
                        Você tem {!!user?.balance ? user.balance : 0} créditos
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="box-content">
            <div className="box-image box-image--3"></div>
            <div className="buttons-box">
              <Button
                variant="contained"
                buttonText="Refazer termômetro"
                onClick={() => {
                  toDoAgain();
                }}
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor: theme.pallete.colors.common.black,
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
              />
              <div className="link">
                <PDFDownloadLink
                  document={
                    <ResultThermometerPDF type={type} answer={answer} />
                  }
                  fileName="resultado_termometro_ideias.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      'Carregando documento...'
                    ) : (
                      <>
                        {' '}
                        <DownloadSVG />
                        <span className="link-span">Baixar resultado</span>
                      </>
                    )
                  }
                </PDFDownloadLink>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="content">
          <div className="box-content">
            <div className="progress-bar-result progress-bar-result--4"></div>
            <h1 className="heading-3">RESULTADO</h1>
            <p className="paragraph-black">
              Que ideia massa! Já pode começar a tirar do papel!
            </p>
            <p className="paragraph-gray">
              Arrasou!!! <br />
              Temos uma ideia massa por aqui!
            </p>
            <p className="paragraph-gray">
              O potencial dela é incrível. Agora é começar a montar um plano de
              execução, aproveitando para corrigir possíveis pontos que possam
              melhorar. Não precisa ter pressa, nem precisa ter tudo pronto para
              começar.
            </p>
            <p className="paragraph-gray">
              O ideal é começar com o mínimo necessário, testando e analisando
              os resultados. Assim, você vai enxergar novos elementos que não
              estavam no campo da ideação, mas que são importantes. Se precisar
              dar algum passo pra trás, não se preocupe, esse é o movimento
              natural das grandes ideias.
            </p>
            <p className="paragraph-gray">Vamos nessa! :D</p>

            {/* <div className="feedback">
              <div className="box-image-ia box-image-ia--1"></div>

              <div className="box-text">
                <h1 className="heading-3">
                  PEÇA FEEDBACK PERSONALIZADO PARA IA
                </h1>
                <p className="paragraph-gray">
                  Nossa Diretora Criativa com miolos eletrônicos vai analisar
                  sua ideia, dar feedbacks, sugerir melhorias e novos
                  desdobramentos.
                </p>
                <div className="buttons-box">
                  <div className="button-points-box">
                    <Button
                      variant="contained"
                      buttonText="PEDIR FEEDBACK PERSONALIZADO"
                      type="button"
                      onClick={() => {
                        if (!user?.balance) {
                          handleToastfy({
                            message: 'Ops, você não tem créditos suficiente',
                            type: 'error',
                          });
                        } else {
                          setIsModalConfirmFeedback((prev) => !prev);
                        }
                      }}
                    />
                    {user?._id && (
                      <span className="button-points-span">
                        Você tem {!!user?.balance ? user.balance : 0} créditos
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="box-content">
            <div className="box-image box-image--4"></div>
            <div className="buttons-box">
              <Button
                variant="contained"
                buttonText="Refazer termômetro"
                onClick={() => {
                  toDoAgain();
                }}
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor: theme.pallete.colors.common.black,
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
              />
              <div className="link">
                <PDFDownloadLink
                  document={
                    <ResultThermometerPDF type={type} answer={answer} />
                  }
                  fileName="resultado_termometro_ideias.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      'Carregando documento...'
                    ) : (
                      <>
                        {' '}
                        <DownloadSVG />
                        <span className="link-span">Baixar resultado</span>
                      </>
                    )
                  }
                </PDFDownloadLink>
              </div>
            </div>
          </div>
        </div>
      )}
    </ResultThermometerStyled>
  );
};

export default ResultThermometer;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    // marginHorizontal: 20,
    // marginVertical: 20,
    // margin: 20,
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading_3: {
    fontSize: 14,
    color: '#000',
    marginBottom: 10,
  },
  paragraph: {
    color: '#5B5B5B',
    marginBottom: 16,
    fontSize: 12,
    fontWeight: 'normal',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 70,
  },
});

interface IPropsPDF {
  type: number;
  answer: IAnswer;
}

// Create Document To Download
const ResultThermometerPDF = ({ type, answer }: IPropsPDF) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image
            style={styles.image}
            src="/static/images/Logo_Pagina_Resultados.png"
          />
        </View>

        <View style={styles.section}>
          <Text style={styles.heading_3}>Dê um nome para a sua ideia:</Text>
          <Text style={styles.paragraph}>{answer.name}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading_3}>
            Descreva sua ideia de forma resumida:
          </Text>
          <Text style={styles.paragraph}>{answer.description}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.heading_3}>
            1. Sua ideia atende bem ao objetivo proposto no briefing ou desafio?
          </Text>
          <Text style={styles.paragraph}>Resposta: {answer.question1}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading_3}>
            2. Sua ideia se diferencia bem do que já existe? Ela não causa
            déjà-vu?
          </Text>
          <Text style={styles.paragraph}>Resposta: {answer.question2}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading_3}>
            3. Sua ideia gera identificação com o público-alvo?
          </Text>
          <Text style={styles.paragraph}>Resposta: {answer.question3}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading_3}>
            4. Sua ideia gera alguma grande emoção no público-alvo? (Pode ser
            alegria, risos, choro, surpresa, curiosidade etc.)
          </Text>
          <Text style={styles.paragraph}>Resposta: {answer.question4}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading_3}>
            5. É possível executar sua ideia dentro do prazo estabelecido?
          </Text>
          <Text style={styles.paragraph}>Resposta: {answer.question5}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading_3}>
            6. Se for necessário orçamento, você tem o suficiente para
            implementá-la, mesmo que parcialmente?
          </Text>
          <Text style={styles.paragraph}>Resposta: {answer.question6}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading_3}>
            7. Sua ideia tem potencial para mudar comportamentos ou causar
            impacto?
          </Text>
          <Text style={styles.paragraph}>Resposta: {answer.question7}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading_3}>
            8. Sua ideia te empolga? Você gosta realmente dela? Ela faz seus
            olhos brilharem?
          </Text>
          <Text style={styles.paragraph}>Resposta: {answer.question8}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.heading_3}>RESPOSTA</Text>

          {type === 1 ? (
            <>
              <Text style={styles.heading_3}>
                É um bom caminho, mas que tal pensar mais um pouco pra ficar
                massa?
              </Text>
              <Text style={styles.paragraph}>
                Muitas vezes uma ideia está no caminho certo e pode até atender
                ao objetivo, mas não tem elementos que fariam ela se destacar
                das outras.
              </Text>
              <Text style={styles.paragraph}>
                Que tal analisar essa ideia e ver se vale a pena deixar ela
                ainda melhor? Ou, se preferir, testar outras ideias a partir
                dessa. Quem sabe não sai uma ideia ainda mais legal?{' '}
              </Text>
              <Text style={styles.paragraph}>
                Não esqueça de descansar a mente e, se precisar, pode trocar
                ideia com a gente. ;)
              </Text>
              {/* <Image style={styles.image} src="/static/images/result_1.gif" /> */}
            </>
          ) : type === 2 ? (
            <>
              {/* <Image style={styles.image} src="/static/images/result_2.gif" /> */}
              <Text style={styles.heading_3}>
                Ideia legal! Vamos tentar melhorar mais um pouco pra ficar
                massa?
              </Text>
              <Text style={styles.paragraph}>
                Você tem uma ideia legal no papel, com potencial para se tornar
                massa.
              </Text>
              <Text style={styles.paragraph}>
                Que tal analisar os pontos que você marcou “não” e “um pouco” e
                tentar melhorá-los? Às vezes, durante esse processo, sua ideia
                pode tomar um novo rumo.
              </Text>
              <Text style={styles.paragraph}>
                Mas não se preocupe, isso é ótimo! Você vai ter mais ideias para
                analisar e testar. Procure por referências de ideias que
                executaram bem todos os pontos ou trace estratégias para sua
                ideia chegar mais próxima de atender aos requisitos. E não
                desanime, hein! As melhores ideias são aquelas que estão em
                constante evolução.
              </Text>
            </>
          ) : type === 3 ? (
            <>
              {/* <Image style={styles.image} src="/static/images/result_3.gif" /> */}
              <Text style={styles.heading_3}>
                Boa ideia! E pode ficar ainda melhor.
              </Text>
              <Text style={styles.paragraph}>
                Parabéns, você tem uma boa ideia pra tirar do papel!
              </Text>
              <Text style={styles.paragraph}>
                E já que estamos tão perto de torná-la ainda mais massa, que tal
                analisar alguns pontos? Mas, calma, não vamos sair alterando
                tudo.{' '}
              </Text>
              <Text style={styles.paragraph}>
                Analise o que pode ser melhorado ou potencializado. Quem sabe se
                você traçar um plano de execução possa ficar mais fácil perceber
                como melhorar. Se sentir segurança na sua ideia, você pode
                começar a executá-la de forma mínima, pra testar. Nem tudo
                precisa ser perfeito, este é um exercício provocativo, com o
                intuito de extrair o máximo das nossas ideias.{' '}
              </Text>
              <Text style={styles.paragraph}>E aí? Vamos nessa?!</Text>
            </>
          ) : (
            <>
              {/* <Image style={styles.image} src="/static/images/result_4.gif" /> */}
              <Text style={styles.heading_3}>
                Que ideia massa! Já pode começar a tirar do papel!
              </Text>
              <Text style={styles.paragraph}>
                Arrasou!!! Temos uma ideia massa por aqui!
              </Text>
              <Text style={styles.paragraph}>
                O potencial dela é incrível. Agora é começar a montar um plano
                de execução, aproveitando para corrigir possíveis pontos que
                possam melhorar. Não precisa ter pressa, nem precisa ter tudo
                pronto para começar.
              </Text>
              <Text style={styles.paragraph}>
                O ideal é começar com o mínimo necessário, testando e analisando
                os resultados. Assim, você vai enxergar novos elementos que não
                estavam no campo da ideação, mas que são importantes. Se
                precisar dar algum passo pra trás, não se preocupe, esse é o
                movimento natural das grandes ideias.
              </Text>
              <Text style={styles.paragraph}>Vamos nessa! :D</Text>
            </>
          )}
        </View>
      </Page>
    </Document>
  );
};
