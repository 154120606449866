import styled from 'styled-components';

export const DashboardContainer = styled.div`
  margin: 9.6rem auto;

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 20.6rem;
  }

  .dashboard-section {
    ${(props) => props.theme.mixins.containerGridDefault};

    & > div {
      grid-column: center-start / center-end;
    }
  }
`;
