import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiShareBoxFill } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Avatar from '../../../../../components/Avatar';
import MenuMobile from '../../../../../components/MenuMobile';
import SeparateLine from '../../../../../components/SeparateLine';
import LightboxCategoryMarket from '../../../../../components/lightboxes/LightboxCategoryMarket';
import Logo from '../../../../../components/svgs/Logo';
import LogoFull from '../../../../../components/svgs/LogoFull';
import { AuthContext } from '../../../../../contexts/AuthContext';
import ToastfyContext from '../../../../../contexts/ToastfyContext';
import { listMarketSegmentService } from '../../../../../services/marketSegments.service';
import { IListMarketSegmentData } from '../../../../../services/marketSegments.types';
import SubMenuUser from '../../../../dashboard/user/Dashboard/SubMenuUser';
import {
  DashboardLayoutTopBarContainer,
  MenuItemsMobileContent,
} from './styles';
import LightboxInternParam from '../../../../../components/lightboxes/LightboxInternParam';
import LightboxTag from '../../../../../components/lightboxes/LightboxTag';
import { listCreativeChallengeTagService } from '../../../../../services/creativeChallengeTag.service';
import { IListCreativeChallengeTagData } from '../../../../../services/creativeChallengeTag.types';

const DashboardLayoutTopBar: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [isNewTagOpen, setIsNewTagOpen] = useState<boolean>(false);
  const refSubMenu = useRef<HTMLDivElement>(null);
  const openMenuRef = useRef<any>(null);
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const [listMarket, setIsListMarket] = useState<IListMarketSegmentData[]>([]);
  const [isNewCategoryOpen, setIsNewCategoryOpen] = useState<boolean>(false);
  const [listCreativeChallengeTag, setIsListCreativeChallengeTag] = useState<
    IListCreativeChallengeTagData[]
  >([]);
  const [
    isInternParamThermometerIdeasOpen,
    setIsInternParamThermometerIdeasOpen,
  ] = useState<boolean>(false);
  const [
    isInternParamChatBrainstormingRoomOpen,
    setIsInternParamChatBrainstormingRoomOpen,
  ] = useState<boolean>(false);
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleCategoryOpen = () => {
    setIsNewCategoryOpen((prev) => !prev);
  };

  const getSegmentMarket = useCallback(async () => {
    await listMarketSegmentService({
      order: 'DESC',
      orderBy: 'createdAt',
      isActive: true,
    })
      .then((response) => {
        setIsListMarket(response.data.slice(0, 4));
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message: !!error.message
              ? error.message
              : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    const handleClickOutside = (evt: any) => {
      if (
        refSubMenu.current &&
        !refSubMenu.current.contains(evt.target) &&
        !openMenuRef.current.contains(evt.target)
      ) {
        setShowSubMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setShowSubMenu(false);
  }, [pathname]);

  useEffect(() => {
    getSegmentMarket();
  }, [getSegmentMarket]);

  const getCreativeChallengeTags = useCallback(async () => {
    await listCreativeChallengeTagService({
      order: 'DESC',
      orderBy: 'createdAt',
      isActive: true,
    })
      .then((response) => {
        setIsListCreativeChallengeTag(response.data.slice(0, 4));
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message: !!error.message
              ? error.message
              : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    getCreativeChallengeTags();
  }, [getCreativeChallengeTags]);

  return (
    <DashboardLayoutTopBarContainer>
      <MenuMobile>
        <MenuItemsMobileContent>
          <div className="menu-items-block">
            <div className="menu-item">
              <span
                className="menu-item-text"
                onClick={() => navigate('ver-editar-conteudos')}
              >
                Visualizar conteúdos
              </span>
              <RiShareBoxFill size={18} />
            </div>
            <div className="menu-sub-items">
              <span
                className="menu-sub-item-text"
                onClick={() => navigate('gatilhos-criativos')}
              >
                Gatilhos Criativos
              </span>
              <span
                className="menu-sub-item-text"
                onClick={() => navigate('salas-de-brainstorming')}
              >
                Salas de Brainstorming
              </span>
              <span
                className="menu-sub-item-text menu-sub-item-text-new-content"
                onClick={() => navigate('criar-novo-conteudo')}
              >
                <AiOutlinePlus size={14} /> Novo conteúdo
              </span>
            </div>
          </div>
          <SeparateLine
            margin="0"
            color={theme.pallete.colors.brandPrimary.gray[2]}
            lineHeight="2px"
          />
          <div className="menu-items-block">
            <div className="menu-item">
              <span
                className="menu-item-text"
                onClick={() => navigate('categorias-de-mercado')}
              >
                Segmento do mercado
              </span>
              <RiShareBoxFill size={18} />
            </div>
            {listMarket.map((itemListMarket) => {
              return (
                <div
                  className="menu-sub-items"
                  key={itemListMarket._id}
                  onClick={() => navigate('categorias-de-mercado')}
                >
                  <span className="menu-sub-item-text">
                    {itemListMarket.label}
                  </span>
                </div>
              );
            })}
            <span
              className="menu-sub-item-text menu-sub-item-text-new-content"
              onClick={handleCategoryOpen}
            >
              <AiOutlinePlus size={14} /> Novo segmento
            </span>
          </div>
          <SeparateLine
            margin="0"
            color={theme.pallete.colors.brandPrimary.gray[2]}
            lineHeight="2px"
          />
          <div className="menu-items-block">
            <div className="menu-item">
              <span
                className="menu-item-text"
                onClick={() => navigate('ver-editar-conteudos')}
              >
                Desafios criativistas
              </span>
              <RiShareBoxFill size={18} />
            </div>
            <div className="menu-sub-items">
              {listCreativeChallengeTag.map((itemListChallengeTag) => {
                return (
                  <span
                    key={itemListChallengeTag._id}
                    className="menu-sub-item-text"
                    onClick={() => navigate('desafio-criativista')}
                  >
                    {itemListChallengeTag.label}
                  </span>
                );
              })}

              <span
                className="menu-sub-item-text menu-sub-item-text-new-content"
                onClick={() => setIsNewTagOpen((prev) => !prev)}
              >
                <AiOutlinePlus size={14} /> Nova tag
              </span>
              <span
                className="menu-sub-item-text menu-sub-item-text-new-content"
                onClick={() => navigate('criar-desafio-criativista')}
              >
                <AiOutlinePlus size={14} /> Novo conteúdo
              </span>
            </div>
          </div>
          <SeparateLine
            margin="0"
            color={theme.pallete.colors.brandPrimary.gray[2]}
            lineHeight="2px"
          />
          <div className="menu-items-block">
            <div className="menu-item">
              <span className="menu-item-text">Parâmetros interno da IA</span>
              {/* <RiShareBoxFill size={18} /> */}
            </div>
            <div className="menu-sub-items">
              <span
                className="menu-sub-item-text"
                onClick={() =>
                  setIsInternParamThermometerIdeasOpen((prev) => !prev)
                }
              >
                Termômetro de Ideias
              </span>
              <span
                className="menu-sub-item-text"
                onClick={() =>
                  setIsInternParamChatBrainstormingRoomOpen((prev) => !prev)
                }
              >
                Chat da Sala de Brainstorming
              </span>
            </div>
          </div>
          <SeparateLine
            margin="0"
            color={theme.pallete.colors.brandPrimary.gray[2]}
            lineHeight="2px"
          />
          <div className="menu-items-block">
            <div className="menu-item">
              <span
                className="menu-item-text"
                onClick={() => navigate('membros-da-equipe')}
              >
                Membros da equipe
              </span>
              <RiShareBoxFill size={18} />
            </div>
          </div>
          <SeparateLine
            margin="0"
            color={theme.pallete.colors.brandPrimary.gray[2]}
            lineHeight="2px"
          />
          <div className="menu-items-block">
            <div className="menu-item">
              <span
                className="menu-item-text"
                onClick={() => navigate('usuarios-cadastrados')}
              >
                Usuários cadastrados
              </span>
              <RiShareBoxFill size={18} />
            </div>
          </div>
          <SeparateLine
            margin="0"
            color={theme.pallete.colors.brandPrimary.gray[2]}
            lineHeight="2px"
          />
        </MenuItemsMobileContent>
      </MenuMobile>
      <Link to="/">
        <div className="logo">
          <LogoFull className="logo-full" />
          <Logo className="logo-half" />
        </div>
      </Link>
      <div className="menu-signed-icons">
        <span className="user-name">{user?.username}</span>
        {!!user?.avatar ? (
          <span
            className="menu-user-icon-box"
            style={{ display: 'flex', alignItems: 'center' }}
            ref={openMenuRef}
          >
            <Avatar
              backgroundImageLink={`${process.env.REACT_APP_URL_IMAGE_BASE}/${user?.avatar}`}
              height="4.8rem"
              width="4.8rem"
              onClick={(event) => {
                setShowSubMenu((prev) => !prev);
              }}
            />
          </span>
        ) : (
          <span
            className="menu-user-icon-box"
            style={{ display: 'flex', alignItems: 'center' }}
            ref={openMenuRef}
          >
            <span
              className="nav-avatar-admin"
              onClick={(event) => {
                setShowSubMenu((prev) => !prev);
              }}
            />
          </span>
        )}
      </div>
      {showSubMenu && (
        <span ref={refSubMenu} style={{ position: 'absolute' }}>
          <SubMenuUser />
        </span>
      )}
      {isNewCategoryOpen && (
        <LightboxCategoryMarket
          handleOpenModal={() => setIsNewCategoryOpen((prev) => !prev)}
          setIsNewCategoryOpen={setIsNewCategoryOpen}
        />
      )}
      {isNewTagOpen && (
        <LightboxTag
          handleOpenModal={() => setIsNewTagOpen((prev) => !prev)}
          setIsTagOpen={setIsNewTagOpen}
        />
      )}
      {isInternParamThermometerIdeasOpen && (
        <LightboxInternParam
          handleOpenModal={() =>
            setIsInternParamThermometerIdeasOpen((prev) => !prev)
          }
          setIsInternParamOpen={setIsInternParamThermometerIdeasOpen}
          name="thermometer-ideas"
          title="Termômetro de Ideias"
        />
      )}
      {isInternParamChatBrainstormingRoomOpen && (
        <LightboxInternParam
          handleOpenModal={() =>
            setIsInternParamChatBrainstormingRoomOpen((prev) => !prev)
          }
          setIsInternParamOpen={setIsInternParamChatBrainstormingRoomOpen}
          name="chat-brainstorming-room"
          title="Chat com a IA da sala de Brainstorming"
        />
      )}
    </DashboardLayoutTopBarContainer>
  );
};

export default DashboardLayoutTopBar;
