import axios from 'axios';
import {
  IAlterMethodPaymentRequest,
  IChangeSignatureRequest,
  ICreateSignatureRequest,
  ICreateSignatureResponse,
  IGetAddressRequest,
  IGetAddressResponse,
  // ICreateSignatureRequest,
  IGetPaymentsPlansResponse,
  IPaymentsPlansMessage,
} from './payments.types';
import { api } from './api.axios';

export const getPaymentsPlansService =
  async (): Promise<IGetPaymentsPlansResponse> => {
    const response = await axios.get<IGetPaymentsPlansResponse>(
      `${process.env.REACT_APP_URL_API_BASE}/payments/plans`
    );


    return response.data;
  };

export const getCurrentSignature = async () => {
  const response = await api.get(
    `${process.env.REACT_APP_URL_API_BASE}/payments/signatures`
  );

  return response.data;
};

export const getDataCustomerSignature = async () => {
  const response = await api.get(
    `${process.env.REACT_APP_URL_API_BASE}/payments/signatures/customer`
  );

  return response.data;
};

export const createSignatureService = async ({
  customer,
  planId,
  address,
}: ICreateSignatureRequest): Promise<ICreateSignatureResponse> => {
  const response = await api.post<ICreateSignatureResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/payments/signatures`,
    {
      customer,
      planId,
      address,
    }
  );

  return response.data;
};

export const alterMethodPaymentService = async ({
  billingInfo,
}: IAlterMethodPaymentRequest): Promise<ICreateSignatureResponse> => {
  const response = await api.put<ICreateSignatureResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/payments/signatures/customer/changePayment`,
    {
      billingInfo,
    }
  );

  return response.data;
};

export const getAddressByCEP = async ({
  cep,
}: IGetAddressRequest): Promise<IGetAddressResponse> => {
  const response =
    await axios.get<IGetAddressResponse>(`https://viacep.com.br/ws/${cep}/json
  `);

  return response.data;
};

export const changeSignatureService = async ({
  email,
  planId,
  subscriptionId,
}: IChangeSignatureRequest): Promise<ICreateSignatureResponse> => {
  const response = await api.post<ICreateSignatureResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/payments/signatures/change`,
    {
      email,

      planId,
      subscriptionId,
    }
  );

  return response.data;
};

export const deleteSignatureService =
  async (): Promise<IPaymentsPlansMessage> => {
    const response = await api.delete<IPaymentsPlansMessage>(
      `${process.env.REACT_APP_URL_API_BASE}/payments/signatures`
    );

    return response.data;
  };
