import styled, { css } from 'styled-components';

interface IProps {
  isBordered: boolean;
}

export const BrainstormingCommentMessageContainer = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.isBordered &&
    css`
      border: 1px solid
        ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
      padding: 2.4rem 1.8rem;
    `}
  border-radius: 0.5rem;

  .brainstorming-message-header {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    justify-content: space-between;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
      gap: 3rem;
      align-items: baseline;
    }
  }

  .brainstorming-message-heading {
    font-size: 2rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
    margin: 2rem 0 0.8rem;
    word-break: break-all;
  }

  .brainstorming-message-text {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    margin-bottom: 2.4rem;
    word-break: break-all;
  }

  .brainstorming-message-avatar-box {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  .brainstorming-message-avatar {
    border-radius: 100%;
    width: 6rem;
    height: 6rem;
    background-image: url('/static/images/profile.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .brainstorming-message-name {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
  }

  .brainstorming-message-time {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[6]};
  }

  .brainstorming-message-time-new-room {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.red[9]};
  }

  .brainstorming-message-count {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-left: auto;
  }

  .brainstorming-message-number {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.common.black};
  }

  .brainstorming-message-group-icon {
    display: flex;
    gap: 0.8rem;
    justify-content: space-between;
    align-items: center;
  }

  .brainstorming-message-text-edit {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    width: max-content;
    position: relative;
    cursor: pointer;

    ${(props) => props.theme.mixins.linkUnderlineDefaultEffect}

    span {
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[11]};
    }
  }
`;
