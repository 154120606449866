import styled from 'styled-components';

export const PolicyPrivacyContainer = styled.div`
  ${(props) => props.theme.mixins.containerGridDefault};
  position: relative;

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 12rem;
  }

  .policy-background {
    grid-column: full-start / full-end;
    width: 100%;
    height: 50vh;
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .policy-heading-2 {
    margin-bottom: 3rem;
  }

  .policy-content {
    grid-column: center-start / center-end;
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    padding: 3rem;
    margin: 6rem 0;
    box-shadow: ${(props) => props.theme.pallete.shadows.default.n12};
  }

  .policy-heading-3 {
    margin: 3rem 0;
  }

  .policy-paragraph {
    margin: 3rem 0;

    a {
      &,
      &:active,
      &:visited {
        text-decoration: none;
        color: ${(props) => props.theme.pallete.colors.common.black};
        position: relative;
        font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
        text-decoration: underline;
      }
    }
  }

  .policy-paragraph-item {
    margin-bottom: 0.8rem;
  }
`;
