import styled from 'styled-components';

interface IType {
  labelColor?: string;
}

export const UploadContainer = styled.div<IType>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .label {
    color: ${(props) =>
      !!props.labelColor
        ? props.labelColor
        : props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5rem;
    font-size: 1.6rem;
    user-select: none;
    user-select: none;
  }

  .input-box {
    position: relative;
  }

  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    cursor: pointer;

    position: absolute;
    top: 50%;
    right: 2.5rem;
    transform: translateY(-50%);

    svg {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    }
  }

  .input {
    width: 100%;
    height: 6rem;
    outline: 0;
    display: block;
    background: none;
    font-size: inherit;
    border: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    border-radius: 100px;
    padding: 1.6rem 6rem 1.6rem 2rem;
    font-family: inherit;
    background-color: ${(props) => props.theme.pallete.colors.common.white};
  }

  .textfield-error {
    color: ${(props) => props.theme.pallete.colors.error.main};
    padding: 0.4rem 2rem 0;
  }
`;
