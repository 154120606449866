import styled from 'styled-components';

export const ChoosePlanContainer = styled.div`
  padding-bottom: 6rem;
  background-image: linear-gradient(#fff 33.3%, transparent 0);
  position: relative;
  ${(props) => props.theme.mixins.containerGridDefault};

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 11rem;
  }

  .form-choose-plan {
    margin-top: 3rem;
    padding: 0 1.6rem;

    .footer-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 3rem;

      img {
        /* width: 150px; */
      }
    }

    .input-group .inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    }

    .input-group {
      margin-top: 2rem;
    }
  }

  .content {
    grid-column: center-start / center-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin: 0 auto 8rem;
    width: 100%;
  }

  .lamp-idea {
    background-image: url('/static/images/lamp-paper.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 18rem;
    height: 17rem;
  }

  .return-page {
    position: absolute;
    left: 3rem;
    top: 3rem;
    width: max-content;
    height: auto;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    ${(props) => props.theme.mixins.linkUnderlineEffect}
  }

  .return-page-text {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
  }

  .texts-box {
    margin-bottom: 3rem;
  }

  .heading-2 {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5.8rem;
    text-align: center;
    margin: 0;
  }

  .subtitle {
    margin: 0 auto;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-size: 1.6rem;
    line-height: 3rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    text-align: center;
  }

  .sign-in {
    line-height: 5rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    a {
      text-decoration: none;
    }

    .link {
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
      font-size: 1.6rem;
      position: relative;
      margin-left: 0.8rem;

      ${(props) => props.theme.mixins.linkUnderlineEffect};
    }
  }

  .plans-box-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
  }

  .plans-box {
    display: grid;
    /* gap: 3rem; */
    width: 100%;
  }

  .plans-paragraph {
    line-height: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    text-align: center;
  }

  .paragraph-span {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: currentColor;
    text-align: center;
  }

  .price-box {
    display: flex;
    justify-content: center;
    margin-top: auto;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[10]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    gap: 0.8rem;
  }

  .grid-plans {
    display: grid;
    grid-template-columns: repeat(auto-fit, 42rem);
    justify-content: center;
    gap: 3.2rem;
    margin-bottom: 6rem;
  }

  .card {
    background-color: ${(props) =>
      props.theme.pallete.colors.brandPrimary.gray[1]};
    border-radius: 2rem;
    box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    min-height: 30rem;

    border: 5px solid ${(props) => props.theme.pallete.colors.primary.main};
  }

  .card-text-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.6rem;
  }

  .card-title {
    text-transform: uppercase;
  }

  .card-text {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    text-align: center;
  }

  .card-list {
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    gap: 2rem;
  }

  .card-list-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .card-list-item-text {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .price-symbol {
    margin: auto 0;
  }

  .price {
    font-size: 5rem;
  }

  .button {
    width: 50%;
    margin: 3rem auto 0;
  }

  .payment-modal-heading-2 {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5.8rem;
    margin: 0;
    text-align: center;
  }

  .payment-modal-subtitle {
    width: 80%;
    margin: 0 auto;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-size: 1.6rem;
    line-height: 3rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    text-align: center;

    a {
      &,
      &:active,
      &:visited {
        text-decoration: none;
        color: ${(props) => props.theme.pallete.colors.common.black};
        position: relative;
        font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      }

      ${(props) => props.theme.mixins.linkUnderlineDefaultEffect}
    }
  }

  .payment-modal-icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.6rem;
  }

  .payment-modal-link {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    position: relative;

    ${(props) => props.theme.mixins.linkUnderlineEffect};
  }

  .payment-modal-inputs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.8rem;
    margin-top: 3rem;
    padding: 0 1.6rem;
  }

  .texts-group {
    display: flex;
    align-items: baseline;
    flex-direction: column;
  }

  .texts-label {
    font-size: 2rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[15]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    white-space: nowrap;
  }

  .texts-value {
    font-size: 1.8rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
  }

  .payment-error-container {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }

  .button-payment-error {
    margin: 3rem auto 0;
  }
`;
