import React from 'react';
import { ArrowProps } from 'react-multi-carousel';
import { MdArrowBackIos } from 'react-icons/md';
import { CustomArrowLeftContainer } from './styles';

const CustomLeftArrow: React.FC<ArrowProps> = ({ onClick, carouselState }) => {
  return (
    <CustomArrowLeftContainer className="arrow-left" onClick={onClick}>
      <MdArrowBackIos size={48} />
    </CustomArrowLeftContainer>
  );
};

export default CustomLeftArrow;
