import React, { useState, useContext } from 'react';
import Select from '../../../../../components/inputs/Select';
import { BiTrash } from 'react-icons/bi';
import { MemberItemContainer } from './styles';
import { IUser } from '../../../../../services/user.types';
import { updateUserService } from '../../../../../services/admin.service';
import ToastfyContext from '../../../../../contexts/ToastfyContext';
import AuthContext from '../../../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import LightBox from '../../../../../components/lightboxes/LightBox';
import Button from '../../../../../components/Button';
import { useTheme } from 'styled-components';

interface IProps {
  listUsers: IUser[];
  getListUser: () => Promise<void>;
}

interface IUpdateUser {
  role: string;
  user: IUser;
}

const MemberItem: React.FC<IProps> = ({ listUsers, getListUser }) => {
  const [deleteUser, setDeleteUser] = useState<IUser>({} as IUser);
  const [updateUser, setUpdateUser] = useState<IUpdateUser>({} as IUpdateUser);
  const [isConfirmDeleteUser, setIsConfirmDeleteUser] =
    useState<boolean>(false);
  const [isDeleteUserLoading, setIsDeleteUserLoading] =
    useState<boolean>(false);
  const [isUpdateRoleLoading, setIsUpdateRoleLoading] =
    useState<boolean>(false);
  const [isConfirmUpdateRoleUser, setIsConfirmUpdateRoleUser] =
    useState<boolean>(false);
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleDeleteUser = async () => {
    setIsDeleteUserLoading((prev) => !prev);
    await updateUserService({
      email: deleteUser.email,
      isActive: false,
      role: deleteUser.role,
    })
      .then((response) => {
        getListUser();
        setIsDeleteUserLoading((prev) => !prev);
        setIsConfirmDeleteUser((prev) => !prev);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsDeleteUserLoading((prev) => !prev);
      });
  };

  const handleUpdateUser = async () => {
    setIsUpdateRoleLoading((prev) => !prev);
    await updateUserService({
      email: updateUser.user.email,
      isActive: true,
      role: updateUser.role,
    })
      .then((response) => {
        setIsUpdateRoleLoading((prev) => !prev);
        setIsConfirmUpdateRoleUser((prev) => !prev);
        getListUser();
        handleToastfy({
          message: 'Usuário atualizado com sucesso!',
          type: 'success',
        });
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsUpdateRoleLoading((prev) => !prev);
      });
  };

  return (
    <MemberItemContainer>
      {listUsers.map((user) => {
        return (
          <div key={user.email} className="member-item">
            <div className="member-identity">
              <span className="member-identity-name">{user.username}</span>
              <span className="member-identity-email">{user.email}</span>
            </div>
            <div className="member-item-select">
              <Select
                textLabel=""
                options={[
                  {
                    optionName: 'Administrador',
                    optionValue: 'admin',
                  },
                  {
                    optionName: 'Moderador',
                    optionValue: 'moderator',
                  },
                  {
                    optionName: 'Usuário',
                    optionValue: 'user',
                  },
                ]}
                defaultValue={user.role}
                onChange={(event) => {
                  setUpdateUser({
                    role: event.target.value,
                    user: user,
                  });
                  setIsConfirmUpdateRoleUser((prev) => !prev);
                }}
              />
            </div>
            <div
              className="grid-member-icon-box"
              onClick={() => {
                setDeleteUser(user);
                setIsConfirmDeleteUser((prev) => !prev);
              }}
            >
              <BiTrash size={24} />
            </div>
          </div>
        );
      })}
      {isConfirmDeleteUser && (
        <LightBox
          handleOpenModal={() => setIsConfirmDeleteUser((prev) => !prev)}
          customLightboxWidth="60rem"
        >
          <h3 className="confirm-delete-heading-3">
            Deseja realmente excluir o usuário?
          </h3>
          <div className="confirm-delete-button-group">
            <Button
              buttonText="Não"
              variant="contained"
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
              buttonSize={160}
              onClick={() => setIsConfirmDeleteUser((prev) => !prev)}
            />
            <Button
              buttonText="Sim"
              variant="contained"
              buttonSize={160}
              onClick={() => handleDeleteUser()}
              isLoading={isDeleteUserLoading}
            />
          </div>
        </LightBox>
      )}
      {isConfirmUpdateRoleUser && (
        <LightBox
          handleOpenModal={() => setIsConfirmUpdateRoleUser((prev) => !prev)}
          customLightboxWidth="60rem"
        >
          <h3 className="confirm-delete-heading-3">
            Deseja atualizar o usuário?
          </h3>
          <div className="confirm-delete-button-group">
            <Button
              buttonText="Não"
              variant="contained"
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
              buttonSize={160}
              onClick={() => setIsConfirmUpdateRoleUser((prev) => !prev)}
            />
            <Button
              buttonText="Sim"
              variant="contained"
              buttonSize={160}
              onClick={() => handleUpdateUser()}
              isLoading={isUpdateRoleLoading}
            />
          </div>
        </LightBox>
      )}
    </MemberItemContainer>
  );
};

export default MemberItem;
