import React from 'react';
import styled from 'styled-components';

interface IProps {
  margin: string;
  lineHeight?: string;
  color?: string;
}

const SeparateLineContainer = styled.hr<IProps>`
  display: block;
  background: ${(props) =>
    !!props.color
      ? props.color
      : props.theme.pallete.colors.brandPrimary.gray[2]};
  width: 100%;
  margin: ${(props) => props.margin};
  height: ${(props) => (!!props.lineHeight ? props.lineHeight : '1px')};
`;

const SeparateLine: React.FC<IProps> = ({ margin, color, lineHeight }) => {
  return (
    <SeparateLineContainer
      margin={margin}
      color={color}
      lineHeight={lineHeight}
    />
  );
};

export default SeparateLine;
