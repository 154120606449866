import styled, { css, keyframes } from 'styled-components';

interface ISelectTimeAnswerProps {
  isActive: boolean;
}

const point = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const MessageUser = styled.div`
  display: flex;
  gap: 10px;
  align-self: self-end;

  div {
    margin-top: 2rem;
    border-radius: 16px 0px 16px 16px;
    background: rgb(255, 209, 0);

    padding: 11px 15px;
    display: flex;
    align-items: center;
    justify-content: start;
    max-width: 500px;
    cursor: pointer;

    p {
      white-space: pre-wrap;
    }

    @media ${(props) => props.theme.medias.tablet} {
      max-width: 225px;
    }
  }

  .brainstorming-avatar {
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    background-image: url('/static/images/profile.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;
export const MessageBot = styled.div`
  display: flex;
  gap: 10px;
  align-self: self-start;

  div {
    margin-top: 2rem;
    border-radius: 0px 16px 16px 16px;
    background: rgb(255, 209, 0);

    padding: 11px 15px;
    display: flex;
    align-items: center;
    max-width: 500px;
    justify-content: start;
    cursor: pointer;

    p {
      white-space: pre-wrap;
    }

    @media ${(props) => props.theme.medias.tablet} {
      max-width: 225px;
    }
  }

  .loading-answer {
    p {
      font-size: 2rem;
    }
    .point-1 {
      animation: ${point} 1s ease-in-out infinite;
      animation-delay: 4s;
    }
    .point-2 {
      animation: ${point} 1s ease-in-out infinite;
      animation-delay: 2s;
    }
    .point-3 {
      animation: ${point} 1s ease-in-out infinite;
      animation-delay: -1s;
    }
  }

  .brainstorming-avatar {
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
    background-image: url('/static/images/feedback_ia.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const ContainerChat = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 35rem;
  outline: 0;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: none;
  font-size: inherit;
  border: 1px solid
    ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
  border-radius: 10px 0 0 10px;
  margin: 2rem 0;
`;

export const ModalFeedback = styled.div`
  display: flex;
  flex-direction: column;

  .button-points-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .creative-trigger-ia-paragraph {
    line-height: 3.4rem;
  }

  .button-points-span-end {
    display: flex;
    align-self: flex-end;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .textarea {
    /* display: block;
    width: 100%; */
    overflow-y: auto;
    /* resize: both; */
    resize: vertical;
    /* min-height: 40px;
    line-height: 20px; */
  }

  .textarea[contenteditable]:empty::before {
    content: 'Escreva a sua mensagem...';
    color: gray;
  }

  /* .input, */
  .textarea {
    width: 100%;
    /* height: 6rem; */
    max-height: 15rem;
    outline: 0;
    display: block;
    background: none;
    font-size: inherit;
    border: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    border-radius: 15px;
    /* padding: '1.6rem 2rem' !important; */
    font-family: inherit;
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    padding-bottom: 1.6rem;
    padding-top: 1.6rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .input-label {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5rem;
  }

  .input-value {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    margin-right: 1.6rem;
    margin-left: 0.8rem;
  }

  .brainstorming-ia-form {
    display: flex;
    /* flex-direction: column; */
    align-items: start;
    justify-content: center;
    gap: 1rem;
  }

  .ia-form-input-control-label {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: 600;
    line-height: 5rem;
    font-size: 1.6rem;
    user-select: none;
  }

  .textfield-error {
    color: ${(props) => props.theme.pallete.colors.error.main};
  }

  .react-select {
    &__control {
      width: 100%;
      min-height: 6rem;
      outline: 0;
      background: none;
      font-size: inherit;
      border: 1px solid #d7d7d7;
      border-radius: 100px;
      font-family: inherit;
      background-color: #ffffff;
      cursor: pointer;
    }

    &__value-container {
      width: 100%;
      min-height: 6rem;
      outline: 0;
      background: none;
      font-size: inherit;
      font-family: inherit;
      padding-left: 2rem;
      cursor: pointer;
    }

    &__menu {
      top: auto;
      bottom: 100%;
      border-radius: 1rem;
      overflow: hidden;
    }
  }
`;

export const BrainstormingRoomsContainer = styled.div`
  ${(props) => props.theme.mixins.containerGridDefault}
  gap: 0;

  .buttons-box {
    display: flex;
    align-items: start;
    gap: 3.2rem;
    margin-top: 4rem;

    @media ${(props) => props.theme.medias.mobile} {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.2rem;
    }
    .button-points-box {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      .button-points-span {
        display: inline-block;
        margin-left: 1.6rem;
      }
    }
  }

  .brainstorming-room-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    grid-column: center-start / center-end;
  }

  .brainstorming-room {
    grid-column: full-start / full-end;
    padding: 9.6rem 0;
    min-height: 90vh;
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 3rem;

    ${(props) => props.theme.mixins.containerGridDefault}

    @media ${(props) => props.theme.medias.laptopSmall} {
      margin-top: 6rem;
    }
  }

  .brainstorming-room-content {
    display: flex;
    flex-direction: column;
    grid-column: center-start / center-end;
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    z-index: 1;
    border-radius: 2rem;
    padding: 4.8rem;
    gap: 4rem;
    box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};
  }

  .brainstorming-header-content {
    display: flex;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
    }
  }

  .header-content-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media ${(props) => props.theme.medias.laptopSmall} {
      align-items: center;
      justify-content: baseline;
    }
  }

  .header-content-right {
    width: 100%;
    min-height: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
    }

    @media ${(props) => props.theme.medias.laptopSmall} {
      width: 80%;
      margin: 0 auto;
    }

    @media ${(props) => props.theme.medias.mobile} {
      width: 100%;
      margin: 0 auto;
    }
  }

  .brainstorming-heading-2 {
    margin-bottom: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-size: 4rem;
    line-height: 5.8rem;
  }

  .header-content-left-button {
    margin: 2.4rem 0;
  }

  .brainstorming-paragraph {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    line-height: 2.8rem;
  }

  .brainstorming-room-content-search {
    display: flex;
    gap: 1.6rem;

    @media ${(props) => props.theme.medias.mobile} {
      flex-direction: column;
      align-items: center;
    }
  }

  .comment-messages-box {
    margin: 6rem 0 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media ${(props) => props.theme.medias.mobile} {
      gap: 6rem;
    }
  }

  .brainstorming-background-white {
    position: absolute;
    top: 0;
    left: 0;
    height: 50rem;
    width: 100%;
    z-index: -1;
    background-color: ${(props) => props.theme.pallete.colors.common.white};
  }

  .brainstorming-background-image {
    position: absolute;
    top: 50vh;
    left: 0;
    width: 100%;
    height: 40rem;
    background-image: linear-gradient(
        180.48deg,
        rgba(217, 217, 217, 0) -11.04%,
        #f3f3f3 66.96%
      ),
      url('/static/images/books.png');
    background-size: cover;
    background-position: 50% 0%;
    background-repeat: no-repeat;

    display: none;
  }

  .brainstorming-common-questions {
    grid-column: full-start / full-end;
  }

  .brainstorming-create-room {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .brainstorming-create-room-button {
    margin-left: auto;
    margin-bottom: 1.6rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      margin: 3rem auto 0.8rem;
    }
  }

  .button-load-more {
    background: transparent;
    outline: 0;
    border: none;
    margin: 0 auto;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[10]};
    padding: 1.2rem 2rem;
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};

    span {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[10]};
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    }

    cursor: pointer;
  }
`;

export const SelectTimeAnswer = styled.span<ISelectTimeAnswerProps>`
  min-height: 5rem;
  width: max-content;
  padding: 1.2rem 1.6rem;
  border-radius: 100px;
  border: 1px solid
    ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  color: ${(props) =>
    props.isActive
      ? props.theme.pallete.colors.common.white
      : props.theme.pallete.colors.brandPrimary.gray[7]};
  background: ${(props) =>
    props.isActive ? props.theme.pallete.colors.common.black : 'transparent'};
  letter-spacing: 1px;
  font-weight: ${(props) => props.theme.typeFaceWeight.medium};
  font-size: 1.2rem;
  transition: all 0.2 ease-in-out;
  position: relative;
  white-space: nowrap;
  user-select: none;

  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${(props) =>
      !props.isActive &&
      css`
        box-shadow: ${(props) => props.theme.pallete.shadows.default.n12};
      `}
  }
`;
