import React, { useContext } from 'react';
import { BsChevronRight, BsGear } from 'react-icons/bs';
import { RxExit } from 'react-icons/rx';
import { Link, useNavigate } from 'react-router-dom';
import SeparateLine from '../../../../../components/SeparateLine';
import Logo from '../../../../../components/svgs/Logo';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { SubMenuUserContainer } from './styles';
import Avatar from '../../../../../components/Avatar';

const SubMenuUser: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate('/sign-in');
  };

  return (
    <SubMenuUserContainer>
      <div className="dashboard-aside-card-header">
        {!!user?.avatar ? (
          <Avatar
            backgroundImageLink={`${process.env.REACT_APP_URL_IMAGE_BASE}/${user.avatar}`}
            height="8rem"
            width="8rem"
          />
        ) : (
          <span className="brainstorming-sub-menu-avatar" />
        )}
        <div className="dashboard-aside-card-paragraphs">
          <span className="dashboard-aside-card-username">
            {user?.username}
          </span>
          <span className="dashboard-aside-card-carrer">
            {user?.profession}
          </span>
        </div>
      </div>
      {user?.role === 'user' && (
        <React.Fragment>
          <div className="dashboard-aside-card-header-bottom">
            <span className="dashboard-aside-card-you-have">Você tem:</span>
            <Logo />
            <span className="dashboard-aside-card-credits">
              {!!user?.balance ? user?.balance : 0}&nbsp;créditos
            </span>
          </div>
          <SeparateLine margin="0" />
          <div className="dashboard-section-menu-item-box">
            <Link className="global-remove-style-link" to="/dashboard/perfil">
              <span className="dashboard-section-menu-current-plan">
                Plano atual
              </span>
              <div className="dashboard-section-menu-item">
                <span>
                  {user.currentPlan === 'free'
                    ? 'Plano Gratuito'
                    : user.currentPlan}
                </span>
                <BsGear size={24} />
              </div>
            </Link>
          </div>
          <SeparateLine margin="0" />
        </React.Fragment>
      )}
      <div className="dashboard-section-menu-item-box">
        <Link className="global-remove-style-link" to="/dashboard">
          <div className="dashboard-section-menu-item">
            <span>Dashboard</span>
            <BsChevronRight size={24} />
          </div>
        </Link>
      </div>
      <SeparateLine margin="0" />
      <div className="dashboard-section-menu-item-box">
        <Link className="global-remove-style-link" to="/dashboard/perfil">
          <div className="dashboard-section-menu-item">
            <span>Configurar conta</span>
            <BsChevronRight size={24} />
          </div>
        </Link>
      </div>
      <SeparateLine margin="0" />
      <div className="dashboard-section-menu-item-box dashboard-section-menu-exit">
        <div
          className="dashboard-section-menu-item"
          onClick={() => handleLogout()}
        >
          <span className="dashboard-section-menu-exit">Sair</span>
          <RxExit size={24} />
        </div>
      </div>
    </SubMenuUserContainer>
  );
};

export default SubMenuUser;
