import { api } from './api.axios';
import {
  ICreateCreativeTriggerResponse,
  IDeleteCreativeTriggerResponse,
  IGenerateCreativeTriggerRequest,
  IGenerateCreativeTriggerResponse,
  IGenerateIdeasIARequest,
  IGenerateIdeasIAResponse,
  IListCreativeTriggerParams,
  IListCreativeTriggerResponse,
  IShowCreativeTriggerResponse,
} from './creativeTriggers.types';

export const createCreativeTriggerService = async (
  formData: unknown
): Promise<ICreateCreativeTriggerResponse> => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const response = await api.post<ICreateCreativeTriggerResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/creative-triggers`,
    formData,
    config
  );

  return response.data;
};

export const updateCreativeTriggerService = async (
  formData: unknown,
  id: string
): Promise<ICreateCreativeTriggerResponse> => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const response = await api.put<ICreateCreativeTriggerResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/creative-triggers/${id}`,
    formData,
    config
  );

  return response.data;
};

export const listCreativeTriggerService = async ({
  order,
  orderBy,
  page,
  perPage,
}: IListCreativeTriggerParams): Promise<IListCreativeTriggerResponse> => {
  const response = await api.get<IListCreativeTriggerResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-triggers?perPage=${perPage}&page=${page}&orderBy=${orderBy}&order=${order}`
  );

  return response.data;
};

export const showCreativeTriggerService = async (
  id: string
): Promise<IShowCreativeTriggerResponse> => {
  const response = await api.get<IShowCreativeTriggerResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-triggers/${id}`
  );

  return response.data;
};

export const deleteCreativeTriggerService = async (
  id: string
): Promise<IDeleteCreativeTriggerResponse> => {
  const response = await api.delete<IDeleteCreativeTriggerResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/creative-triggers/${id}`
  );

  return response.data;
};

/**
 * USER
 */

export const generateCreativeTriggerService = async ({
  creativeTriggerId,
  id,
}: IGenerateCreativeTriggerRequest): Promise<IGenerateCreativeTriggerResponse> => {
  const response = await api.get<IGenerateCreativeTriggerResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-triggers/random?id=${id}&creativeTriggerId=${creativeTriggerId}`
  );

  return response.data;
};

/**
 * IA
 */

export const generateIdeasIAService = async ({
  atributes,
  brandDescription,
  brandName,
  creativeTriggerId,
  distributionChannels,
  goalIdeia,
  targetAudience,
  voiceTone,
}: IGenerateIdeasIARequest): Promise<IGenerateIdeasIAResponse> => {
  const response = await api.get<IGenerateIdeasIAResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-triggers/random/ia?brandName=${brandName}
    &brandDescription=${brandDescription}
    &targetAudience=A${targetAudience}
    &goalIdeia=${goalIdeia}
    atributes=${atributes}
    &voiceTone=${voiceTone}
    &distributionChannels=${distributionChannels}
    &creativeTriggerId=${creativeTriggerId}`
  );

  return response.data;
};

export const regenerateIdeasIAService = async (
  id: string
): Promise<IGenerateIdeasIAResponse> => {
  const response = await api.get<IGenerateIdeasIAResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-triggers/random/ia/regenerate?transactionId=${id}`
  );

  return response.data;
};
