import axios from 'axios';
import { api } from './api.axios';
import {
  ICreativeProjectsMessage,
  IGetCreativeProjectsResponse,
  IListCreativeProjectsRequest,
  IListCreativeProjectsResponse,
  IShowCreativeProjectResponse,
  IUpdateCreativeProjectRequest,
} from './creativeProjects.types';

export const createCreativeProjectService = async (
  formData: any
): Promise<ICreativeProjectsMessage> => {
  const response = await api.post<ICreativeProjectsMessage>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-projects`,
    formData
  );

  return response.data;
};

export const listCreativeProjectsService = async ({
  isActive,
  published,
}: IListCreativeProjectsRequest): Promise<IListCreativeProjectsResponse> => {
  const isActiveParameter = `isActive=${isActive}`;
  const publishedParameter = `&published=${published}`;

  const response = await api.get<IListCreativeProjectsResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-projects?${
      !!isActive ? isActiveParameter : ''
    }${!!published ? publishedParameter : ''}`
  );

  return response.data;
};

export const listGalleryProjectsService = async ({
  isActive,
  published,
}: IListCreativeProjectsRequest): Promise<IListCreativeProjectsResponse> => {
  const isActiveParameter = `isActive=${isActive}`;
  const publishedParameter = `&published=${published}`;

  const response = await api.get<IListCreativeProjectsResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-projects?${
      !!isActive ? isActiveParameter : ''
    }${!!published ? publishedParameter : ''}`
  );

  return response.data;
};

export const getGalleryProjectsService = async ({
  isActive,
  published,
}: IListCreativeProjectsRequest): Promise<IGetCreativeProjectsResponse> => {
  const isActiveParameter = `isActive=${isActive}`;
  const publishedParameter = `&published=${published}`;

  const response = await axios.get<IGetCreativeProjectsResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-projects/gallery?${
      !!isActive ? isActiveParameter : ''
    }${!!published ? publishedParameter : ''}`
  );

  return response.data;
};

export const showCreativeProjectService = async (
  id: string
): Promise<IShowCreativeProjectResponse> => {
  const response = await api.get<IShowCreativeProjectResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-projects/${id}`
  );

  return response.data;
};

export const updateCreativeProjectService = async ({
  id,
  isActive,
  published,
}: IUpdateCreativeProjectRequest): Promise<ICreativeProjectsMessage> => {
  const response = await api.put<ICreativeProjectsMessage>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/creative-projects/${id}`,
    {
      isActive,
      published,
    }
  );

  return response.data;
};

export const deleteCreativeProjectService = async (
  id: string
): Promise<ICreativeProjectsMessage> => {
  const response = await api.delete<ICreativeProjectsMessage>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/creative-projects/${id}`
  );

  return response.data;
};
