import styled from 'styled-components';

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1140rem;
  margin: 0 auto 8rem;
  padding: 0 6rem;

  .background-dashboard {
    background-image: linear-gradient(#fff 50%, transparent 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  .heading-box {
    margin-top: 8rem;
    margin-bottom: 8rem;
    text-align: center;
  }

  .heading-2 {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
    line-height: 5rem;
  }

  .sub-title {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[5]};
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
  }

  .card-grids {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    margin-bottom: 6rem;
  }

  .card {
    width: 100%;
    width: 30rem;
    background-color: ${(props) =>
      props.theme.pallete.colors.brandPrimary.gray[9]};
    border-radius: 2rem;
    padding: 5.4rem 3rem;
    box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .heading-3 {
    color: ${(props) => props.theme.pallete.colors.common.white};
  }

  .image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 12rem;
    height: 12rem;

    &--1 {
      background-image: url('/static/images/lamp-white.png');
    }

    &--2 {
      background-image: url('/static/images/gear-configuration.png');
    }
  }

  .span-boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
  }

  .link {
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .link-text {
    color: ${(props) => props.theme.pallete.colors.common.white};
    border-bottom: 1px solid
      ${(props) => props.theme.pallete.colors.common.white};
    width: max-content;
  }
`;
