import React, { useState, HTMLAttributes, useEffect } from 'react';
import { MenuMobileContainer } from './styles';
import { useLocation } from 'react-router-dom';

const MenuMobile: React.FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsChecked(false);
  }, [pathname]);

  useEffect(() => {
    isChecked
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [isChecked]);

  return (
    <MenuMobileContainer {...props}>
      <input
        className="menu-mobile-checkbox"
        type="checkbox"
        checked={isChecked}
        readOnly
      />
      <label
        className="menu-mobile-button"
        onClick={() => setIsChecked((prev) => !prev)}
      >
        <span className="menu-mobile-icon">&nbsp;</span>
      </label>
      <nav className="nav-mobile">
        <div className="menu-mobile">{props.children}</div>
      </nav>
    </MenuMobileContainer>
  );
};

export default MenuMobile;
