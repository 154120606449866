import styled from 'styled-components';

export const DashboardConfigCardContainer = styled.div`
  grid-column: center-start / center-end;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.pallete.colors.common.white};
  box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};
  border: 2px solid
    ${(props) => props.theme.pallete.colors.brandPrimary.gray[16]};
  border-radius: 2rem;
  width: 100%;
  max-width: 140rem;
  margin: 6rem auto;
  padding: 6rem;

  @media ${(props) => props.theme.medias.laptopSmall} {
    padding: 6rem 3rem;
  }

  .heading-2 {
    margin-bottom: 5.6rem;
  }
`;
