import styled from 'styled-components';

export const LightboxCategoryMarketContainer = styled.div`
  h3 {
    font-size: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
  }

  form {
    display: grid;
    grid-template-columns: repeat(
      12,
      [col-start] minmax(min-content, 9.6rem) [col-end]
    );
    column-gap: 1.6rem;
    row-gap: 3rem;

    > :nth-child(1) {
      grid-column: col-start 1 / col-end 6;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(2) {
      grid-column: col-start 7 / col-end 12;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(3) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(4) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(5) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(6) {
      grid-column: col-start 1 / col-end 12;
    }
  }

  .form-paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .lightbox-category-market-button {
    margin-left: auto;

    @media ${(props) => props.theme.medias.laptopSmall} {
      margin-right: auto;
    }
  }
`;
