import { api } from './api.axios';
import {
  ICreateMarketSegmentRequest,
  IListMarketSegment,
  IListMarketSegmentRequest,
  IMarketSegmentMessageResponse,
  IShowMarketSegment,
  IUpdateMarketSegmentRequest,
} from './marketSegments.types';

export const showMarketSegmentService = async (
  id: string
): Promise<IShowMarketSegment> => {
  const response = await api.get<IShowMarketSegment>(
    `${process.env.REACT_APP_URL_API_BASE}/market-segments/${id}`
  );

  return response.data;
};

export const listMarketSegmentService = async ({
  order,
  isActive,
  orderBy,
  page,
  perPage,
}: IListMarketSegmentRequest): Promise<IListMarketSegment> => {
  const orderParameter = `order=${order}`;
  const isActiveParameter = `&isActive=${isActive || undefined}`;
  const orderByParameter = `&orderBy=${orderBy}`;
  const pageParameter = `&page=${page || undefined}`;
  const perPageParameter = `&perPage=${perPage || undefined}`;

  const response = await api.get<IListMarketSegment>(
    `${
      process.env.REACT_APP_URL_API_BASE
    }/market-segments?${orderParameter}${orderByParameter}${
      typeof isActive === 'boolean' ? isActiveParameter : ''
    }${!!page ? pageParameter : ''}${!!perPage ? perPageParameter : ''}`
  );

  return response.data;
};

export const createMarketSegmentService = async ({
  description,
  label,
  textIa,
}: ICreateMarketSegmentRequest): Promise<IMarketSegmentMessageResponse> => {
  const sendData = {
    description,
    label,
    textIa,
  };
  const response = await api.post<IMarketSegmentMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/market-segments`,
    sendData
  );

  return response.data;
};

export const updateMarketSegmentService = async ({
  description,
  label,
  id,
  isActive,
  textIa,
}: IUpdateMarketSegmentRequest): Promise<IMarketSegmentMessageResponse> => {
  const sendParametersData = {
    description,
    isActive,
    label,
    textIa,
  };
  const response = await api.put<IMarketSegmentMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/market-segments/${id}`,
    sendParametersData
  );

  return response.data;
};

export const deleteMarketSegmentService = async (
  id: string
): Promise<IMarketSegmentMessageResponse> => {
  const response = await api.delete<IMarketSegmentMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/market-segments/${id}`
  );

  return response.data;
};
