import React from 'react';
import { DotProps } from 'react-multi-carousel';
import { Dot } from '../styles';

const CustomDots: React.FC<DotProps> = ({
  carouselState,
  active,
  index,
  onClick,
}) => {
  return (
    <div className="dots-box" onClick={onClick}>
      <Dot className="dot" active={Boolean(active)}></Dot>
    </div>
  );
};

export default CustomDots;
