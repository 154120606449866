import { PagSeguro } from './pagseguro';

interface ICardData {
  holder: string;
  number: string;
  expMonth: string;
  expYear: string;
  securityCode: string;
}

export function encryptCardPagSeguro(cardData: ICardData) {
  const PagSeguroAny: any = PagSeguro;
  const card = PagSeguroAny.encryptCard({
    publicKey: process.env.REACT_APP_TOKEN_PUBLIC_KEY_PAGBANK,
    holder: cardData.holder,
    number: cardData.number,
    expMonth: cardData.expMonth,
    expYear: cardData.expYear,
    securityCode: cardData.securityCode,
  });

  return card;
}

export interface IErrorPagBank {
  code: string;
  message: string;
}
