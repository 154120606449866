import { api } from './api.axios';
import {
  ICreateCreativeChallengeTagRequest,
  IListCreativeChallengeTag,
  IListCreativeChallengeTagRequest,
  ICreativeChallengeTagMessageResponse,
  IShowCreativeChallengeTag,
  IUpdateCreativeChallengeTagRequest,
} from './creativeChallengeTag.types';

export const showCreativeChallengeTagService = async (
  id: string
): Promise<IShowCreativeChallengeTag> => {
  const response = await api.get<IShowCreativeChallengeTag>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-challenge-tag/${id}`
  );

  return response.data;
};

export const listCreativeChallengeTagService = async ({
  order,
  isActive,
  orderBy,
  page,
  perPage,
}: IListCreativeChallengeTagRequest): Promise<IListCreativeChallengeTag> => {
  const orderParameter = `order=${order}`;
  const isActiveParameter = `&isActive=${isActive || undefined}`;
  const orderByParameter = `&orderBy=${orderBy}`;
  const pageParameter = `&page=${page || undefined}`;
  const perPageParameter = `&perPage=${perPage || undefined}`;

  const response = await api.get<IListCreativeChallengeTag>(
    `${
      process.env.REACT_APP_URL_API_BASE
    }/creative-challenge-tag?${orderParameter}${orderByParameter}${
      typeof isActive === 'boolean' ? isActiveParameter : ''
    }${!!page ? pageParameter : ''}${!!perPage ? perPageParameter : ''}`
  );

  return response.data;
};

export const createCreativeChallengeTagService = async ({
  label,
  description,
}: ICreateCreativeChallengeTagRequest): Promise<ICreativeChallengeTagMessageResponse> => {
  const sendData = {
    label,
    description,
  };
  const response = await api.post<ICreativeChallengeTagMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/creative-challenge-tag`,
    sendData
  );

  return response.data;
};

export const updateCreativeChallengeTagService = async ({
  label,
  description,
  id,
  isActive,
}: IUpdateCreativeChallengeTagRequest): Promise<ICreativeChallengeTagMessageResponse> => {
  const sendParametersData = {
    label,
    description,
    isActive,
    id,
  };
  const response = await api.put<ICreativeChallengeTagMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/creative-challenge-tag/${id}`,
    sendParametersData
  );

  return response.data;
};

export const deleteCreativeChallengeTagService = async (
  id: string
): Promise<ICreativeChallengeTagMessageResponse> => {
  const response = await api.delete<ICreativeChallengeTagMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/creative-challenge-tag/${id}`
  );

  return response.data;
};
