import nprogress from 'nprogress';
import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HeaderFooterLayout from './pages/templates/HeaderFooterLayout';
//Pages
import AuthContext from './contexts/AuthContext';
import BrainstormingRooms from './pages/BrainstormingRooms';
import Room from './pages/BrainstormingRooms/Room';
import Cookies from './pages/Cookies';
import CreativeChallenges from './pages/CreativeChallenges';
import CreativeTriggers from './pages/CreativeTriggers';
import EmailValidation from './pages/EmailValidation';
import Error from './pages/Error';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import PolicyPrivacy from './pages/PolicyPrivacy';
import ResetPassword from './pages/ResetPassword';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import SuccessPayment from './pages/SuccessPayment';
import Terms from './pages/Terms';
import ThermometerIdeas from './pages/ThermometerIdeas';
import UnderDevelopment from './pages/UnderDevelopment';
import BrainstormingRoomPosts from './pages/dashboard/admin/BrainstormingRoomPosts';
import BrainstormingRoomsList from './pages/dashboard/admin/BrainstormingRoomsList';
import CreateCreativeTrigger from './pages/dashboard/admin/CreateCreativeTrigger';
import CreateNewContent from './pages/dashboard/admin/CreateNewContent';
import CreativeTriggerList from './pages/dashboard/admin/CreativeTriggerList';
import DashboardAdmin from './pages/dashboard/admin/Dashboard';
import EditCreativeTrigger from './pages/dashboard/admin/EditCreativeTrigger';
import EditMarketCategory from './pages/dashboard/admin/EditMarketCategory';
import MarketCategories from './pages/dashboard/admin/MarketCategories';
import Password from './pages/dashboard/admin/Password';
import Profile from './pages/dashboard/admin/Profile';
import ReadCreativeTrigger from './pages/dashboard/admin/ReadCreativeTrigger';
import SeeEditContents from './pages/dashboard/admin/SeeEditContents';
import StaffMembers from './pages/dashboard/admin/StaffMembers';
import UsersRegistereds from './pages/dashboard/admin/UsersRegistereds';
import DashboardUser from './pages/dashboard/user/Dashboard';
import CreateContentsLayout from './pages/templates/admin/CreateContentsLayout';
import DashboardLayout from './pages/templates/admin/DashboardLayout';
import PrivateRoutes from './routes/PrivateRoutes';
import CreateCreativeChallenge from './pages/dashboard/admin/CreateCreativeChallenge';
import CreativeChallengeList from './pages/dashboard/admin/CreativeChallengeList';
import EditCreativeChallenge from './pages/dashboard/admin/EditCreativeChallenge';
import ReadCreativeChallenge from './pages/dashboard/admin/ReadCreativeChallenge';

type IRoles = 'admin' | 'user' | 'moderator' | 'colunist';

const MainRoutes: React.FC = () => {
  const location = useLocation();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    nprogress.configure({ showSpinner: false });
    nprogress.start();
    nprogress.done();
    window.scrollTo(0, 0);
    document.body.scrollTo(0, 0);
  }, [location.pathname]);

  const userRoutes = (role: IRoles) => {
    switch (role) {
      case 'admin':
        return (
          <Route path="dashboard" element={<PrivateRoutes />}>
            <Route element={<DashboardLayout />}>
              <Route index element={<DashboardAdmin />} />
              <Route path="membros-da-equipe" element={<StaffMembers />} />
              <Route
                path="usuarios-cadastrados"
                element={<UsersRegistereds />}
              />
              <Route path="membros-da-equipe" element={<StaffMembers />} />
              <Route path="perfil" element={<Profile />} />
              <Route path="senha" element={<Password />} />
            </Route>
            <Route element={<CreateContentsLayout />}>
              <Route
                path="ver-editar-conteudos"
                element={<SeeEditContents />}
              />
              <Route
                path="gatilhos-criativos"
                element={<CreativeTriggerList />}
              />
              <Route
                path="salas-de-brainstorming"
                element={<BrainstormingRoomsList />}
              />
              <Route
                path="desafio-criativista"
                element={<CreativeChallengeList />}
              />
              <Route
                path="criar-desafio-criativista"
                element={<CreateCreativeChallenge />}
              />
              <Route
                path="editar-desafio-criativista"
                element={<EditCreativeChallenge />}
              />
              <Route
                path="ver-desafio-criativista"
                element={<ReadCreativeChallenge />}
              />
              <Route
                path="salas-de-brainstorming/postagens"
                element={<BrainstormingRoomPosts />}
              />
              <Route
                path="criar-novo-conteudo"
                element={<CreateNewContent />}
              />
              <Route
                path="criar-gatilho-criativo"
                element={<CreateCreativeTrigger />}
              />
              <Route
                path="categorias-de-mercado"
                element={<MarketCategories />}
              />
              <Route
                path="categoria-de-mercado"
                element={<EditMarketCategory />}
              />
              <Route
                path="ver-conteudo-gatilho-criativo"
                element={<ReadCreativeTrigger />}
              />
              <Route
                path="editar-gatilho-criativo"
                element={<EditCreativeTrigger />}
              />
            </Route>
          </Route>
        );
      case 'moderator':
        return (
          <Route path="dashboard" element={<PrivateRoutes />}>
            <Route element={<DashboardLayout />}>
              <Route index element={<DashboardAdmin />} />
              <Route path="perfil" element={<Profile />} />
              <Route path="senha" element={<Password />} />
            </Route>
            <Route element={<CreateContentsLayout />}>
              <Route
                path="salas-de-brainstorming"
                element={<BrainstormingRoomsList />}
              />
              <Route
                path="salas-de-brainstorming/postagens"
                element={<BrainstormingRoomPosts />}
              />
            </Route>
          </Route>
        );
      case 'user':
        return (
          <Route path="dashboard" element={<PrivateRoutes />}>
            <Route element={<HeaderFooterLayout />}>
              <Route index element={<DashboardUser />} />
              <Route path="perfil" element={<Profile />} />
              <Route path="password" element={<Password />} />
            </Route>
          </Route>
        );
      default:
        return (
          <Route path="dashboard" element={<PrivateRoutes />}>
            <Route path="membros-da-equipe" />
            <Route path="usuarios-cadastrados" />
            <Route path="membros-da-equipe" />
            <Route path="perfil" />
            <Route path="senha" />
            <Route path="ver-editar-conteudos" />
            <Route path="gatilhos-criativos" />
            <Route path="salas-de-brainstorming" />
            <Route path="salas-de-brainstorming/postagens" />
            <Route path="criar-novo-conteudo" />
            <Route path="criar-gatilho-criativo" />
            <Route path="criar-desafio-criativista" />
            <Route path="categorias-de-mercado" />
            <Route path="categoria-de-mercado" />
            <Route path="ver-conteudo-gatilho-criativo" />
            <Route path="editar-gatilho-criativo" />
            <Route path="termometro-de-ideias" />
          </Route>
        );
    }
  };

  return (
    <Routes>
      <Route path="/" element={<HeaderFooterLayout />}>
        <Route index element={<Home />} />
        <Route path="gatilhos-criativos" element={<CreativeTriggers />} />
        <Route path="desafios-criativistas" element={<CreativeChallenges />} />
        <Route path="termometro-de-ideias" element={<ThermometerIdeas />} />
        <Route path="salas-de-brainstorming" element={<BrainstormingRooms />} />
        <Route path="em-desenvolvimento" element={<UnderDevelopment />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="email-verification" element={<EmailValidation />} />
        <Route path="obrigado" element={<SuccessPayment />} />
        <Route path="esqueci-minha-senha" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="politica-de-privacidade" element={<PolicyPrivacy />} />
        <Route path="termos-de-uso" element={<Terms />} />
        <Route path="politica-de-cookies" element={<Cookies />} />
      </Route>
      {/**
       * For Authenticated pages off the dashboard
       */}
      <Route path="/" element={<PrivateRoutes />}>
        <Route element={<HeaderFooterLayout />}>
          <Route path="salas-de-brainstorming/sala" element={<Room />} />
        </Route>
      </Route>
      {/**************************************************************/}
      {userRoutes(user?.role.toString() as IRoles)}
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default MainRoutes;
