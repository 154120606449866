import styled from 'styled-components';

export const LoadingScreenContainer = styled.div`
  background: ${(props) => props.theme.pallete.colors.common.white};
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  justify-content: center;

  svg {
    width: 20rem;
    height: 20rem;
  }
`;
