import React, { createContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../components/Button';

interface ITermsAndPolicyPopUp {
  isHidePopUp: boolean;
}

const TermsAndPolicyPopUpContainer = styled.div<ITermsAndPolicyPopUp>`
  width: 100%;
  max-width: 90rem;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0.8rem;
  background: ${(props) => props.theme.pallete.colors.common.white};
  padding: 3rem;
  border-radius: 2rem;
  box-shadow: ${(propss) => propss.theme.pallete.shadows.default.n12};
  z-index: 100;

  display: ${(props) => (props.isHidePopUp ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  gap: 3rem;

  .terms-and-policy-heading-3 {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 1.6rem;

    img {
      max-width: 3rem;
      height: 3rem;
    }
  }

  .terms-and-policy-grid {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;

    a {
      &,
      &:active,
      &:visited {
        text-decoration: none;
        color: ${(props) => props.theme.pallete.colors.common.black};
        position: relative;
        font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};

        ${(props) => props.theme.mixins.linkUnderlineDefaultEffect}
      }
    }
  }

  .terms-and-policy-button {
    margin-left: auto;
  }
`;

interface IProps {
  children: React.ReactNode;
}

interface ITermsAndPolicyPopUpContext {}

export const TermsAndPolicyPopUpContext =
  createContext<ITermsAndPolicyPopUpContext>({} as ITermsAndPolicyPopUpContext);

export const TermsAndPolicyPopUpProvider: React.FC<IProps> = ({ children }) => {
  const [isHidePopUp, setisHidePopUp] = useState<boolean>(true);
  const localStoragePopUpName = '@Creative:hideTermsAndPolicy';

  const handleRemovePopUp = () => {
    localStorage.setItem(localStoragePopUpName, 'true');
    setisHidePopUp(true);
  };

  useEffect(() => {
    (async () => {
      try {
        const localStoragePop = localStorage.getItem(localStoragePopUpName);
        localStoragePop === 'true'
          ? setisHidePopUp(true)
          : setisHidePopUp(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <TermsAndPolicyPopUpContext.Provider value={{}}>
      <TermsAndPolicyPopUpContainer isHidePopUp={isHidePopUp}>
        <h3 className="terms-and-policy-heading-3">
          <img src="/static/images/cookie.png" alt="cookie" />
          Cookies com gotas de privacidade
        </h3>
        <p>
          Valorizamos a sua privacidade e usamos cookies (não, não os de
          chocolate, infelizmente) para garantir que você tenha a melhor
          experiência possível em nosso site. Eles nos ajudam a lembrar de você
          e do seu gosto incrível por criatividade. Ao continuar navegando, você
          aceita nossas políticas e termos de uso.
        </p>
        <div className="terms-and-policy-grid">
          <Link to="/termos-de-uso" target="_blank">
            Termos de uso
          </Link>
          <Link to="politica-de-privacidade" target="_blank">
            Política de privacidade
          </Link>
          <Link to="politica-de-cookies" target="_blank">
            Política de cookies
          </Link>
        </div>
        <Button
          buttonText="Fechar"
          variant="contained"
          className="terms-and-policy-button"
          buttonSize={200}
          onClick={handleRemovePopUp}
        />
      </TermsAndPolicyPopUpContainer>
      {children}
    </TermsAndPolicyPopUpContext.Provider>
  );
};

export default TermsAndPolicyPopUpContext;
