import React from 'react';
import { FooterStyled } from './styles';
//Components
import LogoFull from '../../../components/svgs/LogoFull';
import MercadoPagoSVG from '../../../components/svgs/payments/MercadoPagoSVG';
import BoletoSVG from '../../../components/svgs/payments/BoletoSVG';
import MastercardSVG from '../../../components/svgs/payments/MastercardSVG';
import AmericanExpressSVG from '../../../components/svgs/payments/AmericanExpressSVG';
import PixSVG from '../../../components/svgs/payments/PixSVG';
import VisaSVG from '../../../components/svgs/payments/VisaSVG';
import DinersClubSVG from '../../../components/svgs/payments/DinersClubSVG';
//Icons
import { AiOutlineInstagram } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  return (
    <FooterStyled>
      <div className="content">
        <div className="logo-column">
          <Link className="menu-item-link" to="/">
            <div className="logo">
              <LogoFull />
            </div>
          </Link>
          <div className="social-media-container">
            <Link
              className="link"
              to="https://www.instagram.com/vemsercriativista/"
              target="_blank"
            >
              <span className="icon-box">
                <AiOutlineInstagram size={24} />
                Instagram
              </span>
            </Link>
          </div>
          <span className="staff-creators">
            Criado por <strong>Alice Ferreira</strong>
          </span>
          <span className="staff-creators">
            Desenhado por <strong>Luvit Design</strong>
          </span>
          <span className="staff-creators">
            Desenvolvido por <strong>Duotech</strong>
          </span>
        </div>
        <nav className="navigation-column">
          <span className="navigation-column-heading">Ferramentas</span>
          <Link className="link" to="/gatilhos-criativos">
            <span className="navigation-column-heading-item">
              Gatilhos Criativos
            </span>
          </Link>
          <Link className="link" to="/salas-de-brainstorming">
            <span className="navigation-column-heading-item">
              Salas de Brainstorming
            </span>
          </Link>
          <Link className="link" to="/desafios-criativistas">
            <span className="navigation-column-heading-item">
              Desafios Criativistas
            </span>
          </Link>
          <Link className="link" to="/termometro-de-ideias">
            <span className="navigation-column-heading-item">
              Termômetro de Ideias
            </span>
          </Link>
        </nav>
        <div className="payments-column">
          <span className="paid-market">
            <img
              src="/static/images/payments/pagseguro_footer.jpg"
              alt="Logotipos de meios de pagamento do PagSeguro"
              width={300}
              // title="Este site aceita pagamentos com as principais bandeiras e bancos, saldo em conta PagSeguro e boleto."
            />
          </span>
          {/* <div className="payments-container">
            <span>
              <PixSVG height={50} width={50} />
            </span>
            <span>
              <VisaSVG height={50} width={50} />
            </span>
            <span>
              <MastercardSVG height={50} width={50} />
            </span>
            <span>
              <AmericanExpressSVG height={50} width={50} />
            </span>
            <span>
              <DinersClubSVG height={50} width={50} />
            </span>
            <span>
              <BoletoSVG height={60} width={60} />
            </span>
          </div> */}
          <nav className="navigation-feedback">
            <span className="navigation-column-heading">
              Dúvidas e feedbacks
            </span>
            <span>
              Email:&nbsp;
              <Link
                className="link-email"
                to="mailto:vemsercriativista@gmail.com"
              >
                vemsercriativista@gmail.com
              </Link>
            </span>
          </nav>
          <span className="terms">
            <Link className="link" to="/politica-de-privacidade">
              <span>Proteção de dados</span>
            </Link>
            <span>|</span>
            <Link className="link" to="/termos-de-uso">
              <span>Termos e Condições</span>
            </Link>
          </span>
        </div>
      </div>
      <div className="copyright-column">
        <span>Todos os direitos reservados.</span>
        <span>Copyright {year}&nbsp;&copy; Criativistas</span>
      </div>
    </FooterStyled>
  );
};

export default Footer;
