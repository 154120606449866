import React, { Dispatch, SetStateAction } from 'react';
import { ConfirmLightBoxContainer } from './styles';
import LightBox from '../LightBox';
import Button from '../../Button';
import { useTheme } from 'styled-components';

interface IProps {
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  buttonCancelLabel: string;
  buttonConfirmlLabel: string;
  buttonConfirmFunction: () => void;
  isLoading: boolean;
  title: string;
}

const ConfirmLightBox: React.FC<IProps> = ({
  setIsOpenModal,
  buttonCancelLabel,
  buttonConfirmFunction,
  buttonConfirmlLabel,
  isLoading,
  title,
}) => {
  const theme = useTheme();

  return (
    <ConfirmLightBoxContainer>
      <LightBox
        handleOpenModal={() => setIsOpenModal((prev) => !prev)}
        customLightboxWidth="60rem"
        className="lightbox-zindex"
        style={{ zIndex: 3000 }}
      >
        <h3 className="confirm-title-h3">{title}</h3>
        <div className="confirm-button-group">
          <Button
            buttonText="Não"
            variant="contained"
            customColors={{
              backgroundColor: theme.pallete.colors.common.black,
              hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
              hoverTextColor: theme.pallete.colors.common.white,
              textColor: theme.pallete.colors.common.white,
            }}
            buttonSize={160}
            onClick={() => setIsOpenModal((prev) => !prev)}
          />
          <Button
            buttonText="Sim"
            variant="contained"
            buttonSize={160}
            onClick={() => buttonConfirmFunction()}
            isLoading={isLoading}
          />
        </div>
      </LightBox>
    </ConfirmLightBoxContainer>
  );
};

export default ConfirmLightBox;
