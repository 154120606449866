import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import { getLuminance } from 'polished';

export interface IInsightFirstCardProps {
  backgroundColor: string;
}

const mobileForm = css`
  @media ${(props) => props.theme.medias.mobile} {
    grid-column: col-start 1 / col-end 12;
  }
`;

const color = (color: string, props: DefaultTheme) => {
  return getLuminance(color) > 0.4
    ? props.pallete.colors.common.black
    : props.pallete.colors.common.white;
};

export const CreativeTriggersStyled = styled.div`
  .creative-triggers-header {
    position: relative;
    padding: 9.6rem 0;
    background-color: ${(props) => props.theme.pallete.colors.common.white};

    @media ${(props) => props.theme.medias.laptopSmall} {
      margin-top: 6rem;
      padding-bottom: 0;
    }

    ${(props) => props.theme.mixins.containerGridDefault}

    .creative-trigger-spinner {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      min-height: 50vh;
      grid-column: center-start / center-end;
    }

    .creative-triggers-header-left {
      grid-column: center-start / col-end 6;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 3rem;
      gap: 3rem;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: center-start / center-end;
        align-items: center;
        margin-bottom: 3rem;
      }

      .creative-triggers-heading-2 {
        font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
        font-size: 3.6rem;
        line-height: 5.8rem;
        color: ${(props) => props.theme.pallete.colors.common.black};
      }

      .creative-triggers-sub-title {
        font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
        font-size: 2rem;
        line-height: 150%;
        color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};

        @media ${(props) => props.theme.medias.mobile} {
          text-align: center;
        }
      }

      .header-left-button-group {
        display: flex;
        align-items: baseline;
        gap: 0.8rem;
        justify-content: space-between;
        flex-wrap: wrap;

        @media ${(props) => props.theme.medias.mobile} {
          justify-content: center;
          gap: 2.4rem;
        }
      }

      .button-points-box {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        .button-points-span {
          display: inline-block;
          margin-left: 1.6rem;
        }
      }
    }

    .creative-triggers-header-right {
      grid-column: col-start 7 / center-end;

      > * {
        @media ${(props) => props.theme.medias.laptopSmall} {
          display: none;
        }
      }

      &--card-reveal {
        display: flex;
        width: 40rem;
        margin-left: auto;
      }

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: center-start / center-end;
      }

      .header-right-image {
        background: url('/static/images/criativista-card.png');
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
      }

      .creative-triggers-card {
        margin-left: auto;
        width: 100%;
        max-width: 40rem;
        border-radius: 2rem;
        background-color: ${(props) =>
          props.theme.pallete.colors.brandPrimary.yellow[8]};
        padding: 4.8rem;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        box-shadow: ${(props) => props.theme.pallete.shadows.default.n4};

        @media ${(props) => props.theme.medias.laptopSmall} {
          margin: 0 auto;
        }
      }

      .creative-triggers-title {
        font-weight: ${(props) => props.theme.typeFaceWeight.bold};
        font-size: 2rem;
        line-height: 2.4rem;
        color: ${(props) => props.theme.pallete.colors.common.black};
      }

      .creative-triggers-card-heading-3 {
        font-weight: ${(props) => props.theme.typeFaceWeight.regular};
        font-size: 3.2rem;
        line-height: 3.9rem;
        color: ${(props) => props.theme.pallete.colors.common.black};
      }

      .creative-triggers-card-paragraph {
        font-weight: ${(props) => props.theme.typeFaceWeight.regular};
        color: ${(props) => props.theme.pallete.colors.common.black};
        font-size: 1.6rem;
        line-height: 2.2rem;
      }

      .creative-triggers-card-paragraph-span {
        display: block;
        font-weight: ${(props) => props.theme.typeFaceWeight.bold};
        margin-bottom: 1.6rem;
        font-size: 1.6rem;
      }
    }

    .social-media-grid {
      position: absolute;
      top: 8rem;
      left: 3rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;

      .social-media-grid-item {
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }

      .social-media-grid-item-outer {
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        position: relative;
        background-color: ${(props) =>
          props.theme.pallete.colors.brandPrimary.gray[4]};
      }

      .social-media-grid-item-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4rem;
        width: 4rem;
        padding: 0.8rem;
        border-radius: 50%;
        background-color: ${(props) => props.theme.pallete.colors.common.white};
        ${(props) => props.theme.mixins.absoluteCenter}

        svg {
          color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[4]};
        }
      }

      @media ${(props) => props.theme.medias.mobile} {
        display: none;
      }
    }
  }

  .insights-container {
    position: relative;
    padding: 9.6rem 0;

    ${(props) => props.theme.mixins.containerGridDefault}

    @media ${(props) => props.theme.medias.laptopSmall} {
      padding: 6rem 0;
    }

    @media ${(props) => props.theme.medias.mobile} {
      padding: 3rem;
    }

    .insights-content {
      grid-column: center-start / full-end;

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: full-start / full-end;
      }

      .insights-content-heading-2 {
        padding-right: 12rem;

        @media ${(props) => props.theme.medias.laptopSmall} {
          text-align: center;
        }

        @media ${(props) => props.theme.medias.mobile} {
          padding-right: 0;
        }
      }

      .insights-content-paragraph {
        padding-right: 12rem;

        @media ${(props) => props.theme.medias.laptopSmall} {
          text-align: center;
        }

        @media ${(props) => props.theme.medias.mobile} {
          padding-right: 0;
        }
      }

      .insights-cards-container {
        padding-top: 6rem;

        ul {
          padding: 0.8rem 0;
        }

        ul li {
          padding-right: 3rem;

          @media ${(props) => props.theme.medias.mobile} {
            padding-right: 0;
          }
        }

        .creative-trigger-spinner {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          min-height: 12rem;
          margin-top: 12rem;
          margin-right: 12rem;
          grid-column: center-start / center-end;

          @media ${(props) => props.theme.medias.laptopSmall} {
            margin-right: 6rem;
          }

          @media ${(props) => props.theme.medias.mobile} {
            margin-right: 0;
          }
        }
      }

      .insights-card {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        width: 100%;
        /* max-width: 48rem;
        min-width: 40rem; */
        padding: 4.2rem 3rem;
        border-radius: 2rem;
        border: 1px solid
          ${(props) => props.theme.pallete.colors.brandPrimary.gray[3]};
        color: ${(props) => props.theme.pallete.colors.common.white};
        background-color: ${(props) =>
          props.theme.pallete.colors.backgrounds.neutral};

        @media ${(props) => props.theme.medias.mobile} {
          margin: 0 auto 0.8rem;
          min-width: 32rem;
        }
      }

      .insights-card-title {
        font-size: 2.4rem;
        font-weight: ${(props) => props.theme.typeFaceWeight.bold};
        color: ${(props) => props.theme.pallete.colors.common.black};
        line-height: 3rem;
      }

      .insights-card-image {
        width: 100%;
        height: 26rem;
        background-image: url('/static/images/insights/car.png');
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        position: relative;
        cursor: pointer;
      }

      .insights-card-image:hover .insights-card-image-background {
        opacity: 1;
      }

      .insights-card-image-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: all 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem;

        span {
          font-size: 2.4rem;
          font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
          color: ${(props) => props.theme.pallete.colors.common.white};
        }
      }

      .insights-card-paragraph {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
        color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
        line-height: 2.1rem;

        &--break {
          word-break: break-all;
        }

        a {
          &,
          &:active,
          &:visited {
            text-decoration: none;
            color: ${(props) => props.theme.pallete.colors.common.black};
            position: relative;
            font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

export const InsightFirstCard = styled.div<IInsightFirstCardProps>`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
  /* max-width: 48rem;
  min-width: 40rem; */
  padding: 4.8rem 3.2rem;
  border-radius: 2rem;

  color: ${(props) => props.theme.pallete.colors.common.white};
  background: ${(props) => props.backgroundColor};

  @media ${(props) => props.theme.medias.mobile} {
    margin: 0 auto 0.8rem;
  }

  .insights-first-card-sub-title {
    color: ${(props) => color(props.backgroundColor, props.theme)};
    font-size: 2rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  .insights-first-card-title {
    color: ${(props) => color(props.backgroundColor, props.theme)};
    font-size: 3.2rem;
    line-height: 4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    word-wrap: break-word;
  }

  .insights-first-card-paragraph-title {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    color: ${(props) => color(props.backgroundColor, props.theme)};
  }

  .insights-first-card-paragraph {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    color: ${(props) => color(props.backgroundColor, props.theme)};
  }
`;

const phraseOne = keyframes`

  0% {
    width: 1%;
  }

  100% {
    width: 100%;
  }
`;

const revealPhrases = keyframes`
  0% {
  opacity: 0;
  }

  100% {
  opacity: 1;
  }
`;

export const CreativeTriggerIAContent = styled.div`
  .loading-ia {
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.pallete.colors.common.white};
    overflow: hidden;

    .loading-ia-content-phrase {
      display: block;
      position: relative;
      height: 3rem;
      margin-bottom: 1.6rem;
      width: 100%;
    }

    .loading-ia-paragraph {
      font-weight: ${(props) => props.theme.typeFaceWeight.regular};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
      font-size: 2rem;
      line-height: 2.2rem;
      text-align: center;
      opacity: 0;

      position: absolute;
      ${(props) => props.theme.mixins.absoluteCenter};

      &--1 {
        position: absolute;
        animation: ${revealPhrases} 1s linear forwards;
        z-index: 1;

        &::before {
          content: '';
          background-color: ${(props) =>
            props.theme.pallete.colors.common.white};
          position: absolute;
          right: 0;
          top: 0;
          width: 0%;
          height: 100%;
          animation: ${phraseOne} 1.5s linear forwards;
          animation-delay: 2.5s;
        }
      }

      &--2 {
        position: absolute;
        animation: ${revealPhrases} 1s linear forwards;
        animation-delay: 4s;
        z-index: 2;

        &::before {
          content: '';
          background-color: ${(props) =>
            props.theme.pallete.colors.common.white};
          position: absolute;
          right: 0;
          top: 0;
          width: 0%;
          height: 100%;
          animation: ${phraseOne} 1.5s linear forwards;
          animation-delay: 7s;
        }
      }

      &--3 {
        position: absolute;
        animation: ${revealPhrases} 1s linear forwards;
        animation-delay: 8.5s;
        z-index: 3;
      }
    }

    .loading-ia-spinner {
      margin: 3rem 0;
    }
  }

  .creative-trigger-ia-heading-2 {
  }

  .creative-trigger-ia-paragraph {
    line-height: 3.4rem;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .input-label {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5rem;
  }

  .input-value {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    margin-right: 1.6rem;
    margin-left: 0.8rem;
  }

  .creative-trigger-ia-form {
    display: grid;
    grid-template-columns: repeat(
      12,
      [col-start] minmax(min-content, 9.5rem) [col-end]
    );
    align-items: baseline;
    column-gap: 1.6rem;
    row-gap: 3rem;

    > :nth-child(1) {
      grid-column: col-start 1 / col-end 6;

      @media ${(props) => props.theme.medias.tablet} {
        grid-column: col-start 1 / col-end 12;
      }

      ${mobileForm}
    }

    > :nth-child(2) {
      grid-column: col-start 7 / col-end 12;

      @media ${(props) => props.theme.medias.tablet} {
        grid-column: col-start 1 / col-end 6;
      }

      ${mobileForm}
    }

    > :nth-child(3) {
      grid-column: col-start 1 / col-end 12;

      @media ${(props) => props.theme.medias.tablet} {
        grid-column: col-start 7 / col-end 12;
      }

      ${mobileForm}
    }

    > :nth-child(4) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(5) {
      grid-column: col-start 1 / col-end 4;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }

      ${mobileForm}
    }

    > :nth-child(6) {
      grid-column: col-start 5 / col-end 8;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }

      ${mobileForm}
    }

    > :nth-child(7) {
      grid-column: col-start 9 / col-end 12;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }

      ${mobileForm}
    }

    > :nth-child(8) {
      grid-column: col-start 1 / col-end 12;

      @media ${(props) => props.theme.medias.mobile} {
        margin: 0 auto;
      }
    }
  }

  .ia-form-input-control-label {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: 600;
    line-height: 5rem;
    font-size: 1.6rem;
    user-select: none;
  }

  .textfield-error {
    color: ${(props) => props.theme.pallete.colors.error.main};
  }

  .react-select {
    &__control {
      width: 100%;
      min-height: 6rem;
      outline: 0;
      background: none;
      font-size: inherit;
      border: 1px solid #d7d7d7;
      border-radius: 100px;
      font-family: inherit;
      background-color: #ffffff;
      cursor: pointer;
    }

    &__value-container {
      width: 100%;
      min-height: 6rem;
      outline: 0;
      background: none;
      font-size: inherit;
      font-family: inherit;
      padding-left: 2rem;
      cursor: pointer;
    }

    &__menu {
      top: auto;
      bottom: 100%;
      border-radius: 1rem;
      overflow: hidden;
    }
  }
`;
