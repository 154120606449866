import React, { useEffect } from 'react';
import nprogress from 'nprogress';
import { LoadingScreenContainer } from './styles';
import Logo from '../svgs/Logo';
import Spinner from '../loadings/Spinner';

const LoadingScreen: React.FC = () => {
  useEffect(() => {
    nprogress.configure({ showSpinner: false });
    nprogress.start();

    return () => {
      nprogress.done();
    };
  }, []);
  return (
    <LoadingScreenContainer>
      <Logo />
      <Spinner size={36} />
    </LoadingScreenContainer>
  );
};

export default LoadingScreen;
