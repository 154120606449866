import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../../../components/Button';
import SeparateLine from '../../../../components/SeparateLine';
import Textfield from '../../../../components/inputs/Textfield';
import LightboxCategoryMarket from '../../../../components/lightboxes/LightboxCategoryMarket';
import { AuthContext } from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
// import { createCreativeChallengeService } from '../../../../services/creativeChallenges.service';
import ReactSelect from 'react-select';
import { createCreativeChallengeService } from '../../../../services/creativeChallenge.service';
import { listCreativeChallengeTagService } from '../../../../services/creativeChallengeTag.service';
import { CreateCreativeChallengeContainer } from './styles';

interface ITagOptionType {
  value: string;
  label: string;
}

interface IInputFields {
  label: string;
  award: string;
  link: string;
  tag: ITagOptionType[];
}

const CreateCreativeChallenge: React.FC = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const [tagSelected, setTagSelected] = useState<ITagOptionType[]>();
  const [optionsTag, setOptionsTag] = useState<ITagOptionType[]>([]);
  const [isNewCategoryOpen, setIsNewCategoryOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IInputFields>({
    defaultValues: {
      tag: [{ value: '', label: '' }],
    },
  });

  useEffect(() => {
    !!tagSelected && setValue('tag', tagSelected);
  }, [setValue, tagSelected]);
  const onError = (errors: FieldErrors<IInputFields>) => {
    handleToastfy({
      message: 'Existem campos que não foram preenchidos.',
      type: 'error',
    });
  };

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsLoading((prev) => !prev);

    const _tags = tagSelected?.map((value) => value.label);
    const _tagsString = _tags?.join(', ');

    await createCreativeChallengeService({
      award: data.award,
      label: data.label,
      link: data.link,
      tag: _tagsString ? _tagsString : '',
    })
      .then((response) => {
        handleToastfy({
          message: 'Nova Desafio cadastrado com sucesso!',
          type: 'success',
        });
        setIsLoading((prev) => !prev);
        navigate('/dashboard/desafio-criativista');
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoading((prev) => !prev);
        console.log(error.response.data);
      });
  };

  const getCreativeChallengeTags = useCallback(async () => {
    await listCreativeChallengeTagService({
      order: 'DESC',
      orderBy: 'createdAt',
      isActive: true,
    })
      .then((response) => {
        const arr: ITagOptionType[] = [];

        response.data.forEach((value) => {
          const newObj = {
            value: value.label,
            label: value.label,
          };

          arr.push(newObj);
        });

        setOptionsTag(arr);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message: !!error.message
              ? error.message
              : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    getCreativeChallengeTags();
  }, [getCreativeChallengeTags]);

  return (
    <CreateCreativeChallengeContainer>
      <h2 className="heading-2 heading-2--margin">Criar Desafio Criativista</h2>
      <SeparateLine
        margin="0 0 5rem"
        color={theme.pallete.colors.brandPrimary.yellow[6]}
        lineHeight="3px"
      />
      <form className="form-grid" onSubmit={handleSubmit(onSubmit, onError)}>
        <Textfield
          label="Título"
          error={errors.label?.message}
          type="text"
          {...register('label', {
            required: 'O campo é requerido.',
          })}
        />
        <div className="select-form-input-control">
          <label className="select-form-input-control-label">TAG's</label>
          <ReactSelect
            options={optionsTag}
            isMulti
            isClearable={false}
            classNamePrefix="react-select"
            placeholder=""
            value={tagSelected}
            {...register('tag', {
              required: 'O campo é requerido.',
            })}
            onChange={(val) => setTagSelected(val as any)}
            // isOptionDisabled={() => watch('tag').length >= 3}
            styles={{
              multiValueRemove: (css) => ({
                ...css,
                ':hover': { background: 'red' },
              }),
            }}
          />
          {!!errors.tag?.message && (
            <span className="textfield-error">{errors.tag.message}</span>
          )}
        </div>

        <Textfield
          label="Prêmio"
          error={errors.award?.message}
          type="text"
          {...register('award')}
        />
        <Textfield
          label="Link do PDF"
          error={errors.link?.message}
          type="text"
          {...register('link', {
            required: 'O campo é requerido.',
          })}
        />

        <Button
          buttonText="Salvar conteúdo"
          variant="contained"
          type="submit"
          className="create-creative-challenge-save-button"
          isLoading={isLoading}
          // onClick={() => navigate('/dashboard/ver-conteudo-gatilho-criativo')}
        />
      </form>
      {isNewCategoryOpen && (
        <LightboxCategoryMarket
          handleOpenModal={() => setIsNewCategoryOpen((prev) => !prev)}
          setIsNewCategoryOpen={setIsNewCategoryOpen}
        />
      )}
    </CreateCreativeChallengeContainer>
  );
};

export default CreateCreativeChallenge;
