import React, { useContext } from 'react';
import { DashboardContainer } from './styles';
import { Link } from 'react-router-dom';
import AuthContext from '../../../../contexts/AuthContext';

const Dashboard: React.FC = () => {
  const { user } = useContext(AuthContext);

  return (
    <DashboardContainer>
      <div className="heading-box">
        <h2 className="heading-2">Olá, esse é seu Painel Administrativo</h2>
        <p className="sub-title">O que você deseja fazer hoje?</p>
      </div>
      <div className="card-grids">
        {user?.role === 'admin' ? (
          <React.Fragment>
            <div className="card">
              <h3 className="heading-3">Conteúdos</h3>
              <div className="image image--1" />
              <div className="span-boxes">
                <Link className="link" to="/dashboard/ver-editar-conteudos">
                  <span className="link-text">Ver e editar conteúdos</span>
                </Link>
                <Link className="link" to="/dashboard/criar-novo-conteudo">
                  <span className="link-text">Criar novo conteúdo</span>
                </Link>
              </div>
            </div>
            <div className="card">
              <h3 className="heading-3">Configuração</h3>
              <div className="image image--2" />
              <div className="span-boxes">
                <Link className="link" to="/dashboard/membros-da-equipe">
                  <span className="link-text">Ver e editar membros</span>
                </Link>
                <Link className="link" to="/dashboard/usuarios-cadastrados">
                  <span className="link-text">
                    Visualizar usuários cadastrados
                  </span>
                </Link>
              </div>
            </div>
          </React.Fragment>
        ) : (
          user?.role === 'moderator' && (
            <React.Fragment>
              <div className="card">
                <h3 className="heading-3">Conteúdos</h3>
                <div className="image image--1" />
                <div className="span-boxes">
                  <Link className="link" to="/dashboard/salas-de-brainstorming">
                    <span className="link-text">Ver e moderar salas</span>
                  </Link>
                </div>
              </div>
              <div className="card">
                <h3 className="heading-3">Configuração</h3>
                <div className="image image--2" />
                <div className="span-boxes">
                  <Link className="link" to="/dashboard/perfil">
                    <span className="link-text">
                      Ver e configurar sua conta
                    </span>
                  </Link>
                </div>
              </div>
            </React.Fragment>
          )
        )}
      </div>
      <div className="background-dashboard" />
    </DashboardContainer>
  );
};

export default Dashboard;
