import React from 'react';
import { SectionToolsStyled } from './styles';
import { useNavigate } from 'react-router-dom';

const SectionTools: React.FC = () => {
  const navigate = useNavigate();

  return (
    <SectionToolsStyled>
      <div className="content">
        <h2 className="heading-2">
          Por um futuro mais criativo.
        </h2>
        <p className="subtitle">
          Use nossas ferramentas para ter ideias mais inovadoras.
        </p>
        <div className="grid-cards">
          {/* 1 */}
          <div className="card" onClick={() => navigate('/gatilhos-criativos')}>
            <div className="icon">
              <img src="/static/images/lamp-idea.png" alt="lamp-idea" />
            </div>
            <div className="card-text">
              <div className="card-text-title-box">
                <h3>Gatilhos Criativos</h3>
                <span className="card-text-type card-text-type--ia">
                  Integrações com IA
                </span>
              </div>
              <p>
                Insights para sair do bloqueio, da caixinha e das ideias comuns.
              </p>
            </div>
          </div>
          {/* 2 */}
          <div
            className="card"
            onClick={() => navigate('/salas-de-brainstorming')}
          >
            <div className="icon">
              <img src="/static/images/comunication.png" alt="comunication" />
            </div>
            <div className="card-text">
              <h3>Salas de Brainstorming</h3>
              <span className="card-text-type card-text-type--ia card-text-type--brainstorming">
                Integrações com IA
              </span>
              <p>
                Troque ideias e referências com outros criativos para tirar sua
                ideia do papel.
              </p>
            </div>
          </div>
          {/* 3 */}
          <div
            className="card"
            onClick={() => navigate('/desafios-criativistas')}
          >
            <div className="icon">
              <img src="/static/images/reference.png" alt="reference" />
            </div>
            <div className="card-text">
              <div className="card-text-title-box">
                <h3>Desafios Criativistas</h3>
                {/* <span className="card-text-type card-text-type--comming-soon">
                  Em breve
                </span> */}
              </div>
              <p>
                Crie ideias com propósito a partir de um briefing cheio de
                inspirações.
              </p>
            </div>
          </div>
          {/* 4 */}
          <div
            className="card"
            onClick={() => navigate('/termometro-de-ideias')}
          >
            <div className="icon">
              <img src="/static/images/thermometer.png" alt="thermometer" />
            </div>
            <div className="card-text">
              <div className="card-text-title-box">
                <h3>Termômetro de Ideias</h3>
                <span className="card-text-type card-text-type--ia">
                  Integrações com IA
                </span>
              </div>
              <p>
                Descubra se sua ideia é boa, genial ou precisa de uns ajustes.
                Aproveite para pedir feedbacks exclusivos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </SectionToolsStyled>
  );
};

export default SectionTools;
