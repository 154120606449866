import styled, { keyframes } from 'styled-components';

export interface IInsightFirstCardProps {
  backgroundColor: string;
}

const phraseOne = keyframes`

  0% {
    width: 1%;
  }

  100% {
    width: 100%;
  }
`;

const revealPhrases = keyframes`
  0% {
  opacity: 0;
  }

  100% {
  opacity: 1;
  }
`;

export const ModalFeedback = styled.div`
  .loading-ia {
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.pallete.colors.common.white};
    overflow: hidden;

    .loading-ia-content-phrase {
      display: block;
      position: relative;
      height: 3rem;
      margin-bottom: 1.6rem;
      width: 100%;
    }

    .loading-ia-paragraph {
      font-weight: ${(props) => props.theme.typeFaceWeight.regular};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
      font-size: 2rem;
      line-height: 2.2rem;
      text-align: center;
      opacity: 0;

      position: absolute;
      ${(props) => props.theme.mixins.absoluteCenter};

      &--1 {
        position: absolute;
        animation: ${revealPhrases} 1s linear forwards;
        z-index: 1;

        &::before {
          content: '';
          background-color: ${(props) =>
            props.theme.pallete.colors.common.white};
          position: absolute;
          right: 0;
          top: 0;
          width: 0%;
          height: 100%;
          animation: ${phraseOne} 1.5s linear forwards;
          animation-delay: 2.5s;
        }
      }

      &--2 {
        position: absolute;
        animation: ${revealPhrases} 1s linear forwards;
        animation-delay: 4s;
        z-index: 2;

        &::before {
          content: '';
          background-color: ${(props) =>
            props.theme.pallete.colors.common.white};
          position: absolute;
          right: 0;
          top: 0;
          width: 0%;
          height: 100%;
          animation: ${phraseOne} 1.5s linear forwards;
          animation-delay: 7s;
        }
      }

      &--3 {
        position: absolute;
        animation: ${revealPhrases} 1s linear forwards;
        animation-delay: 8.5s;
        z-index: 3;
      }
    }

    .loading-ia-spinner {
      margin: 3rem 0;
    }
  }

  .creative-trigger-ia-heading-2 {
  }

  .creative-trigger-ia-paragraph {
    line-height: 3.4rem;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .input-label {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5rem;
  }

  .input-value {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    margin-right: 1.6rem;
    margin-left: 0.8rem;
  }

  .creative-trigger-ia-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .ia-form-input-control-label {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: 600;
    line-height: 5rem;
    font-size: 1.6rem;
    user-select: none;
  }

  .textfield-error {
    color: ${(props) => props.theme.pallete.colors.error.main};
  }

  .react-select {
    &__control {
      width: 100%;
      min-height: 6rem;
      outline: 0;
      background: none;
      font-size: inherit;
      border: 1px solid #d7d7d7;
      border-radius: 100px;
      font-family: inherit;
      background-color: #ffffff;
      cursor: pointer;
    }

    &__value-container {
      width: 100%;
      min-height: 6rem;
      outline: 0;
      background: none;
      font-size: inherit;
      font-family: inherit;
      padding-left: 2rem;
      cursor: pointer;
    }

    &__menu {
      top: auto;
      bottom: 100%;
      border-radius: 1rem;
      overflow: hidden;
    }
  }
`;

export const BannerThermometerStyled = styled.header`
  background-color: ${(props) =>
    props.theme.pallete.colors.backgrounds.neutral};
  width: 100%;
  min-height: 70vh;
  display: block;
  position: relative;

  .box-ia {
    padding: 1.5rem;
    background-color: #f6f6f6;
    border-radius: 16px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 1rem;
    width: max-content;
    margin-top: 4rem;

    @media ${(props) => props.theme.medias.mobile} {
      width: 100%;
    }

    p {
      color: #5b5b5b;

      /* font-size: 1em; */
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      max-width: 350px;
    }

    .box-image-ia {
      height: fit-content;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border-radius: 2rem;
      width: 50px;
      height: 60px;
      margin-right: 1rem;

      &--1 {
        background-image: url('/static/images/feedback_ia.png');
      }
    }
  }

  .content {
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 65vh;
    padding: 0 12rem;
    gap: 3;

    @media ${(props) => props.theme.medias.laptopSmall} {
      margin-top: 10rem;
      flex-direction: column-reverse;
      padding: 0 6rem;
      min-height: 100vh;
    }

    @media ${(props) => props.theme.medias.mobile} {
      padding: 0 3rem;
    }
  }

  .box-content {
    margin: auto 0;
    min-width: 50%;
    width: 100%;
    padding-bottom: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 100%;
      text-align: center;
      height: 65vh;
      margin: 6rem 0 0;
    }
  }

  .heading-1 {
    font-size: 3.2rem;
    line-height: 3.8rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
    margin-bottom: 3rem;
  }

  .paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    margin-bottom: 3.2rem;
    line-height: 2.8rem;
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
  }

  .buttons-box {
    display: flex;
    align-items: start;
    gap: 3.2rem;

    @media ${(props) => props.theme.medias.mobile} {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.2rem;
    }
    .button-points-box {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      .button-points-span {
        display: inline-block;
        margin-left: 1.6rem;
      }
    }
  }

  .box-image {
    height: fit-content;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    border-radius: 2rem;
    width: 100%;
    height: 60vh;
    margin: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      background-position: center;
      margin: 0 auto;
      height: inherit;
    }

    &--1 {
      background-image: url('/static/images/people/13.png');
    }
    &--2 {
      background-image: url('/static/images/banner-home/image-banner-2.png');
    }
    &--3 {
      background-image: url('/static/images/banner-home/image-banner-3.png');
    }
    &--4 {
      background-image: url('/static/images/banner-home/image-banner-4.png');
    }
    &--5 {
      background-image: url('/static/images/banner-home/image-banner-5.gif');
      filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
    }
  }

  .upper-heading {
    margin-bottom: 0.8rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    font-size: 2.4rem;
    line-height: 120%;
  }
`;
