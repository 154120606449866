import React, { useState, ComponentProps } from 'react';
import { useTheme } from 'styled-components';
import Player from 'react-player';
import { RiPlayCircleLine } from 'react-icons/ri';

interface IProps extends ComponentProps<'div'> {
  imagePreviewURL: string;
  videoURL: string;
  className?: string;
}

const ReactPlayer: React.FC<IProps> = ({
  imagePreviewURL,
  videoURL,
  className,
  ...props
}) => {
  const [start, setStart] = useState<boolean>(false);
  const theme = useTheme();

  return (
    <Player
      url={videoURL}
      controls
      height="auto"
      width="100%"
      light={imagePreviewURL}
      playIcon={
        <RiPlayCircleLine
          size={160}
          color={theme.pallete.colors.primary.main}
        />
      }
      playing={start}
      onClickPreview={(event) => {
        setStart((prev) => !prev);
      }}
    />
  );
};

export default ReactPlayer;
