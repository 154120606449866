import { getLuminance } from 'polished';
import styled, { DefaultTheme, css, keyframes } from 'styled-components';

export interface IInsightFirstCardProps {
  backgroundColor: string;
}

const mobileForm = css`
  @media ${(props) => props.theme.medias.mobile} {
    grid-column: col-start 1 / col-end 12;
  }
`;

const color = (color: string, props: DefaultTheme) => {
  return getLuminance(color) > 0.4
    ? props.pallete.colors.common.black
    : props.pallete.colors.common.white;
};

const phraseOne = keyframes`

  0% {
    width: 1%;
  }

  100% {
    width: 100%;
  }
`;

const revealPhrases = keyframes`
  0% {
  opacity: 0;
  }

  100% {
  opacity: 1;
  }
`;

export const ModalFeedback = styled.div`
  .loading-ia {
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.pallete.colors.common.white};
    overflow: hidden;

    .loading-ia-content-phrase {
      display: block;
      position: relative;
      height: 3rem;
      margin-bottom: 1.6rem;
      width: 100%;
    }

    .loading-ia-paragraph {
      font-weight: ${(props) => props.theme.typeFaceWeight.regular};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
      font-size: 2rem;
      line-height: 2.2rem;
      text-align: center;
      opacity: 0;

      position: absolute;
      ${(props) => props.theme.mixins.absoluteCenter};

      &--1 {
        position: absolute;
        animation: ${revealPhrases} 1s linear forwards;
        z-index: 1;

        &::before {
          content: '';
          background-color: ${(props) =>
            props.theme.pallete.colors.common.white};
          position: absolute;
          right: 0;
          top: 0;
          width: 0%;
          height: 100%;
          animation: ${phraseOne} 1.5s linear forwards;
          animation-delay: 2.5s;
        }
      }

      &--2 {
        position: absolute;
        animation: ${revealPhrases} 1s linear forwards;
        animation-delay: 4s;
        z-index: 2;

        &::before {
          content: '';
          background-color: ${(props) =>
            props.theme.pallete.colors.common.white};
          position: absolute;
          right: 0;
          top: 0;
          width: 0%;
          height: 100%;
          animation: ${phraseOne} 1.5s linear forwards;
          animation-delay: 7s;
        }
      }

      &--3 {
        position: absolute;
        animation: ${revealPhrases} 1s linear forwards;
        animation-delay: 8.5s;
        z-index: 3;
      }
    }

    .loading-ia-spinner {
      margin: 3rem 0;
    }
  }

  .creative-trigger-ia-heading-2 {
  }

  .creative-trigger-ia-paragraph {
    line-height: 3.4rem;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .input-label {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5rem;
  }

  .input-value {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    margin-right: 1.6rem;
    margin-left: 0.8rem;
  }

  .creative-trigger-ia-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .ia-form-input-control-label {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: 600;
    line-height: 5rem;
    font-size: 1.6rem;
    user-select: none;
  }

  .textfield-error {
    color: ${(props) => props.theme.pallete.colors.error.main};
  }

  .react-select {
    &__control {
      width: 100%;
      min-height: 6rem;
      outline: 0;
      background: none;
      font-size: inherit;
      border: 1px solid #d7d7d7;
      border-radius: 100px;
      font-family: inherit;
      background-color: #ffffff;
      cursor: pointer;
    }

    &__value-container {
      width: 100%;
      min-height: 6rem;
      outline: 0;
      background: none;
      font-size: inherit;
      font-family: inherit;
      padding-left: 2rem;
      cursor: pointer;
    }

    &__menu {
      top: auto;
      bottom: 100%;
      border-radius: 1rem;
      overflow: hidden;
    }
  }
`;

export const ResultThermometerStyled = styled.header`
  background-color: ${(props) =>
    props.theme.pallete.colors.backgrounds.neutral};
  padding-top: 5rem;
  width: 100%;
  min-height: 70vh;
  display: block;
  position: relative;

  @media ${(props) => props.theme.medias.laptopSmall} {
    /* height: calc(100vh - 16rem); */
    margin-top: 5rem;
  }

  @media ${(props) => props.theme.medias.mobile} {
    margin-top: 11rem;
  }

  .progress-bar-result {
    content: '';
    border-radius: 40px;
    background: ${(props) => props.theme.pallete.colors.brandPrimary.yellow[2]};
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    padding: 0 1rem;
    width: 100%;
    height: 35px;
    margin-bottom: 2rem;
    /* margin-left: 5rem; */
    /* margin-top: 5rem; */
  }

  .progress-bar-result::before {
    content: '';
    border-radius: 80px;
    background: linear-gradient(86deg, #ffc700 0%, #ffa800 65.35%);
    position: absolute;
    height: 15px;
    width: 10%;
  }

  .progress-bar-result--1::before {
    width: calc(25% - 2rem);
  }
  .progress-bar-result--2::before {
    width: calc(50% - 2rem);
  }
  .progress-bar-result--3::before {
    width: calc(75% - 2rem);
  }
  .progress-bar-result--4::before {
    width: calc(100% - 2rem);
  }

  .progress-bar-result::after {
    content: '';
    background-image: url('/static/images/fire-thermometerIdeas.png');
    background-repeat: no-repeat;
    background-position-x: right;
    position: absolute;
    width: 11%;
    height: 50px;

    @media ${(props) => props.theme.medias.mobile} {
      bottom: -15px;
    }
  }

  .progress-bar-result--1::after {
    width: calc(26% - 2rem);
  }
  .progress-bar-result--2::after {
    width: calc(51% - 2rem);
  }
  .progress-bar-result--3::after {
    width: calc(76% - 2rem);
  }
  .progress-bar-result--4::after {
    width: calc(101% - 2rem);
  }

  .content {
    display: flex;
    width: 100%;
    margin: 0 auto;
    /* height: 65vh; */
    padding: 0 12rem;
    gap: 3;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
      padding: 0 6rem;
      /* height: 75vh; */
    }

    @media ${(props) => props.theme.medias.mobile} {
      padding: 0 3rem;
    }
  }

  .box-content {
    /* margin: auto 0; */
    min-width: 50%;
    width: 100%;
    padding-bottom: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 100%;
      text-align: center;
      height: 65vh;
      margin: 6rem 0 0;
    }
  }

  .heading-1 {
    font-size: 3.2rem;
    line-height: 3.8rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
    margin-bottom: 3rem;
  }

  .heading-3 {
    font-size: 2.4rem;

    color: ${(props) => props.theme.pallete.colors.common.black};
    margin-bottom: 1.2rem;
  }

  .paragraph-black {
    color: ${(props) => props.theme.pallete.colors.common.black};
    margin-bottom: 1rem;
    line-height: 2.8rem;
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  .paragraph-gray {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    margin-bottom: 1rem;
    line-height: 2.8rem;
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
  }

  .buttons-box {
    display: flex;
    align-items: center;
    gap: 3.2rem;

    @media ${(props) => props.theme.medias.mobile} {
      flex-direction: column;
    }
  }

  .box-content:last-child .buttons-box {
    margin: 0 30px;
  }

  .feedback {
    display: flex;
    /* align-items: center; */
    gap: 1rem;
    margin: 6rem 0;

    .paragraph-gray {
      margin-bottom: 2rem;
    }
  }

  .button-points-box {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .button-points-span {
      display: inline-block;
      margin-left: 1.6rem;
    }
  }

  .box-image-ia {
    height: fit-content;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top left;
    border-radius: 2rem;
    width: 30%;
    height: 25vh;
    margin-right: 1rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      background-position: center;
      margin: 0 auto;
      height: inherit;
    }

    &--1 {
      background-image: url('/static/images/robot-face.png');
    }
  }

  .link a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .link-span {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    font-size: 1.6rem;
    position: relative;
    text-decoration: none;

    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      bottom: -0.8rem;
      left: 0;
      height: 3px;
      width: 0.001%;
      background-color: currentColor;
      transition: width 0.4s cubic-bezier(1, 0, 0, 1);
    }

    &:hover::after {
      width: 100%;
    }
  }

  .box-image {
    height: fit-content;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    width: 100%;
    height: 50vh;
    margin: 0 0 1rem 4rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      background-position: center;
      margin: 0 auto;
      height: inherit;
    }

    &--1 {
      background-image: url('/static/images/Resultado1.gif');
      @media ${(props) => props.theme.medias.laptopSmall} {
        margin-bottom: 4rem;
      }
    }
    &--2 {
      background-image: url('/static/images/Resultado2.gif');
      @media ${(props) => props.theme.medias.laptopSmall} {
        margin-bottom: 4rem;
      }
    }
    &--3 {
      background-image: url('/static/images/Resultado3.gif');
      @media ${(props) => props.theme.medias.laptopSmall} {
        margin-bottom: 4rem;
      }
    }
    &--4 {
      background-image: url('/static/images/Resultado4.gif');
      @media ${(props) => props.theme.medias.laptopSmall} {
        margin-bottom: 4rem;
      }
    }
  }
`;
