import { api } from './api.axios';
import {
  ICreateInternParamRequest,
  IListInternParam,
  IListInternParamRequest,
  IInternParamMessageResponse,
  IShowInternParam,
  IUpdateInternParamRequest,
} from './internParams.type';

export const showInternParamService = async (
  id: string
): Promise<IShowInternParam> => {
  const response = await api.get<IShowInternParam>(
    `${process.env.REACT_APP_URL_API_BASE}/intern-params/${id}`
  );

  return response.data;
};

export const listInternParamService = async ({
  order,
  isActive,
  orderBy,
  page,
  perPage,
  name,
}: IListInternParamRequest): Promise<IListInternParam> => {
  const orderParameter = `order=${order}`;
  const isActiveParameter = `&isActive=${isActive || undefined}`;
  const nameParameter = `&name=${name || undefined}`;
  const orderByParameter = `&orderBy=${orderBy}`;
  const pageParameter = `&page=${page || undefined}`;
  const perPageParameter = `&perPage=${perPage || undefined}`;

  const response = await api.get<IListInternParam>(
    `${
      process.env.REACT_APP_URL_API_BASE
    }/intern-params?${orderParameter}${orderByParameter}${
      typeof isActive === 'boolean' ? isActiveParameter : ''
    }${!!page ? pageParameter : ''}${!!perPage ? perPageParameter : ''}${
      !!name ? nameParameter : ''
    }`
  );

  return response.data;
};

export const createInternParamService = async ({
  name,
  textIa,
}: ICreateInternParamRequest): Promise<IInternParamMessageResponse> => {
  const sendData = {
    name,
    textIa,
  };
  const response = await api.post<IInternParamMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/intern-params`,
    sendData
  );

  return response.data;
};

export const updateInternParamService = async ({
  name,
  id,
  isActive,
  textIa,
}: IUpdateInternParamRequest): Promise<IInternParamMessageResponse> => {
  const sendParametersData = {
    isActive,
    name,
    textIa,
  };
  const response = await api.put<IInternParamMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/intern-params/${id}`,
    sendParametersData
  );

  return response.data;
};

export const deleteInternParamService = async (
  id: string
): Promise<IInternParamMessageResponse> => {
  const response = await api.delete<IInternParamMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/intern-params/${id}`
  );

  return response.data;
};
