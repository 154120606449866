import { api } from './api.axios';
import {
  IAddMessageRoomResponse,
  IBrainstormingRoomMessage,
  IChatIABrainstormingRoomResponse,
  ICreateBrainstormingRoomRequest,
  ICreateBrainstormingRoomResponse,
  IDeleteImageMessageRoomResponse,
  IDeleteMessageRoomRequest,
  ILikeMessageRoomRequest,
  IListBrainstormingRoomRequest,
  IListBrainstormingRoomResponse,
  IReportMessageRoomRequest,
  IShowBrainstormingRoomResponse,
  IUpdateBrainstormingRoomRequest,
  IUpdateBrainstormingRoomResponse,
  updateMessageRoomRequest,
} from './brainstormingRoom.types';

export const createBrainstormingRoomService = async ({
  description,
  label,
}: ICreateBrainstormingRoomRequest): Promise<ICreateBrainstormingRoomResponse> => {
  const sendData = {
    description,
    label,
  };

  const response = await api.post<ICreateBrainstormingRoomResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/brainstorming-rooms`,
    sendData
  );

  return response.data;
};

export const updateBrainstormingRoomService = async ({
  description,
  id,
  label,
}: IUpdateBrainstormingRoomRequest): Promise<IUpdateBrainstormingRoomResponse> => {
  const sendData = {
    description,
    label,
  };

  const response = await api.put<IUpdateBrainstormingRoomResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/brainstorming-rooms/${id}`,
    sendData
  );

  return response.data;
};

export const deleteBrainstormingRoomService = async (
  id: string
): Promise<IBrainstormingRoomMessage> => {
  const response = await api.delete<IBrainstormingRoomMessage>(
    `${process.env.REACT_APP_URL_API_BASE}/brainstorming-rooms/${id}`
  );

  return response.data;
};

export const chatIABrainstormingRoomService = async (
  message: string
): Promise<IChatIABrainstormingRoomResponse> => {
  const response = await api.get<IChatIABrainstormingRoomResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/brainstorming-rooms/chat?message=${message}`
  );

  return response.data;
};

export const listBrainstormingRoomService = async ({
  label,
  order,
  orderBy,
  isActive,
  page,
  perPage,
}: IListBrainstormingRoomRequest): Promise<IListBrainstormingRoomResponse> => {
  const orderParameter = `order=${order}`;
  const isActiveParameter = `&isActive=${isActive}`;
  const orderByParameter = `&orderBy=${orderBy}`;
  const pageParameter = `&page=${page}`;
  const perPageParameter = `&perPage=${perPage}`;
  const labelParameter = `&label=${label}`;

  const response = await api.get<IListBrainstormingRoomResponse>(
    `${
      process.env.REACT_APP_URL_API_BASE
    }/brainstorming-rooms?${orderParameter}${orderByParameter}${
      typeof isActive === 'boolean' ? isActiveParameter : ''
    }
    }${!!page ? pageParameter : ''}${!!perPage ? perPageParameter : ''}${
      !!label ? labelParameter : ''
    }`
  );

  return response.data;
};

export const showBrainstormingRoomService = async (
  id: string
): Promise<IShowBrainstormingRoomResponse> => {
  const response = await api.get<IShowBrainstormingRoomResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/brainstorming-rooms/${id}`
  );

  return response.data;
};

export const addMessageRoomService = async (
  formData: unknown
): Promise<IAddMessageRoomResponse> => {
  const response = await api.post<IAddMessageRoomResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/brainstorming-rooms/messages`,
    formData
  );

  return response.data;
};

export const updateMessageRoomService = async ({
  formData,
  messageCode,
}: updateMessageRoomRequest): Promise<IBrainstormingRoomMessage> => {
  const response = await api.put<IBrainstormingRoomMessage>(
    `${process.env.REACT_APP_URL_API_BASE}/brainstorming-rooms/messages/${messageCode}`,
    formData
  );

  return response.data;
};

export const deleteImageMessageRoomService = async ({
  brainstormingRoomId,
  imageCode,
  messageCode,
}: IDeleteImageMessageRoomResponse): Promise<IBrainstormingRoomMessage> => {
  const sendData = {
    data: {
      brainstormingRoomId,
      imageCode,
      messageCode,
    },
  };

  const response = await api.delete<IBrainstormingRoomMessage>(
    `${process.env.REACT_APP_URL_API_BASE}/brainstorming-rooms/messages/image`,
    sendData
  );

  return response.data;
};

export const deleteMessageRoomService = async ({
  brainstormingRoomId,
  messageCode,
}: IDeleteMessageRoomRequest): Promise<IBrainstormingRoomMessage> => {
  const sendData = {
    data: {
      brainstormingRoomId,
    },
  };

  const response = await api.delete<IBrainstormingRoomMessage>(
    `${process.env.REACT_APP_URL_API_BASE}/brainstorming-rooms/messages/${messageCode}`,
    sendData
  );

  return response.data;
};

export const reportMessageRoomService = async ({
  brainstormingRoomId,
  message,
  messageCode,
}: IReportMessageRoomRequest): Promise<IBrainstormingRoomMessage> => {
  const sendData = {
    brainstormingRoomId,
    message,
    messageCode,
  };

  const response = await api.post<IBrainstormingRoomMessage>(
    `${process.env.REACT_APP_URL_API_BASE}/brainstorming-rooms/messages/report`,
    sendData
  );

  return response.data;
};

export const likeMessageRoomService = async ({
  brainstormingRoomId,
  dislike,
  like,
  messageCode,
}: ILikeMessageRoomRequest): Promise<IReportMessageRoomRequest> => {
  const sendData = {
    brainstormingRoomId,
    dislike,
    like,
    messageCode,
  };
  const response = await api.post<IReportMessageRoomRequest>(
    `${process.env.REACT_APP_URL_API_BASE}/brainstorming-rooms/messages/like`,
    sendData
  );

  return response.data;
};
