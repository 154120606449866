import React, { InputHTMLAttributes, forwardRef, useState } from 'react';
import { TextfieldStyled } from './styles';
import { v4 as uuidV4 } from 'uuid';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

interface IProps {
  label: string;
  labelColor?: string;
  type: 'text' | 'email' | 'password';
  defaultValue?: string | number;
  disabled?: boolean;
  value?: string | number;
  error?: string;
}

const Textfield: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IProps & InputHTMLAttributes<HTMLInputElement>
> = (
  {
    label,
    type,
    defaultValue,
    disabled,
    value,
    error,
    labelColor,
    required,
    className,
    ...props
  },
  ref
) => {
  const labelId = uuidV4();
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <TextfieldStyled type={type} labelColor={labelColor} className={className}>
      <label className="label" htmlFor={labelId}>
        {label} {required && <span className="required"> *</span>}
      </label>
      <div className="input-box">
        <input
          className="input"
          autoComplete="off"
          id={labelId}
          placeholder={props.placeholder}
          type={isActive ? 'text' : type}
          ref={ref}
          value={value}
          maxLength={props.maxLength}
          {...props}
        />
        <div
          className="text-field-icon-box"
          onClick={() => setIsActive((prev) => !prev)}
        >
          {isActive ? (
            <AiOutlineEye size={24} />
          ) : (
            <AiOutlineEyeInvisible size={24} />
          )}
        </div>
      </div>
      {!!error && <span className="textfield-error">{error}</span>}
    </TextfieldStyled>
  );
};

export default forwardRef(Textfield);
