import React, { HTMLAttributes } from 'react';
import { ButtonContainer, ICustomColors } from './styles';
import Spinner from '../loadings/Spinner';

interface IProps extends HTMLAttributes<HTMLButtonElement> {
  buttonText: string;
  isLoading?: boolean;
  buttonSize?: number;
  isFullWidth?: boolean;
  customColors?: ICustomColors;
  loadingSpinnerSize?: number;
  variant: 'contained' | 'outlined'; // If you change to outlined, only background color work in customColors
  type?: 'button' | 'submit' | 'reset';
  noShadows?: boolean;
  isDisabled?: boolean;
}

const Button: React.FC<IProps> = ({
  buttonText,
  isLoading,
  buttonSize,
  isFullWidth,
  loadingSpinnerSize,
  customColors,
  type,
  variant,
  noShadows,
  isDisabled,
  ...props
}) => {
  return (
    <ButtonContainer
      isLoading={isLoading}
      isDisabled={isDisabled}
      buttonSize={buttonSize}
      isFullWidth={isFullWidth}
      disabled={isLoading || isDisabled}
      customColors={customColors}
      type={type}
      noShadows={noShadows}
      variant={variant}
      {...props}
    >
      {!!buttonText && <span className="text">{buttonText}</span>}
      {props.children}
      <span className="spinner">
        <Spinner size={loadingSpinnerSize || 30} />
      </span>
    </ButtonContainer>
  );
};

export default Button;
