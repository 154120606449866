import styled from 'styled-components';

export const FormCheckboxContainer = styled.div`
  .keep-connect-box {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .checkbox {
    display: none;
  }

  .checkbox:checked ~ .checkbox-icon-box {
    background-color: ${(props) =>
      props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .checkbox:checked ~ .checkbox-icon-box svg {
    opacity: 1;
  }

  .checkbox-icon-box {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    min-height: 3rem;
    border-radius: 5px;
    border: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${(props) => props.theme.pallete.colors.common.white};
      opacity: 0;
    }
  }
  .checkbox-icon-box,
  .keep-connect-label {
    cursor: pointer;
  }

  .keep-connect-label {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    //To Avoid word selection
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .checkbox-error {
    color: ${(props) => props.theme.pallete.colors.error.main};
    padding: 0.4rem 2rem 0 4rem;
  }
`;
