import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AuthContext from '../../../contexts/AuthContext';
import ToastfyContext from '../../../contexts/ToastfyContext';
import { createMarketSegmentService } from '../../../services/marketSegments.service';
import Button from '../../Button';
import SeparateLine from '../../SeparateLine';
import Textfield from '../../inputs/Textfield';
import LightBox from '../LightBox';
import { LightboxCategoryMarketContainer } from './styles';

interface IInputFields {
  label: string;
  description: string;
  textIa: string;
}

interface IProps {
  handleOpenModal: () => void;
  setIsNewCategoryOpen: Dispatch<SetStateAction<boolean>>;
}

const LightboxCategoryMarket: React.FC<IProps> = ({
  handleOpenModal,
  setIsNewCategoryOpen,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IInputFields>();
  const [isRegisterSegment, setIsRegisterSegment] = useState<boolean>(false);
  const theme = useTheme();
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsRegisterSegment((prev) => !prev);

    await createMarketSegmentService({
      description: data.description,
      label: data.label,
      textIa: data.textIa,
    })
      .then((response) => {
        handleToastfy({
          message: 'Segmento de mercado cadastrado com sucesso!',
          type: 'success',
        });
        setIsRegisterSegment((prev) => !prev);
        setIsNewCategoryOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsRegisterSegment((prev) => !prev);
        console.log(error.response.data);
      });
  };

  return (
    <LightBox handleOpenModal={handleOpenModal}>
      <LightboxCategoryMarketContainer>
        <h3>Adicionar nova categoria do mercado</h3>
        <SeparateLine
          margin="2.4rem 0"
          color={theme.pallete.colors.brandPrimary.gray[2]}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Textfield
            label="Nome da categoria do mercado"
            type="text"
            error={errors.label?.message}
            placeholder="Nome da categoria"
            {...register('label', {
              required: 'O campo é requerido.',
            })}
          />
          <Textfield
            label="Descrição"
            type="text"
            error={errors.description?.message}
            {...register('description', {
              required: 'O campo é requerido.',
            })}
            placeholder="Descrição da categoria de mercado"
          />
          <Textfield
            label="Texto para a IA"
            type="text"
            placeholder='Ex: Vamos criar 5 ideias para "%1" um briefing usando um Gatilho criativo da categoria "%2"'
            error={errors.textIa?.message}
            {...register('textIa', {
              required: 'O campo é requerido.',
            })}
          />
          <p className="form-paragraph">
            <strong>Observação:</strong>&nbsp;O Item <strong>"%2"</strong> deve
            ficar na posição do texto do título do segmento de mercado, pois
            esse item é o título da categoria atual e isso será usado para
            melhoria da busca da IA. Exemplo: Vamos criar 5 ideias para um
            briefing usando um Gatilho Criativo da categoria{' '}
            <strong>"%2"</strong>.
          </p>
          <p className="form-paragraph">
            O item <strong>"%1"</strong> é opcional, no lugar dele será
            substituído o formato de resposta que IA irá gerar.
          </p>
          <Button
            buttonText="Salvar"
            variant="contained"
            buttonSize={160}
            className="lightbox-category-market-button"
            isLoading={isRegisterSegment}
          />
        </form>
      </LightboxCategoryMarketContainer>
    </LightBox>
  );
};

export default LightboxCategoryMarket;
