import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import { DashboardConfigCardContainer } from './styles';
import { useLocation } from 'react-router-dom';
import TabsRoute from '../tabs/TabsRoute';
import AuthContext from '../../contexts/AuthContext';

interface IProps {
  children: React.ReactNode;
}

const DashboardConfigCard: React.FC<
  IProps & HTMLAttributes<HTMLDivElement>
> = ({ children, ...props }) => {
  const [routeSplitted, setRouteSplitted] = useState<string>('');
  const { pathname } = useLocation();
  const { user } = useContext(AuthContext);

  const getUserTabs = {
    user: (
      <TabsRoute
        routes={[
          {
            link: '/dashboard/perfil',
            label: 'Perfil',
            isActive: routeSplitted === 'perfil',
          },
          {
            link: '/dashboard/password',
            label: 'Senha',
            isActive: routeSplitted === 'password',
          },
        ]}
      />
    ),
    admin: (
      <TabsRoute
        isCenteredInMobile
        routes={[
          {
            link: '/dashboard/usuarios-cadastrados',
            label: 'Usuários Cadastrados',
            isActive: routeSplitted === 'usuarios-cadastrados',
          },
          {
            link: '/dashboard/membros-da-equipe',
            label: 'Membros da equipe',
            isActive: routeSplitted === 'membros-da-equipe',
          },
          {
            link: '/dashboard/perfil',
            label: 'Perfil',
            isActive: routeSplitted === 'perfil',
          },
          {
            link: '/dashboard/senha',
            label: 'Senha',
            isActive: routeSplitted === 'senha',
          },
        ]}
      />
    ),
    moderator: (
      <TabsRoute
        isCenteredInMobile
        routes={[
          {
            link: '/dashboard/perfil',
            label: 'Perfil',
            isActive: routeSplitted === 'perfil',
          },
          {
            link: '/dashboard/senha',
            label: 'Senha',
            isActive: routeSplitted === 'senha',
          },
        ]}
      />
    ),
  };

  /** I need to refact this function to reusable in all application */
  const routeSplit = useCallback(() => {
    let route = pathname.split('/', 4);
    setRouteSplitted(route[2]);
  }, [pathname]);

  useEffect(() => {
    routeSplit();
  }, [pathname, routeSplit]);
  /*****************************************************************/

  return (
    <DashboardConfigCardContainer {...props}>
      <h2 className="heading-2">Configuração</h2>
      {(getUserTabs as any)[!!user?.role ? user.role.toString() : 'user']}
      {children}
    </DashboardConfigCardContainer>
  );
};

export default DashboardConfigCard;
