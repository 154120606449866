import React from 'react';
import Button from '../../../components/Button';
import { BannerHomeStyled } from './styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTheme } from 'styled-components';

import CustomRightArrow from './CustomRightArrow';
import CustomLeftArrow from './CustomLeftArrow';
import CustomDots from './CustomDots';
import { Link, useNavigate } from 'react-router-dom';
import ArrowDown from '../../../components/svgs/ArrowDown';
import { HashLink } from 'react-router-hash-link';

const BannerHome: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <BannerHomeStyled>
      <Carousel
        responsive={responsive}
        infinite={true}
        draggable={true}
        pauseOnHover
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        customDot={<CustomDots />}
        autoPlay
        autoPlaySpeed={8000}
        renderDotsOutside
        showDots
        dotListClass="dots-box"
      >
        <div className="content">
          <div className="box-content">
            <h1 className="heading-1">
              Ferramentas, inspirações e IA para criar ideias com potencial transformador.
            </h1>
            <div className="buttons-box">
              <Button
                variant="contained"
                buttonText="Seja um Criativista"
                type="button"
                onClick={() => navigate('/sign-up')}
              />
            </div>
          </div>
          <div className="box-image box-image--1"></div>
        </div>
        <div className="content">
          <div className="box-content">
            <h1 className="heading-1">
            Use o baralho de gatilhos criativos para ter ideias mais inovadoras.
            </h1>
            <p className="paragraph">
              Os Gatilhos Criativos ajudam a ter insights menos comuns, as
              referências ajudam a ter mais ideias e a IA ajuda a dobrar a
              quantidade de ideias para o seu desafio.
            </p>
            <div className="buttons-box">
              <Button
                variant="contained"
                buttonText="Gerar Gatilho Criativo"
                type="button"
                onClick={() => navigate('/gatilhos-criativos')}
              />
              <HashLink
                className="link"
                smooth
                to="#content-video-1"
                scroll={(el) =>
                  el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
              >
                <span className="link-span">Como funciona?</span>
              </HashLink>
            </div>
          </div>
          <div className="box-image box-image--2"></div>
        </div>
        <div className="content">
          <div className="box-content">
            <h1 className="heading-1">
              Participe das Salas de Brainstorming para trocar ideias, dicas e
              inspirações
            </h1>
            <p className="paragraph">
              Sinta-se livre para pedir dicas, referências, feedbacks e ideias.
              E não esqueça de contribuir ajudando outros criativos.
            </p>
            <div className="buttons-box">
              <Button
                variant="contained"
                buttonText="Participar das Salas de Brainstorming"
                onClick={() => navigate('/salas-de-brainstorming')}
              />
              <HashLink className="link" smooth to="#content-video-2">
                <span className="link-span">Como funciona?</span>
              </HashLink>
            </div>
          </div>
          <div className="box-image box-image--3"></div>
        </div>
        <div className="content">
          <div className="box-content">
            <h1 className="heading-1">
              Crie ideias com propósito a partir de desafios reais.
            </h1>
            <p className="paragraph">
              Potencialize seu portfólio com boas ideias e ainda ajude a
              transformar realidades.
            </p>
            <div className="buttons-box">
              <Link className="link" to="/desafios-criativistas">
                <Button
                  variant="contained"
                  buttonText="Escolher desafio"
                  onClick={() => navigate('/desafios-criativistas')}
                />
              </Link>
            </div>
          </div>
          <div className="box-image box-image--4"></div>
        </div>
        <div className="content">
          <div className="box-content">
            <span className="upper-heading">Tá quente, morno ou frio?</span>
            <h1 className="heading-1">
              Quer saber a temperatura da sua ideia?
            </h1>
            <p className="paragraph">
              Com o Termômetro de Ideias você vai poder descobrir se a sua ideia
              está no caminho certo e receber feedbacks.
            </p>
            <div className="buttons-box">
              {/* <Link className="link" to="/em-desenvolvimento"> */}
              <Button
                variant="contained"
                buttonText="Descubra agora"
                onClick={() => navigate('/termometro-de-ideias')}
              />
              {/* </Link> */}
            </div>
          </div>
          <div className="box-image box-image--5"></div>
        </div>
      </Carousel>
      <ArrowDown className="banner-home-arrow-down" />
    </BannerHomeStyled>
  );
};

export default BannerHome;
