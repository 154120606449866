import styled from 'styled-components';

export const FooterStyled = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;

  background-color: transparent;
  padding: 6.4rem 6rem;

  @media ${(props) => props.theme.medias.laptopSmall} {
    padding: 3.2rem 6rem;
  }

  .content {
    display: flex;
    justify-content: space-between;
    gap: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
      align-items: center;
    }
  }

  .logo-column {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    grid-column: center-start / col-end 4;

    .social-media-container {
      display: flex;
      gap: 3rem;
      margin-bottom: 1.6rem;

      .icon-box {
        display: flex;
        align-items: center;
        width: max-content;
        gap: 0.8rem;
        padding: 1rem;
        font-weight: ${(props) => props.theme.typeFaceWeight.regular};
        font-size: 1.6rem;
        line-height: 1.9rem;
        position: relative;

        &:hover {
          color: ${(props) => props.theme.pallete.colors.common.black};
        }
      }

      .icon-box::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: ${(props) => props.theme.pallete.colors.primary.main};
        transition: width 0.4s cubic-bezier(1, 0, 0, 1);
      }
    }

    .logo {
      transition: ${(props) => props.theme.transitions('default', 0.15)};
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }

    .staff-creators {
      display: block;
      width: 100%;
      @media ${(props) => props.theme.medias.laptopSmall} {
        text-align: center;
      }
    }

    @media ${(props) => props.theme.medias.laptopSmall} {
      grid-column: center-start / center-end;
      align-items: center;
    }
  }

  .navigation-column {
    grid-column: col-start 5 / col-end 7;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    min-width: 20rem;

    .navigation-column-heading {
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    }

    span {
      font-size: 1.6rem;
      color: ${(props) => props.theme.pallete.colors.common.black};
      line-height: 1.9rem;
      padding: 0.8rem;
      display: inline-block;
      max-width: max-content;
    }

    span:not(:first-child) {
      cursor: pointer;
    }

    @media ${(props) => props.theme.medias.laptopSmall} {
      grid-column: center-start / center-end;
      align-items: center;
    }
  }

  .navigation-feedback {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 1.6rem;
    min-width: 20rem;
    margin-top: 3rem;

    .navigation-column-heading {
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    }

    span {
      font-size: 1.6rem;
      color: ${(props) => props.theme.pallete.colors.common.black};
      line-height: 1.9rem;
      padding: 0.8rem;
      display: inline-block;
      max-width: max-content;
    }

    span:not(:first-child) {
      cursor: pointer;
    }

    @media ${(props) => props.theme.medias.laptopSmall} {
      grid-column: center-start / center-end;
      align-items: center;
    }
  }

  .navigation-column-heading-item {
    transition: all 0.2s;
    &:hover {
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    }
  }

  .payments-column {
    grid-column: col-start 8 / center-end;

    @media ${(props) => props.theme.medias.laptopSmall} {
      grid-column: center-start / center-end;
      justify-content: center;
    }

    .payments-container {
      display: flex;
      justify-content: end;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      gap: 0.8rem;

      @media ${(props) => props.theme.medias.laptopSmall} {
        justify-content: center;
      }
    }

    .paid-market {
      position: relative;
      display: flex;
      justify-content: end;
      max-width: max-content;
      margin-left: auto;
      margin-bottom: 2.4rem;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${(props) => props.theme.pallete.colors.common.black};
      }

      @media ${(props) => props.theme.medias.laptopSmall} {
        justify-content: center;
        margin: 0 auto 2.4rem;
      }
    }

    .terms {
      margin-top: 8rem;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 0.8rem;

      span {
        color: ${(props) => props.theme.pallete.colors.common.black};
        cursor: pointer;
      }

      span:hover {
        text-shadow: 1px 0 0 currentColor;
      }

      @media ${(props) => props.theme.medias.laptopSmall} {
        margin-top: 4rem;
      }
    }
  }

  .link {
    text-decoration: none;
  }

  .link-email {
    text-decoration: none;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    position: relative;
    transition: all 0.3rem;
    word-break: break-all;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.pallete.colors.common.black};
    }
  }

  .copyright-column {
    grid-column: center-start / center-end;
    gap: 12rem;
    display: flex;
    justify-content: center;

    padding: 12rem 0 0;

    @media ${(props) => props.theme.medias.laptopSmall} {
      padding: 4rem 0 0;
    }
  }
`;
