import styled, { css } from 'styled-components';

interface IDotProps {
  active: boolean;
}

export const BannerHomeStyled = styled.header`
  background-color: ${(props) =>
    props.theme.pallete.colors.backgrounds.neutral};
  width: 100%;
  min-height: 70vh;
  display: block;
  position: relative;

  @media ${(props) => props.theme.medias.laptopSmall} {
    height: calc(100vh - 16rem);
    margin-top: 11rem;
  }

  .content {
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 65vh;
    padding: 0 12rem;
    gap: 3;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
      padding: 0 6rem;
      height: 75vh;
    }

    @media ${(props) => props.theme.medias.mobile} {
      padding: 0 3rem;
    }
  }

  .word-break {
    display: inline-block;

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: none;
    }
  }

  .box-content {
    margin: auto 0;
    min-width: 50%;
    width: 100%;
    padding-bottom: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 100%;
      text-align: center;
      height: 65vh;
      margin: 6rem 0 0;
    }
  }

  .heading-1 {
    font-size: 3.2rem;
    line-height: 3.8rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
    margin-bottom: 3rem;
  }

  .paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    margin-bottom: 3.2rem;
    line-height: 2.8rem;
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
  }

  .link {
    text-decoration: none;
  }

  .link-span {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    font-size: 1.6rem;
    position: relative;

    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      bottom: -0.8rem;
      left: 0;
      height: 3px;
      width: 0.001%;
      background-color: currentColor;
      transition: width 0.4s cubic-bezier(1, 0, 0, 1);
    }

    &:hover::after {
      width: 100%;
    }
  }

  .buttons-box {
    display: flex;
    align-items: center;
    gap: 3.2rem;
  }

  .box-image {
    height: fit-content;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    border-radius: 2rem;
    width: 100%;
    height: 60vh;
    margin: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      background-position: center;
      margin: 0 auto;
      height: inherit;
    }

    &--1 {
      background-image: url('/static/images/banner-home/image-banner-1.png');
    }
    &--2 {
      background-image: url('/static/images/banner-home/image-banner-2.png');
    }
    &--3 {
      background-image: url('/static/images/banner-home/image-banner-3.png');
    }
    &--4 {
      background-image: url('/static/images/banner-home/image-banner-4.png');
    }
    &--5 {
      background-image: url('/static/images/banner-home/image-banner-5.gif');
      filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
    }
  }

  .upper-heading {
    margin-bottom: 0.8rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    font-size: 2.4rem;
    line-height: 120%;
  }

  .arrow-left,
  .arrow-right {
    position: absolute;
    top: 50%;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @media ${(props) => props.theme.medias.mobile} {
      display: none;
    }
  }

  .arrow-left {
    left: 3.2rem;
  }

  .arrow-right {
    right: 1.6rem;
  }

  .dots-box {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin-top: 3rem;
    max-width: 16rem;
    bottom: 4.8em;

    @media ${(props) => props.theme.medias.laptopSmall} {
      bottom: 1.6rem;
    }
  }

  .react-multi-carousel-dot-list {
    left: 12rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .banner-home-arrow-down {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: none;
    }
  }
`;

export const Dot = styled.div<IDotProps>`
  ${(props) =>
    props.active
      ? css`
          width: 6rem;
          background-color: ${(props) =>
            props.theme.pallete.colors.brandPrimary.yellow[6]};
          border-radius: 100px;
        `
      : css`
          width: 1.25rem;
          background-color: ${(props) =>
            props.theme.pallete.colors.brandPrimary.gray[7]};
          border-radius: 50%;
        `}
  height: 1.25rem;
  cursor: pointer;
`;
