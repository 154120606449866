import React, { useContext, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import Button from '../../../components/Button';
import { BannerThermometerStyled, ModalFeedback } from './styles';
import ToastfyContext from '../../../contexts/ToastfyContext';
import AuthContext from '../../../contexts/AuthContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { IGenerateFeedbackIARequest } from '../../../services/thermometerIdeas.types';
import { generateFeedbackIAService } from '../../../services/thermometerIdeas.service';
import LightboxWithoutLogin from '../../../components/lightboxes/LightboxWithoutLogin';
import LightBox from '../../../components/lightboxes/LightBox';
import Spinner from '../../../components/loadings/Spinner';
import SeparateLine from '../../../components/SeparateLine';
import Textfield from '../../../components/inputs/Textfield';
import TextArea from '../../../components/inputs/TextArea';

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  setIsSignPlanModal: (value: boolean) => void;
  setIsGeneratedAnswersModal: (value: boolean) => void;
  setGeneratedAnswers: (value: string) => void;
  scrollToIA: () => void;
}

interface IInputFields {
  title: string;
  description: string;
  goals: string;
  targetAudience: string;
  currentState: string;
  channel: string;
  specificAnswer: string;
}

const BannerThermometer: React.FC<IProps> = ({
  onClick,
  setIsGeneratedAnswersModal,
  setIsSignPlanModal,
  scrollToIA,
  setGeneratedAnswers,
}) => {
  const { handleToastfy } = useContext(ToastfyContext);
  const { user, isAuthenticated, isValidUser, signOut } =
    useContext(AuthContext);
  const theme = useTheme();

  // const { user } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IInputFields>({});

  const navigate = useNavigate();

  const [isLoadingAnswersIA, setIsLoadingAnswersIA] = useState<boolean>(false);
  const [isModalConfirmFeedback, setIsModalConfirmFeedback] = useState(false);
  const [isModalFeedback, setIsModalFeedback] = useState(false);

  const handleOpenModal = () => {
    setIsModalFeedback((prev) => !prev);
  };

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsGeneratedAnswersModal(false);

    setIsLoadingAnswersIA((prev) => !prev);
    const dataSend: IGenerateFeedbackIARequest = {
      title: data.title,
      description: data.description,
      goals: data.goals,
      targetAudience: data.targetAudience,
      currentState: data.currentState,
      channel: data.channel,
      specificAnswer: data.specificAnswer,
    };

    await generateFeedbackIAService(dataSend)
      .then((response) => {
        setGeneratedAnswers(response.data.message.content);
        setIsGeneratedAnswersModal(true);
        setIsLoadingAnswersIA((prev) => !prev);
        handleOpenModal();
        scrollToIA();
        isValidUser();
        handleToastfy({
          message: 'Feedback gerado pela inteligência artificial com sucesso!',
          type: 'success',
        });
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingAnswersIA((prev) => !prev);
      });
  };

  return (
    <BannerThermometerStyled>
      <div className="content">
        <div className="box-content">
          <h1 className="heading-1">Termômetro de Ideias</h1>
          <p className="paragraph">
            Aqui a gente te ajuda a avaliar suas ideias e planejar <br />{' '}
            caminhos para potencializá-las. Vamos lá?!
          </p>
          {/* <div className="buttons-box">
            <Button
              variant="contained"
              buttonText="Começar"
              type="button"
              onClick={onClick}
            />
          </div> */}
          <div className="buttons-box">
            <Button
              variant="contained"
              buttonText="Quiz do Termômetro"
              onClick={onClick}
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
              buttonSize={150}
            />
            <div className="button-points-box">
              <Button
                variant="contained"
                buttonText="Pedir Feedback para IA"
                type="button"
                onClick={() => {
                  if (isAuthenticated) {
                    if (!user?.balance) {
                      handleToastfy({
                        message: 'Ops, você não tem créditos suficiente',
                        type: 'error',
                      });
                    } else {
                      setIsModalConfirmFeedback((prev) => !prev);
                    }
                  } else {
                    setIsSignPlanModal(true);
                  }
                }}
              />
              {user?._id && (
                <span className="button-points-span">
                  Você tem {!!user?.balance ? user.balance : 0} créditos
                </span>
              )}
            </div>
          </div>

          <div className="box-ia">
            <div className="box-image-ia box-image-ia--1"></div>
            <p>
              Ao “Pedir Feedback para IA”, nossa IA vai analisar sua ideia, dar
              feedbacks, sugerir melhorias e novos desdobramentos.
            </p>
          </div>
        </div>
        <div className="box-image box-image--1"></div>
      </div>

      {isModalConfirmFeedback && (
        <LightboxWithoutLogin
          handleOpenModal={() => {
            setIsModalConfirmFeedback((prev) => !prev);
          }}
          image={
            <img
              src="/static/images/modal_thermometer_ideas.svg"
              alt="Thermometer ideas"
            />
          }
        >
          <h3>Você acabou de solicitar um Feedback!</h3>
          <p>
            Opa, você solicitou um Feedback para nossa IA! O feedback irá lhe
            custar 6 créditos. Vamos lá?
          </p>

          <div className="buttons-box">
            <Button
              buttonText="Voltar"
              variant="contained"
              type="button"
              // className="whithout-login-button"
              onClick={() => setIsModalConfirmFeedback((prev) => !prev)}
              customColors={{
                backgroundColor: theme.pallete.colors.common.white,
                hoverBackgroundColor: theme.pallete.colors.common.white,
                hoverTextColor: theme.pallete.colors.common.black,
                textColor: theme.pallete.colors.common.black,
              }}
            />
            <Button
              buttonText="Vamos!"
              variant="contained"
              type="button"
              // className="whithout-login-button"
              onClick={() => {
                setIsModalConfirmFeedback((prev) => !prev);
                setIsModalFeedback((prev) => !prev);
              }}
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.common.black,
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
            />
          </div>
        </LightboxWithoutLogin>
      )}
      {isModalFeedback && (
        <LightBox
          handleOpenModal={() => setIsModalFeedback((prev) => !prev)}
          customLightboxWidth="114rem"
        >
          <ModalFeedback>
            {isLoadingAnswersIA ? (
              <div className="loading-ia">
                <div className="loading-ia-content-phrase">
                  <p className="loading-ia-paragraph loading-ia-paragraph--1">
                    Aguarde enquanto estou lendo a sua ideia...
                  </p>
                  <p className="loading-ia-paragraph loading-ia-paragraph--2">
                    Conectando os parâmetros...
                  </p>
                  <p className="loading-ia-paragraph loading-ia-paragraph--3">
                    Queimando os miolos eletrônicos para gerar os feedbacks...
                  </p>
                </div>
                <div className="loading-ia-spinner">
                  <Spinner size={48} />
                </div>
              </div>
            ) : (
              <React.Fragment>
                <h2 className="creative-trigger-ia-heading-2">
                  Melhore suas ideias com a nossa IA usando o termômetro de
                  ideias
                </h2>
                <p className="creative-trigger-ia-paragraph">
                  Preencha o maior número de informações possíveis sobre sua
                  ideia para que a nossa IA seja ainda mais assertiva.
                </p>
                <SeparateLine
                  margin="3rem 0"
                  color={theme.pallete.colors.brandPrimary.gray[2]}
                />

                <form
                  className="creative-trigger-ia-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Textfield
                    label="Título da Ideia"
                    type="text"
                    placeholder="Um breve título que capture a essência da ideia.
              "
                    error={errors.title?.message}
                    {...register('title')}
                  />
                  <TextArea
                    label="Descrição Detalhada"
                    type="text"
                    placeholder="Um espaço para a descrição detalhada da ideia, incluindo o conceito central, como ela funciona, e qualquer contexto relevante que possa afetar sua execução.
              "
                    maxCharacter={600}
                    error={errors.description?.message}
                    {...register('description')}
                    customCountCharacters={watch(`description`)?.length}
                  />
                  <Textfield
                    label="Objetivos e Metas"
                    type="text"
                    placeholder="Qual é o objetivo principal da ideia? 
              "
                    error={errors.goals?.message}
                    {...register('goals')}
                  />

                  <Textfield
                    label="Público-Alvo"
                    type="text"
                    placeholder="Informações sobre quem é o público-alvo da ideia.
              "
                    error={errors.targetAudience?.message}
                    {...register('targetAudience')}
                  />
                  <Textfield
                    label="Estado Atual"
                    type="text"
                    placeholder="Em que estágio de desenvolvimento a ideia está? É apenas um conceito, um protótipo ou já está em fase de implementação?

              "
                    error={errors.currentState?.message}
                    {...register('currentState')}
                  />

                  <Textfield
                    label="Canais de Implementação"
                    type="text"
                    placeholder="Em que canais ou meios a ideia será implementada? Por exemplo, mídia social, publicidade impressa, online, eventos, etc.

              "
                    error={errors.channel?.message}
                    {...register('channel')}
                  />

                  <Textfield
                    label="Perguntas Específicas para a IA"
                    type="text"
                    placeholder="Quais perguntas específicas você tem sobre a ideia que gostaria que a IA abordasse?.
              "
                    error={errors.specificAnswer?.message}
                    {...register('specificAnswer')}
                  />

                  <Button
                    buttonText={`Gerar Feedback (-${6} crédito)`}
                    variant="contained"
                    type="submit"
                    // isLoading={isLoadingAnswersIA}
                  />
                </form>
              </React.Fragment>
            )}
          </ModalFeedback>
        </LightBox>
      )}
    </BannerThermometerStyled>
  );
};

export default BannerThermometer;
