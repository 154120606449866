import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BsEye } from 'react-icons/bs';
import { FiEdit3 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { useTheme } from 'styled-components';
import SeparateLine from '../../../../components/SeparateLine';
import TableAsync from '../../../../components/TableAsync';
import Spinner from '../../../../components/loadings/Spinner';
import AuthContext from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
import { listCreativeTriggerService } from '../../../../services/creativeTriggers.service';
import { IListCreativeTriggerResponse } from '../../../../services/creativeTriggers.types';
import { CreativeTriggerListContainer } from './styles';
import { listMarketSegmentService } from '../../../../services/marketSegments.service';
import { IListMarketSegmentData } from '../../../../services/marketSegments.types';

interface ITriggerData {
  created_at: string;
  label: string;
  received_projects: number;
  category: string;
  edit: React.ReactNode;
  read: React.ReactNode;
}

const CreativeTriggerList: React.FC = () => {
  const { handleToastfy } = useContext(ToastfyContext);
  const [creativeTriggers, setCreativeTriggers] =
    useState<IListCreativeTriggerResponse>({} as IListCreativeTriggerResponse);
  const [loadingTriggerList, setLoadingTriggerList] = useState<boolean>(true);
  const [pageSizeCreativeTriggerAsync, setPageSizeCreativeTriggerAsync] =
    useState<number>(10);
  const [marketSegments, setMarketSegments] =
    useState<IListMarketSegmentData[]>();
  const [pageIndexCreativeTriggerAsync, setPageIndexCreativeTriggerAsync] =
    useState<number>(0);
  const [isCreativeTriggerTableLoading, setIsCreativeTriggerTableLoading] =
    useState<boolean>(false);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const creativeTriggersColumns: Column<ITriggerData>[] = useMemo(
    (): Column<ITriggerData>[] => [
      {
        Header: 'Data da Criação',
        accessor: 'created_at',
      },
      {
        Header: 'Título',
        accessor: 'label',
      },
      {
        Header: 'Projetos recebidos',
        accessor: 'received_projects',
      },
      {
        Header: 'Categoria',
        accessor: 'category',
      },
      {
        Header: 'Editar',
        accessor: 'edit',
        disableSortBy: true,
      },
      {
        Header: 'Ver',
        accessor: 'read',
        disableSortBy: true,
      },
    ],
    []
  );

  const creativeTriggersData = useMemo(
    (): ITriggerData[] =>
      creativeTriggers.data?.map((creativeTrigger) => {
        return {
          created_at: moment(creativeTrigger.createdAt)
            .locale('pt-br')
            .format('DD/MM/YYYY'),
          label: creativeTrigger.label,
          received_projects: 0, //Verificar o valor do projeto recebido
          category:
            marketSegments?.find(
              (segment) => segment._id === creativeTrigger.marketSegmentId
            )?.label ?? '',
          edit: (
            <FiEdit3
              size={20}
              onClick={() =>
                navigate(
                  `/dashboard/editar-gatilho-criativo?id=${creativeTrigger._id}`
                )
              }
              style={{ cursor: 'pointer', userSelect: 'none' }}
            />
          ),
          read: (
            <BsEye
              size={20}
              onClick={() =>
                navigate(
                  `/dashboard/ver-conteudo-gatilho-criativo?id=${creativeTrigger._id}`
                )
              }
              style={{ cursor: 'pointer', userSelect: 'none' }}
            />
          ),
        };
      }),
    [creativeTriggers.data, marketSegments, navigate]
  );

  const getListCreativeTrigger = useCallback(async () => {
    setLoadingTriggerList(true);
    setIsCreativeTriggerTableLoading(true);
    await listCreativeTriggerService({
      order: 'DESC',
      orderBy: 'createdAt',
      perPage: pageSizeCreativeTriggerAsync,
      page: pageIndexCreativeTriggerAsync + 1,
    })
      .then((response) => {
        setCreativeTriggers(response);
        setLoadingTriggerList(false);
        setIsCreativeTriggerTableLoading(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsCreativeTriggerTableLoading(false);
      });
  }, [
    handleToastfy,
    navigate,
    pageIndexCreativeTriggerAsync,
    pageSizeCreativeTriggerAsync,
    signOut,
  ]);

  const getSegmentsMarket = useCallback(async () => {
    setIsCreativeTriggerTableLoading(true);
    await listMarketSegmentService({
      order: 'DESC',
      orderBy: 'createdAt',
      perPage: 1000,
      isActive: true,
    })
      .then((response) => {
        setMarketSegments(response.data);
        setIsCreativeTriggerTableLoading(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsCreativeTriggerTableLoading(true);
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    getListCreativeTrigger();
  }, [getListCreativeTrigger]);

  useEffect(() => {
    getSegmentsMarket();
  }, [getSegmentsMarket]);

  return (
    <CreativeTriggerListContainer>
      <h2 className="heading-2 heading-2--margin">Gatilhos Criativos</h2>
      <SeparateLine
        margin="0 0 5rem"
        color={theme.pallete.colors.brandPrimary.yellow[6]}
        lineHeight="3px"
      />
      {loadingTriggerList ? (
        <div className="creative-trigger-list-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <TableAsync
          columns={creativeTriggersColumns}
          data={creativeTriggersData}
          pageSizeAsync={pageSizeCreativeTriggerAsync}
          setPageSizeAsync={setPageSizeCreativeTriggerAsync}
          pageIndexAsync={pageIndexCreativeTriggerAsync}
          setPageIndexAsync={setPageIndexCreativeTriggerAsync}
          totalItems={creativeTriggers.total}
          isTableLoading={isCreativeTriggerTableLoading}
        />
      )}
    </CreativeTriggerListContainer>
  );
};

export default CreativeTriggerList;
