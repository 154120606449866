import styled from 'styled-components';

export const EmailValidationContainer = styled.div`
  padding: 0rem 3rem 6rem;
  background-image: linear-gradient(#fff 50%, transparent 0);
  position: relative;

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 12rem;
  }

  .email-verification-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    min-width: 40rem;
    max-width: 56rem;
    margin: 0 auto 8rem;
  }

  .lamp-idea {
    background-image: url('/static/images/lamp-paper.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 18rem;
    height: 17rem;
  }

  .heading-2 {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5.8rem;
    margin: 0;
    text-align: center;
  }

  .subtitle {
    width: 80%;
    margin: 0 auto;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-size: 1.6rem;
    line-height: 3rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};
    text-align: center;

    a {
      text-decoration: none;
    }
  }

  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.6rem;
  }

  .link {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    font-size: 1.6rem;
    position: relative;

    ${(props) => props.theme.mixins.linkUnderlineEffect};
  }
`;
