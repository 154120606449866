import React from 'react';
import Navigation from '../templates/Navigation';
import Footer from '../templates/Footer';
import { ErrorContainer } from './styles';
import PageNotFoundSVG from '../../components/svgs/PageNotFoundSVG';
import Button from '../../components/Button';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Error: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <React.Fragment>
      <Navigation />
      <ErrorContainer>
        <div className="error-content">
          <PageNotFoundSVG />
          <div className="error-block-right">
            <h2 className="error-block-heading-2">
              Opa, tivemos um probleminha por aqui.
            </h2>
            <p className="error-block-paragraph">
              Verifique se a URL está correta. Talvez sua mente criativa tenha
              digitado algo inovador demais. :D
            </p>
            <p className="error-block-paragraph">
              Se o problema persistir, entre em contato com a gente!
            </p>
            <div className="error-block-button-group">
              <Button
                buttonText="Reportar problema"
                variant="contained"
                type="button"
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                onClick={() => navigate('em-desenvolvimento')}
              />
              <Button
                buttonText="Recarregar página"
                variant="contained"
                type="button"
                onClick={() => window.location.reload()}
              />
            </div>
          </div>
        </div>
      </ErrorContainer>
      <Footer />
    </React.Fragment>
  );
};

export default Error;
