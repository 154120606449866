import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import BrainstormingMessage from '../../../components/BrainstormingMessage';
import Button from '../../../components/Button';
import TextArea from '../../../components/inputs/TextArea';
import LightBox from '../../../components/lightboxes/LightBox';
import AuthContext from '../../../contexts/AuthContext';
import ToastfyContext from '../../../contexts/ToastfyContext';
import { reportMessageRoomService } from '../../../services/brainstormingRoom.service';
import {
  IMessageRoom,
  IReportMessageRoomRequest,
} from '../../../services/brainstormingRoom.types';
import { ReportMessagesContainer } from './styles';
import lodash from 'lodash';

interface IProps {
  setIsOpenReportMessage: Dispatch<SetStateAction<boolean>>;
  brainstormingRoomId: string;
  message: IMessageRoom;
}

interface IInputFields {
  message: string;
}

const ReportMessages: React.FC<IProps> = ({
  setIsOpenReportMessage,
  brainstormingRoomId,
  message,
}) => {
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const [isReportMessageLoading, setIsReportMessageLoading] =
    useState<boolean>(false);
  const [isReport, setIsReport] = useState<boolean>(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IInputFields>({
    defaultValues: {
      message: '',
    },
  });

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsReportMessageLoading((prev) => !prev);
    const sendData: IReportMessageRoomRequest = {
      message: data.message,
      brainstormingRoomId,
      messageCode: message.code,
    };
    await reportMessageRoomService(sendData)
      .then((response) => {
        handleToastfy({
          message: 'Mensagem reportada com sucesso!',
          type: 'success',
        });
        setIsOpenReportMessage((prev) => !prev);
        setIsReportMessageLoading((prev) => !prev);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsReportMessageLoading((prev) => !prev);
      });
  };

  useEffect(() => {
    watch((value) => {
      setIsReport(lodash.values(value).every(lodash.isEmpty));
    });
  }, [watch]);

  return (
    <LightBox handleOpenModal={() => setIsOpenReportMessage((prev) => !prev)}>
      <ReportMessagesContainer>
        <BrainstormingMessage message={message} />
        <form
          className="brainstorming-create-room"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextArea
            label=""
            placeholder="Escreva sua mensagem"
            {...register('message', {
              required: 'O campo é requerido.',
            })}
            error={errors.message?.message}
            maxCharacter={300}
            customCountCharacters={watch(`message`)?.length}
          />
          <div className="report-messages-button-group">
            <Button
              buttonText="Cancelar"
              variant="contained"
              className="room-button-write-contribution"
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
              type="button"
              buttonSize={200}
              onClick={() => setIsOpenReportMessage((prev) => !prev)}
            />
            <Button
              buttonText="Reportar"
              variant="contained"
              className="room-button-write-contribution"
              buttonSize={200}
              isLoading={isReportMessageLoading}
              isDisabled={isReport}
              type="submit"
            />
          </div>
        </form>
      </ReportMessagesContainer>
    </LightBox>
  );
};

export default ReportMessages;
