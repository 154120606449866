import lodash from 'lodash';
import moment from 'moment';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
  AiTwotoneDislike,
} from 'react-icons/ai';
import { IoMdArrowBack } from 'react-icons/io';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { VscWarning } from 'react-icons/vsc';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import BrainstormingCommentMessage from '../../../components/BrainstormingCommentMessage';
import Button from '../../../components/Button';
import SeparateLine from '../../../components/SeparateLine';
import TextArea from '../../../components/inputs/TextArea';
import Textfield from '../../../components/inputs/Textfield';
import ImagePreview from '../../../components/lightboxes/ImagePreview';
import LightBox from '../../../components/lightboxes/LightBox';
import Spinner from '../../../components/loadings/Spinner';
import { AuthContext } from '../../../contexts/AuthContext';
import { ToastfyContext } from '../../../contexts/ToastfyContext';
import {
  deleteBrainstormingRoomService,
  likeMessageRoomService,
  showBrainstormingRoomService,
  updateBrainstormingRoomService,
} from '../../../services/brainstormingRoom.service';
import {
  ILikeMessageRoomRequest,
  IMessageRoom,
  IShowBrainstormingRoomData,
} from '../../../services/brainstormingRoom.types';
import CommonQuestions from '../../Home/CommonQuestions';
import EditMessages from '../EditMessages';
import ReportMessages from '../ReportMessages';
import SendMessages from '../SendMessages';
import { EditRoomContainer, RoomContainer } from './styles';
import Avatar from '../../../components/Avatar';

interface IEditRoomFields {
  label: string;
  description: string;
}

const Room: React.FC = () => {
  const [brainstormingRoom, setBrainstormingRoom] =
    useState<IShowBrainstormingRoomData>({} as IShowBrainstormingRoomData);
  const [isLoadingBrainstormingRoom, setIsLoadingBrainstormingRoom] =
    useState<boolean>(true);
  const { handleToastfy } = useContext(ToastfyContext);
  const { user, signOut, isAuthenticated } = useContext(AuthContext);
  const [isOpenLightbox, setIsOpenLightbox] = useState<boolean>(false);
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [openImageLink, setOpenImageLink] = useState<string>('');
  const [isEditComment, setIsEditComment] = useState<boolean>(false);
  const [isEditRoom, setIsEditRoom] = useState(false);
  const [loadingLikeCode, setLoadingLikeCode] = useState<string>('');
  const [editMessage, setEditMessage] = useState<IMessageRoom>(
    {} as IMessageRoom
  );
  const [reportMessage, setReportMessage] = useState<IMessageRoom>(
    {} as IMessageRoom
  );
  const [isOpenReportMessage, setIsOpenReportMessage] =
    useState<boolean>(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const calcRoomCreatedTime = (createdAt: Date) => {
    return moment().diff(createdAt, 'minutes');
  };

  const handleLikeMessageRoom = async ({
    messageCode,
    like,
    dislike,
    brainstormingRoomId,
  }: ILikeMessageRoomRequest) => {
    setLoadingLikeCode(messageCode);
    await likeMessageRoomService({
      messageCode,
      like,
      dislike,
      brainstormingRoomId,
    })
      .then((response) => {
        showBrainstormingRoomWithoutLoading(brainstormingRoomId);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  };

  //Refatorar as próximas duas funções para a parte de loading de likes e dislikes

  const showBrainstormingRoomWithoutLoading = useCallback(
    async (id: string) => {
      await showBrainstormingRoomService(id)
        .then((response) => {
          setBrainstormingRoom(response.data);
          setLoadingLikeCode('');
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          setLoadingLikeCode('');
        });
    },
    [handleToastfy, navigate, signOut]
  );

  const showBrainstormingRoom = useCallback(
    async (id: string) => {
      setIsLoadingBrainstormingRoom(true);
      await showBrainstormingRoomService(id)
        .then((response) => {
          setBrainstormingRoom(response.data);
          setIsLoadingBrainstormingRoom(false);
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
        });
    },
    [handleToastfy, navigate, signOut]
  );
  //------------------------------------------------------------------------------
  const handleOpenImage = (imageLink: string) => {
    setIsOpenImage((prev) => !prev);
    setOpenImageLink(imageLink);
  };

  const handleEditMessage = (message: IMessageRoom) => {
    setEditMessage(message);
    setIsEditComment((prev) => !prev);
  };

  const handleReportMessage = (message: IMessageRoom) => {
    setReportMessage(message);
    setIsOpenReportMessage((prev) => !prev);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const id = searchParams.get('id');
      !!id && showBrainstormingRoom(id);
    } else {
      handleToastfy({
        message: 'Sessão expirada!',
        type: 'error',
      });
      navigate('/sign-in');
    }
  }, [
    showBrainstormingRoom,
    searchParams,
    isAuthenticated,
    handleToastfy,
    navigate,
  ]);

  return (
    <RoomContainer>
      <Link to="/salas-de-brainstorming">
        <span className="return-page">
          <IoMdArrowBack size={24} />
          <span className="return-page-text">voltar</span>
        </span>
      </Link>
      <section className="room-content">
        <div className="room-content-card">
          {isLoadingBrainstormingRoom ? (
            <div className="brainstorming-room-spinner">
              <Spinner size={100} />
            </div>
          ) : (
            <BrainstormingCommentMessage
              buttonText={'Deixe um comentário'.toUpperCase()}
              onClick={() => setIsOpenLightbox((prev) => !prev)}
              roomData={brainstormingRoom}
              setIsEditRoom={setIsEditRoom}
            />
          )}
        </div>
        <div className="room-answers">
          {isLoadingBrainstormingRoom ? (
            <div className="brainstorming-room-spinner">
              <Spinner size={100} />
            </div>
          ) : !!brainstormingRoom?.messages ? (
            <React.Fragment>
              <h3 className="room-answers-heading-3">
                Respostas (
                {!!brainstormingRoom?.messages
                  ? brainstormingRoom.messages.length
                  : 0}
                ):
              </h3>
              {brainstormingRoom?.messages
                .sort((a, b) => {
                  return (
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                  );
                })
                .map((message, index) => {
                  return (
                    <React.Fragment key={message.code}>
                      <div
                        key={message.createdAt.toString()}
                        className="room-answers-content"
                      >
                        <div className="room-answers-content-avatar">
                          {!!message.user?.avatar ? (
                            <Avatar
                              backgroundImageLink={`${process.env.REACT_APP_URL_IMAGE_BASE}/${message.user.avatar}`}
                              height="6rem"
                              width="6rem"
                            />
                          ) : (
                            <span className="brainstorming-message-avatar" />
                          )}
                        </div>
                        <div className="room-answers-content-box">
                          <div className="room-answers-content-texts">
                            <div className="room-answers-content-texts-header">
                              <p className="room-answers-content-title">
                                <span>
                                  {!!message.user?.username
                                    ? message.user?.username
                                    : 'Sem nome de usuário'}
                                </span>{' '}
                                {calcRoomCreatedTime(
                                  message.createdAt || new Date()
                                ) > 5 ? (
                                  <span className="brainstorming-message-time">
                                    &#8226;&nbsp; há {message?.createdAtMessage}
                                  </span>
                                ) : (
                                  <span className="brainstorming-message-time-new-room">
                                    &#8226;&nbsp; postou agora
                                  </span>
                                )}
                              </p>
                              {message.userId === user?._id && (
                                <div
                                  className="room-answers-content-texts-edit"
                                  onClick={() => handleEditMessage(message)}
                                >
                                  <MdOutlineModeEditOutline size={24} />
                                  <span>Editar</span>
                                </div>
                              )}
                            </div>
                            <p className="room-answers-content-paragraph">
                              {message.message}
                            </p>
                            {!!message.images.length && (
                              <div className="room-answers-content-images">
                                {message.images.map((image) => {
                                  return (
                                    <div
                                      key={image.code}
                                      className="room-answers-content-image-box"
                                    >
                                      <div
                                        className="room-answers-content-image"
                                        style={{
                                          backgroundImage: `url(${process.env.REACT_APP_URL_IMAGE_BASE}/${image.image})`,
                                        }}
                                        onClick={() =>
                                          handleOpenImage(
                                            `${process.env.REACT_APP_URL_IMAGE_BASE}/${image.image}`
                                          )
                                        }
                                      >
                                        <div className="room-answers-image-background">
                                          <span>Visualizar</span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          <div className="room-answers-content-icons">
                            {loadingLikeCode === message.code ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  width: '9rem',
                                }}
                              >
                                <Spinner size={24} />
                              </div>
                            ) : (
                              <React.Fragment>
                                <div className="room-answers-content-icon">
                                  {(() => {
                                    const likeFiltered = message.likes.filter(
                                      (like) => like.userId === user?._id
                                    );
                                    if (likeFiltered.length) {
                                      return (
                                        <AiFillLike
                                          size={24}
                                          color={
                                            theme.pallete.colors.brandPrimary
                                              .green[2]
                                          }
                                          onClick={() =>
                                            handleLikeMessageRoom({
                                              brainstormingRoomId:
                                                brainstormingRoom._id,
                                              dislike: false,
                                              like: false,
                                              messageCode: message.code,
                                            })
                                          }
                                        />
                                      );
                                    } else {
                                      return (
                                        <AiOutlineLike
                                          size={24}
                                          color={
                                            theme.pallete.colors.brandPrimary
                                              .green[2]
                                          }
                                          onClick={() =>
                                            handleLikeMessageRoom({
                                              brainstormingRoomId:
                                                brainstormingRoom._id,
                                              dislike: false,
                                              like: true,
                                              messageCode: message.code,
                                            })
                                          }
                                        />
                                      );
                                    }
                                  })()}
                                  <span className="room-answers-content-span room-answers-content-span--green">
                                    {!!message.likes ? message.likes.length : 0}
                                  </span>
                                </div>
                                <div className="room-answers-content-icon">
                                  {(() => {
                                    const dislikeFiltered =
                                      message.dislikes.filter(
                                        (like) => like.userId === user?._id
                                      );
                                    if (dislikeFiltered.length) {
                                      return (
                                        <AiTwotoneDislike
                                          size={24}
                                          color={
                                            theme.pallete.colors.brandPrimary
                                              .red[3]
                                          }
                                          onClick={() =>
                                            handleLikeMessageRoom({
                                              brainstormingRoomId:
                                                brainstormingRoom._id,
                                              dislike: false,
                                              like: false,
                                              messageCode: message.code,
                                            })
                                          }
                                        />
                                      );
                                    } else {
                                      return (
                                        <AiOutlineDislike
                                          size={24}
                                          color={
                                            theme.pallete.colors.brandPrimary
                                              .red[3]
                                          }
                                          onClick={() =>
                                            handleLikeMessageRoom({
                                              brainstormingRoomId:
                                                brainstormingRoom._id,
                                              dislike: true,
                                              like: false,
                                              messageCode: message.code,
                                            })
                                          }
                                        />
                                      );
                                    }
                                  })()}
                                  <span className="room-answers-content-span room-answers-content-span--red">
                                    {!!message.dislikes
                                      ? message.dislikes.length
                                      : 0}
                                  </span>
                                </div>
                              </React.Fragment>
                            )}
                            {message.userId === user?._id && (
                              <VscWarning
                                className="room-answers-content-warning-icon"
                                size={24}
                                color={theme.pallete.colors.common.black}
                                onClick={() => handleReportMessage(message)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {index + 1 < brainstormingRoom.messages.length && (
                        <SeparateLine lineHeight="1px" margin="1rem 0" />
                      )}
                    </React.Fragment>
                  );
                })}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3 className="room-answers-heading-3">
                Respostas (
                {!!brainstormingRoom?.messages
                  ? brainstormingRoom.messages.length
                  : 0}
                ):
              </h3>
              <span className="room-any-messages">
                Ninguém comentou ainda. Seja o primeiro a comentar!
              </span>
            </React.Fragment>
          )}
        </div>
      </section>
      {isOpenLightbox && (
        <SendMessages
          brainstormingRoom={brainstormingRoom}
          setIsOpenLightbox={setIsOpenLightbox}
          showBrainstormingRoom={showBrainstormingRoom}
        />
      )}
      {isOpenImage && (
        <ImagePreview
          setIsOpenImage={setIsOpenImage}
          linkImage={openImageLink}
        />
      )}
      {isEditComment && (
        <EditMessages
          brainstormingRoomId={brainstormingRoom?._id || ''}
          setIsEditComment={setIsEditComment}
          showBrainstormingRoom={showBrainstormingRoom}
          message={editMessage}
        />
      )}
      {isOpenReportMessage && (
        <ReportMessages
          setIsOpenReportMessage={setIsOpenReportMessage}
          brainstormingRoomId={brainstormingRoom?._id || ''}
          message={reportMessage}
        />
      )}
      {isEditRoom && (
        <EditRoom
          setIsEditRoom={setIsEditRoom}
          brainstormingRoom={brainstormingRoom}
          showBrainstormingRoom={showBrainstormingRoom}
        />
      )}
      <CommonQuestions className="common-questions" />
      <div className="room-background-white" />
      {/* <div className="room-background-image" /> */}
    </RoomContainer>
  );
};

export default Room;

interface IEditRoomProps {
  setIsEditRoom: Dispatch<SetStateAction<boolean>>;
  brainstormingRoom: IShowBrainstormingRoomData;
  showBrainstormingRoom: (id: string) => Promise<void>;
}

export const EditRoom: React.FC<IEditRoomProps> = ({
  setIsEditRoom,
  brainstormingRoom,
  showBrainstormingRoom,
}) => {
  const [isUpdateRoomLoading, setIsUpdateRoomLoading] = useState(false);
  const [isConfirmDeleteRoom, setIsConfirmDeleteRoom] =
    useState<boolean>(false);
  const [isDeleteRoomLoading, setIsDeleteRoomLoading] =
    useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IEditRoomFields>({
    defaultValues: {
      description: brainstormingRoom.description,
      label: brainstormingRoom.label,
    },
  });
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const onSubmit: SubmitHandler<IEditRoomFields> = async (data) => {
    setIsUpdateRoomLoading((prev) => !prev);
    await updateBrainstormingRoomService({
      description: data.description,
      label: data.label,
      id: brainstormingRoom._id,
    })
      .then((response) => {
        handleToastfy({
          message: 'Sala atualizada com sucesso!',
          type: 'success',
        });
        setIsUpdateRoomLoading((prev) => !prev);
        setIsEditRoom((prev) => !prev);
        showBrainstormingRoom(brainstormingRoom._id);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  };

  const handleRemoveRoom = async () => {
    setIsDeleteRoomLoading((prev) => !prev);
    await deleteBrainstormingRoomService(brainstormingRoom._id)
      .then((response) => {
        handleToastfy({
          message: 'Sala deletada com sucesso!',
          type: 'success',
        });
        setIsDeleteRoomLoading((prev) => !prev);
        setIsEditRoom((prev) => !prev);
        showBrainstormingRoom(brainstormingRoom._id);
        navigate('/salas-de-brainstorming');
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  };

  useEffect(() => {
    watch((value) => {
      setIsEdit(
        lodash.isEqual(
          {
            label: brainstormingRoom.label,
            description: brainstormingRoom.description,
          },
          value
        )
      );
    });
  }, [brainstormingRoom, watch]);

  return (
    <LightBox handleOpenModal={() => setIsEditRoom((prev) => !prev)}>
      <EditRoomContainer>
        <h3>Editar sala</h3>
        <SeparateLine
          margin="1.6rem 0"
          color={theme.pallete.colors.brandPrimary.gray[2]}
        />
        <form className="edit-create-room" onSubmit={handleSubmit(onSubmit)}>
          <Textfield
            label="Nome da sala"
            type="text"
            placeholder="Atualizar título"
            error={errors.label?.message}
            {...register('label', {
              required: 'O campo é requerido.',
            })}
          />
          <TextArea
            label="Descrição"
            maxCharacter={300}
            placeholder="Atualizar descrição"
            customCountCharacters={watch(`description`)?.length}
            error={errors.description?.message}
            {...register('description', {
              required: 'O campo é requerido.',
            })}
          />
          <div className="edit-room-button-group">
            <Button
              buttonText="Cancelar"
              variant="contained"
              className="edit-room-button"
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
              type="button"
              onClick={() => setIsEditRoom((prev) => !prev)}
              buttonSize={180}
            />
            <Button
              buttonText="Editar sala"
              variant="contained"
              className="edit-room-button"
              isLoading={isUpdateRoomLoading}
              type="submit"
              buttonSize={180}
              isDisabled={isEdit}
            />
          </div>
          <Button
            buttonText="Excluir sala"
            variant="outlined"
            type="button"
            className="creative-trigger-delete-button"
            customColors={{
              backgroundColor: theme.pallete.colors.brandPrimary.red[3],
              hoverBackgroundColor: theme.pallete.colors.brandPrimary.red[3],
              hoverTextColor: theme.pallete.colors.brandPrimary.red[3],
              textColor: theme.pallete.colors.brandPrimary.red[3],
            }}
            onClick={() => setIsConfirmDeleteRoom((prev) => !prev)}
            buttonSize={180}
          />
        </form>
        {isConfirmDeleteRoom && (
          <LightBox
            handleOpenModal={() => setIsConfirmDeleteRoom((prev) => !prev)}
            customLightboxWidth="60rem"
          >
            <h3 className="confirm-delete-room-h3">
              Deseja realmente excluir a sala?
            </h3>
            <div className="confirm-delete-button-group">
              <Button
                buttonText="Não"
                variant="contained"
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                buttonSize={160}
                onClick={() => setIsConfirmDeleteRoom((prev) => !prev)}
              />
              <Button
                buttonText="Sim"
                variant="contained"
                buttonSize={160}
                onClick={() => handleRemoveRoom()}
                isLoading={isDeleteRoomLoading}
              />
            </div>
          </LightBox>
        )}
      </EditRoomContainer>
    </LightBox>
  );
};
