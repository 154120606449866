import styled from 'styled-components';

export const CreateCreativeChallengeContainer = styled.div`
  .heading-2 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  .create-creative-challenge-line {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    span {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
      font-weight: 600;
      font-size: 1.6rem;
    }
  }

  .select-form-input-control-label {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: 600;
    line-height: 5rem;
    font-size: 1.6rem;
    user-select: none;
  }

  .react-select {
    &__control {
      width: 100%;
      min-height: 6rem;
      outline: 0;
      background: none;
      font-size: inherit;
      border: 1px solid #d7d7d7;
      border-radius: 100px;
      font-family: inherit;
      background-color: #ffffff;
      cursor: pointer;
    }

    &__value-container {
      width: 100%;
      min-height: 6rem;
      outline: 0;
      background: none;
      font-size: inherit;
      font-family: inherit;
      padding-left: 2rem;
      cursor: pointer;
    }

    &__menu {
      top: auto;
      bottom: 100%;
      border-radius: 1rem;
      overflow: hidden;
    }
  }

  .label-radio-group {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: 600;
    line-height: 5rem;
    font-size: 1.6rem;
  }

  .textfield-error {
    color: ${(props) => props.theme.pallete.colors.error.main};
    padding: 0.4rem 2rem 0;
  }

  .radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
  }

  .form-grid {
    display: grid;
    grid-template-columns: repeat(
      12,
      [col-start] minmax(min-content, 9.5rem) [col-end]
    );
    align-items: baseline;
    column-gap: 1.6rem;
    row-gap: 3rem;

    > :nth-child(1) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(2) {
      grid-column: col-start 1 / col-end 6;

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(3) {
      grid-column: col-start 7 / col-end 12;

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(4) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(5) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(6) {
      grid-column: col-start 1 / col-end 12;
    }

    /**
      The next item it's the reference line
    */

    > :nth-child(7) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(8) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(9) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(10) {
      grid-column: col-start 1 / col-end 12;
    }
  }

  .reference-content {
    display: grid;
    grid-template-columns: repeat(
      12,
      [col-start] minmax(min-content, 9.5rem) [col-end]
    );
    align-items: baseline;
    column-gap: 1.6rem;
    row-gap: 3rem;

    > :nth-child(1) {
      grid-column: col-start 1 / col-end 6;

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(2) {
      grid-column: col-start 7 / col-end 12;

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(3) {
      grid-column: col-start 1 / col-end 6;

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(4) {
      grid-column: col-start 7 / col-end 12;

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(5) {
      grid-column: col-start 1 / col-end 6;

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(6) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(7) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(8) {
      grid-column: col-start 1 / col-end 12;
    }
  }

  .reference-content:not(:last-child) {
    margin-bottom: 3rem;
  }

  .create-creative-challenge-remove-insight-box {
    display: flex;
    align-items: center;
    justify-self: right;
    gap: 0.8rem;
    width: max-content;
    user-select: none;
    cursor: pointer;

    span {
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.medium};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.red[3]};
    }

    svg {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.red[3]};
    }
  }

  .create-creative-challenge-new-insight-box {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    width: max-content;
    user-select: none;
    cursor: pointer;

    span {
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.medium};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    }

    svg {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    }
  }

  .create-creative-challenge-save-button {
    margin-left: auto;

    @media ${(props) => props.theme.medias.mobile} {
      margin: 0 auto;
    }
  }

  .creative-challenge-reference-text-group {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .creative-challenge-without-image {
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.medium};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    }
  }

  .creative-challenge-reference-text {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .creative-challenge-reference-image-container:hover
    .creative-challenge-reference-image-background {
    opacity: 1;
  }

  .creative-challenge-reference-image-container {
    position: relative;
    display: flex;
    width: 100%;
    cursor: pointer;
  }

  .creative-challenge-reference-image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;

    span {
      font-size: 2.4rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      color: ${(props) => props.theme.pallete.colors.common.white};
      padding: 3rem;
    }
  }

  .creative-challenge-reference-image {
    max-width: 60rem;
    width: 100%;
    border-radius: 1rem;
    cursor: pointer;
  }
`;
