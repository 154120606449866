import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BsEye } from 'react-icons/bs';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { useTheme } from 'styled-components';
import Button from '../../../../components/Button';
import SeparateLine from '../../../../components/SeparateLine';
import TableAsync from '../../../../components/TableAsync';
import LightBox from '../../../../components/lightboxes/LightBox';
import Spinner from '../../../../components/loadings/Spinner';
import AuthContext from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
import {
  deleteBrainstormingRoomService,
  listBrainstormingRoomService,
} from '../../../../services/brainstormingRoom.service';
import { IListBrainstormingRoomResponse } from '../../../../services/brainstormingRoom.types';
import { BrainstormingRoomsListContainer } from './styles';

interface IBrainstormingData {
  createdAt: string;
  name: string;
  description: string;
  totalMessages: number;
  read: React.ReactNode;
  delete: React.ReactNode;
}

const BrainstormingRoomsList: React.FC = () => {
  const [brainstormingRooms, setBrainstormingRooms] =
    useState<IListBrainstormingRoomResponse>(
      {} as IListBrainstormingRoomResponse
    );
  const [deleteRoomId, setDeleteRoomId] = useState<string>('');
  const [pageSizeBrainstormingRoomsAsync, setPageSizeBrainstormingRoomsAsync] =
    useState<number>(10);
  const [
    pageIndexBrainstormingRoomsAsync,
    setPageIndexBrainstormingRoomsAsync,
  ] = useState<number>(0);
  const [
    isBrainstormingRoomsTableLoading,
    setIsBrainstormingRoomsTableLoading,
  ] = useState<boolean>(false);
  const [isBrainstormingRoomsLoading, setIsBrainstormingRoomsLoading] =
    useState<boolean>(true);
  const [isConfirmDeleteRoom, setIsConfirmDeleteRoom] =
    useState<boolean>(false);
  const [isDeleteRoomLoading, setIsDeleteRoomLoading] =
    useState<boolean>(false);
  const { signOut } = useContext(AuthContext);
  const { handleToastfy } = useContext(ToastfyContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const brainstormingColumns: Column<IBrainstormingData>[] = useMemo(
    (): Column<IBrainstormingData>[] => [
      {
        Header: 'Data de Postagem',
        accessor: 'createdAt',
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Conteúdo',
        accessor: 'description',
      },
      {
        Header: 'Comentários',
        accessor: 'totalMessages',
      },
      {
        Header: 'Ver',
        accessor: 'read',
        disableSortBy: true,
      },
      {
        Header: 'Excluir',
        accessor: 'delete',
        disableSortBy: true,
      },
    ],
    []
  );

  const brainstormingData = useMemo(
    (): IBrainstormingData[] =>
      brainstormingRooms.data?.map((room) => {
        return {
          createdAt: moment(room.createdAt)
            .locale('pt-br')
            .format('DD/MM/YYYY'),
          name: room.user?.username,
          description: room.description,
          totalMessages: room.totalMessages,
          read: (
            <BsEye
              size={20}
              onClick={() => navigate(`postagens?id=${room._id}`)}
              style={{ cursor: 'pointer', userSelect: 'none' }}
            />
          ),
          delete: (
            <FaRegTrashAlt
              size={20}
              color={theme.pallete.colors.brandPrimary.red[3]}
              onClick={() => {
                setDeleteRoomId(room._id);
                setIsConfirmDeleteRoom((prev) => !prev);
              }}
              style={{ cursor: 'pointer', userSelect: 'none' }}
            />
          ),
        };
      }),
    [brainstormingRooms, theme.pallete.colors.brandPrimary.red, navigate]
  );

  const handleRemoveRoom = async () => {
    setIsDeleteRoomLoading((prev) => !prev);

    await deleteBrainstormingRoomService(deleteRoomId)
      .then((response) => {
        handleToastfy({
          message: 'Sala deletada com sucesso!',
          type: 'success',
        });
        setIsDeleteRoomLoading((prev) => !prev);
        setIsConfirmDeleteRoom((prev) => !prev);
        listBrainstormingRooms();
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
          setIsDeleteRoomLoading((prev) => !prev);
          setIsConfirmDeleteRoom((prev) => !prev);
          listBrainstormingRooms();
        }
      });
  };

  const listBrainstormingRooms = useCallback(async () => {
    setIsBrainstormingRoomsLoading(true);
    setIsBrainstormingRoomsTableLoading(true);
    await listBrainstormingRoomService({
      order: 'DESC',
      orderBy: 'createdAt',
      perPage: pageSizeBrainstormingRoomsAsync,
      page: pageIndexBrainstormingRoomsAsync + 1,
    })
      .then((response) => {
        setBrainstormingRooms(response);
        setIsBrainstormingRoomsLoading(false);
        setIsBrainstormingRoomsTableLoading(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsBrainstormingRoomsTableLoading(false);
      });
  }, [
    handleToastfy,
    navigate,
    pageIndexBrainstormingRoomsAsync,
    pageSizeBrainstormingRoomsAsync,
    signOut,
  ]);

  useEffect(() => {
    listBrainstormingRooms();
  }, [listBrainstormingRooms]);

  return (
    <BrainstormingRoomsListContainer>
      <h2 className="heading-2 heading-2--margin">Salas de Brainstorming</h2>
      <SeparateLine
        margin="0 0 5rem"
        color={theme.pallete.colors.brandPrimary.yellow[6]}
        lineHeight="3px"
      />
      {isBrainstormingRoomsLoading ? (
        <div className="brainstorming-list-room-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <TableAsync
          columns={brainstormingColumns}
          data={brainstormingData}
          pageSizeAsync={pageSizeBrainstormingRoomsAsync}
          setPageSizeAsync={setPageSizeBrainstormingRoomsAsync}
          pageIndexAsync={pageIndexBrainstormingRoomsAsync}
          setPageIndexAsync={setPageIndexBrainstormingRoomsAsync}
          totalItems={brainstormingRooms.total}
          isTableLoading={isBrainstormingRoomsTableLoading}
        />
      )}
      {isConfirmDeleteRoom && (
        <LightBox
          handleOpenModal={() => setIsConfirmDeleteRoom((prev) => !prev)}
          customLightboxWidth="60rem"
        >
          <h3 className="confirm-delete-room-h3">
            Deseja realmente excluir a sala?
          </h3>
          <div className="confirm-delete-button-group">
            <Button
              buttonText="Não"
              variant="contained"
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
              buttonSize={160}
              onClick={() => setIsConfirmDeleteRoom((prev) => !prev)}
            />
            <Button
              buttonText="Sim"
              variant="contained"
              buttonSize={160}
              onClick={() => handleRemoveRoom()}
              isLoading={isDeleteRoomLoading}
            />
          </div>
        </LightBox>
      )}
    </BrainstormingRoomsListContainer>
  );
};

export default BrainstormingRoomsList;
