import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BsEye } from 'react-icons/bs';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FiDownload, FiEdit3 } from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Column } from 'react-table';
import { useTheme } from 'styled-components';
import Button from '../../../../components/Button';
import SeparateLine from '../../../../components/SeparateLine';
import Table from '../../../../components/Table';
import ImagePreview from '../../../../components/lightboxes/ImagePreview';
import LightBox from '../../../../components/lightboxes/LightBox';
import Spinner from '../../../../components/loadings/Spinner';
import AuthContext from '../../../../contexts/AuthContext';
import ToastfyContext from '../../../../contexts/ToastfyContext';
import {
  deleteCreativeProjectService,
  listCreativeProjectsService,
  updateCreativeProjectService,
} from '../../../../services/creativeProjects.service';
import {
  IListCreativeProjectsData,
  IUpdateCreativeProjectRequest,
} from '../../../../services/creativeProjects.types';
import { showCreativeTriggerService } from '../../../../services/creativeTriggers.service';
import { IListCreativeTriggerData } from '../../../../services/creativeTriggers.types';
import { showMarketSegmentService } from '../../../../services/marketSegments.service';
import {
  ColorBox,
  PublishedProjects,
  ReadCreativeTriggerContainer,
} from './styles';

interface IReadCreativeTriggerData {
  createdAt: string;
  creators: string;
  file: string;
  read: React.ReactNode;
  download: React.ReactNode;
  delete: React.ReactNode;
  published: React.ReactNode;
}

const ReadCreativeTrigger: React.FC = () => {
  const [deleteCreativeProjectId, setDeleteCreativeProjectId] =
    useState<string>('');
  const [creativeProjects, setCreativeProjects] = useState<
    IListCreativeProjectsData[]
  >([]);
  const [creativeTrigger, setCreativeTrigger] =
    useState<IListCreativeTriggerData>();
  const [publishProject, setPublishProject] =
    useState<IUpdateCreativeProjectRequest>(
      {} as IUpdateCreativeProjectRequest
    );
  const { signOut } = useContext(AuthContext);
  const { handleToastfy } = useContext(ToastfyContext);
  const [isloadingCreativeTrigger, setIsLoadingCreativeTrigger] =
    useState<boolean>(true);
  const [marketSegmentName, setMarketSegmentName] = useState<string>('');
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] =
    useState<boolean>(false);
  const [isOpenConfirmPublish, setIsOpenConfirmPublish] =
    useState<boolean>(false);
  const [isPublishProjectLoading, setIsPublishProjectLoading] =
    useState<boolean>(false);
  const [isDeleteProjectLoading, setIsDeleteProjectLoading] =
    useState<boolean>(false);
  const [isLoadingProjects, setIsLoadingProjects] = useState<boolean>(true);
  const [openImageLink, setOpenImageLink] = useState<string>('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const imagebasePath = process.env.REACT_APP_URL_IMAGE_BASE;

  const readCreativeTriggerColumns: Column<IReadCreativeTriggerData>[] =
    useMemo(
      (): Column<IReadCreativeTriggerData>[] => [
        {
          Header: 'Data do envio',
          accessor: 'createdAt',
        },
        {
          Header: 'Nome do criativista',
          accessor: 'creators',
        },
        {
          Header: 'Projeto enviado',
          accessor: 'file',
        },
        {
          Header: 'Ver',
          accessor: 'read',
          disableSortBy: true,
        },
        {
          Header: 'Baixar',
          accessor: 'download',
          disableSortBy: true,
        },
        {
          Header: 'Excluir',
          accessor: 'delete',
          disableSortBy: true,
        },
        {
          Header: '',
          accessor: 'published',
          disableSortBy: true,
        },
      ],
      []
    );

  const readCreativeTriggerData = useMemo(
    (): IReadCreativeTriggerData[] =>
      creativeProjects
        .sort((a, b) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        })
        .map((project) => {
          return {
            createdAt: moment(project.createdAt)
              .locale('pt-br')
              .format('DD/MM/YYYY'),
            creators: project.creators,
            delete: (
              <FaRegTrashAlt
                size={20}
                onClick={() => {
                  setDeleteCreativeProjectId(project._id);
                  setIsOpenConfirmDelete((prev) => !prev);
                }}
                color={theme.pallete.colors.brandPrimary.red[3]}
                style={{ cursor: 'pointer', userSelect: 'none' }}
              />
            ),
            download: (
              <FiDownload
                size={20}
                onClick={() =>
                  handleDownloadFile(
                    `${process.env.REACT_APP_URL_IMAGE_BASE}/${project.file}`,
                    `${project.file}`
                  )
                }
                style={{ cursor: 'pointer', userSelect: 'none' }}
              />
            ),
            file: project.file,
            read: (
              <BsEye
                size={20}
                onClick={() =>
                  handleOpenImage(
                    `${process.env.REACT_APP_URL_IMAGE_BASE}/${project.file}`
                  )
                }
                style={{ cursor: 'pointer', userSelect: 'none' }}
              />
            ),
            published: (
              <React.Fragment>
                <PublishedProjects
                  onClick={() => {
                    if (!project.published) {
                      setIsOpenConfirmPublish((prev) => !prev);
                      setPublishProject({
                        id: project._id,
                        isActive: true,
                        published: true,
                      });
                    }
                  }}
                  style={{ cursor: !project.published ? 'pointer' : 'auto' }}
                  backgroundColor={
                    project.published
                      ? theme.pallete.colors.brandPrimary.gray[4]
                      : theme.pallete.colors.brandPrimary.yellow[6]
                  }
                >
                  {project.published ? 'Publicado' : 'Publicar'}
                </PublishedProjects>
                <span className="creative-projects-published-none">
                  {project.published ? 'Publicado' : 'Publicar'}
                </span>
              </React.Fragment>
            ),
          };
        }),
    [
      creativeProjects,
      theme.pallete.colors.brandPrimary.gray,
      theme.pallete.colors.brandPrimary.red,
      theme.pallete.colors.brandPrimary.yellow,
    ]
  );

  const deleteCreativeProject = async () => {
    setIsDeleteProjectLoading((prev) => !prev);
    await deleteCreativeProjectService(deleteCreativeProjectId)
      .then((response) => {
        setIsDeleteProjectLoading((prev) => !prev);
        setIsOpenConfirmDelete((prev) => !prev);
        listCreativeProjects();
        handleToastfy({
          message: 'Projeto deletado com sucesso!',
          type: 'success',
        });
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsDeleteProjectLoading((prev) => !prev);
      });
  };

  const getMarketSegmentName = useCallback(
    async (id: string) => {
      await showMarketSegmentService(id)
        .then((response) => {
          setMarketSegmentName(response.data.label);
          setIsLoadingCreativeTrigger(false);
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          setIsLoadingCreativeTrigger(false);
        });
    },
    [handleToastfy, navigate, signOut]
  );

  const updateCreativeProject = async () => {
    setIsPublishProjectLoading((prev) => !prev);
    await updateCreativeProjectService(publishProject)
      .then((response) => {
        setIsPublishProjectLoading((prev) => !prev);
        setIsOpenConfirmPublish((prev) => !prev);
        listCreativeProjects();
        handleToastfy({
          message: 'Projeto publicado com sucesso!',
          type: 'success',
        });
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsPublishProjectLoading((prev) => !prev);
      });
  };

  const handleOpenImage = (link: string | undefined) => {
    setIsOpenImage((prev) => !prev);
    setOpenImageLink(link || '');
  };

  const handleDownloadFile = (urlFile: string, imageName: string) => {
    fetch(urlFile).then((response) => {
      response.arrayBuffer().then((buffer) => {
        const name = imageName.split(/(.*)\./);
        const urlTemp = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        // eslint-disable-next-line no-useless-escape
        const extension = response.url.split(/([^\.]+$)/);
        link.href = urlTemp;
        link.setAttribute('download', `${name[1]}.${extension.at(-2)}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(urlTemp);
      });
    });
  };

  const getCreativeTrigger = useCallback(
    async (id: string) => {
      await showCreativeTriggerService(id)
        .then((response) => {
          setCreativeTrigger(response.data);
          getMarketSegmentName(response.data.marketSegmentId);
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
        });
    },
    [getMarketSegmentName, handleToastfy, signOut, navigate]
  );

  const listCreativeProjects = useCallback(async () => {
    setIsLoadingProjects(true);
    await listCreativeProjectsService({ isActive: true })
      .then((response) => {
        setCreativeProjects(response.data);
        setIsLoadingProjects(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingProjects(false);
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    const id = searchParams.get('id');

    if (!!id) {
      getCreativeTrigger(id);
    }
  }, [searchParams, getCreativeTrigger]);

  useEffect(() => {
    listCreativeProjects();
  }, [listCreativeProjects]);

  return (
    <ReadCreativeTriggerContainer>
      {isloadingCreativeTrigger ? (
        <div className="read-creative-trigger-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <React.Fragment>
          <div className="read-creative-trigger-heading-box">
            <h3 className="read-creative-trigger-heading-3">
              {creativeTrigger?.label}
            </h3>
            <div
              className="read-creative-trigger-edit"
              onClick={() =>
                navigate(
                  `/dashboard/editar-gatilho-criativo?id=${creativeTrigger?._id}`
                )
              }
            >
              <FiEdit3 size={20} />
              <span>Editar</span>
            </div>
          </div>
          <div className="read-creative-trigger-grid-content-1">
            <div className="read-creative-trigger-label-group">
              <span className="read-creative-trigger-label">
                Categoria de conteúdo:
              </span>
              <span className="read-creative-trigger-answer">
                Gatilhos Criativos
              </span>
            </div>
            <div className="read-creative-trigger-label-group">
              <span className="read-creative-trigger-label">
                Segmento do mercado:
              </span>
              <span className="read-creative-trigger-answer">
                {marketSegmentName}
              </span>
            </div>
            <div className="read-creative-trigger-label-group">
              <span className="read-creative-trigger-label">Cor do card:</span>
              <span className="read-creative-trigger-answer">
                <ColorBox
                  backgroundColor={creativeTrigger?.color || '#000000'}
                />
                {/* {creativeTrigger} */}
              </span>
            </div>
            <div className="read-creative-trigger-label-group">
              <span className="read-creative-trigger-label">
                Data da Criação:
              </span>
              <span className="read-creative-trigger-answer">
                {moment(creativeTrigger?.createdAt)
                  .locale('pt-br')
                  .format('DD/MM/YYYY')}
              </span>
            </div>
            <div className="read-creative-trigger-label-group">
              <span className="read-creative-trigger-label">Descrição:</span>
              <span className="read-creative-trigger-answer">
                {creativeTrigger?.description}
              </span>
            </div>
          </div>
          <div className="read-creative-trigger-line">
            <span>Referências</span>
            <SeparateLine
              margin="0"
              color={theme.pallete.colors.common.black}
              lineHeight="1px"
            />
          </div>
          {creativeTrigger?.insights
            .sort((a, b) => {
              return a.position - b.position;
            })
            .map((insight, index) => {
              return (
                <div key={index} className="creative-trigger-reference">
                  <div className="creative-trigger-reference-text-group">
                    <span className="creative-trigger-reference-text">
                      Imagem:
                    </span>
                    <div
                      className="creative-trigger-reference-image-container"
                      onClick={() =>
                        handleOpenImage(`${imagebasePath}/${insight.image}`)
                      }
                    >
                      <img
                        className="creative-trigger-reference-image"
                        src={`${imagebasePath}/${insight.image}`}
                        alt="reference-1"
                      />
                      <div className="creative-trigger-reference-image-background">
                        <span>Visualizar imagem</span>
                      </div>
                    </div>
                  </div>
                  <div className="creative-trigger-reference-group-after-image">
                    <div className="creative-trigger-reference-text-group">
                      <span className="creative-trigger-reference-text">
                        Título da referência:
                      </span>
                      <span className="creative-trigger-reference-answer">
                        {insight.label}
                      </span>
                    </div>
                    <div className="creative-trigger-reference-text-group">
                      <span className="creative-trigger-reference-text">
                        Posição:
                      </span>
                      <span className="creative-trigger-reference-answer">
                        {insight.position}
                      </span>
                    </div>
                    <div className="creative-trigger-reference-text-group">
                      <span className="creative-trigger-reference-text">
                        Link:
                      </span>
                      <a
                        className="creative-trigger-reference-answer creative-trigger-link"
                        href={insight.link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {insight.link}
                      </a>
                    </div>
                  </div>
                  <div className="creative-trigger-reference-text-group">
                    <span className="creative-trigger-reference-text">
                      Descrição:
                    </span>
                    <span className="creative-trigger-reference-answer">
                      {insight.description}
                    </span>
                  </div>
                  <SeparateLine
                    margin="0"
                    color={theme.pallete.colors.brandPrimary.gray[2]}
                    lineHeight="1px"
                  />
                </div>
              );
            })}
        </React.Fragment>
      )}
      {isLoadingProjects ? (
        <div className="read-creative-trigger-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <React.Fragment>
          <h4 className="read-creative-trigger-heading-4">
            Projetos recebidos
          </h4>
          {!!readCreativeTriggerData.length ? (
            <Table
              columns={readCreativeTriggerColumns}
              data={readCreativeTriggerData}
            />
          ) : (
            <p>Nenhum projeto recebido até o momento</p>
          )}
        </React.Fragment>
      )}
      {isOpenImage && (
        <ImagePreview
          setIsOpenImage={setIsOpenImage}
          linkImage={openImageLink}
        />
      )}
      {isOpenConfirmDelete && (
        <LightBox
          handleOpenModal={() => setIsOpenConfirmDelete((prev) => !prev)}
          customLightboxWidth="60rem"
        >
          <h3 className="confirm-delete-title">
            Deseja realmente excluir o projeto?
          </h3>
          <div className="confirm-delete-button-group">
            <Button
              buttonText="Não"
              variant="contained"
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
              buttonSize={160}
              onClick={() => setIsOpenConfirmDelete((prev) => !prev)}
            />
            <Button
              buttonText="Sim"
              variant="contained"
              buttonSize={160}
              onClick={() => deleteCreativeProject()}
              isLoading={isDeleteProjectLoading}
            />
          </div>
        </LightBox>
      )}
      {isOpenConfirmPublish && (
        <LightBox
          handleOpenModal={() => setIsOpenConfirmPublish((prev) => !prev)}
          customLightboxWidth="60rem"
        >
          <h3 className="confirm-delete-title">Deseja publicar o projeto?</h3>
          <div className="confirm-delete-button-group">
            <Button
              buttonText="Não"
              variant="contained"
              customColors={{
                backgroundColor: theme.pallete.colors.common.black,
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
                hoverTextColor: theme.pallete.colors.common.white,
                textColor: theme.pallete.colors.common.white,
              }}
              buttonSize={160}
              onClick={() => setIsOpenConfirmPublish((prev) => !prev)}
            />
            <Button
              buttonText="Sim"
              variant="contained"
              buttonSize={160}
              onClick={() => updateCreativeProject()}
              isLoading={isPublishProjectLoading}
            />
          </div>
        </LightBox>
      )}
    </ReadCreativeTriggerContainer>
  );
};

export default ReadCreativeTrigger;
