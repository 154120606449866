import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../../components/Button';
import AuthContext from '../../../contexts/AuthContext';
import ToastfyContext from '../../../contexts/ToastfyContext';
import { getPaymentsPlansService } from '../../../services/payments.service';
import { IGetPaymentsPlansData } from '../../../services/payments.types';
import { PlansStyled } from './styles';
import Spinner from '../../../components/loadings/Spinner';

const Plans: React.FC = () => {
  const [paymentsPlans, setPaymentsPlans] = useState<IGetPaymentsPlansData[]>(
    []
  );
  const [isLoadingPaymentPlans, setIsLoadingPaymentPlans] =
    useState<boolean>(true);
  const { signOut } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleToastfy } = useContext(ToastfyContext);

  const getPaymentsPlans = useCallback(async () => {
    setIsLoadingPaymentPlans(true);
    await getPaymentsPlansService()
      .then((response) => {
        setPaymentsPlans(response.data);
        setIsLoadingPaymentPlans(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    getPaymentsPlans();
  }, [getPaymentsPlans]);

  return (
    <PlansStyled>
      <div className="content">
        <h2 className="heading-2">Faça parte da comunidade Criativistas</h2>
        <div className="grid-cards">
          {isLoadingPaymentPlans ? (
            <div className="plans-box-spinner">
              <Spinner size={100} />
            </div>
          ) : (
            paymentsPlans && paymentsPlans.map((plans, index) => {
              return (
                <div className="card" key={index}>
                  <div className="card-text-box">
                    <h2 className="card-title">{plans.title}</h2>
                    <div className="card-text">{plans.description}</div>
                  </div>
                  <div className="card-list">
                    {plans.descriptions.map((description, index) => {
                      return (
                        <div className="card-list-item" key={index}>
                          <div>
                            <BsFillCheckCircleFill
                              size={24}
                              color={theme.pallete.colors.brandPrimary.green[3]}
                            />
                          </div>
                          <span className="card-list-item-text">
                            <strong>{description.title}</strong>
                            {!!description.subtitle && (
                              <React.Fragment>
                                &nbsp;-&nbsp;{description.subtitle}
                              </React.Fragment>
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  {!!plans.value ? (
                      <span className="price-box">
                        <span className="price-symbol">R$</span>
                        <span className="price">{plans.valueString}</span>
                      <span className="month">/mês</span>
                    </span>
                  ) : (
                    <span className="price-box" />
                  )}
                  {!!plans.value ? (
                    <Button
                      className="button"
                      variant="contained"
                      buttonText="Assine o plano"
                      onClick={() => navigate('/sign-up')}
                    />
                  ) : (
                    <Button
                      className="button"
                      variant="contained"
                      buttonText="Crie sua conta grátis"
                      onClick={() => navigate('/sign-up')}
                    />
                  )}
                </div>
              );
            })
          )}
        </div>
        <p className="plans-paragraph">
          *Cada crédito de IA equivale a mais ou menos uma solicitação. A
          contagem dos créditos pode variar caso a solicitação gere respostas
          que ultrapassem o valor do token pré-estabelecido pela OpenAI.
        </p>
      </div>
    </PlansStyled>
  );
};

export default Plans;
