import styled from 'styled-components';

export const VideoSectionStyled = styled.section`
  ${(props) => props.theme.mixins.containerGridDefault};
  padding: 8rem 0;

  @media ${(props) => props.theme.medias.laptopSmall} {
    padding: 4rem 0;
  }

  .content {
    position: relative;
    grid-column: center-start / center-end;

    display: flex;
    gap: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      align-items: center;
      text-align: center;
    }

    &--1 {
      padding-bottom: 8rem;

      @media ${(props) => props.theme.medias.laptopSmall} {
        flex-direction: column-reverse;
        padding-bottom: 0.5rem;
      }
    }

    &--2 {
      padding-top: 8rem;

      @media ${(props) => props.theme.medias.laptopSmall} {
        flex-direction: column;
        padding-top: 0.5rem;
      }
    }
  }

  .video {
    border-radius: 2rem;
    width: 50%;
    display: flex;

    /*Element inside the library react player */
    .react-player__preview {
      border-radius: 2rem;
      transition: all 0.2s;
      box-shadow: ${(props) => props.theme.pallete.shadows.default.n4};

      &:hover {
        box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};
      }
    }

    video {
      border-radius: 2rem;
      box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};
    }

    @media ${(props) => props.theme.medias.laptopSmall} {
      width: 100%;
      max-width: 45rem;
      height: 35rem;
    }

    span {
      color: ${(props) => props.theme.pallete.colors.common.black};
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
      font-size: 3.2rem;
    }
  }

  .video-section-texts {
    padding: 3rem;
    width: 50%;

    @media ${(props) => props.theme.medias.laptopSmall} {
      width: 100%;
      padding: 3rem 0;
    }
  }

  .video-section-heading-2 {
  }

  .video-section-paragraph {
    color: ${(props) => props.theme.pallete.colors.common.black};
    margin-bottom: 1.6rem;
  }

  button {
    margin-top: 1.6rem;
  }
`;
