import styled from 'styled-components';

interface IStatusMarketCategoriesProps {
  backgroundColor: string;
}

export const MarketCategoriesContainer = styled.div`
  .heading-2 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  .heading-4 {
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    margin-bottom: 2.4rem;
  }

  .segments--list-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }

  .status-market-categories-none {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-size: 1.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
  }
`;

export const StatusMarketCategories = styled.span<IStatusMarketCategoriesProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.backgroundColor};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.pallete.colors.common.black};
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
`;
