import styled from 'styled-components';

export const DashboardLayoutSideBarMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  position: fixed;
  width: 32rem;
  height: 100%;
  padding: 15rem 3rem 3rem;
  background-color: ${(props) =>
    props.theme.pallete.colors.backgrounds.neutral};
  box-shadow: ${(props) => props.theme.pallete.shadows.default.n8};
  overflow-y: auto;
  user-select: none;

  @media ${(props) => props.theme.medias.laptopSmall} {
    display: none;
  }

  .menu-items-block {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .menu-link {
    text-decoration: none;
  }

  .menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }

  .menu-item-text,
  .menu-sub-item-text {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    width: max-content;
    align-items: center;
    display: flex;
    gap: 0.8rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    cursor: pointer;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 26rem;
  }

  .menu-sub-item-text-new-content {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[5]};
    font-size: 1.4rem;
    display: flex;
    align-items: center;
  }

  .menu-item-text {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  .menu-sub-items {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .menu-sub-item-text {
    padding: 0 1.6rem 0 2.4rem;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }
`;
