import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { useTheme } from 'styled-components';
import Button from '../../components/Button';
import ProjectWall from '../../components/ProjectWall';
import SeparateLine from '../../components/SeparateLine';
import ShowYourIdeaSection from '../../components/ShowYourIdeaSection';
import Select from '../../components/inputs/Select';
import Select2 from '../../components/inputs/Select2';
import Textfield from '../../components/inputs/Textfield';
import ImagePreview from '../../components/lightboxes/ImagePreview';
import LightBox from '../../components/lightboxes/LightBox';
import LightboxWithoutLogin from '../../components/lightboxes/LightboxWithoutLogin';
import Spinner from '../../components/loadings/Spinner';
import Megaphone from '../../components/svgs/Megaphone';
import { AuthContext } from '../../contexts/AuthContext';
import { ToastfyContext } from '../../contexts/ToastfyContext';
import {
  generateCreativeTriggerService,
  generateIdeasIAService,
  regenerateIdeasIAService,
} from '../../services/creativeTriggers.service';
import {
  IGenerateIdeasIARequest,
  IListCreativeTriggerData,
} from '../../services/creativeTriggers.types';
import { listMarketSegmentService } from '../../services/marketSegments.service';
import CustomLeftArrow from '../Home/BannerHome/CustomLeftArrow';
import CustomRightArrow from '../Home/BannerHome/CustomRightArrow';
import GeneratedFromIA from './GeneratedFromIA';
import {
  CreativeTriggerIAContent,
  CreativeTriggersStyled,
  InsightFirstCard,
} from './styles';

interface IOptions {
  optionValue: string;
  optionName: string;
}

interface IToneVoiceOptionType {
  value: string;
  label: string;
}

interface IInputFields {
  brandName: string;
  brandDescription: string;
  targetAudience: string;
  goalIdeia: string;
  atributes: string;
  voiceTone: IToneVoiceOptionType[];
  distributionChannels: string;
}

const responsive: ResponsiveType = {
  huge: {
    breakpoint: { max: 2560, min: 1921 },
    items: 3,
    partialVisibilityGutter: 50,
  },
  extraLarge: {
    breakpoint: { max: 1920, min: 1301 },
    items: 3,
    partialVisibilityGutter: 10,
  },
  large: {
    breakpoint: { max: 1300, min: 1025 },
    items: 2,
    partialVisibilityGutter: 100,
  },
  medium: {
    breakpoint: { max: 1024, min: 725 },
    items: 2,
    partialVisibilityGutter: 10,
  },
  small: {
    breakpoint: { max: 724, min: 601 },
    items: 1,
    partialVisibilityGutter: 100,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

const optionsToneVoice = [
  { value: 'bem humorada', label: 'Bem humorada' },
  { value: 'simpática', label: 'Simpática' },
  { value: 'confiante', label: 'Confiante' },
  { value: 'criativa', label: 'Criativa' },
  { value: 'amorosa', label: 'Amorosa' },
  { value: 'sincera', label: 'Sincera' },
  { value: 'focada', label: 'Focada' },
  { value: 'extrovertida', label: 'Extrovertida' },
  { value: 'otimista', label: 'Otimista' },
  { value: 'determinada', label: 'Determinada' },
  { value: 'calma', label: 'Calma' },
  { value: 'observadora', label: 'Observadora' },
  { value: 'altruísta', label: 'Altruísta' },
  { value: 'ponderada', label: 'Ponderada' },
  { value: 'zelosa', label: 'Zelosa' },
  { value: 'organizada', label: 'Organizada' },
  { value: 'compreensiva', label: 'Compreensiva' },
  { value: 'positiva', label: 'Positiva' },
  { value: 'responsável', label: 'Responsável' },
  { value: 'eloquênte', label: 'Eloquênte' },
  { value: 'leal', label: 'Leal' },
  { value: 'ambição', label: 'Ambição' },
  { value: 'sensível', label: 'Sensível' },
  { value: 'meiga', label: 'Meiga' },
  { value: 'assertiva', label: 'Assertiva' },
  { value: 'carismática', label: 'Carismática' },
  { value: 'generosa', label: 'Generosa' },
];

const distributionChannelsOptions = [
  {
    optionName: 'Títulos Criativos',
    optionValue: 'Títulos Criativos',
  },
  {
    optionName: 'Ideias de Campanhas Publicitárias',
    optionValue: 'Ideias de Campanhas Publicitárias',
  },
  {
    optionName: 'Ideias de Ações de Ativação de Marca',
    optionValue: 'Ideias de Ações de Ativação de Marca',
  },
  {
    optionName: 'Redes Sociais (plataformas gerais)',
    optionValue: 'Redes Sociais (plataformas gerais)',
  },
  {
    optionName: 'Digital Criativo (formatos digitais gerais)',
    optionValue: 'Digital Criativo (formatos digitais gerais)',
  },
  { optionName: 'Instagram Carrossel', optionValue: 'Instagram Carrossel' },
  {
    optionName: 'Instagram Reels e TikTok',
    optionValue: 'Instagram Reels e TikTok',
  },
  {
    optionName: 'Outdoor e Mobiliário Urbano',
    optionValue: 'Outdoor e Mobiliário Urbano',
  },
  {
    optionName: 'Ideias de Design e Direção de Arte',
    optionValue: 'Ideias de Design e Direção de Arte',
  },
  {
    optionName: 'Roteiros de Filmes Publicitários',
    optionValue: 'Roteiros de Filmes Publicitários',
  },
  {
    optionName: 'Ideias para Plataformas de Áudio',
    optionValue: 'Ideias para Plataformas de Áudio',
  },
  {
    optionName: 'Ideias de Naming para Marcas',
    optionValue: 'Ideias de Naming para Marcas',
  },
  {
    optionName: 'Ideias de Negócios & Startups',
    optionValue: 'Ideias de Negócios & Startups',
  },
  {
    optionName: 'Ideias com Propósito e Impacto Social',
    optionValue: 'Ideias com Propósito e Impacto Social',
  },
  {
    optionName: 'Ideias de Inovação em Produtos',
    optionValue: 'Ideias de Inovação em Produtos',
  },
];

const CreativeTriggers: React.FC = () => {
  const [generatedTrigger, setGeneratedTrigger] =
    useState<IListCreativeTriggerData>();
  const [regenerateAnswerId, setRegenerateAnswerId] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<string>('');
  const [creativeTriggerID, setCreativeTriggerId] = useState<string>('');
  const [marketSegmentGeneratedName, setMarketSegmentGeneratedName] =
    useState('');
  const [isLoadingSegment, setIsLoadingSegment] = useState<boolean>(true);
  const [isLoadingAnswersIA, setIsLoadingAnswersIA] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [optionSegment, setOptionSegment] = useState<IOptions[]>([]);
  const [isSignPlanModal, setIsSignPlanModal] = useState<boolean>(false);
  const [isSignPlanModalIA, setIsSignPlanModalIA] = useState<boolean>(false);
  const [generatedAnswers, setGeneratedAnswers] = useState<string>('');
  const [isGeneratedAnswersModal, setIsGeneratedAnswersModal] =
    useState<boolean>(false);
  const [removeOnLoading, setRemoveOnLoading] = useState<boolean>(false);
  const [errorMarket, setErrorMarket] = useState<string>('');
  const [isGeneratingTrigger, setIsGeneratingTrigger] =
    useState<boolean>(false);
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [openImageLink, setOpenImageLink] = useState<string>('');
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<IInputFields>({
    defaultValues: {
      atributes: '',
      brandDescription: '',
      brandName: '',
      distributionChannels: '',
      goalIdeia: '',
      targetAudience: '',
      voiceTone: [{ value: '', label: '' }],
    },
  });
  const IARef = useRef<null | HTMLDivElement>(null);
  const TriggerRef = useRef<null | HTMLDivElement>(null);
  const { handleToastfy } = useContext(ToastfyContext);
  const [voiceToneSelected, setVoiceToneSelected] =
    useState<IToneVoiceOptionType[]>();
  const [keepDistributionChannelsValue, setKeepDistributionChannelsValue] =
    useState<IOptions>();
  const { isAuthenticated, user, signOut, isValidUser } =
    useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsGeneratedAnswersModal(false);

    if (!!generatedTrigger?._id) {
      setIsLoadingAnswersIA((prev) => !prev);
      const dataSend: IGenerateIdeasIARequest = {
        atributes: data.atributes,
        brandDescription: data.brandDescription,
        brandName: data.brandName,
        creativeTriggerId: generatedTrigger._id,
        distributionChannels: data.distributionChannels,
        goalIdeia: data.goalIdeia,
        targetAudience: data.targetAudience,
        voiceTone: data.voiceTone.map((voice) => voice.value).join(', '),
      };

      await generateIdeasIAService(dataSend)
        .then((response) => {
          setGeneratedAnswers(response.data.message.content);
          setRegenerateAnswerId(response.data._id);
          setIsGeneratedAnswersModal(true);
          setIsLoadingAnswersIA((prev) => !prev);
          handleOpenModal();
          scrollToIA();
          isValidUser();
          handleToastfy({
            message: 'Ideias geradas pela inteligência artificial com sucesso!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          setIsLoadingAnswersIA((prev) => !prev);
        });
    }
  };

  const regenerateIaAnswers = async () => {
    setIsLoadingAnswersIA((prev) => !prev);
    setIsGeneratedAnswersModal((prev) => !prev);
    setIsOpenModal((prev) => !prev);
    scrollToIA();
    await regenerateIdeasIAService(regenerateAnswerId)
      .then((response) => {
        setGeneratedAnswers(response.data.message.content);
        setRegenerateAnswerId(response.data._id);
        setIsGeneratedAnswersModal(true);
        setIsLoadingAnswersIA((prev) => !prev);
        handleOpenModal();
        scrollToIA();
        isValidUser();
        handleToastfy({
          message: 'Ideias geradas pela inteligência artificial com sucesso!',
          type: 'success',
        });
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingAnswersIA((prev) => !prev);
      });
  };

  const handleOpenModal = () => {
    setIsOpenModal((prev) => !prev);
  };

  const handleGenerateTriggers = async () => {
    if (isAuthenticated) {
      if (!!selectedOptions) {
        setIsGeneratedAnswersModal(false);
        setRemoveOnLoading((prev) => !prev);
        setIsGeneratingTrigger((prev) => !prev);
        setErrorMarket('');
        await generateCreativeTriggerService({
          id: selectedOptions,
          creativeTriggerId: creativeTriggerID,
        })
          .then((response) => {
            setGeneratedTrigger(response.data);
            setIsGeneratingTrigger((prev) => !prev);
            setCreativeTriggerId(response.data._id);
            setRemoveOnLoading((prev) => !prev);
            reset();
            scrollToTrigger();
            setVoiceToneSelected(undefined);
            setKeepDistributionChannelsValue(undefined);
            handleToastfy({
              message: 'Gatilhos criativos gerados com sucesso!',
              type: 'success',
            });
            setMarketSegmentGeneratedName(
              optionSegment.filter(
                (option) => option.optionValue === selectedOptions
              )[0].optionName
            );
          })
          .catch((error) => {
            if (
              error.code === 'EXPIRED_TOKEN' ||
              error.code === 'TOKEN_REVOKED' ||
              error.code === 'TOKEN_NOT_FOUND'
            ) {
              handleToastfy({
                message: 'Sessão expirada!',
                type: 'error',
              });
              signOut();
              navigate('/sign-in');
            } else {
              handleToastfy({
                message:
                  !!error.message || error.code
                    ? error.code === 'DATA_NOT_FOUND'
                      ? 'Não existem gatilhos cadastrados para essa categoria.'
                      : error.message || error.code
                    : 'Ocorreu um erro ao processar a requisição.',
                type: 'error',
              });
            }
            setRemoveOnLoading((prev) => !prev);
            setCreativeTriggerId('');
            setIsGeneratingTrigger((prev) => !prev);
            setIsGeneratedAnswersModal(false);
          });
      } else {
        setErrorMarket('O campo não pode ficar vazio.');
      }
    } else {
      setIsSignPlanModal((prev) => !prev);
    }
  };

  const handleAskForIa = () => {
    if (isAuthenticated) {
      handleOpenModal();
    } else {
      setIsSignPlanModalIA((prev) => !prev);
    }
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOptions(event.target.value);
    setErrorMarket('');
  };

  const handleOpenImage = (link: string) => {
    setIsOpenImage((prev) => !prev);
    setOpenImageLink(link);
  };

  const scrollToIA = () =>
    !!IARef && !!IARef.current && IARef.current.scrollIntoView();

  const scrollToTrigger = () =>
    !!TriggerRef && !!TriggerRef.current && TriggerRef.current.scrollIntoView();

  const getSegmentsMarket = useCallback(async () => {
    setIsLoadingSegment(true);
    await listMarketSegmentService({
      order: 'DESC',
      orderBy: 'createdAt',
      perPage: 200,
      isActive: true,
    })
      .then((response) => {
        const createOptions = response.data.map((segment) => {
          return {
            optionName: segment.label,
            optionValue: segment._id,
          };
        });
        setIsLoadingSegment(false);
        setOptionSegment(createOptions);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingSegment(false);
        console.log(error.response.data);
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    getSegmentsMarket();
  }, [getSegmentsMarket]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      name === 'distributionChannels' && clearErrors('distributionChannels');
      !!value.distributionChannels &&
        setKeepDistributionChannelsValue(
          distributionChannelsOptions.find(
            (channel) => channel.optionValue === value.distributionChannels
          )
        );
    });
    return () => subscription.unsubscribe();
  }, [clearErrors, watch]);

  useEffect(() => {
    !!voiceToneSelected && setValue('voiceTone', voiceToneSelected);
  }, [setValue, voiceToneSelected]);

  return (
    <CreativeTriggersStyled>
      <header className="creative-triggers-header">
        {isLoadingSegment ? (
          <div className="creative-trigger-spinner">
            <Spinner size={100} />
          </div>
        ) : (
          <React.Fragment>
            <div className="creative-triggers-header-left">
              <h2 className="creative-triggers-heading-2">
                Gatilhos Criativos
              </h2>
              <p className="creative-triggers-sub-title">
              Nossos Gatilhos Criativos usam a técnica de ideação “E se…” para estimular o pensamento disruptivo.
              <br /><br />
              Defina seu desafio ou problema. Descubra quem é o seu público-alvo. Gere Gatilhos
              Criativos para ter boas ideias.
              </p>
              <Select
                textLabel="Escolha sua categoria"
                options={optionSegment}
                onChange={handleOptionChange}
                value={selectedOptions}
                error={!!errorMarket ? errorMarket : undefined}
              />
              <div className="header-left-button-group">
                <Button
                  variant="contained"
                  buttonText="Gerar gatilhos criativos"
                  onClick={handleGenerateTriggers}
                  isLoading={isGeneratingTrigger}
                  customColors={{
                    backgroundColor: theme.pallete.colors.common.black,
                    hoverBackgroundColor: theme.pallete.colors.common.black,
                    hoverTextColor: theme.pallete.colors.common.white,
                    textColor: theme.pallete.colors.common.white,
                  }}
                />
                <div className="button-points-box">
                  <Button
                    variant="contained"
                    buttonText="Pedir ideias para a IA"
                    onClick={handleAskForIa}
                    isDisabled={!generatedTrigger?._id}
                  />
                  {user?._id && (
                    <span className="button-points-span">
                      Você tem {!!user?.balance ? user.balance : 0} créditos
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`creative-triggers-header-right ${
                !!generatedTrigger &&
                'creative-triggers-header-right--card-reveal'
              }`}
            >
              {!!generatedTrigger ? (
                <InsightFirstCard backgroundColor={generatedTrigger.color}>
                  <span className="insights-first-card-sub-title">
                    /gatilhoscriativos
                  </span>
                  <h3 className="insights-first-card-title">
                    {generatedTrigger.label}
                  </h3>
                  <span className="insights-first-card-paragraph-title">
                    Como fazer:
                  </span>
                  <div className="insights-first-card-paragraph">
                    {generatedTrigger.description}
                  </div>
                </InsightFirstCard>
              ) : (
                <div className="header-right-image" />
              )}
            </div>
          </React.Fragment>
        )}
      </header>
      <div ref={IARef}>
        {isGeneratedAnswersModal && (
          <GeneratedFromIA
            generatedAnswers={generatedAnswers}
            marketSegmentGeneratedName={marketSegmentGeneratedName}
            regenerateIaAnswers={regenerateIaAnswers}
            isLoadingAnswersIA={isLoadingAnswersIA}
          />
        )}
      </div>
      <div ref={TriggerRef}>
        {!!generatedTrigger && (
          <section className="insights-container">
            <div className="insights-content">
              <h2 className="insights-content-heading-2">
                Referências para você criar com o seu Gatilho Criativo
              </h2>
              <p className="insights-content-paragraph">
                Inspire-se no que outros criativos criaram usando o insight da
                sua carta.
              </p>
              <div className="insights-cards-container">
                {removeOnLoading ? (
                  <div className="creative-trigger-spinner">
                    <Spinner size={100} />
                  </div>
                ) : (
                  <Carousel
                    additionalTransfrom={0}
                    arrows
                    partialVisbile
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    ssr={true}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                  >
                    <InsightFirstCard backgroundColor={generatedTrigger.color}>
                      <span className="insights-first-card-sub-title">
                        /gatilhoscriativos
                      </span>
                      <h3 className="insights-first-card-title">
                        {generatedTrigger.label}
                      </h3>
                      <span className="insights-first-card-paragraph-title">
                        Como fazer:
                      </span>
                      <div className="insights-first-card-paragraph">
                        {generatedTrigger.description}
                      </div>
                    </InsightFirstCard>
                    {generatedTrigger?.insights.map((insight, index) => {
                      return (
                        <div key={index} className="insights-card">
                          <h3 className="insights-card-title">/referências</h3>
                          <h3>{insight.label}</h3>
                          <div
                            className="insights-card-image"
                            style={{
                              backgroundImage: `url(${process.env.REACT_APP_URL_IMAGE_BASE}/${insight.image})`,
                            }}
                            onClick={() =>
                              handleOpenImage(
                                `${process.env.REACT_APP_URL_IMAGE_BASE}/${insight.image}`
                              )
                            }
                          >
                            <div className="insights-card-image-background">
                              <span>Visualizar imagem</span>
                            </div>
                          </div>
                          <div className="insights-card-paragraphs">
                            <p className="insights-card-paragraph">
                              {insight.description}
                            </p>
                            {!!insight.link && (
                              <p className="insights-card-paragraph insights-card-paragraph--break">
                                Veja mais no link:&nbsp;
                                <a
                                  href={insight.link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {insight.link}
                                </a>
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                )}
              </div>
            </div>
          </section>
        )}
      </div>
      {isOpenModal && (
        <LightBox
          handleOpenModal={handleOpenModal}
          customLightboxWidth="114rem"
        >
          <CreativeTriggerIAContent>
            {isLoadingAnswersIA ? (
              <div className="loading-ia">
                <div className="loading-ia-content-phrase">
                  <p className="loading-ia-paragraph loading-ia-paragraph--1">
                    Aguarde enquanto estou lendo o seu briefing...
                  </p>
                  <p className="loading-ia-paragraph loading-ia-paragraph--2">
                    Conectando os parâmetros...
                  </p>
                  <p className="loading-ia-paragraph loading-ia-paragraph--3">
                    Queimando os miolos eletrônicos para gerar as ideias...
                  </p>
                </div>
                <div className="loading-ia-spinner">
                  <Spinner size={48} />
                </div>
              </div>
            ) : (
              <React.Fragment>
                <h2 className="creative-trigger-ia-heading-2">
                  Gere ideias com a nossa IA usando seu Gatilho Criativo
                </h2>
                <p className="creative-trigger-ia-paragraph">
                  Preencha o maior número de informações possíveis sobre seu
                  desafio para que a nossa IA seja ainda mais assertiva.
                </p>
                <SeparateLine
                  margin="3rem 0"
                  color={theme.pallete.colors.brandPrimary.gray[2]}
                />
                <div className="input-group">
                  <span className="input-label">
                    Título do gatilho criativo
                  </span>
                  <span className="input-value">{generatedTrigger?.label}</span>
                </div>
                <div className="input-group">
                  <span className="input-label">
                    Nome do segmento de mercado
                  </span>
                  <span className="input-value">
                    {marketSegmentGeneratedName}
                  </span>
                </div>
                <form
                  className="creative-trigger-ia-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Textfield
                    label="Nome da marca"
                    type="text"
                    placeholder="Ex: Pão de açúcar"
                    error={errors.brandName?.message}
                    {...register('brandName', {
                      required: 'O campo é requerido.',
                    })}
                  />
                  <Textfield
                    label="Público-alvo"
                    type="text"
                    placeholder="Ex: Adultos de Classe A e B"
                    error={errors.targetAudience?.message}
                    {...register('targetAudience', {
                      required: 'O campo é requerido.',
                    })}
                  />
                  <Textfield
                    label="Breve descritivo da marca"
                    type="text"
                    placeholder="Ex: É uma rede de supermercados"
                    error={errors.brandDescription?.message}
                    {...register('brandDescription', {
                      required: 'O campo é requerido.',
                    })}
                  />
                  <Textfield
                    label="Objetivo da Ideia"
                    type="text"
                    placeholder="Ex: É o Dia Nacional do Pão e queremos surpreender nossos clientes com ações de experiência"
                    error={errors.goalIdeia?.message}
                    {...register('goalIdeia', {
                      required: 'O campo é requerido.',
                    })}
                  />
                  <Textfield
                    label="Atributos do produto/marca"
                    type="text"
                    placeholder="Ex: Temos uma das melhores padarias dentro do supermercado"
                    error={errors.atributes?.message}
                    {...register('atributes', {
                      required: 'O campo é requerido.',
                    })}
                  />
                  <div className="ia-form-input-control">
                    <label className="ia-form-input-control-label">
                      Tom de voz
                    </label>
                    <ReactSelect
                      options={optionsToneVoice}
                      isMulti
                      isClearable={false}
                      classNamePrefix="react-select"
                      placeholder="Selecione até três opções de voz..."
                      value={voiceToneSelected}
                      {...register('voiceTone', {
                        required: 'O campo é requerido.',
                      })}
                      onChange={(val) => setVoiceToneSelected(val as any)}
                      isOptionDisabled={() => watch('voiceTone').length >= 3}
                      styles={{
                        multiValueRemove: (css) => ({
                          ...css,
                          ':hover': { background: 'red' },
                        }),
                      }}
                    />
                    {!!errors.voiceTone?.message && (
                      <span className="textfield-error">
                        {errors.voiceTone.message}
                      </span>
                    )}
                  </div>
                  <Select2
                    setValue={setValue}
                    textLabel="Formato de resposta"
                    error={errors.distributionChannels?.message}
                    options={distributionChannelsOptions.map(
                      (marketSegment) => {
                        return {
                          optionName: marketSegment.optionName,
                          optionValue: marketSegment.optionValue,
                        };
                      }
                    )}
                    {...register('distributionChannels', {
                      required: 'O campo é requerido.',
                    })}
                    isInvertMenu
                    initialValue={keepDistributionChannelsValue}
                  />
                  <Button
                    buttonText={`Gerar ideias (-${1} crédito)`}
                    variant="contained"
                    type="submit"
                    isLoading={isLoadingAnswersIA}
                  />
                </form>
              </React.Fragment>
            )}
          </CreativeTriggerIAContent>
        </LightBox>
      )}
      {isSignPlanModalIA && (
        <LightboxWithoutLogin
          handleOpenModal={() => setIsSignPlanModalIA((prev) => !prev)}
          image={<Megaphone />}
        >
          <h3>Quer pedir ideias para a IA?</h3>
          <p>
            Assine o Plano Criativistas para receber todo mês X créditos de IA e
            solicitar ideias exclusivas de acordo com o seu desafio. Você ainda
            poderá categorizar o Baralho de Gatilhos Criativos para receber
            insights da sua área.
          </p>
          <Button
            buttonText="Fazer cadastro gratuito"
            variant="contained"
            type="button"
            className="whithout-login-button"
            onClick={() => navigate('/sign-up')}
            customColors={{
              backgroundColor: theme.pallete.colors.common.white,
              hoverBackgroundColor: theme.pallete.colors.common.white,
              hoverTextColor: theme.pallete.colors.common.black,
              textColor: theme.pallete.colors.common.black,
            }}
          />
        </LightboxWithoutLogin>
      )}
      {isSignPlanModal && (
        <LightboxWithoutLogin
          handleOpenModal={() => setIsSignPlanModal((prev) => !prev)}
          image={<Megaphone />}
        >
          <h3>Cadastre-se para usar nossas ferramentas</h3>
          <p>
            Para utilizar nossas ferramentas criativas, você precisa ter uma
            conta na nossa plataforma. Faça agora mesmo seu cadastro e comece a
            potencializar seu processo criativo.
          </p>
          <Button
            buttonText="Fazer cadastro gratuito"
            variant="contained"
            type="button"
            className="whithout-login-button"
            onClick={() => navigate('/sign-up')}
            customColors={{
              backgroundColor: theme.pallete.colors.common.white,
              hoverBackgroundColor: theme.pallete.colors.common.white,
              hoverTextColor: theme.pallete.colors.common.black,
              textColor: theme.pallete.colors.common.black,
            }}
          />
        </LightboxWithoutLogin>
      )}
      {isOpenImage && (
        <ImagePreview
          setIsOpenImage={setIsOpenImage}
          linkImage={openImageLink}
        />
      )}
      <ProjectWall />
      <ShowYourIdeaSection />
    </CreativeTriggersStyled>
  );
};

export default CreativeTriggers;
