import React from 'react';
import { CookiesContainer } from './styles';
import { Link } from 'react-router-dom';

const Cookies: React.FC = () => {
  return (
    <CookiesContainer>
      <div className="cookies-background" />
      <div className="cookies-content">
        <h2 className="cookies-heading-2">Política de cookies</h2>
        <p className="cookies-paragraph">
          Nosso site,{' '}
          <Link to="www.criativistas.com.br" target="_blank">
            www.criativistas.com.br
          </Link>{' '}
          (doravante: "o site") usa cookies e outras tecnologias relacionadas
          (por conveniência todas as tecnologias são referidas como "cookies").
          Os cookies também são inseridos por terceiros que contratamos.
        </p>
        <p className="cookies-paragraph">
          Este documento possui a finalidade de estabelecer alguns parâmetros
          para tratamento de dados que incluem, não taxativamente, a recepção,
          transmissão, compartilhamento, armazenamento, acesso, comunicação,
          modificação ou transferência das informações coletadas de nossos
          &nbsp;<strong>USUÁRIOS</strong>, além de registrar toda e qualquer
          atividade realizada dentro da plataforma, de acordo com a legislação
          aplicável.
        </p>
        <p className="cookies-paragraph">
          O <strong>USUÁRIO</strong> confere sua livre e expressa concordância
          perante este instrumento ao aceitar essa{' '}
          <Link to="/politica-de-cookies">POLÍTICA DE COOKIES</Link> e a&nbsp;
          <Link to="politica-de-privacidade" target="_blank">
            POLÍTICA DE PRIVACIDADE
          </Link>
          .
        </p>
        <p className="cookies-paragraph">
          Este documento foi criado pelo advogado{' '}
          <strong>Diego Castro Advogado – OAB/PI 15.613</strong> e modificado
          com permissão para este site
        </p>
        <h3 className="cookies-heading-3">1 – GLOSSÁRIO</h3>
        <p className="cookies-paragraph">
          <strong>1.1 –</strong> Para melhorar o desempenho e sua experiência em
          nosso site, utilizamos cookies, devendo ser consideradas as seguintes
          definições acerca desta Política:
        </p>
        <p className="cookies-paragraph">
          <strong>COOKIES –</strong> São arquivos de texto criados pelo site e
          armazenados no dispositivo que acessa a plataforma. (notebooks,
          tablets, smartphones, etc. ). Quando a condição de “permissão” estiver
          habilitada, o armazenamento de cookies ocorrerá de forma automática.
          Estes pequenos pacotes de dados visam identificar e coletar as
          informações do <strong>USUÁRIO</strong> para aprimorar os serviços do
          nosso site.
        </p>
        <p className="cookies-paragraph">
          <strong>DADOS:</strong> Conjunto de informações anônimas (ou dados
          anonimizados) e pessoais.
        </p>
        <p className="cookies-paragraph">
          <strong>DADOS ANONIMIZADOS:</strong> Informações isoladas que não
          permite a identificação do <strong>USUÁRIO</strong>. Incluem a coleta
          de gênero, localização, idade, etc.
        </p>
        <p className="cookies-paragraph">
          <strong>DADOS PESSOAIS:</strong> Informações da pessoa natural
          identificada por meio do sistema. Incluem nome, endereço, telefone,
          redes sociais, etc.
        </p>
        <p className="cookies-paragraph">
          <strong>HIPERLINKS:</strong> Um link clicável que pode aparecer no
          site ou estar disposto em algum conteúdo, que direciona o USUÁRIO para
          outra página na plataforma ou site externo, seja parceiro ou não
        </p>
        <p className="cookies-paragraph">
          <strong>NAVEGAÇÃO:</strong> Ato de interagir, pesquisar, adquirir ou
          consumir algum material ou conteúdo em nossa plataforma, site ou
          aplicativo.
        </p>
        <p className="cookies-paragraph">
          <strong>USUÁRIO:</strong> Visitantes do site que acessem independente
          do meio a nossa plataforma, site ou aplicativo. Deverá,
          necessariamente, ter capacidade legal para aceitar os termos e
          condições da presente Política de Cookies e demais documentos legais
          da plataforma.
        </p>
        <p className="cookies-paragraph">
          Havendo dúvidas acerca de qualquer termo ou palavra empregada nesta
          Política de Cookies, o <strong>VISITANTE/ USUÁRIO</strong> poderá
          entrar em contato com a plataforma através dos nossos canais de
          comunicação.
        </p>
        <h3 className="cookies-heading-3">2 – Coleta de dados</h3>
        <p className="cookies-paragraph">
          <strong>2.1 –</strong> A coleta de informações e dados de nossos{' '}
          &nbsp;<strong>USUÁRIOS</strong> ocorre na medida em que ele nos
          fornece, por meio de acesso e uso no site, clics em hiperlinks,
          interação nos aplicativos e serviços do site, suas preferências e
          referências.
        </p>
        <p className="cookies-paragraph">
          <strong>2.2 –</strong> Os dados serão coletados:
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Quando o <strong>USUÁRIO</strong> inserir ou
          submeter voluntariamente suas informações na nossa plataforma ou
          acessar algum serviço oferecido pelo site, também poderá ocorrer por
          meio da interação e navegação com o conteúdo exposto no website.
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Quando o <strong>USUÁRIO</strong> inserir ou
          submeter voluntariamente dados de terceiros no nosso site, uma vez
          declarada a autorização da utilização desses dados perante o site.
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Quando não necessariamente utilizar-se-á a ação do{' '}
          <strong>USUÁRIO</strong>, uma vez que a automatização do sistema se
          responsabiliza pela coleta de dados disponíveis por meio dos Cookies.
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Por meio de parceiros que tenham sido autorizados
          pelos nossos&nbsp;<strong>USUÁRIOS</strong> a compartilharem seus
          dados conosco.
        </p>
        <p className="cookies-paragraph">
          <strong>2.3 –</strong> As informações coletadas podem incluir, não se
          limitando, os seguintes dados:
        </p>
        <p className="cookies-paragraph-item">&#8226;&nbsp;&nbsp;Nome;</p>
        <p className="cookies-paragraph-item">&#8226;&nbsp;&nbsp;Gênero;</p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Endereço (por meio da geolocalização);
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Endereço de IP;
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Endereço de e-mail;
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Dados bancários (cartões de crédito, por exemplo.);
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Números de documentos (CPF, Carteira de Identidade,
          etc.)
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Data de nascimento;
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Informações de pagamento;
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;Links, hiperlinks e serviços clicados, etc.
        </p>
        <p className="cookies-paragraph">
          <strong>2.4 –</strong> Os demais dados que podem ser objeto de coleta
          estão dispostos, também, na{' '}
          <Link to="/politica-de-privacidade" target="_blank">
            Política de Privacidade
          </Link>
          .
        </p>
        <p className="cookies-paragraph">
          <strong>2.5 –</strong> Os cookies utilizados pelo nosso site e
          aplicações estão condicionados à validade de sua coleta:
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong> Cookies de sessão:</strong> permanece com
          o site até o mesmo ser fechado. As informações coletadas permitem a
          identificação de problemas e visam o fornecer melhores experiências
          aos USUÁRIOS.
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong> Cookies permanentes:</strong> mesmo após o
          fechamento da plataforma, esses cookies ainda ficarão armazenados na
          memória do navegador de internet do dispositivo que você está
          acessando. São utilizados cada vez que o utilizador visita o website
          para orientar a navegação de acordo com as preferências pessoais, que
          são recolhidas através destes cookies.
        </p>
        <p className="cookies-paragraph">
          <strong>2.6 –</strong> Os cookies utilizado podem ser tanto de
          titularidade própria, possuindo, a plataforma, total controle sobre os
          cookies; ou de titularidade de terceiros, incluídos nos dispositivos
          dos <strong>USUÁRIOS</strong> por meio dos sites, plataformas,
          hiperlinks, serviços e funções que possuímos parceria.
        </p>
        <h3 className="cookies-heading-3">
          3 – Finalidade da coleta de Cookies:
        </h3>
        <p className="cookies-paragraph">
          <strong>3.1 –</strong> Nossos cookies possuem diferentes funções e
          finalidades, a seguir listamos os tipos que podem ser utilizados no
          nosso site.
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>cookies essenciais:</strong> necessários
          para que a plataforma funcione corretamente e forneça, perfeitamente,
          os serviços que estão disponíveis aos nossos <strong>USUÁRIOS</strong>
          . Sendo que, sob pena do mau funcionamento da plataforma, o website
          não será capaz de prestar, com eficiência, todas as ferramentas ou
          recursos a ele associado.
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong> cookies de performance:</strong>{' '}
          responsável por coletar informações anônimas sobre como os{' '}
          <strong>USUÁRIOS</strong> usam e interagem com a plataforma e com as
          ferramentas fornecidas.
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>cookies de funcionalidade:</strong>{' '}
          associados à memória dos dados fornecidos, estes cookies permitem que
          a plataforma lembre das eventuais escolhas feitas pelo USUÁRIO, o
          direcionando às funções que o mesmo pode vir a optar. É responsável
          por proporcionar melhores experiências e garantir praticidade ao
          USUÁRIO. Essas informações também podem estar associadas aos dados
          anonimizados, que não irão rastrear a atividade do USUÁRIO fora do
          espaço da plataforma.
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>cookies analíticos:</strong> utilizamos
          estes cookies com a funcionalidade de monitorar e analisar com nossos
          USUÁRIOS usam as ferramentas e as funcionalidades da plataforma. Nos
          permitindo verificar eventuais falhas no site e proporcionar
          praticidade e customização para os USUÁRIOS. Além disso, baseados
          nesses cookies, podemos destacar os principais serviços ou artigos que
          pensamos ser de maior interesse de nossos USUÁRIOS.
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;<strong>cookies de segurança:</strong> evitando que
          os dados de nossos USUÁRIOS estejam vulneráveis a ataques maliciosos
          de terceiros, estes cookies armazenam as informações de modo
          criptografado
        </p>
        <h3 className="cookies-heading-3">4 – Da autorização</h3>
        <p className="cookies-paragraph">
          <strong>4.1 –</strong> Depois de autorizada a utilização de cookies,
          o&nbsp;
          <strong>USUÁRIO</strong> tem a seu dispor a opção de desativá-los
          assim que tiver interesse, podendo optar, ainda, inativar parte ou a
          totalidades dos nossos cookies.
        </p>
        <p className="cookies-paragraph">
          <strong>4.2 –</strong> A princípio, todos os dispositivos permitem que
          o utilizador aceite, recuse, apague ou administre cookies, através das
          funções do próprio programa do respectivo navegador. Podendo ser
          configurados, em geral, no menu “opções” ou “preferências”, dependendo
          do seu dispositivo.
        </p>
        <p className="cookies-paragraph">
          <strong>4.3 –</strong> Lembramos que a função de desativar cookies não
          nos permite oferecer aos nossos USUÁRIOS a excelente prestação de
          serviço que objetivamos, já que partes de nossa plataforma podem
          apresentar instabilidades e não funcionar corretamente.
        </p>
        <h3 className="cookies-heading-3">
          5 – Da legitimação do tratamento dos cookies
        </h3>
        <p className="cookies-paragraph">
          <strong>5.1 –</strong> Por consentimento do interessado, a Política de
          Cookies se aplicará aos dispositivos que autorizarem o acesso aos
          dados anonimizados e pessoais de nossos <strong>USUÁRIOS</strong>.
        </p>
        <h3 className="cookies-heading-3">6 – Da comunicação dos dados</h3>
        <p className="cookies-paragraph">
          <strong>6.1 –</strong> Em via de regra, não se comunicarão os dados a
          terceiros, exceto em caso de cookies de propriedade de outros sites,
          por obrigação legal, ou por hiperlinks parceiros à nossa plataforma.
        </p>
        <h3 className="cookies-heading-3">7 – Direitos do USUÁRIO</h3>
        <p className="cookies-paragraph">
          <strong>7.1 –</strong> Assistem ao <strong>USUÁRIO</strong> alguns
          direitos perante esta Política de Cookies, desde que o USUÁRIO tenha
          capacidade de interagir com o sistema da plataforma.
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;retirada de consentimento desta Política;
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;acesso, controle, retificação, limitação e oposição
          ao tratamento de seus dados;
        </p>
        <p className="cookies-paragraph-item">
          &#8226;&nbsp;&nbsp;contestar a utilização de seus dados junto aos
          administradores da plataforma, entrando em contato pela aba de
          contato. Diante dos termos apresentados, o <strong>USUÁRIO</strong> dá
          o seu consentimento prévio, salvo indicação ao contrário, ao uso e
          utilização de “cookies”, perante a utilização do nosso site.
        </p>
        <h3 className="cookies-heading-3">8. Detalhes de contato</h3>
        <p className="cookies-paragraph">
          Para perguntas e/ou comentários sobre nossa Política de Cookies e esta
          declaração, entre em contato conosco pelo e-mail:&nbsp;
          <Link to="mailto:contato@blog.criativistas.com.br">
            contato@blog.criativistas.com.br
          </Link>
          .
        </p>
      </div>
    </CookiesContainer>
  );
};

export default Cookies;
