import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import jwt_decode from 'jwt-decode';
//Services
import { checkLoginService, signInService } from '../services/user.service';
import { ISignInResponse, IUser } from '../services/user.types';
import { api } from '../services/api.axios';
import LoadingScreen from '../components/LoadingScreen';
import { ToastfyContext } from './ToastfyContext';
import { useNavigate } from 'react-router';

interface IProps {
  children: React.ReactNode;
}

interface IAuthContext {
  isAuthenticated: boolean;
  signIn(email: string, password: string): Promise<ISignInResponse>;
  signOut(): void;
  isValidUser(): Promise<boolean>;
  isValidToken(): boolean;
  user: IUser | null;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [isReady, setIsReady] = useState(false);
  const { handleToastfy } = useContext(ToastfyContext);
  const navigate = useNavigate();
  const nameToken = '@Creative:token';

  const setSession = (accessToken?: string) => {
    if (accessToken) {
      localStorage.setItem(nameToken, accessToken);
      api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem(nameToken);
      delete api.defaults.headers.common.Authorization;
    }
  };

  const signIn = async (email: string, password: string) => {
    const response = await signInService(email, password);

    if (!!response.user && !!response.token) {
      const token = response.token;

      setSession(token);
      setUser(response.user);

      // console.log('Signed in', response.user);

      return response;
    }

    return response;
  };

  const signOut = useCallback(() => {
    setSession();
    setUser(null);
  }, []);

  const isValidToken = useCallback(() => {
    const storagedToken = localStorage.getItem(nameToken);

    if (!storagedToken) {
      return false;
    }

    const decodedToken: { exp: number; iat: number; uid: string } = jwt_decode(
      storagedToken || ''
    );
    const currentDate = Math.floor(Date.now() / 1000);

    return decodedToken.exp > currentDate;
  }, []);

  const isValidUser = async () => {
    const storagedToken = localStorage.getItem(nameToken);

    const user = await checkLoginService(storagedToken || '');

    if (!!user.user) {
      user.user.balance = Number(user.user.balance.toFixed(1));
      setUser(user.user);
      return true;
    } else {
      signOut();
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      const storagedToken = localStorage.getItem(nameToken);

      if (!!storagedToken && isValidToken()) {
        setSession(storagedToken || '');

        await checkLoginService(storagedToken || '')
          .then((user) => {
            user.user.balance = Number(user.user.balance.toFixed(1));
            setUser(user.user);
            // console.log('Checked in', user);
          })
          .catch((error) => {
            if (
              error.response.data.code === 'EXPIRED_TOKEN' ||
              error.response.data.code === 'TOKEN_REVOKED' ||
              error.response.data.code === 'TOKEN_NOT_FOUND'
            ) {
              handleToastfy({
                message: 'Sessão expirada!',
                type: 'error',
              });
              signOut();
              navigate('/sign-in');
            } else {
              handleToastfy({
                message:
                  !!error.response.data.message || error.response.data.code
                    ? error.response.data.message || error.response.data.code
                    : 'Ocorreu um erro ao processar a requisição.',
                type: 'error',
              });
            }
          });
      } else {
        signOut();
      }

      setIsReady(true);
    })();
  }, [handleToastfy, isValidToken, navigate, signOut]);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        isValidUser,
        user,
        isAuthenticated: !!user,
        isValidToken,
      }}
    >
      {isReady ? children : <LoadingScreen />}
    </AuthContext.Provider>
  );
};

export default AuthContext;
