import styled from 'styled-components';

export const UnderDevelopmentStyled = styled.section`
  ${(props) => props.theme.mixins.containerGridDefault};

  background-color: ${(props) =>
    props.theme.pallete.colors.backgrounds.neutral};
  width: 100%;
  position: relative;
  min-height: max-content;

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 11rem;
  }

  .under-development-content {
    grid-column: center-start / center-end;
    display: flex;
    align-items: center;
    gap: 3rem;
    padding: 6rem 0;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
      gap: 0;
      text-align: center;
    }

    svg {
      width: 100%;
    }

    .under-development-texts {
      width: 100%;

      @media ${(props) => props.theme.medias.laptopSmall} {
        width: 100%;
      }
    }

    .under-development-heading-2 {
      line-height: 4.4rem;
    }

    .under-development-paragraph {
      color: ${(props) => props.theme.pallete.colors.common};
      margin-bottom: 1.6rem;

      span {
        ${(props) => props.theme.mixins.linkUnderlineDefaultEffect};
      }

      a {
        &:link,
        &:active {
          color: ${(props) => props.theme.pallete.colors.common};
          text-decoration: none;
        }
      }
    }
  }
`;
