import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { LightboxWithoutLoginContainer } from './styles';

interface IProps {
  children: React.ReactNode;
  image: React.ReactNode;
  handleOpenModal: () => void;
  customLightboxWidth?: string;
}

const LightboxWithoutLogin: React.FC<IProps> = ({
  children,
  handleOpenModal,
  image,
  customLightboxWidth,
}) => {
  return (
    <LightboxWithoutLoginContainer>
      <div className="without-content">
        <div className="without-content-left">{image}</div>
        <div className="without-content-right">{children}</div>
        <span className="without-icon-box" onClick={handleOpenModal}>
          <MdOutlineClose size={36} />
        </span>
      </div>
      <div className="without-background" onClick={handleOpenModal} />
    </LightboxWithoutLoginContainer>
  );
};

export default LightboxWithoutLogin;
