import styled from 'styled-components';

export const RoomContainer = styled.div`
  ${(props) => props.theme.mixins.containerGridDefault};

  position: relative;
  min-height: 82rem;

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 11rem;
  }

  .brainstorming-room-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    grid-column: center-start / center-end;
  }

  .room-content {
    position: relative;
    grid-column: center-start / center-end;
    z-index: 1;

    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 12rem 0 6rem;
  }

  .room-content-card {
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    border-radius: 2rem;
    box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};
    padding: 4rem 3.2rem;
  }

  .room-answers-content-image {
    width: 100%;
    max-width: 20rem;
    height: 20rem;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .room-answers-content-image:hover .room-answers-image-background {
    opacity: 1;
  }

  .room-answers-image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;

    span {
      text-align: center;
      font-size: 2.4rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      color: ${(props) => props.theme.pallete.colors.common.white};
    }
  }

  .room-answers-heading-3 {
    color: ${(props) => props.theme.pallete.colors.common.black};
  }

  .return-page {
    position: absolute;
    left: 3rem;
    top: 3rem;
    width: max-content;
    height: auto;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    z-index: 2;
    ${(props) => props.theme.mixins.linkUnderlineEffect};

    .return-page-text {
      font-weight: ${(props) => props.theme.typeFaceWeight.bold};
      font-size: 1.6rem;
      color: ${(props) => props.theme.pallete.colors.common.black};
    }
  }

  .room-answers {
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    border-radius: 2rem;
    box-shadow: ${(props) => props.theme.pallete.shadows.default.primary};

    position: relative;
    z-index: 5;
    padding: 4rem 3.2rem;

    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .room-answers-content {
    display: flex;
    gap: 3rem;
  }

  .room-answers-content-avatar {
    min-width: 6rem;
  }

  .brainstorming-message-avatar {
    display: block;
    border-radius: 100%;
    width: 6rem;
    height: 6rem;
    background-image: url('/static/images/profile.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .room-answers-content-box {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
  }

  .room-answers-content-texts {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .room-answers-content-title {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[6]};

    span {
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[11]};
      word-break: break-all;
    }
  }

  .room-answers-content-texts-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.6rem;
  }

  .room-answers-content-texts-edit {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    width: max-content;
    position: relative;
    cursor: pointer;

    ${(props) => props.theme.mixins.linkUnderlineDefaultEffect}

    span {
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[11]};
    }
  }

  .room-answers-content-paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    line-height: 2.4rem;
    word-break: break-all;
  }

  .room-answers-content-icons {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  .room-answers-content-icon {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    svg {
      cursor: pointer;
    }
  }

  .room-answers-content-span {
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    font-size: 1.6rem;
    user-select: none;

    &--green {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.green[2]};
    }

    &--red {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.red[3]};
    }
  }

  .room-answers-content-warning-icon {
    margin-left: auto;
    cursor: pointer;
  }

  .common-questions {
    grid-column: full-start / full-end;
  }

  .room-any-messages {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    margin-bottom: 2.4rem;
  }

  //************************ Images *********************************/

  .room-answers-content-images {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
  }

  .room-answers-content-image-box {
    width: 20rem;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .room-answers-content-image {
    border-radius: 1rem;
  }

  .room-answers-content-image-icon-box {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  //************************ Backgrounds ***************************/
  .room-background-white {
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    height: 42rem;
    width: 100%;
  }

  .room-background-image {
    position: absolute;
    top: 42rem;
    left: 0;
    width: 100%;
    height: 40rem;
    background-image: linear-gradient(
        180.48deg,
        rgba(217, 217, 217, 0) -11.04%,
        #f3f3f3 66.96%
      ),
      url('/static/images/books.png'),
      linear-gradient(180.48deg, rgba(217, 217, 217, 0) -11.04%, #f3f3f3 66.96%);
    background-size: cover;
    background-position: 50% 0%;
    background-repeat: no-repeat;
    z-index: -1;
  }
`;

export const EditRoomContainer = styled.div`
  .confirm-delete-room-h3 {
    font-size: 2.4rem;
  }

  .edit-create-room {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .creative-trigger-delete-button {
    margin: 0 auto;
  }

  .edit-room-button-group {
    display: flex;
    gap: 3rem;

    @media ${(props) => props.theme.medias.mobile} {
      flex-direction: column-reverse;
    }
  }

  .edit-room-button {
    margin: 0 auto;
  }

  .confirm-delete-button-group {
    display: flex;
    gap: 3rem;
    justify-content: center;
    margin-top: 3rem;
  }
`;
