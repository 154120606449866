import lodash from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Avatar from '../../components/Avatar';
import BrainstormingCommentMessage from '../../components/BrainstormingCommentMessage';
import Button from '../../components/Button';
import TextArea from '../../components/inputs/TextArea';
import Textfield from '../../components/inputs/Textfield';
import LightBox from '../../components/lightboxes/LightBox';
import LightboxCloseButtonOut from '../../components/lightboxes/LightboxCloseButtonOut';
import LightboxWithoutLogin from '../../components/lightboxes/LightboxWithoutLogin';
import Spinner from '../../components/loadings/Spinner';
import Brainstorming from '../../components/svgs/Brainstorming';
import Megaphone from '../../components/svgs/Megaphone';
import AuthContext from '../../contexts/AuthContext';
import { ToastfyContext } from '../../contexts/ToastfyContext';
import {
  chatIABrainstormingRoomService,
  createBrainstormingRoomService,
  listBrainstormingRoomService,
} from '../../services/brainstormingRoom.service';
import { IListBrainstormingRoomData } from '../../services/brainstormingRoom.types';
import CommonQuestions from '../Home/CommonQuestions';
import {
  BrainstormingRoomsContainer,
  ContainerChat,
  MessageBot,
  MessageUser,
  ModalFeedback,
} from './styles';

interface IInputFields {
  label: string;
  description: string;
}

interface IChatMessage {
  message: string;
  typeUser: 'bot' | 'user';
}

const BrainstormingRooms: React.FC = () => {
  const [isOpenLightbox, setIsOpenLightbox] = useState<boolean>(false);
  const [isModalSignOpen, setIsModalSignOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCreateRoomLoading, setIsCreateRoomLoading] =
    useState<boolean>(false);
  const [searchRoom, setSearchRoom] = useState<string>('');
  const [pageRooms, setPageRooms] = useState<number>(1);
  const [isBrainstormingRoomsLoading, setIsBrainstormingRoomsLoading] =
    useState<boolean>(true);
  const [isLoadMoreRoomsLoading, setIsLoadMoreRoomsLoading] =
    useState<boolean>(false);
  const [isModalChatIA, setIsModalChatIA] = useState<boolean>(false);
  const [isLoadingAnswerBot, setIsLoadingAnswerBot] = useState<boolean>(false);
  const [brainstormingRooms, setBrainstormingRooms] = useState<
    IListBrainstormingRoomData[]
  >([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IInputFields>({
    defaultValues: {
      description: '',
      label: '',
    },
  });
  const { handleToastfy } = useContext(ToastfyContext);
  const { isAuthenticated, signOut, user, isValidUser } =
    useContext(AuthContext);
  const [chatMessages, setChatMessages] = useState<Array<IChatMessage>>([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const perPage = 4;

  useEffect(() => {
    var objDiv = document.getElementById('container-chat-ia');

    if (objDiv) {
      objDiv.scrollTo(0, objDiv.scrollHeight); // scroll to bottom

      // objDiv.scrollIntoView();
    }
  }, [chatMessages]);

  const onSubmitMessageToChatIA = async (event) => {
    event.preventDefault();

    const _spanMessage = document.querySelector('.textarea');
    let _currentMessage: string = '';

    if (_spanMessage) {
      _currentMessage = _spanMessage.innerHTML
        .trim()
        .replaceAll(/(\r\n|\n|\r)/gm, '')
        .replaceAll('<br>', '')
        .replaceAll('&nbsp;', '');
      // console.log(_currentMessage);
      _spanMessage.innerHTML = '';
    }

    const _messages = [...chatMessages];
    // const _currentMessage = currentMessageUser;

    const objMessageUser = {
      message: _currentMessage,
      typeUser: 'user' as const,
    };

    _messages.push(objMessageUser);
    setChatMessages(_messages);
    setIsLoadingAnswerBot((prev) => !prev);

    await chatIABrainstormingRoomService(_currentMessage)
      .then((response) => {
        isValidUser(); // To update user credit
        setIsLoadingAnswerBot((prev) => !prev);
        const objMessageBot = {
          message: response.data.message.content,
          typeUser: 'bot' as const,
        };

        _messages.push(objMessageBot);
        setChatMessages(_messages);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsLoadingAnswerBot((prev) => !prev);
      });

    setChatMessages(_messages);

    // await
  };

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsCreateRoomLoading((prev) => !prev);
    await createBrainstormingRoomService(data)
      .then((response) => {
        handleToastfy({
          message: 'Sala criada com sucesso!',
          type: 'success',
        });
        navigate(`sala?id=${response.data._id}`);
        setIsCreateRoomLoading((prev) => !prev);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setIsCreateRoomLoading((prev) => !prev);
      });
  };

  const loadMoreBrainstormingRooms = async (page?: number) => {
    setIsLoadMoreRoomsLoading((prev) => !prev);

    await listBrainstormingRoomService({
      order: 'ASC',
      orderBy: 'createdAt',
      perPage: perPage,
      page: page,
      label: searchRoom,
    })
      .then((response) => {
        setIsLoadMoreRoomsLoading((prev) => !prev);
        !!page && setPageRooms((prev) => prev + 1);
        setBrainstormingRooms((prev) => [...prev, ...response.data]);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
      });
  };

  const searchBrainstormingRooms = async () => {
    setIsBrainstormingRoomsLoading(true);
    await listBrainstormingRoomService({
      order: 'ASC',
      orderBy: 'createdAt',
      perPage: perPage,
      label: searchRoom,
    })
      .then((response) => {
        setBrainstormingRooms(response.data);
        setIsBrainstormingRoomsLoading(false);
        setPageRooms(1);
        setTotalItems(response.total);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setPageRooms(1);
        setTotalItems(0);
      });
  };

  const listBrainstormingRooms = useCallback(async () => {
    setIsBrainstormingRoomsLoading(true);
    await listBrainstormingRoomService({
      order: 'ASC',
      orderBy: 'createdAt',
      perPage: perPage,
    })
      .then((response) => {
        setBrainstormingRooms(response.data);
        setIsBrainstormingRoomsLoading(false);
        setPageRooms(1);
        setTotalItems(response.total);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        setPageRooms(1);
        setTotalItems(0);
      });
  }, [handleToastfy, navigate, signOut]);

  useEffect(() => {
    listBrainstormingRooms();
  }, [listBrainstormingRooms]);

  useEffect(() => {
    watch((value) => {
      setIsEdit(lodash.values(value).every(lodash.isEmpty));
    });
  }, [watch]);

  return (
    <BrainstormingRoomsContainer>
      <section className="brainstorming-room">
        <div className="brainstorming-room-content">
          <div className="brainstorming-header-content">
            <div className="header-content-left">
              <h2 className="brainstorming-heading-2">
                Salas de Brainstorming
              </h2>
              <p className="brainstorming-paragraph">
                Troque ideias, referências e feedbacks com outros criativos para
                tirar sua ideia do papel. Deixe também a sua contribuição no
                tópico de outros criativos. Vamos trocar ideias!
              </p>

              <div className="buttons-box">
                <Button
                  variant="contained"
                  buttonText="Abrir Sala Com Criativistas"
                  onClick={() => {
                    isAuthenticated
                      ? setIsOpenLightbox((prev) => !prev)
                      : setIsModalSignOpen((prev) => !prev);
                  }}
                  customColors={{
                    backgroundColor: theme.pallete.colors.common.black,
                    hoverBackgroundColor:
                      theme.pallete.colors.brandPrimary.gray[9],
                    hoverTextColor: theme.pallete.colors.common.white,
                    textColor: theme.pallete.colors.common.white,
                  }}
                  buttonSize={150}
                />
                <div className="button-points-box">
                  <Button
                    variant="contained"
                    buttonText="Abrir Sala Privada Com IA"
                    type="button"
                    onClick={() => {
                      if (isAuthenticated) {
                        if (!user?.balance) {
                          handleToastfy({
                            message: 'Ops, você não tem créditos suficiente',
                            type: 'error',
                          });
                        } else {
                          setIsModalChatIA((prev) => !prev);
                        }
                      } else {
                        setIsModalSignOpen(true);
                      }
                    }}
                  />
                  {user?._id && (
                    <span className="button-points-span">
                      Você tem {!!user?.balance ? user.balance : 0} créditos
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="header-content-right">
              <Brainstorming />
            </div>
          </div>
          <div className="brainstorming-messages-content">
            <div className="brainstorming-room-content-search">
              <Textfield
                label=""
                placeholder="Pesquise uma sala"
                type="text"
                value={searchRoom}
                onChange={(event) => setSearchRoom(event.target.value.trim())}
              />
              <Button
                buttonText="Buscar Salas dos Criativistas"
                variant="contained"
                type="button"
                isLoading={isBrainstormingRoomsLoading}
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                buttonSize={180}
                onClick={() =>
                  isAuthenticated
                    ? searchBrainstormingRooms()
                    : setIsModalSignOpen((prev) => !prev)
                }
              />
            </div>
            {isBrainstormingRoomsLoading ? (
              <div className="brainstorming-room-spinner">
                <Spinner size={100} />
              </div>
            ) : (
              <div className="comment-messages-box">
                {brainstormingRooms.map((room, index) => {
                  return (
                    <BrainstormingCommentMessage
                      key={room._id as React.Key}
                      buttonText={'Participar da sala'.toUpperCase()}
                      isBordered
                      onClick={() =>
                        isAuthenticated
                          ? navigate(`sala?id=${room._id}`)
                          : setIsModalSignOpen((prev) => !prev)
                      }
                      roomData={room}
                    />
                  );
                })}
              </div>
            )}
          </div>
          {Math.ceil(totalItems / perPage) > pageRooms && (
            <button
              className="button-load-more"
              onClick={() => {
                isAuthenticated
                  ? loadMoreBrainstormingRooms(pageRooms + 1)
                  : setIsModalSignOpen((prev) => !prev);
              }}
            >
              {isLoadMoreRoomsLoading ? (
                <Spinner size={24} />
              ) : (
                <span>Carregar mais...</span>
              )}
            </button>
          )}
        </div>
        {isOpenLightbox && (
          <LightboxCloseButtonOut
            handleOpenModal={() => setIsOpenLightbox((prev) => !prev)}
          >
            <form
              className="brainstorming-create-room"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Textfield
                label="Nome da sala"
                type="text"
                placeholder="Escreva um título resumido sobre seu desafio"
                error={errors.label?.message}
                {...register('label', {
                  required: 'O campo é requerido.',
                })}
              />
              <TextArea
                label="Descrição"
                maxCharacter={300}
                placeholder="Ex: Estou precisando de ideias para um instagram que fala sobre moda"
                error={errors.description?.message}
                {...register('description', {
                  required: 'O campo é requerido.',
                })}
                customCountCharacters={watch(`description`)?.length}
              />
              <Button
                buttonText="CRIAR SALA"
                variant="contained"
                className="brainstorming-create-room-button"
                onClick={listBrainstormingRooms}
                isDisabled={isEdit}
                isLoading={isCreateRoomLoading}
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                type="submit"
              />
            </form>
          </LightboxCloseButtonOut>
        )}
        {isModalSignOpen && (
          <LightboxWithoutLogin
            handleOpenModal={() => setIsModalSignOpen((prev) => !prev)}
            image={<Megaphone />}
          >
            <h3>Cadastre-se para usar nossas ferramentas</h3>
            <p>
              Para utilizar nossas ferramentas criativas, você precisa ter uma
              conta na nossa plataforma. Faça agora mesmo seu cadastro e comece
              a potencializar seu processo criativo.
            </p>
            <Button
              buttonText="Fazer cadastro gratuito"
              variant="contained"
              type="button"
              className="whithout-login-button"
              onClick={() => navigate('/sign-up')}
              customColors={{
                backgroundColor: theme.pallete.colors.common.white,
                hoverBackgroundColor: theme.pallete.colors.common.white,
                hoverTextColor: theme.pallete.colors.common.black,
                textColor: theme.pallete.colors.common.black,
              }}
            />
          </LightboxWithoutLogin>
        )}
        {isModalChatIA && (
          <LightBox
            handleOpenModal={() => setIsModalChatIA((prev) => !prev)}
            customLightboxWidth="114rem"
          >
            <ModalFeedback>
              <p className="creative-trigger-ia-paragraph">
                Oi, tudo bem? Estou aqui para trocar ideia com você. <br />
                Se precisar, lembre-se de copiar algo da nossa conversa para
                salvar em outro local.
                <br /> <strong>Observação:</strong> Cada mensagem trocada com a
                IA custará 0.1 crédito.
              </p>
              <ContainerChat id="container-chat-ia">
                {chatMessages.map((value: IChatMessage, index) => {
                  if (value.typeUser === 'bot') {
                    return (
                      <MessageBot>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <span className="brainstorming-avatar" />
                        </span>
                        <div
                          onClick={() => {
                            navigator.clipboard.writeText(value.message);
                            handleToastfy({
                              message: 'Texto copiado com sucesso!',
                              type: 'success',
                            });
                          }}
                        >
                          <p>{value.message}</p>
                        </div>
                      </MessageBot>
                    );
                  } else {
                    return (
                      <MessageUser>
                        <div
                          onClick={() => {
                            navigator.clipboard.writeText(value.message);
                            handleToastfy({
                              message: 'Texto copiado com sucesso!',
                              type: 'success',
                            });
                          }}
                        >
                          <p>{value.message}</p>
                        </div>
                        {!!user?.avatar ? (
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <Avatar
                              backgroundImageLink={`${process.env.REACT_APP_URL_IMAGE_BASE}/${user.avatar}`}
                              height="2rem"
                              width="2rem"
                            />
                          </span>
                        ) : (
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <span className="brainstorming-avatar" />
                          </span>
                        )}
                      </MessageUser>
                    );
                  }
                })}
                {isLoadingAnswerBot && (
                  <MessageBot>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <span className="brainstorming-avatar" />
                    </span>
                    <div className="loading-answer">
                      <p className="point-1">.</p>
                      <p className="point-2">.</p>
                      <p className="point-3">.</p>
                    </div>
                  </MessageBot>
                )}
              </ContainerChat>

              <form
                className="brainstorming-ia-form"
                onSubmit={onSubmitMessageToChatIA}
              >
                {/* <Textfield
                  label=""
                  type="text"
                  placeholder="Escreva a sua mensagem...
              "
                  onChange={(event) =>
                    setCurrentMessageUser(event.target.value)
                  }
                  value={currentMessageUser}
                  // error={errors.specificAnswer?.message}
                  // {...register('specificAnswer')}
                /> */}
                <span
                  className="textarea"
                  role="textbox"
                  contentEditable
                ></span>

                <div className="button-points-box">
                  <Button
                    buttonText={`Enviar`}
                    variant="contained"
                    type="submit"
                    buttonSize={200}
                    // isLoading={isLoadingAnswersIA}
                  />

                  {user?._id && (
                    <span className="button-points-span">
                      Você tem {!!user?.balance ? user.balance : 0} créditos
                    </span>
                  )}
                </div>
              </form>
            </ModalFeedback>
          </LightBox>
        )}
        <div className="brainstorming-background-image" />
        <div className="brainstorming-background-white" />
      </section>
      <CommonQuestions className="brainstorming-common-questions" />
    </BrainstormingRoomsContainer>
  );
};

export default BrainstormingRooms;
