import moment from 'moment';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { IMessageRoom } from '../../services/brainstormingRoom.types';
import SeparateLine from '../SeparateLine';
import ImagePreview from '../lightboxes/ImagePreview';
import { BrainstormingMessageContainer } from './styles';

interface IProps {
  message: IMessageRoom;
}

const BrainstormingMessage: React.FC<IProps> = ({ message }) => {
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [openImageLink, setOpenImageLink] = useState<string>('');
  const theme = useTheme();

  const calcRoomCreatedTime = (createdAt: Date) => {
    return moment().diff(createdAt, 'minutes');
  };

  const handleOpenImage = (imageLink: string) => {
    setIsOpenImage((prev) => !prev);
    setOpenImageLink(imageLink);
  };

  return (
    <BrainstormingMessageContainer>
      <h3>Reportar mensagem</h3>
      <SeparateLine
        margin="1.6rem 0"
        color={theme.pallete.colors.brandPrimary.gray[2]}
      />
      <div
        key={message.createdAt.toString()}
        className="brainstorming-message-content"
      >
        <div className="brainstorming-message-content-avatar">
          <img src="/static/images/profile/person-2.jpg" alt="person-2" />
        </div>
        <div className="brainstorming-message-content-box">
          <div className="brainstorming-message-content-texts">
            <div className="brainstorming-message-content-texts-header">
              <p className="brainstorming-message-content-title">
                <span>{message.user.username}</span>{' '}
                {calcRoomCreatedTime(message.createdAt || new Date()) > 5 ? (
                  <span className="brainstorming-message-time">
                    &#8226;&nbsp; há {message?.createdAtMessage}
                  </span>
                ) : (
                  <span className="brainstorming-message-time-new-room">
                    &#8226;&nbsp; postou agora
                  </span>
                )}
              </p>
            </div>
            <p className="brainstorming-message-content-paragraph">
              {message.message}
            </p>
            {!!message.images.length && (
              <div className="brainstorming-message-content-images">
                {message.images.map((image) => {
                  return (
                    <div
                      key={image.code}
                      className="brainstorming-message-content-image-box"
                    >
                      <div
                        className="brainstorming-message-content-image"
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_URL_IMAGE_BASE}/${image.image})`,
                        }}
                        onClick={() =>
                          handleOpenImage(
                            `${process.env.REACT_APP_URL_IMAGE_BASE}/${image.image}`
                          )
                        }
                      >
                        <div className="brainstorming-message-image-background">
                          <span>Visualizar</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {isOpenImage && (
          <ImagePreview
            setIsOpenImage={setIsOpenImage}
            linkImage={openImageLink}
          />
        )}
      </div>
    </BrainstormingMessageContainer>
  );
};

export default BrainstormingMessage;
