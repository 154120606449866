import React from 'react';
import { EmailBannerStyled } from './styles';
import Button from '../../../components/Button';
import { Link } from 'react-router-dom';

interface IProps {
  isAuthenticated: boolean;
  setIsSignPlanModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmailBanner = ({ isAuthenticated, setIsSignPlanModal }: IProps) => {
  return (
    <EmailBannerStyled>
      <div className="banner-container">
        <div className="banner-image"></div>
        <div className="banner-text">
          <h3 className="heading-3">Envie um Desafio para Criativistas </h3>
          <p className="banner-paragraph">
            Sua ONG, empresa ou agência tem um desafio e está precisando de
            ideias transformadoras? Envie um e-mail para{' '}
            <strong>vemsercriativista@gmail.com</strong> e nós iremos criar um
            briefing para nossos criativistas ajudarem com ideias.
          </p>
          <Link
            to={isAuthenticated ? 'mailto:vemsercriativista@gmail.com' : '#'}
            target={isAuthenticated ? '_blank' : '_self'}
            onClick={() => {
              if (!isAuthenticated) {
                setIsSignPlanModal((prev) => !prev);
              }
            }}
          >
            <Button
              className="button"
              variant="contained"
              buttonText="Enviar desafio"
              noShadows
            />
          </Link>
        </div>
      </div>
    </EmailBannerStyled>
  );
};

export default EmailBanner;
