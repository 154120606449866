import styled from 'styled-components';

export const DashboardLayoutTopBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: min-content;
  align-items: center;
  padding: 2rem 2.4rem;
  background-color: ${(props) =>
    props.theme.pallete.colors.backgrounds.neutral};
  box-shadow: ${(props) => props.theme.pallete.shadows.default.n8};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;

  @media ${(props) => props.theme.medias.laptopSmall} {
    min-height: 9rem;
  }

  .logo {
    display: flex;
    transition: ${(props) => props.theme.transitions('default', 0.15)};
    cursor: pointer;

    @media ${(props) => props.theme.medias.laptopSmall} {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .logo-full {
    display: flex;

    &:hover {
      transform: scale(1.05);
    }

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: none;
    }
  }

  .logo-half {
    width: 6rem;
    height: 6rem;
    display: none;

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: flex;
    }
  }

  .menu-signed-icons {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .menu-user-icon-box {
    cursor: pointer;
    user-select: none;
  }

  .user-name {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
    line-height: 2rem;
    text-transform: capitalize;
    font-weight: ${(props) => props.theme.typeFaceWeight.regular};

    @media ${(props) => props.theme.medias.mobile} {
      display: none;
    }
  }

  .nav-avatar-admin {
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    background-image: url('/static/images/profile.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const MenuItemsMobileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  overflow-x: hidden;
  overflow-y: auto;
  margin: 12rem 0 6rem;
  width: 90%;

  .menu-items-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .menu-link {
    text-decoration: none;
  }

  .menu-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    transition: all 0.2s;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      bottom: 0rem;
      left: 0;
      height: 3px;
      width: 0.001%;
      background-color: currentColor;
      transition: width 0.4s cubic-bezier(1, 0, 0, 1);
    }

    &:hover::after {
      width: 100%;
    }
  }

  .menu-item-text,
  .menu-sub-item-text {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    width: max-content;
    cursor: pointer;

    font-size: 2.4rem;
    padding: 1.6rem;
    font-weight: 400;
  }

  .menu-sub-item-text-new-content {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[5]};
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
  }

  .menu-item-text {
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  .menu-sub-items {
    display: flex;
    flex-direction: column;
  }

  .menu-sub-item-text {
    position: relative;
    width: 100%;

    &::after {
      content: '';
      position: absolute;
      bottom: 0rem;
      left: 0;
      height: 3px;
      width: 0.001%;
      background-color: currentColor;
      transition: width 0.4s cubic-bezier(1, 0, 0, 1);
    }

    &:hover::after {
      width: 100%;
    }
  }
`;
