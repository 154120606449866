import styled from 'styled-components';

export const StaffMembersContainer = styled.div`
  ${(props) => props.theme.mixins.containerGridDefault};

  @media ${(props) => props.theme.medias.laptopSmall} {
    margin-top: 11rem;
  }

  .staff-members-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
  }

  .staff-members-heading-3 {
    font-size: 2.4rem;
    margin: 0;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[8]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
  }

  .staff-members-paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    line-height: 3.6rem;
  }

  .staff-members-block-text {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-top: 6rem;

    @media ${(props) => props.theme.medias.mobile} {
      align-items: center;
    }
  }

  .staff-members-button-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3rem;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
      align-items: center;
    }
  }

  .grid-invite-list {
    margin: 3rem 0 0;
  }

  .invite-identity {
    display: flex;
    flex-direction: column;
    margin-right: auto;

    @media ${(props) => props.theme.medias.mobile} {
      margin-right: initial;
    }
  }

  .invite-identity-name {
    font-size: 2rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    margin-bottom: 1.2rem;
  }

  .invite-identity-email {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .invite-identity {
    position: relative;

    @media ${(props) => props.theme.medias.laptopSmall} {
      margin-left: auto;
    }

    @media ${(props) => props.theme.medias.mobile} {
      margin-right: auto;
    }
  }

  .invite-item {
    display: flex;
    align-items: center;
    gap: 3rem;
    border-bottom: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    padding: 3rem 0;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
    }
  }

  .invite-identity-name-box {
    display: flex;
    align-items: baseline;
    gap: 3rem;
  }

  .invite-identity-status {
    padding: 0.8rem 2rem;
    border-radius: 100px;

    color: ${(props) => props.theme.pallete.colors.common.white};
    line-height: 1.2rem;
  }
`;

export const InvitePeopleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .heading-2 {
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-size: 2.4rem;
    margin-bottom: 0;
  }

  .function-title {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  .grid-boxes {
    display: grid;
    column-gap: 1.6rem;
    row-gap: 3rem;
    grid-template-columns: min-content 1fr;
  }

  .grid-checkbox-box {
  }

  .checkbox {
    display: none;
  }

  .grid-texts-title {
    display: block;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    font-size: 1.6rem;
    margin: 0.4rem 0 0.8rem;
  }

  .grid-texts-paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  button {
    margin: 0 auto;
  }

  .textfield-error {
    color: ${(props) => props.theme.pallete.colors.error.main};
    padding: 0.4rem 2rem 0;
  }
`;

export const InactiveUsersContainer = styled.div`
  .users-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
  }

  .form-inactive-users {
    display: grid;
    grid-template-columns: repeat(
      12,
      [col-start] minmax(min-content, 9.5rem) [col-end]
    );
    align-items: baseline;
    column-gap: 1.6rem;
    row-gap: 3rem;
    margin: 3rem 0;

    > :nth-child(1) {
      grid-column: col-start 1 / col-end 6;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(2) {
      grid-column: col-start 7 / col-end 12;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(3) {
      grid-column: col-start 1 / col-end 12;

      justify-self: end;

      @media ${(props) => props.theme.medias.laptopSmall} {
        justify-self: center;
      }
    }
  }

  .grid-user-list {
    margin: 3rem 0 0;
  }

  .user-identity {
    display: flex;
    flex-direction: column;
    margin-right: auto;

    @media ${(props) => props.theme.medias.mobile} {
      margin-right: initial;
    }
  }

  .user-identity-name {
    font-size: 2rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    margin-bottom: 1.2rem;
  }

  .user-identity-email {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.medium};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .user-item {
    display: flex;
    align-items: center;
    gap: 3rem;
    border-bottom: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    padding: 3rem 0;

    @media ${(props) => props.theme.medias.laptopSmall} {
      flex-direction: column;
    }
  }

  .user-identity-name-box {
    display: flex;
    align-items: baseline;
    gap: 3rem;
  }
`;
