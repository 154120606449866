import styled from 'styled-components';

export const EditMarketCategoryContainer = styled.div`
  .market-category-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }

  .heading-2 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  form {
    display: grid;
    grid-template-columns: repeat(
      12,
      [col-start] minmax(min-content, 9.6rem) [col-end]
    );
    column-gap: 1.6rem;
    row-gap: 3rem;

    > :nth-child(1) {
      grid-column: col-start 1 / col-end 5;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(2) {
      grid-column: col-start 6 / col-end 9;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(3) {
      grid-column: col-start 10 / col-end 12;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    > :nth-child(4) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(5) {
      grid-column: col-start 1 / col-end 12;
    }

    > :nth-child(6) {
      grid-column: col-start 1 / col-end 12;
    }
  }

  .edit-market-category-button {
    margin-left: auto;

    @media ${(props) => props.theme.medias.laptopSmall} {
      margin-right: auto;
    }
  }

  .form-paragraph {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }
`;
