import React from 'react';
import { Link } from 'react-router-dom';
import { TabsRouteContainer, Tab } from './styles';

interface IRoutes {
  link: string;
  label: string;
  isActive: boolean;
}

interface IProps {
  routes: IRoutes[];
  isCenteredInMobile?: boolean;
}

const TabsRoute: React.FC<IProps> = ({ routes, isCenteredInMobile }) => {
  return (
    <TabsRouteContainer isCenteredInMobile={isCenteredInMobile || false}>
      {routes.map((route) => {
        return (
          <Link key={route.label} to={route.link}>
            <Tab isRouteActive={route.isActive}>{route.label}</Tab>
          </Link>
        );
      })}
    </TabsRouteContainer>
  );
};

export default TabsRoute;
