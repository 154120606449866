import lodash from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  FieldErrors,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../../../components/Button';
import SeparateLine from '../../../../components/SeparateLine';
import RadioColors from '../../../../components/inputs/RadioColors';
import Select from '../../../../components/inputs/Select';
import TextArea from '../../../../components/inputs/TextArea';
import Textfield from '../../../../components/inputs/Textfield';
import Upload from '../../../../components/inputs/Upload';
import Spinner from '../../../../components/loadings/Spinner';
import ToastfyContext from '../../../../contexts/ToastfyContext';
import {
  deleteCreativeTriggerService,
  showCreativeTriggerService,
  updateCreativeTriggerService,
} from '../../../../services/creativeTriggers.service';
import { listMarketSegmentService } from '../../../../services/marketSegments.service';
import { IListMarketSegmentData } from '../../../../services/marketSegments.types';
import { EditCreativeTriggerContainer } from './styles';
import LightBox from '../../../../components/lightboxes/LightBox';
import ImagePreview from '../../../../components/lightboxes/ImagePreview';
import AuthContext from '../../../../contexts/AuthContext';

interface IInsights {
  labelReference: string;
  position: string;
  link: string;
  description: string;
  image: string;
  imagePreview?: string;
  file?: File[];
}

interface IInputFields {
  label: string;
  marketSegmentId: string;
  color: string;
  description: string;
  insights: IInsights[];
}

const EditCreativeTrigger: React.FC = () => {
  const [marketSegments, setMarketSegments] = useState<
    IListMarketSegmentData[]
  >([]);
  const [isloadingCreativeTrigger, setIsLoadingCreativeTrigger] =
    useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [openImageLink, setOpenImageLink] = useState<string>('');
  // const imageBasePath = process.env.REACT_APP_URL_IMAGE_BASE;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    watch,
    clearErrors,
    reset,
    setValue,
  } = useForm<IInputFields>({
    defaultValues: {
      insights: [
        {
          description: '',
          labelReference: '',
          link: '',
        },
      ],
    },
  });
  const imagebasePath = process.env.REACT_APP_URL_IMAGE_BASE;
  const [revokeList, setRevokeList] = useState<string[]>([]);
  const [dataFormBackup, setDataFormBackup] = useState<IInputFields>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const maximumFilesSize = 9000000; // Equal 9MB
  const [isSegmentMarketLoading, setIsSegmentMarketLoading] =
    useState<boolean>(true);
  const { fields, append, remove } = useFieldArray<IInputFields, 'insights'>({
    control,
    name: 'insights',
  });
  const theme = useTheme();
  const navigate = useNavigate();

  const onError = (errors: FieldErrors<IInputFields>) => {
    handleToastfy({
      message: 'Existem campos que não foram preenchidos.',
      type: 'error',
    });
  };

  const onSubmit: SubmitHandler<IInputFields> = async (submitFormData) => {
    setIsLoading((prev) => !prev);
    const idCreativeTrigger = searchParams.get('id');
    let formData = new FormData();
    const filesFromSubmit: File[] = [];
    submitFormData.insights.forEach(
      (insight) =>
        !!insight.file?.length && filesFromSubmit.push(insight.file[0])
    );

    const sanitizeInsights = submitFormData.insights.map((insight) => {
      return {
        label: insight.labelReference,
        position: insight.position,
        link: insight.link,
        description: insight.description,
        ...(!insight.file && { image: insight.image }),
      };
    });

    const dataSend = {
      label: submitFormData.label,
      color: submitFormData.color,
      marketSegmentId: submitFormData.marketSegmentId,
      description: submitFormData.description,
      insights: sanitizeInsights,
    };

    formData.append('data', JSON.stringify(dataSend));
    !!filesFromSubmit.length &&
      filesFromSubmit.map((file) => formData.append('images', file));

    const imageTotalSize = !!filesFromSubmit.length
      ? filesFromSubmit
          .map((image) => image.size)
          .reduce((previousSize, currentSize) => previousSize + currentSize)
      : 0;

    if (imageTotalSize < maximumFilesSize) {
      !!idCreativeTrigger
        ? await updateCreativeTriggerService(formData, idCreativeTrigger)
            .then((response) => {
              setIsLoading((prev) => !prev);
              handleToastfy({
                message: 'Gatilho criativo atualizado com sucesso!',
                type: 'info',
              });
              navigate(
                `/dashboard/ver-conteudo-gatilho-criativo?id=${idCreativeTrigger}`
              );
              revokeList.map((list) => URL.revokeObjectURL(list));
            })
            .catch((error) => {
              setIsLoading((prev) => !prev);
              error.code === 'LABEL_ALREADY_EXISTS' &&
                setError('label', {
                  type: 'custom',
                  message: 'O título já existe.',
                });
              if (
                error.code === 'EXPIRED_TOKEN' ||
                error.code === 'TOKEN_REVOKED' ||
                error.code === 'TOKEN_NOT_FOUND'
              ) {
                handleToastfy({
                  message: 'Sessão expirada!',
                  type: 'error',
                });
                signOut();
                navigate('/sign-in');
              } else {
                handleToastfy({
                  message:
                    !!error.message || error.code
                      ? error.message || error.code
                      : 'Ocorreu um erro ao processar a requisição.',
                  type: 'error',
                });
              }
            })
        : handleToastfy({
            message: 'Id não encontrado.',
            type: 'error',
          });
    } else {
      setIsLoading((prev) => !prev);
      handleToastfy({
        message: `Tamanho total de arquivos ${imageTotalSize} bytes, é superior a ${maximumFilesSize} bytes. Tente enviar arquivos menores.`,
        type: 'error',
      });
    }
  };

  const handleFiles = (file: File[], fieldId: string, fieldIndex: number) => {
    const objectURL = URL.createObjectURL(file[0]);

    setRevokeList((prev) => [...prev, objectURL]);
    setValue(`insights.${fieldIndex}.imagePreview`, objectURL);
    setValue(`insights.${fieldIndex}.file`, file);
    clearErrors(`insights.${fieldIndex}.image`);
  };

  const addInsight = () => {
    append({
      description: '',
      image: '',
      labelReference: '',
      link: '',
      position: '',
    });
  };

  const removeInsight = (idToRemove: number, fieldId: string) => {
    remove(idToRemove);
  };

  const getMarketSegments = useCallback(async () => {
    setIsSegmentMarketLoading(true);
    await listMarketSegmentService({
      order: 'DESC',
      orderBy: 'createdAt',
      perPage: 200,
    })
      .then((response) => {
        setMarketSegments(response.data);
        setIsSegmentMarketLoading(false);
      })
      .catch((error) => {
        if (
          error.code === 'EXPIRED_TOKEN' ||
          error.code === 'TOKEN_REVOKED' ||
          error.code === 'TOKEN_NOT_FOUND'
        ) {
          handleToastfy({
            message: 'Sessão expirada!',
            type: 'error',
          });
          signOut();
          navigate('/sign-in');
        } else {
          handleToastfy({
            message:
              !!error.message || error.code
                ? error.message || error.code
                : 'Ocorreu um erro ao processar a requisição.',
            type: 'error',
          });
        }
        console.log(error.response.data);
      });
  }, [handleToastfy, navigate, signOut]);

  const getCreativeTrigger = useCallback(
    async (id: string) => {
      await showCreativeTriggerService(id)
        .then((response) => {
          const sanitizeForm = {
            label: response.data.label,
            color: response.data.color,
            marketSegmentId: response.data.marketSegmentId,
            description: response.data.description,
            insights: response.data.insights
              .sort((a, b) => {
                return a.position - b.position;
              })
              .map((insight) => {
                return {
                  labelReference: insight.label,
                  position: insight.position.toString(),
                  link: insight.link,
                  description: insight.description,
                  imagePreview: `${imagebasePath}/${insight.image}`,
                  image: insight.image,
                };
              }),
          };

          setDataFormBackup(sanitizeForm);
          setIsLoadingCreativeTrigger(false);
          reset(sanitizeForm);
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          console.log(error.response.data);
        });
    },
    [handleToastfy, imagebasePath, navigate, reset, signOut]
  );

  const handleDeleteCreativeTrigger = async () => {
    setIsDeleteLoading(true);
    const id = searchParams.get('id');

    !!id &&
      (await deleteCreativeTriggerService(id)
        .then((response) => {
          handleToastfy({
            message: 'Gatilho criativo deletado com sucesso!',
            type: 'success',
          });
          setIsDeleteLoading(false);
          setIsDelete(false);
          navigate('/dashboard/gatilhos-criativos');
        })
        .catch((error) => {
          setIsDeleteLoading(false);
          setIsDelete(false);
          !!error.response.data.message
            ? handleToastfy({
                message: error.response.data.message,
                type: 'error',
              })
            : handleToastfy({
                message: 'Ocorreu um erro ao excluir o gatilho criativo.',
                type: 'error',
              });
        }));
  };

  const handleOpenImage = (link: string | undefined) => {
    setIsOpenImage((prev) => !prev);
    setOpenImageLink(link || '');
  };

  const validateFileSize = (file: File) => {
    if (file.size > 800000) {
      return {
        code: 'size-too-large',
        message: 'O arquivo é maior que 800 Kbytes',
      };
    }

    return null;
  };

  useEffect(() => {
    Object.keys(errors).length &&
      handleToastfy({
        message: 'Existem campos que não foram preenchidos',
        type: 'error',
      });
  }, [errors, handleToastfy]);

  useEffect(() => {
    const id = searchParams.get('id');

    if (!!id) {
      getCreativeTrigger(id);
    }
  }, [searchParams, getCreativeTrigger]);

  useEffect(() => {
    !!dataFormBackup &&
      watch((value) => {
        setIsEdit(lodash.isEqual(dataFormBackup, value));
      });
  }, [watch, dataFormBackup]);

  useEffect(() => {
    getMarketSegments();
  }, [getMarketSegments]);

  return (
    <EditCreativeTriggerContainer>
      {isloadingCreativeTrigger ? (
        <div className="creative-trigger-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <React.Fragment>
          <h2 className="heading-2 heading-2--margin">
            Editar Gatilho Criativo
          </h2>
          <SeparateLine
            margin="0 0 5rem"
            color={theme.pallete.colors.brandPrimary.yellow[6]}
            lineHeight="3px"
          />
          <form
            className="form-grid"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <Textfield
              label="Título"
              error={errors.label?.message}
              type="text"
              {...register('label', {
                required: 'O campo é requerido.',
              })}
            />
            <Select
              textLabel="Selecione a categoria do conteúdo"
              options={[
                {
                  optionName: 'Gatilhos Criativos',
                  optionValue: 'creative-trigger',
                },
                {
                  optionName: 'Salas de Brainstorming',
                  optionValue: 'brainstorming-rooms',
                },
              ]}
              value={'creative-trigger'}
              onChange={() => {}}
              disabled
            />
            {isSegmentMarketLoading ? (
              <div className="segment-spinner">
                <Spinner size={50} />
              </div>
            ) : (
              <Select
                textLabel="Selecione o segmento do mercado"
                error={errors.marketSegmentId?.message}
                options={marketSegments.map((marketSegment) => {
                  return {
                    optionName: marketSegment.label,
                    optionValue: marketSegment._id,
                  };
                })}
                {...register('marketSegmentId', {
                  required: 'O campo é requerido.',
                })}
              />
            )}
            <div className="radio-group-container">
              <span className="label-radio-group">Selecione a cor do card</span>
              <div className="radio-group">
                <RadioColors
                  type="radio"
                  value={theme.pallete.colors.brandPrimary.blue[1]}
                  {...register('color', {
                    required: 'O campo é requerido.',
                  })}
                />
                <RadioColors
                  type="radio"
                  value={theme.pallete.colors.brandPrimary.blue[2]}
                  {...register('color', {
                    required: 'O campo é requerido.',
                  })}
                />
                <RadioColors
                  type="radio"
                  value={theme.pallete.colors.brandPrimary.purple[1]}
                  {...register('color', {
                    required: 'O campo é requerido.',
                  })}
                />
                <RadioColors
                  type="radio"
                  value={theme.pallete.colors.brandPrimary.purple[2]}
                  {...register('color', {
                    required: 'O campo é requerido.',
                  })}
                />
                <RadioColors
                  type="radio"
                  value={theme.pallete.colors.brandPrimary.pink[1]}
                  {...register('color', {
                    required: 'O campo é requerido.',
                  })}
                />
                <RadioColors
                  type="radio"
                  value={theme.pallete.colors.brandPrimary.red[3]}
                  {...register('color', {
                    required: 'O campo é requerido.',
                  })}
                />
                <RadioColors
                  type="radio"
                  value={theme.pallete.colors.brandPrimary.yellow[4]}
                  {...register('color', {
                    required: 'O campo é requerido.',
                  })}
                />
                <RadioColors
                  type="radio"
                  value={theme.pallete.colors.brandPrimary.yellow[6]}
                  {...register('color', {
                    required: 'O campo é requerido.',
                  })}
                />
                <RadioColors
                  type="radio"
                  value={theme.pallete.colors.brandPrimary.green[1]}
                  {...register('color', {
                    required: 'O campo é requerido.',
                  })}
                />
                <RadioColors
                  type="radio"
                  value={theme.pallete.colors.brandPrimary.green[2]}
                  {...register('color', {
                    required: 'O campo é requerido.',
                  })}
                />
              </div>
              {!!errors.color?.message && (
                <span className="textfield-error">{errors.color?.message}</span>
              )}
            </div>
            <TextArea
              label="Descrição"
              maxCharacter={300}
              error={errors.description?.message}
              customCountCharacters={watch('description')?.length}
              {...register('description', {
                required: 'O campo é requerido.',
              })}
            />
            <div className="create-creative-trigger-line">
              <span>Referência</span>
              <SeparateLine
                margin="0"
                color={theme.pallete.colors.common.black}
                lineHeight="1px"
              />
            </div>
            <div>
              {fields.map((field, index) => {
                return (
                  <div className="reference-content" key={field.id}>
                    <Textfield
                      label="Título de referência"
                      type="text"
                      error={
                        !!errors?.insights
                          ? errors?.insights[index]?.labelReference?.message
                          : ''
                      }
                      {...register(`insights.${index}.labelReference`, {
                        required: 'O campo é requerido.',
                      })}
                    />
                    <Select
                      textLabel="Posição"
                      options={fields.map((field, index) => {
                        return {
                          optionName: `${index + 1}`,
                          optionValue: `${index + 1}`,
                        };
                      })}
                      error={
                        !!errors?.insights
                          ? errors?.insights[index]?.position?.message
                          : ''
                      }
                      {...register(`insights.${index}.position`, {
                        required: 'O campo é requerido.',
                        validate: (value) => {
                          const position = watch('insights').map(
                            (insight) => insight.position
                          );
                          const isAllUniqueItems = position.every(
                            (value, index, array) => {
                              return array.indexOf(value) === index;
                            }
                          );

                          isAllUniqueItems &&
                            watch('insights').forEach((insight, index) =>
                              clearErrors(`insights.${index}.position`)
                            );

                          if (!isAllUniqueItems) {
                            return 'Existe uma posição igual.';
                          }
                        },
                      })}
                    />
                    <Upload
                      label="Inserir nova imagem"
                      type="text"
                      validator={validateFileSize}
                      acceptTypeFiles={{
                        'image/png': ['.png'],
                        'image/jpeg': ['.jpeg'],
                      }}
                      handleCallbackArchive={(file) =>
                        handleFiles(file, field.id, index)
                      }
                      {...register(`insights.${index}.image`, {
                        validate: (value) => {
                          const insightValue = watch(`insights.${index}`);

                          if (
                            !insightValue.imagePreview &&
                            !insightValue.image.length
                          ) {
                            return 'O campo é requerido';
                          }
                        },
                      })}
                      error={
                        !!errors?.insights
                          ? errors?.insights[index]?.image?.message
                          : undefined
                      }
                    />
                    <Textfield
                      label="Acrescentar link"
                      type="text"
                      placeholder="https://www.link.com"
                      {...register(`insights.${index}.link`)}
                    />
                    <div className="creative-trigger-reference-text-group">
                      <span className="creative-trigger-reference-text">
                        Imagem atual
                      </span>
                      {!!watch(`insights.${index}.imagePreview`) ? (
                        <div
                          className="creative-trigger-reference-image-container"
                          onClick={() =>
                            handleOpenImage(
                              watch(`insights.${index}.imagePreview`)
                            )
                          }
                        >
                          <div
                            style={{ position: 'relative', display: 'flex' }}
                          >
                            <img
                              className="creative-trigger-reference-image"
                              src={watch(`insights.${index}.imagePreview`)}
                              alt="reference-1"
                            />
                            <div className="creative-trigger-reference-image-background">
                              <span>Visualizar imagem</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <span className="creative-trigger-without-image">
                          Imagem não inserida
                        </span>
                      )}
                    </div>
                    <TextArea
                      label="Descrição"
                      maxCharacter={300}
                      {...register(`insights.${index}.description`, {
                        required: 'O campo é requerido.',
                      })}
                      customCountCharacters={
                        watch(`insights.${index}.description`)?.length
                      }
                      error={
                        !!errors?.insights
                          ? errors?.insights[index]?.description?.message
                          : ''
                      }
                    />
                    {fields.length > 1 && (
                      <div
                        className="create-creative-trigger-remove-insight-box"
                        onClick={() => removeInsight(index, field.id)}
                      >
                        <IoIosRemoveCircleOutline size={20} />
                        <span>Remover insight</span>
                      </div>
                    )}
                    <SeparateLine
                      margin="0"
                      color={theme.pallete.colors.brandPrimary.gray[2]}
                      lineHeight="1px"
                    />
                  </div>
                );
              })}
            </div>
            <div
              className="create-creative-trigger-new-insight-box"
              onClick={addInsight}
            >
              <AiOutlinePlus size={20} />
              <span>Adicionar novo insight</span>
            </div>
            <div className="edit-button-group">
              <Button
                buttonText="Cancelar"
                variant="contained"
                type="button"
                className="form-button-submit"
                buttonSize={200}
                onClick={() => navigate('/dashboard/gatilhos-criativos')}
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
              />
              <Button
                buttonText="Editar conteúdo"
                variant="contained"
                type="submit"
                isDisabled={isEdit}
                isLoading={isLoading}
              />
            </div>
            <Button
              buttonText="Excluir conteúdo"
              variant="outlined"
              type="button"
              className="creative-trigger-delete-button"
              onClick={() => setIsDelete((prev) => !prev)}
              customColors={{
                backgroundColor: theme.pallete.colors.brandPrimary.red[3],
                hoverBackgroundColor: theme.pallete.colors.brandPrimary.red[3],
                hoverTextColor: theme.pallete.colors.brandPrimary.red[3],
                textColor: theme.pallete.colors.brandPrimary.red[3],
              }}
            />
          </form>
          {isDelete && (
            <LightBox
              customLightboxWidth="90rem"
              handleOpenModal={() => setIsDelete((prev) => !prev)}
            >
              <div className="delete-creative-trigger-container">
                <h3>Deseja Realmente excluir o gatilho criativo?</h3>
                <div className="delete-creative-trigger-button-group">
                  <Button
                    buttonText="Não"
                    variant="contained"
                    buttonSize={150}
                    onClick={() => setIsDelete((prev) => !prev)}
                    customColors={{
                      backgroundColor: theme.pallete.colors.common.black,
                      hoverBackgroundColor:
                        theme.pallete.colors.brandPrimary.gray[9],
                      hoverTextColor: theme.pallete.colors.common.white,
                      textColor: theme.pallete.colors.common.white,
                    }}
                  />
                  <Button
                    buttonText="Sim"
                    variant="contained"
                    isLoading={isDeleteLoading}
                    buttonSize={150}
                    onClick={() => handleDeleteCreativeTrigger()}
                  />
                </div>
              </div>
            </LightBox>
          )}
          {isOpenImage && (
            <ImagePreview
              setIsOpenImage={setIsOpenImage}
              linkImage={openImageLink}
            />
          )}
        </React.Fragment>
      )}
    </EditCreativeTriggerContainer>
  );
};

export default EditCreativeTrigger;
