import styled from 'styled-components';

interface IProps {
  labelColor?: string;
}

export const TextAreaContainer = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .text-area-content {
    position: relative;
  }

  .label {
    color: ${(props) =>
      !!props.labelColor
        ? props.labelColor
        : props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    line-height: 5rem;
    font-size: 1.6rem;
    user-select: none;
  }

  .text-area {
    outline: none;
    resize: vertical;
    padding: 1.6rem 2rem 3.2rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    line-height: 2.4rem;
    font-size: 1.6rem;
    border-radius: 2rem;
    width: 100%;
    min-height: 15rem;
    max-height: 25rem;
    display: block;
    border: 1px solid
      ${(props) => props.theme.pallete.colors.brandPrimary.gray[2]};
    font-family: inherit;
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    word-break: normal;
  }

  .character-box {
    position: absolute;
    bottom: 0.6rem;
    right: 2rem;
    line-height: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    font-size: 1.4rem;
    background-color: ${(props) => props.theme.pallete.colors.common.white};
    padding: 0 0.8rem;
    border-radius: 2rem;
  }

  .current-character {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .maximum-character {
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .textfield-error {
    color: ${(props) => props.theme.pallete.colors.error.main};
    padding: 0.4rem 2rem 0;
  }
`;
