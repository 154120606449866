import React, { HTMLAttributes } from 'react';
import { AvatarContainer, IAvatarProps } from './styles';

type IProps = IAvatarProps & HTMLAttributes<HTMLDivElement>;

const Avatar: React.FC<IProps> = ({ ...props }) => {
  return <AvatarContainer {...props} />;
};

export default Avatar;
