import styled from 'styled-components';

export const LightboxSendCreditsContainer = styled.div`
  .credits-content {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .credits-heading-3 {
    font-size: 2.4rem;
    color: ${(props) => props.theme.pallete.colors.common.black};
  }

  .credits-label {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    line-height: 5rem;
    display: block;
  }

  .credits-name {
    display: inline-block;
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
    margin-right: 1.6rem;
  }

  .credits-email {
    display: inline-block;
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .credits-button {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .credits-confirm-title {
    font-size: 2rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    margin-right: 1.6rem;
    display: block;
    margin-bottom: 3rem;
    text-align: center;
  }

  .credits-confirm-button-group {
    display: flex;
    justify-content: center;
    gap: 1.6rem;
  }

  .credits-confirm-value {
    font-size: 2rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;
