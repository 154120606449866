import styled from 'styled-components';

interface IProps {
  customLightboxWidth?: string;
}

export const LightBoxContainer = styled.div<IProps>`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  ${(props) => props.theme.mixins.containerGridDefault};

  .light-box-content {
    grid-column: center-start / center-end;
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: ${(props) =>
      !!props.customLightboxWidth ? props.customLightboxWidth : '100%'};
    min-height: 20rem;
    max-height: 85vh;
    background-color: ${(props) =>
      props.theme.pallete.colors.backgrounds.neutral};
    border-radius: 2rem;
    padding: 6rem 3rem 3rem;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1200;

    ::-webkit-scrollbar-thumb {
      background-color: ${(props) =>
        props.theme.pallete.colors.brandPrimary.gray[3]};
      border: 4px solid transparent;
      border-radius: 8px;
      background-clip: padding-box;
      padding: 1rem 0;
    }

    ::-webkit-scrollbar {
      width: 16px;
    }

    @media ${(props) => props.theme.medias.mobile} {
      padding-top: 9rem;
    }
  }

  .icon-box {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    padding: 0.8rem;
    cursor: pointer;

    svg {
      color: ${(props) => props.theme.pallete.colors.common.black};
    }
  }

  .light-box-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1100;
  }

  .delete-creative-trigger-container {
    h3 {
      text-align: center;
      margin-bottom: 3rem;
    }
  }

  .delete-creative-trigger-button-group {
    display: flex;
    gap: 1.6rem;
    justify-content: center;
  }
`;
