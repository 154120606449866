import React, { useState, useContext } from 'react';
import ToastfyContext from '../../contexts/ToastfyContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Textfield from '../../components/inputs/Textfield';
import { forgotPasswordService } from '../../services/user.service';
import InstagramBanner from '../Home/InstagramBanner';
import { ForgotPasswordStyled } from './styles';

type IInput = {
  email: string;
};

const ForgotPassword: React.FC = () => {
  const [isResetLoading, setIsResetLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IInput>();
  const navigate = useNavigate();
  const { handleToastfy } = useContext(ToastfyContext);

  const onSubmit: SubmitHandler<IInput> = async (data) => {
    setIsResetLoading((prev) => !prev);
    forgotPasswordService(data.email)
      .then((response) => {
        handleToastfy({ message: response.message, type: 'success' });
        navigate('/sign-in');
        setIsResetLoading((prev) => !prev);
      })
      .catch((error) => {
        console.log(error);
        handleToastfy({ message: error.response.data.message, type: 'error' });
        setIsResetLoading((prev) => !prev);
      });
  };

  return (
    <ForgotPasswordStyled>
      <section className="forgot-password">
        <div className="text-box">
          <h2 className="heading-2">Oi, resete sua senha! :)</h2>
          <p className="heading-subtitle">
            E quando entrar, pode sair da caixinha.
          </p>
        </div>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Textfield
            label="E-mail"
            type="email"
            error={errors.email?.message}
            {...register('email', {
              required: 'O campo é requerido.',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Digite um e-mail válido',
              },
            })}
          />
          <Button
            variant="contained"
            buttonText="Resetar senha"
            type="submit"
            isFullWidth
            isLoading={isResetLoading}
          />
        </form>
        <p className="sign-in">
          Já possui uma conta?
          <Link to="/sign-in">
            <span className="link">Entrar</span>
          </Link>
        </p>
      </section>
      <InstagramBanner />
    </ForgotPasswordStyled>
  );
};

export default ForgotPassword;
