import styled from 'styled-components';

export interface IAvatarProps {
  width: string;
  height: string;
  backgroundImageLink: string;
}

export const AvatarContainer = styled.div<IAvatarProps>`
  border-radius: 100%;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-image: ${(props) => `url(${props.backgroundImageLink})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
