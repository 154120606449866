import styled from 'styled-components';

interface IColorBoxProps {
  backgroundColor: string;
}

interface IPublishedProps {
  backgroundColor: string;
}

export const ReadCreativeTriggerContainer = styled.div`
  .read-creative-trigger-line {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    span {
      color: ${(props) => props.theme.pallete.colors.common.black};
      font-weight: 600;
      font-size: 1.6rem;
    }
  }

  .read-creative-trigger-heading-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
    margin-bottom: 3rem;
  }

  .read-creative-trigger-heading-3 {
    display: inline-block;
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.bold};
  }

  .read-creative-trigger-edit {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    position: relative;
    cursor: pointer;

    ${(props) => props.theme.mixins.linkUnderlineDefaultEffect}

    span {
      color: ${(props) => props.theme.pallete.colors.common.black};
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    }
  }

  .read-creative-trigger-grid-content-1 {
    display: grid;
    grid-template-columns: repeat(
      12,
      [col-start] minmax(min-content, 9.5rem) [col-end]
    );
    gap: 1.6rem;
    margin-bottom: 6rem;

    & > :nth-child(1) {
      grid-column: col-start 1 / col-end 3;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
      }

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    & > :nth-child(2) {
      grid-column: col-start 4 / col-end 6;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 7 / col-end 12;
      }

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    & > :nth-child(3) {
      grid-column: col-start 7 / col-end 9;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 1 / col-end 6;
      }

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    & > :nth-child(4) {
      grid-column: col-start 10 / col-end 12;

      @media ${(props) => props.theme.medias.laptopSmall} {
        grid-column: col-start 7 / col-end 12;
      }

      @media ${(props) => props.theme.medias.mobile} {
        grid-column: col-start 1 / col-end 12;
      }
    }

    & > :nth-child(5) {
      grid-column: col-start 1 / col-end 12;
    }
  }

  .read-creative-trigger-label-group {
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
  }

  .read-creative-trigger-label {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
  }

  .read-creative-trigger-answer {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .read-creative-trigger-line {
    margin-bottom: 3rem;
  }

  .read-creative-trigger-references {
    display: flex;
    flex-direction: column;
  }

  .read-creative-trigger-spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }

  /**
    REFERENCE
  */

  .creative-trigger-reference {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    margin-bottom: 3rem;
    align-items: center;

    @media ${(props) => props.theme.medias.mobile} {
      justify-content: center;
    }

    > :nth-child(1) {
      flex: 0 0 auto;

      @media ${(props) => props.theme.medias.mobile} {
        flex: 1 1 auto;
      }
    }

    > :nth-child(2) {
      flex: 1;

      @media ${(props) => props.theme.medias.mobile} {
        flex: 1 1 auto;
      }
    }

    > :nth-child(3) {
      flex: 0 0 100%;
    }

    > :nth-child(4) {
      flex: 0 0 100%;
    }
  }

  .creative-trigger-reference-image-container:hover
    .creative-trigger-reference-image-background {
    opacity: 1;
  }

  .creative-trigger-reference-image-container {
    position: relative;
    display: flex;
    width: max-content;
    cursor: pointer;
  }

  .creative-trigger-reference-image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;

    span {
      font-size: 2.4rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      color: ${(props) => props.theme.pallete.colors.common.white};
      padding: 3rem;
    }
  }

  .creative-trigger-reference-image {
    width: 40rem;
    border-radius: 1rem;

    @media ${(props) => props.theme.medias.mobile} {
      margin: 0 auto;
    }
  }

  .creative-trigger-reference-group-after-image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 0.8rem;
    row-gap: 3rem;

    > :nth-child(1) {
      min-width: calc(50% - 0.8rem);
    }

    > :nth-child(2) {
      min-width: calc(50% - 0.8rem);
    }

    > :nth-child(3) {
      min-width: calc(50% - 0.8rem);
    }
  }

  .creative-trigger-reference-text-group {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    word-break: break-all;
  }

  .creative-trigger-reference-text {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[9]};
  }

  .creative-trigger-reference-answer {
    font-size: 1.6rem;
    color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[7]};
  }

  .creative-trigger-link {
  }

  .read-creative-trigger-heading-4 {
    margin-bottom: 3rem;
  }

  .confirm-delete-title {
    font-size: 2.4rem;
  }

  .confirm-delete-button-group {
    display: flex;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }

  .creative-projects-published-none {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    color: ${(props) => props.theme.pallete.colors.common.black};
    font-size: 1.4rem;
    font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
    z-index: -1;
    position: relative;
  }
`;

export const ColorBox = styled.div<IColorBoxProps>`
  background-color: ${(props) => props.backgroundColor};
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
`;

export const PublishedProjects = styled.span<IPublishedProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.backgroundColor};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.pallete.colors.common.black};
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
  user-select: none;
  cursor: pointer;

  &:active {
    transform: translate(-50%, -50%), scale(0.95);
  }
`;
