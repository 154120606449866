import { api } from './api.axios';
import {
  IAddUserCreditsRequest,
  IAdminMessageResponse,
  ICreateUserResponse,
  IListEmailPendingResponse,
  IListUserRequest,
  IListUserResponse,
  IShowUserResponse,
  IUpdateUserRequest,
} from './admin.types';

export const showUserService = async (
  email: string
): Promise<IShowUserResponse> => {
  const response = await api.get<IShowUserResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/users/${email}`
  );
  return response.data;
};

export const listUserService = async ({
  order,
  orderBy,
  page,
  perPage,
  role,
  withSubUser,
  find,
  isActive,
}: IListUserRequest): Promise<IListUserResponse> => {
  const orderParameter = `order=${order}`;
  const orderByParameter = `&orderBy=${orderBy}`;
  const pageParameter = `&page=${page}`;
  const perPageParameter = `&perPage=${perPage}`;
  const roleParameter = `&role=${role}`;
  const withSubUserParameter = `&withSubUser=${withSubUser}`;
  const findParameter = `&find=${find}`;
  const isActiveParameter = `&isActive=${isActive}`;

  const response = await api.get<IListUserResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/users?${orderParameter}${
      !!orderBy ? orderByParameter : ''
    }${!!page ? pageParameter : ''}${!!perPage ? perPageParameter : ''}${
      !!role ? roleParameter : ''
    }${!!withSubUser ? withSubUserParameter : ''}${
      !!find ? findParameter : ''
    }${typeof isActive === 'boolean' ? isActiveParameter : ''}
    `
  );

  return response.data;
};

export const createUserService = async ({
  email,
  password,
  passwordConfirm,
  role,
  username,
}: ICreateUserResponse): Promise<IAdminMessageResponse> => {
  const response = await api.post<IAdminMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/users`,
    {
      email,
      password,
      passwordConfirm,
      role,
      username,
    }
  );
  return response.data;
};

export const updateUserService = async ({
  isActive,
  role,
  email,
}: IUpdateUserRequest): Promise<IAdminMessageResponse> => {
  const response = await api.put<IAdminMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/users/${email}`,
    {
      isActive,
      role,
    }
  );
  return response.data;
};

export const resendEmailConfirmationService = async (
  email: string
): Promise<IAdminMessageResponse> => {
  const response = await api.post<IAdminMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/users/registry-resend-mail`,
    {
      email,
    }
  );
  return response.data;
};

export const cancelEmailConfirmationService = async (
  email: string
): Promise<IAdminMessageResponse> => {
  const response = await api.delete<IAdminMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/users/registry-resend-mail`,
    {
      data: {
        email,
      },
    }
  );
  return response.data;
};

export const addUserCreditsService = async ({
  userId,
  value,
}: IAddUserCreditsRequest): Promise<IAdminMessageResponse> => {
  const response = await api.post<IAdminMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/users/credits`,
    {
      userId,
      value,
    }
  );
  return response.data;
};

export const removeUserCreditsService = async ({
  userId,
  value,
}: IAddUserCreditsRequest): Promise<IAdminMessageResponse> => {
  const response = await api.delete<IAdminMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/users/credits`,
    {
      data: {
        userId,
        value,
      },
    }
  );
  return response.data;
};

export const listEmailPendingService =
  async (): Promise<IListEmailPendingResponse> => {
    const response = await api.get<IListEmailPendingResponse>(
      `${process.env.REACT_APP_URL_API_BASE}/admin/users/email-pending`
    );
    return response.data;
  };
