import { api } from './api.axios';
import {
  ICreateCreativeChallengeRequest,
  IListCreativeChallenge,
  IListCreativeChallengeRequest,
  ICreativeChallengeMessageResponse,
  IShowCreativeChallenge,
  IUpdateCreativeChallengeRequest,
} from './creativeChallenge.types';

export const showCreativeChallengeService = async (
  id: string
): Promise<IShowCreativeChallenge> => {
  const response = await api.get<IShowCreativeChallenge>(
    `${process.env.REACT_APP_URL_API_BASE}/creative-challenge/${id}`
  );

  return response.data;
};

export const listCreativeChallengeService = async ({
  order,
  isActive,
  orderBy,
  page,
  perPage,
  tag,
}: IListCreativeChallengeRequest): Promise<IListCreativeChallenge> => {
  const orderParameter = `order=${order}`;
  const isActiveParameter = `&isActive=${isActive || undefined}`;
  const orderByParameter = `&orderBy=${orderBy}`;
  const pageParameter = `&page=${page || undefined}`;
  const perPageParameter = `&perPage=${perPage || undefined}`;
  const tagParameter = `&tag=${tag || undefined}`;

  const response = await api.get<IListCreativeChallenge>(
    `${
      process.env.REACT_APP_URL_API_BASE
    }/creative-challenge?${orderParameter}${orderByParameter}${
      typeof isActive === 'boolean' ? isActiveParameter : ''
    }${!!page ? pageParameter : ''}${!!perPage ? perPageParameter : ''}${
      !!tag ? tagParameter : ''
    }`
  );

  return response.data;
};

export const createCreativeChallengeService = async ({
  label,
  award,
  link,
  tag,
}: ICreateCreativeChallengeRequest): Promise<ICreativeChallengeMessageResponse> => {
  const sendData = {
    label,
    award,
    link,
    tag,
  };
  const response = await api.post<ICreativeChallengeMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/creative-challenge`,
    sendData
  );

  return response.data;
};

export const updateCreativeChallengeService = async ({
  label,
  award,
  link,
  tag,
  id,
  isActive,
}: IUpdateCreativeChallengeRequest): Promise<ICreativeChallengeMessageResponse> => {
  const sendParametersData = {
    label,
    award,
    link,
    tag,
    isActive,
    id,
  };
  const response = await api.put<ICreativeChallengeMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/creative-challenge/${id}`,
    sendParametersData
  );

  return response.data;
};

export const deleteCreativeChallengeService = async (
  id: string
): Promise<ICreativeChallengeMessageResponse> => {
  const response = await api.delete<ICreativeChallengeMessageResponse>(
    `${process.env.REACT_APP_URL_API_BASE}/admin/creative-challenge/${id}`
  );

  return response.data;
};
