import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AuthContext from '../../contexts/AuthContext';
import ToastfyContext from '../../contexts/ToastfyContext';
import { createCreativeProjectService } from '../../services/creativeProjects.service';
import Button from '../Button';
import FormCheckbox from '../checkboxes/FormCheckbox';
import Textfield from '../inputs/Textfield';
import Upload from '../inputs/Upload';
import LightboxWithoutLogin from '../lightboxes/LightboxWithoutLogin';
import Spinner from '../loadings/Spinner';
import Megaphone from '../svgs/Megaphone';
import { ShowYourIdeaSectionContainer } from './styles';

interface IInputCreativeProjectFields {
  creators: string;
  socialMedia: string;
  portfolio: string;
  title: string;
  image: File[];
  confirmProject: boolean;
}

const ShowYourIdeaSection: React.FC = () => {
  const [image, setImage] = useState<File>();
  const [isSendProject, setIsSendProject] = useState<boolean>(false);
  const [isConfirmedForm, setIsConfirmedForm] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<IInputCreativeProjectFields>({
    defaultValues: {
      creators: '',
      socialMedia: '',
      portfolio: '',
      title: '',
      image: undefined,
    },
  });
  const [isSignPlanModal, setIsSignPlanModal] = useState<boolean>(false);
  const { signOut, isAuthenticated } = useContext(AuthContext);
  const { handleToastfy } = useContext(ToastfyContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const onSubmit: SubmitHandler<IInputCreativeProjectFields> = async (data) => {
    if (isAuthenticated) {
      setIsSendProject((prev) => !prev);
      let formData = new FormData();

      const dataSend = {
        creators: data.creators,
        socialMedia: data.socialMedia,
        portfolio: data.portfolio,
        title: data.title,
      };

      formData.append('data', JSON.stringify(dataSend));
      !!image && formData.append('file', image);

      await createCreativeProjectService(formData)
        .then((response) => {
          setIsSendProject((prev) => !prev);
          setImage(undefined);
          reset();
          handleToastfy({
            message: 'Projeto enviado com sucesso!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          setIsSendProject((prev) => !prev);
        });
    } else {
      setIsSignPlanModal((prev) => !prev);
    }
  };

  const handleFiles = (file: File[]) => {
    setImage(file[0]);
    clearErrors('image');
  };

  const validateFileSize = (file: File) => {
    if (file.size > 2000000) {
      //2mb
      return {
        code: 'size-too-large',
        message: 'O arquivo é maior que 800 Kbytes',
      };
    }

    return null;
  };

  return (
    <ShowYourIdeaSectionContainer>
      {isSendProject ? (
        <div className="send-creative-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <div className="form-idea">
          <div className="form-idea-title-box">
            <h2 className="form-idea-heading-2">
              Queremos ver como ficou sua ideia!
            </h2>
          </div>
          <p className="form-idea-paragraph">
            Envie seu projeto para entrar no nosso mural e divulgar seu
            trabalho.
          </p>
          <form className="form-idea-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-idea-form-input">
              <Textfield
                label="Criador(a) ou Criadores"
                labelColor={theme.pallete.colors.common.black}
                type="text"
                placeholder="Escreva os nomes"
                error={errors.creators?.message}
                {...register('creators', {
                  required: 'O campo é requerido.',
                })}
              />
            </div>
            <div className="form-idea-form-input">
              <Textfield
                label="Principal rede social"
                labelColor={theme.pallete.colors.common.black}
                type="text"
                placeholder="Insira o link"
                error={errors.socialMedia?.message}
                {...register('socialMedia', {
                  required: 'O campo é requerido.',
                })}
              />
            </div>
            <div className="form-idea-form-input">
              <Textfield
                label="Portfólio"
                labelColor={theme.pallete.colors.common.black}
                type="text"
                placeholder="Insira o link"
                error={errors.portfolio?.message}
                {...register('portfolio', {
                  required: 'O campo é requerido.',
                })}
              />
            </div>
            <div className="form-idea-form-input">
              <Textfield
                label="Título da criação"
                labelColor={theme.pallete.colors.common.black}
                type="text"
                placeholder="Esqueva aqui"
                error={errors.title?.message}
                {...register('title', {
                  required: 'O campo é requerido.',
                })}
              />
            </div>
            <div className="form-idea-form-input">
              <Upload
                label="Envie seu arquivo"
                placeholder="Selecione sua imagem..."
                labelColor={theme.pallete.colors.common.black}
                validator={validateFileSize}
                acceptTypeFiles={{
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpeg'],
                }}
                value={image?.name || undefined}
                handleCallbackArchive={(file) => handleFiles(file)}
                {...register('image', {
                  required: 'O campo é requerido.',
                })}
                error={errors.image?.message}
              />
            </div>
            <div className="form-idea-bottom">
              <div className="form-idea-checkbox-container">
                <FormCheckbox
                  isActive={isConfirmedForm}
                  {...register('confirmProject', {
                    required: 'É necessário confirmar.',
                  })}
                  error={errors.confirmProject?.message}
                  onClick={() => setIsConfirmedForm((prev) => !prev)}
                >
                  <span className="form-idea-checkbox-text">
                    Eu concordo que o arquivo enviado é de minha autoria e
                    autorizo a divulgação dele na plataforma{' '}
                    <strong>Criativistas</strong>.
                  </span>
                </FormCheckbox>
              </div>
              <Button
                variant="contained"
                className="button"
                buttonText="Enviar"
                buttonSize={160}
                customColors={{
                  backgroundColor: theme.pallete.colors.common.black,
                  hoverBackgroundColor:
                    theme.pallete.colors.brandPrimary.gray[9],
                  hoverTextColor: theme.pallete.colors.common.white,
                  textColor: theme.pallete.colors.common.white,
                }}
                type="submit"
                isLoading={isSendProject}
              />
            </div>
          </form>
        </div>
      )}
      {isSignPlanModal && (
        <LightboxWithoutLogin
          handleOpenModal={() => setIsSignPlanModal((prev) => !prev)}
          image={<Megaphone />}
        >
          <h3>Cadastre-se para usar nossas ferramentas</h3>
          <p>
            Para utilizar nossas ferramentas criativas, você precisa ter uma
            conta na nossa plataforma. Faça agora mesmo seu cadastro e comece a
            potencializar seu processo criativo.
          </p>
          <Button
            buttonText="Fazer cadastro gratuito"
            variant="contained"
            type="button"
            className="whithout-login-button"
            onClick={() => navigate('/sign-up')}
            customColors={{
              backgroundColor: theme.pallete.colors.common.white,
              hoverBackgroundColor: theme.pallete.colors.common.white,
              hoverTextColor: theme.pallete.colors.common.black,
              textColor: theme.pallete.colors.common.black,
            }}
          />
        </LightboxWithoutLogin>
      )}
    </ShowYourIdeaSectionContainer>
  );
};

export default ShowYourIdeaSection;
