import React from 'react';
import { StatusUserSmallContainer, IStatusUserSmallProps } from './styles';

const StatusUserSmall: React.FC<IStatusUserSmallProps> = ({ type }) => {
  const handleTypeToText = {
    gratuito: 'Plano Gratuito',
    criativista: 'Plano Criativista',
  };

  return (
    <StatusUserSmallContainer type={type}>
      {handleTypeToText[type]}
    </StatusUserSmallContainer>
  );
};

export default StatusUserSmall;
