import styled from 'styled-components';

export const SendMessagesContainer = styled.div`
  .brainstorming-create-room {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .brainstorming-create-room-button-group {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;

    @media ${(props) => props.theme.medias.mobile} {
      flex-direction: column-reverse;
    }
  }

  .room-button-write-contribution {
    margin: 0 auto;
  }

  .brainstorming-create-room-images {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    position: relative;

    @media ${(props) => props.theme.medias.mobile} {
      justify-content: center;
    }
  }

  .brainstorming-create-room-image-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    width: 26rem;
    height: 26rem;
  }

  .brainstorming-create-room-image {
    width: 100%;
    max-width: 26rem;
    height: 26rem;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 1rem;
    cursor: pointer;

    overflow: hidden;
  }

  .insights-card-image {
    width: 100%;
    height: 26rem;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
    cursor: pointer;
  }

  .brainstorming-create-room-image:hover .insights-card-image-background {
    opacity: 1;
  }

  .insights-card-image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;

    span {
      text-align: center;
      font-size: 2.4rem;
      font-weight: ${(props) => props.theme.typeFaceWeight.semiBold};
      color: ${(props) => props.theme.pallete.colors.common.white};
    }
  }

  .brainstorming-create-room-image-icon {
    svg {
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.95);
      }
    }
  }
`;
