import React, { useState, useContext } from 'react';
import {
  DashboardTabsContainer,
  DashboardTab,
  DashboardTabContent,
} from './styles';
import Logo from '../../../../../components/svgs/Logo';
import CreativeTrigger from '../../../../../components/svgs/CrativeTrigger';
import BraimstormingRoom from '../../../../../components/svgs/BraimstormingRoom';
import CreativeChallenge from '../../../../../components/svgs/CreativeChallenge';
import ThermometerSVG from '../../../../../components/svgs/ThermometerSVG';
import Button from '../../../../../components/Button';
import { useTheme } from 'styled-components';
import AuthContext from '../../../../../contexts/AuthContext';

const DashboardTabs: React.FC = () => {
  const [tabActive, setTabActive] = useState<number>(1);
  const theme = useTheme();
  const { user } = useContext(AuthContext);

  const handleDashboardTabContent = {
    1: (
      <DashboardTabContent backgroundImageLink="/static/images/banner-home/image-banner-2.png">
        <div className="dashboard-tabs-background" />
        <div className="dashboard-tabs-content-texts">
          <h3 className="dashboard-tabs-content-heading-3">
            Use nosso Baralho de Gatilhos Criativos para gerar ideias mais
            originais
          </h3>
          <p className="dashboard-tabs-content-paragraph">
            Os Gatilhos Criativos ajudam a ter insights menos comuns, as
            referências ajudam a ter mais ideias e a IA ajuda a dobrar a
            quantidade de ideias para o seu desafio.
          </p>
          <Button
            buttonText="Gerar Gatilho Criativo"
            variant="contained"
            customColors={{
              backgroundColor: theme.pallete.colors.common.black,
              hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
              hoverTextColor: theme.pallete.colors.common.white,
              textColor: theme.pallete.colors.common.white,
            }}
          />
        </div>
      </DashboardTabContent>
    ),
    2: (
      <DashboardTabContent backgroundImageLink="/static/images/banner-home/image-banner-3.png">
        <div className="dashboard-tabs-background" />
        <div className="dashboard-tabs-content-texts">
          <h3 className="dashboard-tabs-content-heading-3">
            Precisa de uma forcinha?
          </h3>
          <p className="dashboard-tabs-content-paragraph">
            Sinta-se livre para pedir dicas, referências, feedbacks e ideias. E
            não esqueça de contribuir ajudando outros criativos.
          </p>
          <Button
            buttonText="Participar das Salas"
            variant="contained"
            customColors={{
              backgroundColor: theme.pallete.colors.common.black,
              hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
              hoverTextColor: theme.pallete.colors.common.white,
              textColor: theme.pallete.colors.common.white,
            }}
          />
        </div>
      </DashboardTabContent>
    ),
    3: (
      <DashboardTabContent backgroundImageLink="/static/images/banner-home/image-banner-4.png">
        <div className="dashboard-tabs-background" />
        <div className="dashboard-tabs-content-texts">
          <h3 className="dashboard-tabs-content-heading-3">
            Tenha ideias com propósito a partir de briefings com desafios reais
          </h3>
          <p className="dashboard-tabs-content-paragraph">
            A gente te inspira a criar e você inspira o mundo. Sua ideia pode
            ajudar a transformar realidade e ainda potencializar seu portfólio.
          </p>
          <Button
            buttonText="EM BREVE!"
            variant="contained"
            buttonSize={180}
            customColors={{
              backgroundColor: theme.pallete.colors.brandPrimary.gray[6],
              hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
              hoverTextColor: theme.pallete.colors.common.white,
              textColor: theme.pallete.colors.common.white,
            }}
          />
        </div>
      </DashboardTabContent>
    ),
    4: (
      <DashboardTabContent backgroundImageLink="/static/images/banner-home/image-banner-5.gif">
        <div className="dashboard-tabs-background dashboard-tabs-background--box-shadow" />
        <div className="dashboard-tabs-content-texts">
          <h3 className="dashboard-tabs-content-heading-3">
            Quer saber a temperatura da sua ideia?
          </h3>
          <p className="dashboard-tabs-content-paragraph">
            Com o Termômetro de Ideias você vai poder descobrir se a sua ideia
            está no caminho certo e receber feedbacks.
          </p>
          <Button
            buttonText="Descubra agora"
            variant="contained"
            buttonSize={180}
            customColors={{
              backgroundColor: theme.pallete.colors.brandPrimary.gray[6],
              hoverBackgroundColor: theme.pallete.colors.brandPrimary.gray[9],
              hoverTextColor: theme.pallete.colors.common.white,
              textColor: theme.pallete.colors.common.white,
            }}
          />
        </div>
      </DashboardTabContent>
    ),
  };

  return (
    <DashboardTabsContainer>
      <div className="dashboard-tabs-container">
        <div className="dashboard-tabs-credits-box">
          <span className="dashboard-tabs-you-have">Você tem:</span>
          <Logo />
          <span className="dashboard-tabs-credits">
            {!!user?.balance ? user?.balance : 0}&nbsp;créditos
          </span>
        </div>
        <div className="tabs">
          <DashboardTab
            onClick={() => setTabActive(1)}
            isActive={tabActive === 1}
          >
            <CreativeTrigger className="tab-svg" />
            <span className="tab-text">Gatilhos Criativos</span>
          </DashboardTab>
          <DashboardTab
            onClick={() => setTabActive(2)}
            isActive={tabActive === 2}
          >
            <BraimstormingRoom className="tab-svg" />
            <span className="tab-text">Salas de Brainstorming</span>
          </DashboardTab>
          <DashboardTab
            onClick={() => setTabActive(3)}
            isActive={tabActive === 3}
          >
            <CreativeChallenge className="tab-svg" />
            <span className="tab-text">Desafios Criativistas</span>
          </DashboardTab>
          <DashboardTab
            onClick={() => setTabActive(4)}
            isActive={tabActive === 4}
          >
            <ThermometerSVG className="tab-svg" />
            <span className="tab-text">Termômetro de Ideias</span>
          </DashboardTab>
        </div>
      </div>
      {
        handleDashboardTabContent[
          tabActive as keyof typeof handleDashboardTabContent
        ]
      }
    </DashboardTabsContainer>
  );
};

export default DashboardTabs;
