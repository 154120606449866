import React from 'react';
import { LightboxCloseButtonOutContainer } from './styles';
import { MdOutlineClose } from 'react-icons/md';

interface IProps {
  children: React.ReactNode;
  handleOpenModal: () => void;
}

const LightboxCloseButtonOut: React.FC<IProps> = ({
  children,
  handleOpenModal,
}) => {
  return (
    <LightboxCloseButtonOutContainer>
      <div className="light-box-content">
        <div className="light-box-children">{children}</div>
        <span className="icon-box" onClick={handleOpenModal}>
          <MdOutlineClose size={36} />
        </span>
      </div>
      <div className="light-box-background" />
    </LightboxCloseButtonOutContainer>
  );
};

export default LightboxCloseButtonOut;
