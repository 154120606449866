import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AuthContext from '../../../contexts/AuthContext';
import ToastfyContext from '../../../contexts/ToastfyContext';
import Button from '../../Button';
import SeparateLine from '../../SeparateLine';
import Textfield from '../../inputs/Textfield';
import LightBox from '../LightBox';
import { LightboxTagContainer } from './styles';
import {
  createCreativeChallengeTagService,
  showCreativeChallengeTagService,
  updateCreativeChallengeTagService,
} from '../../../services/creativeChallengeTag.service';
import TextArea from '../../inputs/TextArea';
import Select from '../../inputs/Select';
// import {
//   createTagService,
//   listTagService,
//   updateTagService,
// } from '../../../services/tags.service';

interface IInputFields {
  label: string;
  description: string;
}

interface IProps {
  handleOpenModal: () => void;
  setIsTagOpen: Dispatch<SetStateAction<boolean>>;
  id?: string;
}

const LightboxTag: React.FC<IProps> = ({
  handleOpenModal,
  setIsTagOpen,
  id,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IInputFields>();
  const [isRegisterTag, setIsRegisterTag] = useState<boolean>(false);
  const [isEditParam, setIsEditParam] = useState<boolean>(false);
  const [idTag, setIdTag] = useState<string>('');
  const [statusSelected, setStatusSelected] = useState<string>('true');
  const theme = useTheme();
  const { handleToastfy } = useContext(ToastfyContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const getData = useCallback(async () => {
    if (id) {
      await showCreativeChallengeTagService(id)
        .then((response) => {
          setIsEditParam(true);
          setIdTag(response.data._id);
          setValue('label', response.data.label);
          setValue('description', response.data.description);
          setStatusSelected(response.data.isActive ? 'true' : 'false');
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          // setIsRegisterInternParam((prev) => !prev);
          console.log(error.response.data);
        });
    }
  }, [handleToastfy, id, navigate, setValue, signOut]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit: SubmitHandler<IInputFields> = async (data) => {
    setIsRegisterTag((prev) => !prev);

    if (isEditParam) {
      await updateCreativeChallengeTagService({
        label: data.label,
        description: data.description,
        id: idTag,
        isActive: statusSelected === 'true' ? true : false,
      })
        .then((response) => {
          handleToastfy({
            message: 'Tag editada com sucesso!',
            type: 'success',
          });
          setIsRegisterTag((prev) => !prev);
          setIsTagOpen(false);
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          setIsRegisterTag((prev) => !prev);
          console.log(error.response.data);
        });
    } else {
      await createCreativeChallengeTagService({
        label: data.label,
        description: data.description,
        // textIa: data.textIa,
      })
        .then((response) => {
          handleToastfy({
            message: 'Nova Tag cadastrada com sucesso!',
            type: 'success',
          });
          setIsRegisterTag((prev) => !prev);
          setIsTagOpen(false);
        })
        .catch((error) => {
          if (
            error.code === 'EXPIRED_TOKEN' ||
            error.code === 'TOKEN_REVOKED' ||
            error.code === 'TOKEN_NOT_FOUND'
          ) {
            handleToastfy({
              message: 'Sessão expirada!',
              type: 'error',
            });
            signOut();
            navigate('/sign-in');
          } else {
            handleToastfy({
              message:
                !!error.message || error.code
                  ? error.message || error.code
                  : 'Ocorreu um erro ao processar a requisição.',
              type: 'error',
            });
          }
          setIsRegisterTag((prev) => !prev);
          console.log(error.response.data);
        });
    }
  };

  return (
    <LightBox handleOpenModal={handleOpenModal}>
      <LightboxTagContainer>
        <h3>
          Desafios Criativista - {isEditParam ? 'Editar' : 'Adicionar'} TAG
        </h3>
        <SeparateLine
          margin="2.4rem 0"
          color={theme.pallete.colors.brandPrimary.gray[2]}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Textfield
              label="TAG"
              type="text"
              placeholder=""
              error={errors.label?.message}
              {...register('label', {
                required: 'O campo é requerido.',
              })}
            />
            <Select
              textLabel="Status"
              options={[
                {
                  optionName: 'Ativo',
                  optionValue: 'true',
                },
                {
                  optionName: 'Inativo',
                  optionValue: 'false',
                },
              ]}
              value={statusSelected}
              onChange={(e) => {
                setStatusSelected(e.target.value);
              }}
              disabled={!isEditParam}
            />
          </div>

          <TextArea
            label="Descrição"
            type="text"
            placeholder=""
            error={errors.description?.message}
            {...register('description', {
              required: 'O campo é requerido.',
            })}
            maxCharacter={300}
            customCountCharacters={watch(`description`)?.length}
          />
          <Button
            buttonText="Salvar"
            variant="contained"
            buttonSize={160}
            className="lightbox-intern-param-button"
            isLoading={isRegisterTag}
          />
        </form>
      </LightboxTagContainer>
    </LightBox>
  );
};

export default LightboxTag;
