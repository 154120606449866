import React, { useCallback, useEffect, useState, useContext } from 'react';
import ToastfyContext from '../../contexts/ToastfyContext';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { VscError } from 'react-icons/vsc';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import InstagramBanner from '../Home/InstagramBanner';
import { emailConfirmService } from '../../services/user.service';
import Spinner from '../../components/loadings/Spinner';
import { EmailValidationContainer } from './styles';

const EmailValidation: React.FC = () => {
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(true);
  const [isConfirmed, setIsConfimed] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleToastfy } = useContext(ToastfyContext);

  const emailConfirm = useCallback(
    async (token: string) => {
      await emailConfirmService(token)
        .then((response) => {
          setIsConfimed(true);
          handleToastfy({ message: response.message, type: 'success' });
          setIsLoginLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsConfimed(false);
          handleToastfy({
            message: error.response.data.message,
            type: 'error',
          });
          setIsLoginLoading(false);
        });
    },
    [handleToastfy]
  );

  useEffect(() => {
    const getParamsToken = searchParams.get('token') || '';

    if (!getParamsToken) {
      navigate('/sign-in');
    } else if (getParamsToken?.length > 55 && getParamsToken?.length < 65) {
      emailConfirm(getParamsToken);
    } else {
      setIsConfimed(false);
      setIsLoginLoading(false);
    }
  }, [searchParams, emailConfirm, navigate]);

  return (
    <EmailValidationContainer>
      {isLoginLoading ? (
        <div className="email-verification-spinner">
          <Spinner size={100} />
        </div>
      ) : (
        <section className="content">
          <span className="lamp-idea" />
          <div>
            {isConfirmed ? (
              <React.Fragment>
                <h2 className="heading-2">E-mail confirmado com sucesso!</h2>
                <div className="icon-box">
                  <AiOutlineCheckCircle
                    size={56}
                    color={theme.pallete.colors.success.main}
                  />
                </div>
                <p className="subtitle">
                  <Link to="/sign-in">
                    <span className="link">Clique aqui</span>
                  </Link>
                  &nbsp;para começar a criar ideias, soluções e maluquices
                  também.
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h2 className="heading-2">E-mail não confirmado!</h2>
                <div className="icon-box">
                  <VscError size={56} color={theme.pallete.colors.error.main} />
                </div>
                <p className="subtitle">
                  Poxa parece que seu e-mail não foi confirmado,&nbsp;
                  <Link to="/esqueci-minha-senha">
                    <span className="link">clique aqui</span>
                  </Link>
                  &nbsp;para solicitar uma nova senha.
                </p>
              </React.Fragment>
            )}
          </div>
        </section>
      )}
      <InstagramBanner />
    </EmailValidationContainer>
  );
};

export default EmailValidation;
