import styled from 'styled-components';

export interface IInsightFirstCardProps {
  backgroundColor: string;
}

export interface IColorButtonProps {
  isActive: boolean;
}

export const CreativeChallengesStyled = styled.div`
  .creative-challenges-header {
    display: flex;
    position: relative;
    padding: 5rem 2rem 0rem 2rem;
    align-items: center;
    background-color: ${(props) => props.theme.pallete.colors.common.white};

    @media ${(props) => props.theme.medias.laptopSmall} {
      margin-top: 3rem;
      padding-bottom: 0;
      flex-direction: column;
    }

    .creative-challenges-header-text {
      max-width: 500px;

      @media ${(props) => props.theme.medias.laptopSmall} {
        max-width: 300px;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin-bottom: 9.6rem;
        margin-top: 2rem;
      }

      @media ${(props) => props.theme.medias.tablet} {
        margin-top: 5rem;
      }
    }

    /* ${(props) => props.theme.mixins.containerGridDefault} */
    .box-image {
      height: fit-content;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
      border-radius: 2rem;
      width: 50%;
      height: 60vh;
      /* margin: 3rem; */

      @media ${(props) => props.theme.medias.tablet} {
        /* background-position: center;
        margin: 0 auto;
        height: inherit; */
        /* margin-bottom: -5rem; */
        height: inherit;
      }

      &--1 {
        background-image: url('/static/images/header-creative-challenges.png');
      }
    }
  }

  .creative-challenges-section-1 {
    position: relative;
    /* margin: 2rem 0px; */
    display: grid;
    ${(props) => props.theme.mixins.containerGridDefault};
    gap: 3rem;

    @media ${(props) => props.theme.medias.tablet} {
      margin: 0rem 0px;
      padding: 4rem;
    }

    .creative-container-reason-create {
      display: flex;
      align-items: center;
      grid-column: center-start / center-end;
      gap: 3rem;

      @media ${(props) => props.theme.medias.tablet} {
        flex-direction: column;
      }

      span {
        color: #1c1b1f;
        font-family: Inter;
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 28px */
      }

      p {
        color: var(--Vem-ser-criativista-Gray01, #1c1b1f);
        font-family: Inter;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
    }

    .creative-container-reason-create:last-child {
      margin-top: -20rem;

      @media ${(props) => props.theme.medias.tablet} {
        flex-direction: column-reverse;
        margin-top: 0;
      }
    }

    .box-image {
      height: fit-content;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 2rem;
      width: 40%;
      height: 45vh;
      /* margin: 3rem; */

      @media ${(props) => props.theme.medias.laptopSmall} {
        /* background-position: center;
        margin: 0 auto;
        height: inherit; */
      }

      &--1 {
        /* background-size: auto; */
        background-position: center right;
        background-image: url('/static/images/collaboration-cuate.png');

        @media ${(props) => props.theme.medias.laptopSmall} {
          background-size: contain;
        }

        @media ${(props) => props.theme.medias.tablet} {
          height: 30vh;
          background-size: cover;
          width: 100%;
          margin-top: -2rem;
          background-position: center center;
        }

        @media ${(props) => props.theme.medias.mobile} {
          margin-top: -5rem;
        }
      }
      &--2 {
        background-position: center left;
        background-image: url('/static/images/discussion-cuate.png');
      }

      @media ${(props) => props.theme.medias.tablet} {
        height: 30vh;
        background-size: cover;
        width: 70%;
        /* margin-top: -4rem; */
        background-position: center center;
      }

      @media ${(props) => props.theme.medias.mobile} {
        margin-top: -10rem;
        margin-bottom: -8rem;
        background-size: contain;
      }
    }
  }

  .creative-challenges-section-2 {
    position: relative;
    margin: 7rem 0px;
    display: grid;
    ${(props) => props.theme.mixins.containerGridDefault};
    gap: 3rem;

    .creative-challenges-heading-3 {
      text-align: center;
      color: #000;

      text-align: center;
      font-family: Inter;
      font-size: 3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 58px;
    }
    .creative-challenges-filter {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      margin-bottom: 3rem;
    }

    .creative-challenges-heading-3,
    .creative-challenges-filter,
    .challenges {
      grid-column: center-start / center-end;
    }

    .challenges {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      flex-wrap: wrap;

      @media ${(props) => props.theme.medias.tablet} {
        justify-content: space-between;
        gap: 2rem;
      }

      a {
        text-decoration: none;
        width: 288px;
        height: 196px;
        @media ${(props) => props.theme.medias.tablet} {
          width: calc(50% - 2rem);
        }

        @media ${(props) => props.theme.medias.mobile} {
          width: 100%;
        }
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 100px 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10.135px;

        border-radius: 20px;
        background: var(--Vem-ser-criativista-Yellow02, #ffc148);
        cursor: pointer;
      }

      span {
        display: block;
        color: var(--Vem-ser-criativista-Black, #000);
        text-align: center;
        font-family: Inter;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
`;

export const ButtonFilter = styled.div<IColorButtonProps>`
  cursor: pointer;
  display: flex;
  height: 49px;
  padding: 20px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: ${(props) => (props.isActive ? '#1c1b1f' : '#d7d7d7')};

  color: ${(props) => (props.isActive ? '#ffffff' : '#1c1b1f')};
  text-align: center;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  &:hover {
    background: var(--Vem-ser-criativista-Gray01, #1c1b1f);
    color: #ffffff;
  }
`;
