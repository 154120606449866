import React, { InputHTMLAttributes, forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CheckboxSelectContainer } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

const CheckboxSelect: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IProps
> = ({ error, ...props }, ref) => {
  const idInput = uuidv4();

  return (
    <CheckboxSelectContainer>
      <input type="radio" id={idInput} {...props} ref={ref} />
      <label htmlFor={idInput} className="label-checkbox" />
    </CheckboxSelectContainer>
  );
};

export default forwardRef(CheckboxSelect);
