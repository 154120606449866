import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import ReactPlayer from '../../../components/ReactPlayer';
import { VideoSectionStyled } from './styles';

const VideoSection: React.FC = () => {
  const navigate = useNavigate();

  return (
    <VideoSectionStyled>
      <div className="content content--1" id="content-video-1">
        <div className="video">
          <ReactPlayer
            imagePreviewURL="/static/images/creative-home.jpg"
            videoURL="/static/videos/how-to-use-creative-triggers.mp4"
          />
        </div>
        <div className="video-section-texts">
          <h2 className="video-section-heading-3">
            A união dos Gatilhos Criativos com inteligência artificial eleva o
            nível das suas ideias.
          </h2>
          <p className="video-section-paragraph">
            A Criativistas integrou inteligência artificial aos Gatilhos
            Criativos, trabalhando parâmetros únicos e diferenciados para que as
            respostas da IA sejam cada vez mais criativas e originais.
          </p>
          <Button
            buttonText="Experimente agora"
            variant="contained"
            type="button"
            onClick={() => navigate('/sign-up')}
          />
        </div>
      </div>
      <div className="content content--2" id="content-video-2">
        <div className="video-section-texts">
          <h2 className="video-section-heading-3">
            A nossa comunidade de criativos te espera nas Salas de Brainstorming
          </h2>
          <p className="video-section-paragraph">
            Nossas salas virtuais de brainstorming abrem espaço para discutir
            ideias, trocar referências, pedir feedbacks e participar de
            discussões sobre os mais variados desafios, aumentando ainda mais a
            sua bagagem criativa.
          </p>
          <Button
            buttonText="Faça parte da comunidade"
            variant="contained"
            type="button"
            onClick={() => navigate('/sign-up')}
          />
        </div>
        <div className="video">
          <ReactPlayer
            imagePreviewURL="/static/images/brainstorming-rooms.jpg"
            videoURL="/static/videos/how-to-use-brainstorming-rooms.mp4"
            className="video"
          />
        </div>
      </div>
    </VideoSectionStyled>
  );
};

export default VideoSection;
