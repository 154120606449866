import React, { Dispatch, SetStateAction } from 'react';
import { ImagePreviewContainer } from './styles';
import { MdOutlineClose } from 'react-icons/md';

interface IProps {
  linkImage: string;
  setIsOpenImage: Dispatch<SetStateAction<boolean>>;
}

const ImagePreview: React.FC<IProps> = ({ linkImage, setIsOpenImage }) => {
  return (
    <ImagePreviewContainer>
      <div className="image-preview-content">
        <div className="image-preview-children">
          <img src={linkImage} alt={linkImage} />
          <span
            className="image-preview-icon-box"
            onClick={() => setIsOpenImage((prev) => !prev)}
          >
            <MdOutlineClose size={36} />
          </span>
        </div>
      </div>
      <div className="image-preview-background" />
    </ImagePreviewContainer>
  );
};

export default ImagePreview;
