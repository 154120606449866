import styled from 'styled-components';

export const LightboxWithoutLoginContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  ${(props) => props.theme.mixins.containerGridDefault}

  .without-content {
    grid-column: center-start / center-end;
    position: relative;
    display: flex;
    width: 100%;
    max-width: 100%;
    min-height: 20rem;
    max-height: 85vh;
    background: ${(props) => props.theme.pallete.gradients.gradient01};
    border-radius: 2rem;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 12000;

    @media ${(props) => props.theme.medias.laptopSmall} {
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.pallete.colors.brandPrimary.gray[3]};
      border: 4px solid transparent;
      border-radius: 8px;
      background-clip: padding-box;
      padding: 1rem 0;
    }

    ::-webkit-scrollbar {
      width: 16px;
    }
  }

  .without-content-left {
    width: 100%;
    max-height: 40rem;
    display: flex;

    svg {
      max-height: 40rem;
      margin-top: auto;
      margin-bottom: auto;
    }

    @media ${(props) => props.theme.medias.laptopSmall} {
      display: none;
    }
  }

  .without-content-right {
    width: 100%;
    padding: 6rem 3rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.4rem;

    p {
      color: ${(props) => props.theme.pallete.colors.brandPrimary.gray[12]};
    }

    h3 {
      font-size: 2.4rem;
      color: ${(props) => props.theme.pallete.colors.common.black};
    }

    .whithout-login-button {
    }

    @media ${(props) => props.theme.medias.laptopSmall} {
      text-align: center;
      align-items: center;
    }
  }

  .without-icon-box {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    padding: 0.8rem;
    cursor: pointer;

    svg {
      color: ${(props) => props.theme.pallete.colors.common.black};
    }
  }

  .without-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11000;
  }
`;
